import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import './BackendValidationPopup.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    p: 4,
};

export default function BackendValidationPopup({ backendValidation, setBackendValidationModal, canIgnoreWarning }) {
    const [open, setOpen] = useState(true);

    const type = backendValidation?.type === 'warning' ? 'warning_msg' : 'err_msg'; 

    const handleClose = () => {
        setOpen(false);
        setBackendValidationModal(false);
    }

    const handleIgnore = () => {
        setOpen(false);
        setBackendValidationModal(false);
        canIgnoreWarning(true);
    }

    const renderHtmlText = (data) => {
        if(Array.isArray(data)){
            return data?.map((item, index) => {
                return <div className='modal-section-error'>
                    {item?.text && <div dangerouslySetInnerHTML={{ __html: item?.text + '' }} />}
                </div>
            })
        } else{ 
                return <div className='modal-section-error'>
                    {data?.text && <div dangerouslySetInnerHTML={{ __html: data?.text + '' }} />}
                </div>
            
        }
       
    }

  const renderArrayErrr = (data, header) => {
    return data?.map((questionDataItem, questionDataItemIndex) => {
      if (
        !data[questionDataItemIndex]?.[type] &&
        data[questionDataItemIndex] !== null &&
        typeof data[questionDataItemIndex] === "object"
      ) {
        return Object.keys(data[questionDataItemIndex])?.map(
          (questionData, index) => {
            if (
              data?.[questionDataItemIndex]?.[questionData]?.[type] &&
              typeof data?.[questionDataItemIndex]?.[questionData]?.[type] ===
                "string"
            ) {
              return (
                <div>
                  <span className="modal-section-title">
                    {data?.replaceAll("_", " ")} #{questionDataItemIndex + 1}:
                  </span>
                  <span className="modal-section-error">
                    {data[questionDataItemIndex][questionData]?.[type]}
                  </span>
                </div>
              );
            }
          }
        );
      } else if (
        data[questionDataItemIndex]?.[type] &&
        typeof data[questionDataItemIndex]?.[type] === "string"
      ) {
        return (
          <div>
            <span className="modal-section-title">
              {header.replaceAll("_", " ")} #{questionDataItemIndex + 1}:
            </span>
            <span className="modal-section-error">
              {data?.[questionDataItemIndex]?.[type]}
            </span>
          </div>
        );
      } else if (data[questionDataItemIndex]?.[type]) {
        return (
          <div>
            <span className="modal-section-title">
              {header.replaceAll("_", " ")} #{questionDataItemIndex + 1}:
            </span>
            {renderHtmlText(data[questionDataItemIndex]?.[type])}
          </div>
        );
      }
    });
  };

    const renderSummaryData = (section) => {
        return (
            Object.keys(backendValidation?.data?.[section]?.summary)?.map((data) => {
                if(Array.isArray(backendValidation?.data?.[section]?.summary?.[data])){
                    return  backendValidation?.data?.[section]?.summary?.[data]?.map((questionDataItem, questionDataItemIndex) => {
                        if (!backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]?.[type] &&
                            backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]!==null &&
                            typeof (backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]) === 'object'
                        ) {
                           return Object.keys(backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex])?.map((questionData, index) => {
                                if(backendValidation?.data?.[section]?.summary?.[data]?.[questionDataItemIndex]?.[questionData]?.[type] && typeof backendValidation?.data?.[section]?.summary?.[data]?.[questionDataItemIndex]?.[questionData]?.[type]==='string' ){
                                   return (<div>
                                        <span className='modal-section-title'>{data?.replaceAll('_', ' ')} #{questionDataItemIndex + 1}:</span>
                                        <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex][questionData]?.[type]}</span>
                                    </div>)
                                }
                            })
                        } else if (backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]?.[type] &&
                            typeof (backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]?.[type]) === 'string'
                        ) {
                            return (<div>
                                <span className='modal-section-title'>{data?.replaceAll('_', ' ')} #{questionDataItemIndex + 1}:</span>
                                <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[questionDataItemIndex]?.[type]}</span>
                            </div>)
                        } else if (backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]?.[type]) {
                            return (
                                <div>
                                    <span className='modal-section-title'>{data?.replaceAll('_', ' ')} #{questionDataItemIndex + 1}:</span>
                                    {
                                      renderHtmlText(backendValidation?.data?.[section]?.summary?.[data][questionDataItemIndex]?.[type])
                                    }
                                </div>)
                        }
                          })
                  } else if (backendValidation?.data?.[section]?.summary?.[data]?.[type] && typeof(backendValidation?.data?.[section]?.summary?.[data]?.[type]) === 'string') {
                    return (
                        <p>
                            <span className='modal-section-title'>{data?.replaceAll('_', ' ')}:</span>
                            <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[type]}</span>
                        </p>
                    )
                } else if (backendValidation?.data?.[section]?.summary?.[data]?.[type]) {
                    return (
                        <p>
                            <span className='modal-section-title'>{data?.replaceAll('_', ' ')}:</span>
                            {
                                renderHtmlText(backendValidation?.data?.[section]?.summary?.[data][type])
                            }
                       </p>
                    )
                } else if (typeof backendValidation?.data?.[section]?.summary?.[data] == 'object' &&  backendValidation?.data?.[section]?.summary?.[data] != null) {

                       return  Object.keys(backendValidation?.data?.[section]?.summary?.[data])?.map((item , itemIndex) => {

                        if (
                            Array.isArray(
                              backendValidation?.data?.[section]?.summary?.[data]?.[item]
                            )
                          ) {
                            return renderArrayErrr(
                              backendValidation?.data?.[section]?.summary?.[data]?.[item],
                              item
                            );
                          } else if(typeof(backendValidation?.data?.[section]?.summary?.[data]?.[item]) == 'object' && backendValidation?.data?.[section]?.summary?.[data]?.[item]!==null) {
                                        return  Object.keys(backendValidation?.data?.[section]?.summary?.[data]?.[item])?.map((element, elementIndex) => {
                                                 
                                                   if(typeof(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]) == 'object' && backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]!==null) {
                                                            return Object.keys(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element])?.map((elements, elementsIndex) => {
                                                                       if( backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements]?.[type] &&  typeof (backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements]?.[type]) === 'string') {
                                                                        return (<div>
                                                                            <span className='modal-section-title'>({item}) - {element?.replaceAll('_', ' ')} #{+elements + 1}:</span>
                                                                            <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements]?.[type]}</span>
                                                                        </div>)      
                                                                       }else if(typeof(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements]) ==='object' && backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements] !=null){
                                                                        return Object.keys(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements])?.map((subelements, subelementsIndex) => {
                                                                            if( backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements]?.[subelements]?.[type] &&  typeof (backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements]?.[subelements]?.[type]) === 'string') {
                                                                             return (<div>
                                                                                 <span className='modal-section-title'>({element}) - {elements?.replaceAll('_', ' ')} #{+subelements + 1}:</span>
                                                                                 <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[elements]?.[subelements]?.[type]}</span>
                                                                             </div>)      
                                                                            }
                                                                        })
                                                                       }
                                                            })
                                                   } else if(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[type] && backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[type] == 'string') {
                                                            return(
                                                                <p>
                                                                        <span className='modal-section-title'>{element?.replaceAll('_', ' ')}:</span>
                                                                        <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[type]}</span>
                                                                </p> 
                                                            )
                                                   } else if(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[type]) {
                                                    return (
                                                        <p>
                                                            <span className='modal-section-title'>{element?.replaceAll('_', ' ')}:</span>
                                                            {
                                                                renderHtmlText(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[element]?.[type])
                                                            }
                                                       </p>
                                                    )
                                                   }

                                        })


                                    }  else if(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[type] &&  typeof backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[type] == 'string') {
                                                    return(
                                                        <p>
                                                             <span className='modal-section-title'>{item?.replaceAll('_', ' ')}:</span>
                                                             <span className='modal-section-error'>{backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[type]}</span>
                                                        </p> 
                                                    )
                                    }  else if(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[type]) {
                                        return (
                                            <p>
                                                <span className='modal-section-title'>{item?.replaceAll('_', ' ')}:</span>
                                                {
                                                    renderHtmlText(backendValidation?.data?.[section]?.summary?.[data]?.[item]?.[type])
                                                }
                                           </p>
                                        )
                                    }
                       })
                                                                  
                                
                }
            })
        )

    }



    const renderQuestionData = (section) => {
        return (
            backendValidation?.data?.[section]?.questions?.map((questionData, index) => {
                let queErrorMessage = false;

                Object.keys(questionData)?.map((data) => {
                    if(Array.isArray(questionData?.[data])){
                        questionData?.[data]?.map((questionDataItem, questionDataItemIndex) => {
                            if (questionData?.[data][questionDataItemIndex]?.[type]) {
                                queErrorMessage = true;
                            }
                            })
                    }else{
                        if (questionData?.[data]?.[type]) {
                            queErrorMessage = true;
                      } else if(questionData?.[data]){    
                        Object.keys(questionData?.[data])?.map((quesDataItem, index) => {         
                          if(typeof(questionData?.[data]?.[quesDataItem]) === 'object' && questionData?.[data]?.[quesDataItem]?.[type]){
                            queErrorMessage = true ;
                          } else if (Array.isArray(questionData?.[data]?.[quesDataItem])){
                            questionData?.[data]?.[quesDataItem]?.map((questionDataSubItem, questionDataSubItemIndex) => {
                                Object.keys(questionData?.[data]?.[quesDataItem][questionDataSubItemIndex])?.map((questionDataChildItem) => {
                                    if (questionData?.[data]?.[quesDataItem][questionDataSubItemIndex][questionDataChildItem]?.[type]) {
                                        queErrorMessage = true;
                                    }
                                })
                                })
                          }

                        })
                        }
                    }
                   
                })

                if (queErrorMessage) {
                    return (
                        <p>
                            <div className='modal-section-question'>{`Question #${index + 1}`}:</div>
                            {
                                Object.keys(questionData)?.map((data) => {
                                    if(Array.isArray(questionData?.[data])){
                                      return  questionData?.[data]?.map((questionDataItem, questionDataItemIndex) => {
                                            if (questionData?.[data][questionDataItemIndex]?.[type]) {
                                                return (<div>
                                                    <span className='modal-section-title'>{data?.replaceAll('_', ' ')} #{questionDataItemIndex+1}:</span>
                                                    <span className='modal-section-error'>{questionData?.[data][questionDataItemIndex]?.[type]}</span>
                                                </div>)
                                            }
                                            })
                                    } else {
                                        if (questionData?.[data]?.[type]) {
                                        return (<div>
                                            <span className='modal-section-title'>{data?.replaceAll('_', ' ')}:</span>
                                            <span className='modal-section-error'>{questionData?.[data]?.[type]}</span>
                                        </div>)
                                    } else if(questionData?.[data]){
                                      return Object.keys(questionData?.[data])?.map((quesDataItem, index) => {
                                                           
                                        if(typeof(questionData?.[data]?.[quesDataItem]) === 'object' && questionData?.[data]?.[quesDataItem]?.[type]){
                                         return (<div>
                                            <span className='modal-section-title'>{quesDataItem}:</span>
                                            <span className='modal-section-error'>{questionData?.[data]?.[quesDataItem]?.[type]}</span>
                                        </div>)
                                        } else if (Array.isArray(questionData?.[data]?.[quesDataItem])) {
                                           return  questionData?.[data]?.[quesDataItem]?.map((questionDataSubItem, questionDataSubItemIndex) => {
                                                return Object.keys(questionData?.[data]?.[quesDataItem]?.[questionDataSubItemIndex])?.map((questionDataChildItem) => {
                                                    if (questionData?.[data]?.[quesDataItem][questionDataSubItemIndex][questionDataChildItem]?.[type]) {
                                                      return  <div>
                                                            <span className='modal-section-title'>{questionDataChildItem?.replaceAll('_', ' ')}:</span>
                                                            <span className='modal-section-error'>{questionData?.[data]?.[quesDataItem][questionDataSubItemIndex][questionDataChildItem]?.[type]}</span>
                                                        </div>
                                                    }
                                                })
                                            })
                                        }
                                      })
                  
                                    }
                                    }
                                   
                                })
                            }
                        </p>
                    )
                }
            }))
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='backend-validation-modal-container'>
                    <Box className="modal-heading">
                        <span>
                            <CancelIcon style={{ position: "relative", top: "-5px", marginRight: "10px", color: '#D85554' }} />
                            <span className='modal-error'>{backendValidation?.type === 'warning' ? 'WARNING' : 'ERROR'}</span>
                        </span>
                        <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                    </Box>

                    <Box className="modal-details">
                        {
                            Object.keys(backendValidation?.data)?.map((section) => {
                                return (
                                    <>
                                        <p className='modal-section-heading'> {section}</p>
                                        {(backendValidation?.data?.[section]?.summary) && renderSummaryData(section)}
                                        {renderQuestionData(section)}
                                    </>
                                )
                            })
                        }
                    </Box>

                    <button className="modal-ok-button ml-10" onClick={handleClose} >
                        OK
                    </button>

                   { backendValidation?.type === 'warning' && <button className="modal-ok-button ml-10" onClick={handleIgnore} >
                        Ignore
                    </button>}
                </Box>
            </Modal>
        </div>
    );
}