import axios from "./axios";
import moment from 'moment';
import { InterviewFeedbackStatus } from "@iv/shared/components/pages/Feedback/Constants/FeedbackConstants";
import checkmark from "../images/check-mark.svg";
import { INTERVIEWER_TYPE } from "../views/User/Constant";


export const fetchSchedule = async (user, interviewerType) => {
    try{
        const resp = await axios.get('/interviewer/scheduled', user, { headers: {'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'} });
        return resp.data.map(item => ({
            ...item,
            candidateName: item.candidate_details.name,
            companyName: item.client_details.client_name,
            positionName: item.position_details.position_name,
            roundName: item.round_details.name,
            positionAndRound: (item.position_details.position_name || item.round_details.name)?
                    {label: <><div>{item?.position_details?.position_name}</div>
                            <div className='belowText'>{item?.round_details?.name}</div></>}: "--",
            date: moment(new Date(item.interview_details.scheduled_start_timestamp * 1000)).format('DD-MM-YY'),
            time: moment(new Date(item.interview_details.scheduled_start_timestamp * 1000)).format('hh:mm a'),
            dateTime: item?.interview_details?.scheduled_start_timestamp ? {value: item?.interview_details?.scheduled_start_timestamp || '', label: <div class = "text-nowrap">{item?.interview_details?.scheduled_start_timestamp ? <> <div>{moment(new Date(item?.interview_details?.scheduled_start_timestamp * 1000)).format('MMM DD, YYYY')}</div> 
                                                                                                                                                                                <div className='belowText'>{moment(new Date(item?.interview_details?.scheduled_start_timestamp * 1000)).format('hh:mm a')}</div></>: ''}</div>} : "",
            id: item.interview_details.id,
            resultPreview:  ( item?.interview_details?.feedback_preview ? { value: 'Submitted', label: <><div className={`dflex ${interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL && "preview-not-available"}`}><img src={checkmark} alt="" />
                <div className='submitted'>Submitted</div></div><div className={`belowText ml-30  ${interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL && "preview-not-available"}`}>
                {item?.interview_details?.feedback_preview === "STRONG_PROCEED"? "Strong Proceed" : item?.interview_details?.feedback_preview === "PROCEED"? "Proceed": item?.interview_details?.feedback_preview === "REJECT"? "Reject": item?.interview_details?.feedback_preview === "STRONG_REJECT"? "Strong Reject" : ""}</div></> }                    
                    : (item?.interview_details?.feedback_status && (( item?.interview_details?.scheduled_start_timestamp * 1000 ) < (new Date().getTime())) && !item?.interview_details?.interview_result ) ? { value: 'Submit', label: <div className='submitReview' > Submit </div> }
                    : "--" ),     
            isUrgent: true,
            isReviewd: true,
            status: (item.interview_details.comments && item.interview_details.comments.cancel_request_data) ? {value: 'Cancelled', label: <div class = "">Cancelled</div>}: (item?.interview_details?.comments?.reschedule_request_data) ? {value: 'Reschuled', label: <div class = "">Rescheduled</div>} : {value: 'Scheduled', label: <div class = "">Scheduled</div>},
            feedbackStatus: (item?.interview_details?.feedback_status && item?.interview_details?.scheduled_start_timestamp  * 1000 < (new Date().getTime() + (15 * 60 * 1000))) ?
            ((item?.interview_details?.feedback_status === InterviewFeedbackStatus.APPROVED) ? { value: 'Feedback Approved', label: <div class = "text-common text-approved"> • Approved</div> }
            : (item?.interview_details?.feedback_status === InterviewFeedbackStatus.CHANGES_REQUESTED) ? {value: 'Changes Requested', label: <div class = "text-common text-changesRequested"> • Changes Requested</div>}
            : (item?.interview_details?.feedback_status === InterviewFeedbackStatus.SUBMITTED) ? {value: 'Not Approved', label: <div class = "text-common text-not-approved"> • Yet to Approve</div>}
            : (item?.interview_details?.feedback_status === InterviewFeedbackStatus.DRAFT) ? {value: 'Saved in Draft', label: <div class = "text-common text-draft"> • Saved in Draft</div>}
            : {value: 'Yet to fill', label: <div class = "text-common text-empty"> • Yet to Fill</div>}) : "--",
            isCancelled:  (item.interview_details.comments && item.interview_details.comments.cancel_request_data) ? true : false,
            isRescheduled : (item?.interview_details?.comments?.reschedule_request_data) ? true : false,
            candidateFeedbackMailOption: item?.interview_details?.extra_info?.candidate_feedback_email_sent ? { value: 'Sent', label: <div class="sent-feedback">Sent</div> }
            : ((((item?.interview_details?.scheduled_start_timestamp * 1000)  + (60* 60* 1000) ) < (new Date().getTime() + (10 * 60 * 1000)))) ? { value: 'Send', label: <div class="send-feedback">Send</div> }
            : "--",
        }));
    } catch(err){
        throw err;
    }
};


export const addInterviewPreview = async ( user, data ) => {
    try{
        const resp = await axios.post('/interview/'+ user +'/preview', { overall_recommendation : data} );
        return resp.data;
    } catch(err){
        throw err;
    }
};


