import React from "react";
import "./AuditReport.scss";

// Material Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
// List...
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemTextMUI from "@mui/material/ListItemText";
import { ListItemIcon } from "@material-ui/core";

// accordian
import Divider from "@mui/material/Divider";
import { makeStyles } from "@material-ui/core";

//table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//image..
import img1 from "./images/check-mark 2.svg";
import worst from "./images/worst.svg";
import bad from "./images/bad.svg";
import okay from "./images/okay.svg";
import Appropriate_small from "./images/Appropriate_small.svg";
import Appropriate from "./images/Appropriate.svg";
import underrated_small_group from "./images/underrated_group_small.svg";
import overrated_small from "./images/Overrated_small.svg";
import Underrated_Big from "./images/Underrated_Big.svg";
import overrated_Big from "./images/Overrated_Big.svg";
import best from "./images/5star.svg";
import good from "./images/4star.svg";
import thumb from "./images/thumb.svg";
import down_arrow from "./images/down_arrow.svg";
import up_arrow from "./images/up_arrow.svg";
import closeIconImg from "./images/close-icon-red.svg";

// External Comp
import AccordionComp from "./Accordion";
import NavBar from "./Navbar";
import SideComponent from "./SideComponent";
const ListItemText = styled(ListItemTextMUI)({
  "& > span":{
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "13px",
    letterSpacing: "0.005em",
    color: "#3d3d3d",
  }
});
const customStyle = {
  fontFamily: 'Poppins',
  fontWeight: "400",
  fontSize: "13px",
  letterSpacing: "0.005em",
  color: "#3D3D3D",
}
const useStyles = makeStyles({
  ListColor: {
    "&:nth-of-type(odd)": {
      background: "#F5F8FF",
    },
  },

  list: {
    width: 793,
    height: 46,
  },
});
const AuditReportShared = ({data, canDownload}) => {
  const panelActive ={
    panel1:true,
    panel2:true,
    panel3:true,
    panel4:true,
    panel5:true,
    panel6:true,
    panel7:true,
  }

  const overallRecommendation = {
    STRONGLY_PROCEED: "STRONG_PROCEED",
    PROCEED: "PROCEED",
    REJECT: "REJECT",
    STRONGLY_REJECT: "STRONG_REJECT",
  };

  const [expanded, setExpanded] = React.useState(panelActive);

  const classes = useStyles();
  const nonNaData = data?.audit_report?.interview_assessment && Object.values(data?.audit_report?.interview_assessment).filter(item => item.answer !== "NA");

  const handelToggle = (panel, value) =>{
    setExpanded({ ...expanded, [panel]: !value })
  };

  const giveInitialRating = (value) =>{
    let rating;
    data?.audit_report?.skill_ratings?.forEach((item)=>{
      if (item?.name === value)
      {
        rating = item?.value === null ? 'N/A' : item?.value;
      }
    })
    return rating;
  }

 const CheckboxListSecondary = () => {
    return (
      <div>
        <AccordionComp  label="Interview Process Assessment" expand={expanded.panel1} onChangeIcon={() => handelToggle('panel1', expanded.panel1)} >
          <List>
            {data?.audit_report?.interview_assessment &&
              Object.values(nonNaData).map(
                (item, index) => {
                  return (
                    <ListItem
                      key={index}
                      className={classes.ListColor}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemText   className="assessment-tp-list" secondary={
                         <React.Fragment>
                         {item?.comments &&<Box
                          sx={{paddingLeft: '18px' }}
                         >
                           <Typography className='comment-label'>Comments</Typography>
                           <p className='comment-data'>{item.comments}</p>

                         </Box>}
                       </React.Fragment>
                        }>
                        {index + 1}.  {item?.question}
                        </ListItemText>
                        <ListItemIcon>
                          {
                            item?.expected_answer &&
                            <img src={item.expected_answer === item?.answer ? img1 : closeIconImg} alt="right-wrong-icon"/>
                          }
                          {
                            !item?.expected_answer &&
                            <img src={(((item.comments && item.answer === 'Yes') || item.answer === 'No')) ? closeIconImg : img1} alt="right-wrong-icon"/>
                          }                        
                        </ListItemIcon>
                        <ListItemIcon>({item.answer})</ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  );
                }
              )}
          </List>
        </AccordionComp>

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            border:"0px"
          }}
        />

        <AccordionComp label="Feedback Summary" expand={expanded.panel2} onChangeIcon={() => handelToggle('panel2', expanded.panel2)}>
          <List>
            <ListItem
              // key={value}
              className={classes.ListColor}
              sx={{
                width: 793,
                minHeight: 46,
              }}
              disablePadding
            >
              <ListItemButton>
                <ListItemText>
                  <span className="Feedback_summary_h1">1. Highlights</span>
                </ListItemText>

                <ListItemText>
                { data?.audit_report?.feedback_assessment?.highlights?.rating ==
                  "Appropriate" ?
                      <img src={Appropriate_small} className="appro_img" alt="appropriate-icon"/> :
                  data?.audit_report?.feedback_assessment?.highlights?.rating ==
                  "OverRated" ?
                      <img src={overrated_small} className="over_img" alt="overrated-icon"/>
                  : data?.audit_report?.feedback_assessment?.highlights?.rating ==
                  "UnderRated" ?
                      <img src={underrated_small_group} className="under_img" alt="underrated-icon"/> : ""
                  }
                </ListItemText>
                <ListItemText
                  sx={{ marginRight: 62, mt: 1 }}
                  className="lowlights_downarrow"
                >
                  {data?.audit_report?.feedback_assessment?.highlights?.rating ==
                  "OverRated" ? (
                    <span className="uparrow">
                      (<img src={up_arrow} alt="up-arrow"/>)
                    </span>
                  ): data?.audit_report?.feedback_assessment?.highlights?.rating ==
                  "UnderRated" ? (
                    <span>
                      (<img src={down_arrow} alt="down-arrow"/>)
                    </span>
                  ): ""}
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <Box className="highlighttext">
            {data?.audit_report?.feedback_assessment?.highlights?.comments == ''? "": <>
                <ListItemButton>
                  <ListItemText className="corections_h">Corrections:</ListItemText>
                </ListItemButton>
                <ListItemButton className="corections_text">
                  <p style={{whiteSpace:'pre-line'}}>
                  {data?.audit_report?.feedback_assessment?.highlights?.comments}
                  </p>
                </ListItemButton>
                </>
            }
            </Box>

            <ListItem
              className={classes.ListColor}
              sx={{
                width: 793,
                height: 46,
              }}
              disablePadding
            >
              <ListItemButton>
                <ListItemText>
                  <span className="Feedback_summary_h1"> 2. Lowlights </span>
                </ListItemText>
                <ListItemText>
                  { data?.audit_report?.feedback_assessment?.lowlights?.rating ==
                  "Appropriate" ?
                      <img src={Appropriate_small} className="appro_img" alt="appropriate-icon"/> :
                  data?.audit_report?.feedback_assessment?.lowlights?.rating ==
                  "OverRated" ?
                      <img src={overrated_small} className="over_img" alt="overrated-icon"/>
                  : data?.audit_report?.feedback_assessment?.lowlights?.rating ==
                  "UnderRated" ?
                      <img src={underrated_small_group} className="under_img" alt="underrated-icon"/> : ""
                  }
                </ListItemText>
                <ListItemText
                  sx={{ marginRight: 62, mt: 1 }}
                  className="lowlights_downarrow"
                >
                  {data?.audit_report?.feedback_assessment?.lowlights?.rating ==
                  "OverRated" ? (
                    <span className="uparrow">
                      (<img src={up_arrow} alt="up-arrow"/>)
                    </span>
                  ): data?.audit_report?.feedback_assessment?.lowlights?.rating ==
                  "UnderRated" ? (
                    <span>
                      (<img src={down_arrow} alt="down-arrow"/>)
                    </span>
                  ): ""}
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {data?.audit_report?.feedback_assessment?.lowlights?.comments == ''? "" : <>
                <ListItemButton>
                  <ListItemText className="corections_h">Corrections:</ListItemText>
                </ListItemButton>
                <ListItemButton className="corections_text">
                  {" "}
                  <p style={{whiteSpace:'pre-line'}}>
                  {data?.audit_report?.feedback_assessment?.lowlights?.comments}
                  </p>
                </ListItemButton>
                </>
            }
          </List>
        </AccordionComp>

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            border:"0px"
          }}
        />

        <AccordionComp label="Skill Ratings" expand={expanded.panel3} onChangeIcon={() =>handelToggle('panel3', expanded.panel3)}>
          <TableContainer component={Paper} className='skilltable noborder'>
            <Table sx={{ minWidth: 700 }} className='noborder' aria-label="customized table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#FFFFFF;" }}>
                  <TableCell sx={{ color: "#848484;" }} className='noborders'>Skills</TableCell>
                  <TableCell
                    className="initial_rating_heading noborders"
                    sx={{ color: "#848484;" }}
                    align="center"
                  >
                    Initial Ratings
                  </TableCell>
                  <TableCell
                    className="audit_rating_heading noborders"
                    sx={{ color: "#6F84B4;", paddingRight: "50px" }}
                    align="right"
                  >
                    Ratings after Audit
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* {
                  data?.audit_report?.skill_ratings?.map((item, index)=>
                     <TableRow className={classes.ListColor} key={index}>
                      <TableCell className="noborders"> {item?.name} </TableCell>
                      <TableCell align="center" className="noborders skill_rating_initial">
                          {
                            giveInitialRating(item?.name)
                          }
                      </TableCell>
                      <TableCell
                        align="right"
                        className="noborders skill_rating_audit"
                        sx={{ paddingRight: "100px" }}
                      >
                        {item?.value}
                      </TableCell>
                    </TableRow>
                  )
                } */}
                {Array.isArray(data?.audit_details?.interviewer_feedback?.evaluation_ratings) ?
                   data?.audit_details?.interviewer_feedback?.evaluation_ratings?.map((item, index)=>    
                    {  return (
                <>
                     { (item?.rating !== ''  && item?.rating !== null) &&
                      <TableRow className={classes.ListColor} key={index}>
                      <TableCell className="noborders" sx={customStyle}> {item?.type} </TableCell>
                      <TableCell align="center" className="noborders skill_rating_initial">
                        {item?.rating}
                      </TableCell>
                      <TableCell
                        align="right"
                        className="noborders skill_rating_audit"
                        sx={{ paddingRight: "100px" }}
                      >
                        {giveInitialRating(item?.type)}
                      </TableCell>
                    </TableRow>
                   }</>) }
                    
                  )

                  :

                  Object.keys(data?.audit_details?.interviewer_feedback?.evaluation_ratings || {})?.map((item, index)=>
                    { return (
                    <>
                      {(data?.audit_details?.interviewer_feedback?.evaluation_ratings?.[item]?.rating !== '' && data?.audit_details?.interviewer_feedback?.evaluation_ratings?.[item]?.rating !== null) &&
                      <TableRow className={classes.ListColor} key={index}>
                      <TableCell className="noborders" sx={customStyle}> {item} </TableCell>
                      <TableCell align="center" className="noborders skill_rating_initial">
                        {data?.audit_details?.interviewer_feedback?.evaluation_ratings?.[item]?.rating}
                      </TableCell>
                      <TableCell
                        align="right"
                        className="noborders skill_rating_audit"
                        sx={{ paddingRight: "100px" }}
                      >
                        {giveInitialRating(item)}
                      </TableCell>
                    </TableRow>
                    }
                    </>)  }
                  )


                }
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionComp>

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            border:"0px"
          }}
        />
        <AccordionComp label=" Interview Quality Rating" expand={expanded.panel4} onChangeIcon={() =>handelToggle('panel4', expanded.panel4)}>
          <Box display="flex" className='ratingquality'>
            <Box>
              <Typography
                component="div"
                className="interview_qty_typography"
                sx={{ color: "#848484;", margin: "0px 0px 10px 0px" }}
              >
                Overall rating of the interview
              </Typography>
              <Box className="imgBox1">
                {" "}
                <Box sx={{ marginLeft: "15px" }}>
                  {data?.audit_report?.interview_rating == "WORST" ? (
                    <img src={worst} alt="worst-emoji"/>
                  ) : data?.audit_report?.interview_rating == "BAD" ? (
                    <img src={bad} alt="bad-emoji"/>
                  ) : data?.audit_report?.interview_rating == "OKAY" ? (
                    <img src={okay} alt="okay-emoji"/>
                  ) : data?.audit_report?.interview_rating == "GOOD" ? (
                    <img src={good} alt="good-emoji"/>
                  ) : (
                    <img src={best} alt="best-emoji"/>
                  )}
                </Box>
                <Box>
                  <Box className="rating">
                    {data?.audit_report?.interview_rating == "WORST"
                      ? "Worst"
                      : data?.audit_report?.interview_rating == "BAD"
                      ? "Bad"
                      : data?.audit_report?.interview_rating == "OKAY"
                      ? "Okay"
                      : data?.audit_report?.interview_rating == "GOOD"
                      ? "Good"
                      : "Best"}
                  </Box>
                  <Box className="rating-text">
                    {data?.audit_report?.interview_rating == "WORST"
                      ? "1"
                      : data?.audit_report?.interview_rating == "BAD"
                      ? "2"
                      : data?.audit_report?.interview_rating == "OKAY"
                      ? "3"
                      : data?.audit_report?.interview_rating == "GOOD"
                      ? "4"
                      : "5"}
                    <span style={{ color: " #3D3D3D", fontWeight: "500" }}>
                      {" "}
                      out of{" "}
                    </span>{" "}
                    5
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  marginLeft: "30px",
                  marginBottom: "10px",
                  color: "#848484",
                }}
              >
                Overall rating of the feedback
              </Typography>
              <Box className="imgBox1" sx={{ marginLeft: "30px" }}>
                <Box sx={{ marginLeft: "15px" }}>
                  {" "}
                  {data?.audit_report?.feedback_rating == "WORST" ? (
                    <img src={worst} alt="worst-emoji"/>
                  ) : data?.audit_report?.feedback_rating == "BAD" ? (
                    <img src={bad} alt="bad-emoji"/>
                  ) : data?.audit_report?.feedback_rating == "OKAY" ? (
                    <img src={okay} alt="okay-emoji"/>
                  ) : data?.audit_report?.feedback_rating == "GOOD" ? (
                    <img src={good} alt="good-emoji"/>
                  ) : (
                    <img src={best} alt="best-emoji"/>
                  )}
                </Box>
                <Box>
                  <Box className="rating">
                    {" "}
                    {data?.audit_report?.feedback_rating == "WORST"
                      ? "Worst"
                      : data?.audit_report?.feedback_rating == "BAD"
                      ? "Bad"
                      : data?.audit_report?.feedback_rating == "OKAY"
                      ? "Okay"
                      : data?.audit_report?.feedback_rating == "GOOD"
                      ? "Good"
                      : "Best"}
                  </Box>
                  <Box className="rating-text">
                    {data?.audit_report?.feedback_rating == "WORST"
                      ? "1"
                      : data?.audit_report?.feedback_rating == "BAD"
                      ? "2"
                      : data?.audit_report?.feedback_rating == "OKAY"
                      ? "3"
                      : data?.audit_report?.feedback_rating == "GOOD"
                      ? "4"
                      : "5"}
                    <span style={{ color: " #3D3D3D", fontWeight: "500" }}>
                      {" "}
                      out of
                    </span>{" "}
                    5
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionComp>

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            border: "0px"
          }}
        />
        <AccordionComp label="Miscellaneous" expand={expanded.panel5} onChangeIcon={() =>handelToggle('panel5', expanded.panel5)}>
          <Box>
            <Typography className="missing-info">
              Missing information from the feedback
            </Typography>
            <Box className="unordered-list1">
              <ul>
                {data?.audit_report?.feedback_missing_info?.map((item)=>{
                  return(
                    <li>{item || "NA"}</li>
                  )
                })} 
              </ul>
            </Box>
          </Box>
           <Box>
            <Typography className="missing-info">
              Scope of improvement for interviewer
            </Typography>
            <Box className="unordered-list1">
              <ul>
                {data?.audit_report?.scope_of_improvement.map((item)=>{
                  return(
                    <li>{item || "NA"}</li>
                  )
                })}
              </ul>
            </Box>
          </Box>
        </AccordionComp>
        {
          data?.audit_report?.extra_comments?<>
        <Divider
          sx={{
            width: "100%",
            mt: 2,
            border: "0px"
          }}
        />
        <AccordionComp label="Extra Comments" expand={expanded.panel6} onChangeIcon={() =>handelToggle('panel6', expanded.panel6)}>
            <Box className="unordered-list1">
              <ul>
                {data?.audit_report?.extra_comments.map((item)=>{
                  return(
                    <li>{item  || "NA"}</li>
                  )
                })}
              </ul>
            </Box>
        </AccordionComp>
          </>:""
        }
        
        
        {
          data?.audit_report?.interviewer_comments && 
          data?.audit_report?.interviewer_comments?.length>0?<>
          <Divider
          sx={{
            width: "100%",
            mt: 2,
            border: "0px"
          }}
        />
          <AccordionComp label="Interviewer Comments" expand={expanded.panel7} onChangeIcon={() =>handelToggle('panel7', expanded.panel7)}>
        <Box className="unordered-list1">
              <ul>
                 {data?.audit_report?.interviewer_comments?.map((item)=>{
                  return(
                    <li>{item}</li>
                  )
                })}
              </ul>
            </Box>
        </AccordionComp>
          </>:""
        }
        
      </div>
    );
  };

  function recommendationBox(){
    if(data?.audit_report?.overall_recommendation === overallRecommendation?.STRONGLY_PROCEED){
      return "a-f-h3 bgDarkGreen"
    }else if(data?.audit_report?.overall_recommendation === overallRecommendation?.PROCEED){
      return "a-f-h3 bgGreen"
    }else if(data?.audit_report?.overall_recommendation === overallRecommendation?.REJECT){
      return "a-f-h3 bgRed"
    }else {
      return "a-f-h3 bgDarkRed"
    }
  }

  const FeedbackBox = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="FeedbackBox-main-wrapper"
      >
        <Box
          className="FeedbackBox-child-wrapper"
          display="flex"
          justifyContent="space-around"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography component="p" className="audit-feedback-h">
              Audit Report Suggestion
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              className="audit-feedback-h-child1"
            >
              <Box display="flex" alignContent="center">
                <Typography component="Box" className="a-f-h1">
                  {data?.audit_report?.overall_rating}
                </Typography>
                <Typography component="Box" className="a-f-h2">
                  {" "}
                  (Overall Rating)
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={recommendationBox()}
              >
                <Typography id="a-f-h4">
                  {data?.audit_report?.overall_recommendation === overallRecommendation?.STRONGLY_REJECT
                    ? "STRONG REJECT"
                    : data?.audit_report?.overall_recommendation ===
                      overallRecommendation?.STRONGLY_PROCEED
                    ? "STRONG PROCEED"
                    : data?.audit_report?.overall_recommendation === overallRecommendation?.REJECT
                    ? "REJECT"
                    : data?.audit_report?.overall_recommendation === overallRecommendation?.PROCEED ? "PROCEED" : ""}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            sx={{ height: "70%", margin: "auto 0px" }}
          />

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography className="feedback-rating-h">
              Initial Feedback Rating
            </Typography>
            <Box className="feedback-r-child1">
              <Typography component="span" id="f-r-h1">
                {data?.audit_details?.interviewer_feedback?.overall_rating}
              </Typography>
              <Typography component="span" id="f-r-h2">
                {" "}
                (Overall Rating)
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="f-r-h3"
              >
                <Typography id="f-r-h4">
                  {data?.audit_details?.interviewer_feedback
                    ?.overall_recommendation == "STRONG_REJECT"
                    ? "STRONG REJECT"
                    : data?.audit_details?.interviewer_feedback
                        ?.overall_recommendation == "STRONG_PROCEED"
                    ? "STRONG PROCEED"
                    : data?.audit_details?.interviewer_feedback
                        ?.overall_recommendation == "REJECT"
                    ? "REJECT"
                    : data?.audit_details?.interviewer_feedback
                    ?.overall_recommendation == "PROCEED"?"PROCEED" : ""}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box>
              <Box display="flex" justifyContent="end">
                <Typography component="p" className="post-audit">
                  Post Audit
                </Typography>
              </Box>
            </Box>

            <Typography component="span" id="p-a-h1">
              Initial feedback was found to be
            </Typography>

            <Box display="flex" justifyContent="space-around">
              <Box>
                <Typography id="p-a-h2">
                  {data?.audit_details?.result == "APPROPRIATE" ? (
                    <img src={Appropriate} alt="appropriate-img"/>
                  ) : data?.audit_details?.result == "OVER_RATED" ? (
                    <img src={overrated_Big} alt="overrated-img"/>
                  ) : (
                    <img src={Underrated_Big} alt="underrated-img"/>
                  )}
                </Typography>
              </Box>
              <Box>
                {data?.audit_details?.result == "APPROPRIATE" ? (
                  <div id="p-a-h-2-img">
                    <img src={thumb} alt="thumb-icon"/>
                  </div>
                ) : data?.audit_details?.result == "OVER_RATED" ? (
                  <div id="p-a-h-2-img-overrated">
                    (<img src={up_arrow} alt="up-arrow"/>)
                  </div>
                ) : (
                  <div id="p-a-h-2-img-underrated">
                    (<img src={down_arrow} alt="down-arrow"/>)
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box display="flex" flexDirection="column" className="Body">
        {CheckboxListSecondary()}
      </Box>
    );
  };

  return (
    <>
      <NavBar headerValue="Interview Audit report" 
        data={data}
        canDownload={canDownload}
      />
      <Box display="flex" sx={{ justifyContent: "center" }}>
        <Box sx={{ mt: "22px", mr: "13px" }}>
          <SideComponent details={data?.audit_details} />
        </Box>
        <Box sx={{ mt: "14px" }}>
          <FeedbackBox />
          <Box sx={{ mt: "26px" }}>
            <Body />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AuditReportShared;
