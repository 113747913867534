import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import jwt_decode from 'jwt-decode';
import moment from "moment-timezone";
import CustomizedDialogs from "./ViewQuestion";
import ViewReferenceImage from "./ViewReferenceImage";
import { QuestionCard } from "./QuestionCard";
import { CommentStatus, GuideCommentIconClass, GuidelineIntroductionType, GuideSectionCssClass, StagesType, TechnicalType, TheoreticalType , InDepthKnowledgeType, GUIDE_APPROVAL_STATUS} from "../Constants";
import "./GuidePreview.scss";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ResultClarity from "../Components/ResultClarity";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import logoiv from "../../../../assets/images/logo-iv.png";
import eyeIcon from "../../../../assets/images/eyeIcon.svg";
import copy from "../../../../assets/images/copy.svg";
import Arrow from "../../../../assets/images/Arrow.svg";
import redirectLink from "../../../../assets/images/redirect-link.svg";
import approvedIcon from "../../../../assets/images/approvedIcon.svg"
import guideRejectedIcon from "../../../../assets/images/guideRejectedIcon.svg"
import { Comments } from "../Components/Comments/Comments";
import { RightCommentBox } from "./CommentBox";
import SwtAlert from "../../../../Utils/SwtAlert";
import Button from '@mui/material/Button';
import { DashboardType } from "../../../../constants";
import RejectModal from "./RejectGuideModal";
import cogoToast from 'cogo-toast';
const GuidePreview = ({ dataPreview, previewShareLinkData, copyLinkVisibility = false,  getGuideShareableLinkData,guideCommentAPIS,guideCommentData,jobDescriptionLink, downloadImage, guideApprovalAPI, clientId, isOpsDashboard, isClientDashboard }) => {
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);
  const guide_id = parsedQueryString?.guide_id;
  const template_id = parsedQueryString?.template_id;
  const isTemplate =  !template_id || template_id?.length == 0 || template_id === 'undefined' ? false : true;
  const [guideData, setGuideData] = useState(dataPreview);
  const [technicalQuestionTopic, setTechnicalQuestionTopics] = useState([]);
  const [theoreticalQuestionTopics, setTheoreticalQuestionTopics] = useState([]);
  const [inDepthKnowledgeQuestionTopic, setInDepthKnowledgeQuestionTopic] = useState([]);
  const [dataPreviewList, setDataPreviewList] = useState([]);
  const [isIndepthKnowledge, setIsInDepthKnowledge] = useState(false);
  const [filterEvaluationObject, setFilterEvaluationObject] = useState({});
  const [openImageReferencePopUp, setOpenImageReferencePopUp] = useState(false);
  const [openRejectGuideModal, setOpenRejectGuideModal] = useState(false);
  const [shareLinkCopied, setShareLinkCopied] = useState({ open: false, vertical: 'top', horizontal: 'center'});
  const localStorageData = JSON.parse(localStorage.getItem("guide_commenter_details"));
  const [commentData,setCommentData] = useState({
    name:guideCommentData?.name && guideCommentData?.name?.length>0?guideCommentData?.name:localStorageData?.name?localStorageData?.name:"",
    email:guideCommentData?.email && guideCommentData?.email?.length>0?guideCommentData?.email:localStorageData?.email?localStorageData?.email:"",
    guide_id:guide_id,
    data:{},
    isError:{
      name:null,
      email:null,
    },
    dashboardType:guideCommentData?.dashboardType,
  })
  const [guideSectionCssEffect,setGuideSectionCssEffect] = useState({});
  const [showNewCommentBox,setShowNewCommentBox] = useState({
    status:true
  });
  const [isProfileDialogOpen,setIsProfileDialogOpen] = useState(false);
  const { vertical, horizontal, open } = shareLinkCopied;
  let importantGenericInstructions = [];
  let normalGenericInstructions = [];
  const {getAllGuideCommentsAPI} = guideCommentAPIS;
  const [technicalArray, setTechnicalArray] = useState([]);
  const [theoreticalArray, setTheoreticalArray] = useState([]);
  const [inDepthKnowledgeArray, setInDepthKnowledgeArray] = useState([]);
  const [referenceState, setReferenceState] = useState(null);
  const [guideRejectionReason, setGuideRejectionReason] = useState('');
  const [isApprovalStatusBtnDisable, setIsApprovalStatusBtnDisable] = useState(true);
  const approverHistoryCount = guideData?.approval_history?.length;
  const guideLatestStatus =  guideData?.approval_history?.length && guideData?.approval_history[guideData?.approval_history?.length -1]?.is_approved;
  const guidePreviousStatus =   (approverHistoryCount >  1 && guideLatestStatus === GUIDE_APPROVAL_STATUS.PENDING) ?  guideData?.approval_history[guideData?.approval_history?.length -2]?.is_approved : null
  
  const [temporaryAccessToken, setTemporaryAccessToken] =  useState(window?.sessionStorage.getItem('temporary_access_token'));
  const [decodedToken, setDecodedToken] = useState(null);
  const [showGuideApprovedStatus, setShowApprovedGuideStatus] = useState('');



  useEffect(() => {
    decodeJWT();
  },[temporaryAccessToken])

  const decodeJWT = () => {
    if(temporaryAccessToken) {
      const decodedToken = jwt_decode(temporaryAccessToken);
      setDecodedToken(decodedToken);
    }
  };

  // these comment details is for indepth or theoritical.
  let commentDetails = isIndepthKnowledge ?  commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[1]?.type] : commentData?.data?.[dataPreviewList[2]?.type]?.[dataPreviewList[2]?.sections[1]?.type]
  let questionIndex = 0;
  const getGuidePreviewData = async () => {
    setGuideData(dataPreview);
    setDataPreviewList(dataPreview?.stages || []);
    setTechnicalArray(dataPreview?.stages ?  (dataPreview?.stages[1]?.sections[0]?.values?.question_topic || []) : [])
    setTheoreticalArray(dataPreview?.stages ?  (dataPreview?.stages[2]?.sections[0]?.values?.question_topic || []) : [])
    setInDepthKnowledgeArray(dataPreview?.stages && dataPreview?.stages[1]?.type == 'INDEPTH_KNOWLEDGE' ?  (dataPreview?.stages[1]?.sections[0]?.values?.question_topic || []) : []);
    const guidelinesIntroduction = dataPreview?.stages?.find(stage => stage?.type === StagesType.GUIDELINES_INTRODUCTION) || []
    const skillEvaluation = guidelinesIntroduction?.sections?.find(section => section?.type === GuidelineIntroductionType.SKILL_EVALUATION)
    filterEvaluation(skillEvaluation);
  };

  const structureCssEffect = (type) => {
    const cssEffect = {
      GUIDELINES_INTRODUCTION : {},
      TECHNICAL : {},
      THEORETICAL : {},
      INDEPTH_KNOWLEDGE: {}
    };
    for(let key in GuidelineIntroductionType)
    {
        cssEffect.GUIDELINES_INTRODUCTION[GuidelineIntroductionType[key]] = GuideSectionCssClass?.INITIAL;
    }

    for(let key in TechnicalType)
    {
      cssEffect.TECHNICAL[TechnicalType[key]] = GuideSectionCssClass?.INITIAL;
    }

    for(let key in TheoreticalType)
    {
      cssEffect.THEORETICAL[TheoreticalType[key]] = GuideSectionCssClass?.INITIAL;
    } 
    for(let key in InDepthKnowledgeType)
    {
      cssEffect.INDEPTH_KNOWLEDGE[InDepthKnowledgeType[key]] = GuideSectionCssClass?.INITIAL;
    } 
    if(type!=='reset')
    {
      setGuideSectionCssEffect(cssEffect);
    }
    return cssEffect;
  }

  const structureData = (data) => {
        const tempObj = {
          GUIDELINES_INTRODUCTION : {},
          TECHNICAL : {},
          THEORETICAL : {},
          INDEPTH_KNOWLEDGE: {}
        };

        for(let key in GuidelineIntroductionType)
        {
          const tempArr = data?.filter(item=>item?.section===GuidelineIntroductionType[key]) || [];
          tempObj.GUIDELINES_INTRODUCTION[GuidelineIntroductionType[key]] = tempArr;
        }
        for(let key in TechnicalType)
        {
          const tempArr = data?.filter(item=>item?.section===TechnicalType[key]) || [];
          tempObj.TECHNICAL[TechnicalType[key]] = tempArr;
        }
        for(let key in TheoreticalType)
        {
          const tempArr = data?.filter(item=>item?.section===TheoreticalType[key]) || [];
          tempObj.THEORETICAL[TheoreticalType[key]] = tempArr;
        }
        for(let key in InDepthKnowledgeType)
        {
          const tempArr = data?.filter(item=>item?.section===InDepthKnowledgeType[key]) || [];
          tempObj.INDEPTH_KNOWLEDGE[InDepthKnowledgeType[key]] = tempArr;
        }
        commentData.data = tempObj;
        setCommentData({...commentData});
  }
  const handleDefaultCss = (section,subSection) => {
    guideSectionCssEffect[section][subSection] = GuideSectionCssClass?.INITIAL;
      setGuideSectionCssEffect({...guideSectionCssEffect}); 
  }

  const getDefaultCss = (initialCss, commentData) => {
    let cssName=  initialCss
      if(commentData?.length > 0) {    
                 cssName = cssName +" "+ GuideSectionCssClass?.PREV_COMMENT_HOVER
      } 
      return cssName;   
  }

  const handleMouseEnter = (section,subSection,length) => {
    const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
    if(guideSectionCssEffect[section][subSection]!== prevCss)
    {
      let cssName = GuideSectionCssClass?.INITIAL+" ";
        if(length>0)
        {
          cssName += GuideSectionCssClass?.PREV_COMMENT_HOVER;
        }
        else
          cssName += GuideSectionCssClass?.NEW_COMMENT_HOVER;
        guideSectionCssEffect[section][subSection] = cssName;
        setGuideSectionCssEffect({...guideSectionCssEffect});  
    }
  }
  const handleMouseLeave = (section,subSection) => {
    const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
    if( guideSectionCssEffect[section][subSection]!== prevCss)
    {
      handleDefaultCss(section,subSection); 
    }
  }
  
  const handleMouseClick = (length,section,subSection) => {
    if(isTemplate)
      return;
    const prevCss =  GuideSectionCssClass?.INITIAL+" "+GuideSectionCssClass.PREV_COMMENT_CLICK;
    if(guideSectionCssEffect[section][subSection] === prevCss)
    {
      handleDefaultCss(section,subSection); 
      return;
    }
    if(!commentData?.name || commentData?.name?.length===0 || !commentData?.email || commentData?.email?.length===0)
    {
      setIsProfileDialogOpen(true);
    }
    if(length===0)
    {
      showNewCommentBox.status = true;
      
    }
    else
    {
      showNewCommentBox.status = false;
    }
    setShowNewCommentBox({...showNewCommentBox});
    const resetObj = structureCssEffect("reset");
    let cssName = GuideSectionCssClass?.INITIAL+" ";
    cssName += GuideSectionCssClass?.PREV_COMMENT_CLICK;
    resetObj[section][subSection] = cssName;
    setGuideSectionCssEffect(resetObj);  
  }
 
  const getCssClass= (length) =>{
    if(!length || length===0)
      return GuideCommentIconClass?.NEW_COMMENT
    return GuideCommentIconClass?.PREV_COMMENT;
  }
  const getAllGuideComments = () => {
    const token = window?.sessionStorage.getItem('temporary_access_token');
    if(!guide_id || guide_id?.length === 0 || guide_id === 'undefined')
    {
      SwtAlert("There is an error while fetching guide details since guide id is not present",2500,'error',false);
      return;
    }
    const payload = {
      guide_id: guide_id,
    }
    getAllGuideCommentsAPI(payload,token).then(({data})=>{
        if(data)
        {
          structureData(data?.data);
        }
    }).catch(error=>{
        SwtAlert(error?.response || error?.msg || error?.data || error?.toString(),2000,'error',false);
    })
}

    function handleTechnicalQuestionTopic(technicalArray)  {
      if(Array.isArray(technicalArray) === false){
          let arr = [];
          Object.keys(technicalArray)?.map((data) => {
              arr.push({
                  key : data,
                  value : technicalArray[data],
              })
          })
          setTechnicalQuestionTopics(arr);
      }else{
        setTechnicalQuestionTopics(technicalArray);
      }
    }

    function handleTheoreticalQuestionTopic(theoreticalArray)  {
      if(Array.isArray(theoreticalArray) === false){
          let arr = [];
          Object.keys(theoreticalArray)?.map((data) => {
              arr.push({
                  key : data,
                  value : theoreticalArray[data],
              })
          })
          setTheoreticalQuestionTopics(arr);
      }else{
        setTheoreticalQuestionTopics(theoreticalArray);
      }
    }

    function handleInDepthKnowledgeQuestionTopic(inDepthKnowledgeArray)  {
      if(Array.isArray(inDepthKnowledgeArray) === false){
          let arr = [];
          Object.keys(inDepthKnowledgeArray)?.map((data) => {
              arr.push({
                  key : data,
                  value : inDepthKnowledgeArray[data],
              })
          })
          setInDepthKnowledgeQuestionTopic(arr);
      }else{
        setInDepthKnowledgeQuestionTopic(inDepthKnowledgeArray);
      }
    }


   useEffect(() => {
        if(dataPreviewList?.length > 0) {
          if(dataPreviewList[1]?.type == 'INDEPTH_KNOWLEDGE') {
            setIsInDepthKnowledge(true);
          }
           
        }
   },[dataPreviewList])

  useEffect(() => {
    if (guide_id || template_id) getGuidePreviewData();
  }, [dataPreview]);

  useEffect(() => {
    handleTechnicalQuestionTopic(technicalArray);
  },[technicalArray]);

  useEffect(() => {
    handleTheoreticalQuestionTopic(theoreticalArray);
  },[theoreticalArray]);


  useEffect(() => {
    handleInDepthKnowledgeQuestionTopic(inDepthKnowledgeArray)
  },[inDepthKnowledgeArray])

  useEffect(()=>{
    structureCssEffect();
    if(!isTemplate)
    getAllGuideComments();
  },[]);


  useEffect(() => {
    if(guideRejectionReason?.length > 0) {
      setIsApprovalStatusBtnDisable(false)
 } else {
  setIsApprovalStatusBtnDisable(true)
 }
  },[guideRejectionReason])

  const filterEvaluation = (mainData) => {
    if (mainData?.values) {
      let obj = {
        high: [],
        medium: [],
        low: [],
      };
      mainData?.values?.forEach((data) => {
        if (data?.value?.toLowerCase() === "high" && data?.selected) {
          obj.high.push(data);
        } else if (data?.value?.toLowerCase() === "medium" && data?.selected) {
          obj.medium.push(data);
        } else if (data?.value?.toLowerCase() === "low" && data?.selected) {
          obj.low.push(data);
        }
      });
      setFilterEvaluationObject(obj);
    }
  };
   

    // Function to write text to clipboard and update state
    const writeToClipboard = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          setShareLinkCopied({ open: true, vertical: 'top', horizontal: 'center' });
        })
        .catch((error) => {
          cogoToast.error("Failed to write to clipboard: " + error?.message);
        });
    };

      const handleShareableLink = () => {
        // Check if the clipboard API is supported by the browser
        if (!navigator.clipboard) {
          cogoToast.error("Clipboard API not supported");
          return;
        } else {
            // Always fetch the shareable link data before copying
            getGuideShareableLinkData().then((res) => {
              const url = res?.data?.client_access_url;
              if (url) {
                writeToClipboard(url);
              } else {
                cogoToast.error("No client access URL found in response");
              }
            }).catch((error) => {
              cogoToast.error("Failed to fetch guide shareable link data: " + error?.message);
            });
        }
            
      };

  const handleGuideApproval = async (action) => {
    setIsApprovalStatusBtnDisable(true);
       let payload = {
        guide_id: parseInt(guide_id),
        client_id: clientId,
        approver_email: decodedToken?.approver_email
       }

       if(action === 'reject') {
             payload.approved_detail = {
              is_approved: false,
               reason: guideRejectionReason
              }
       } else {
         payload.approved_detail = {
           is_approved: true,
           reason: null
          }
       }

       try {
         let response = await guideApprovalAPI(payload, guide_id, temporaryAccessToken)
            if(response?.status === 200) {
                 setOpenRejectGuideModal(false);
                 setIsApprovalStatusBtnDisable(false);
                 setShowApprovedGuideStatus(action === 'reject' ? 'Rejected' : 'Approved')
                    setTimeout(() => {
                      window.location.reload(); 
                    }, 1000)
            } 
       } catch(error) {
        setIsApprovalStatusBtnDisable(false);
            cogoToast.error(
              error?.data?.msg?.toString() || 
              error?.data?.toString() || 
              error?.toString() ||
              "Failed please try again."
            )
       }
  }

  const totalQuestionasked = (data) => {
      let count = data?.easy + data?.medium + data?.hard;
      return count;
  }

  const topicList = (data) => {

    let tagList = data;
  
    return(
      <div>
        {tagList?.map((data) => {
          return(
            <div className="topicList-Container">
              <div><p className="questionTopicList">{data?.key}</p></div>
              <div style={{marginLeft: 5, marginRight: 5}}><img src={Arrow}/></div>
              <div><p className="questionAskText">Ask <span style={{fontWeight: 600}}>{data?.value}</span>{data?.[1] != 1 ? " questions" : " question"}</p></div>
            
            </div>
          )
        })}
      </div> 
    )
  }


const renderTheoreticalRow = (questionIndex, data, index, commentData) => {

 let isHighlightAutoCommentQstn = false;

  let questionIds = [];
  let filteredAutoComments = commentData?.filter((item) => item?.created_by?.commentor_name === 'AUTO_COMMENT')
  if(filteredAutoComments?.length > 0) {
   filteredAutoComments?.map((item, index) => {
       let objKeys = Object.keys(item?.extra_info);
       if(objKeys) {
         questionIds.push(parseInt(objKeys));
       }
   })
  }

 if(questionIds?.length > 0) {
       let findQstn = questionIds?.find((item) => item === data?.id)
       if(findQstn) {
        isHighlightAutoCommentQstn = true
       } else {
        isHighlightAutoCommentQstn = false
       }
 }
  return (
    <>
      <Box display='flex' flexDirection='column' padding={'5px'}>
        <Box display='flex' flexDirection='row' >
          <Box display='flex' className="font-12 fg-black bold-600 width-25">
            Q{questionIndex}.{" "}
          </Box>
          <Box display='flex' className={`font-13 bold-500 fg-black white-space pmlr-5 quill-box ${isHighlightAutoCommentQstn && 'red-text'}`}
            dangerouslySetInnerHTML={{__html: data?.question?.statement || ""}}>
          </Box>
        </Box>
        {data?.question?.image_link && 
                <Box>
                <Box className="referenceImageBox" 
                 onClick={ () => {
                  setOpenImageReferencePopUp(true)
                  setReferenceState(data);
                } }
                >
                 <Typography className="referenceText">
                     Image reference
                 </Typography>
                 <img src={eyeIcon} className="referenceImgIcon"/>
                </Box>
             </Box>
        }
        

        <Box display='flex' >
          {data?.difficulty_level && <>
            <span className={`difficulty ${data?.difficulty_level?.toLowerCase() === 'easy' ? 'fg-green' : data?.difficulty_level?.toLowerCase() === 'medium' ? 'fg-gold' : 'fg-red'}`}>{data?.difficulty_level?.toUpperCase()}</span>
            <a className={`bold-400 fg-black td-none pmr-5`}> &#8226; </a>
          </>}
          <CustomizedDialogs
            index={questionIndex}
            mainData={data}
            section={"Theoretical Question"}
            isIndepthKnowledge={isIndepthKnowledge}
          />
        </Box>
      </Box>
    </>
  )
}

  if (dataPreviewList?.length === 0) {
    return <></>;
  }

  const handleCloseSnackBar = (e) => {
    setShowEmptyFieldWarning('');
}

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const filterGuideHistoryInfo = (data) => {
    let result = data?.approvalHistory?.filter((item) => item?.is_approved === GUIDE_APPROVAL_STATUS.APPROVED || item?.is_approved === GUIDE_APPROVAL_STATUS.REJECTED);
    return result;
  }


  const checkGuidePreviousHistory = () => {
      let result = filterGuideHistoryInfo(guideData);
      if(result?.length > 0) {
              return true;
      } else {
            return false;
      }
  }

  const guideApprovalPreviousStatusDetails = (type) => {
    let result = filterGuideHistoryInfo(guideData);
    if(result?.length > 0) {
               if(type === 'isApproved') {
                  return result[result?.length -1]?.is_approved;
               } else if(type === 'reason') {
                  return result[result?.length -1]?.reason;
               } else if( type ==='timestamp') {
                   return result[result?.length -1]?.approval_timestamp;
               }
           
    } else {
          return GUIDE_APPROVAL_STATUS.PENDING;
    }
  }


  const guidePreviousApproverDetails = (type) => {
    let approvalDetails = guideData?.approval_history?.filter((item) => item?. is_approved === GUIDE_APPROVAL_STATUS.APPROVED || item?. is_approved === GUIDE_APPROVAL_STATUS.REJECTED);
    if(approvalDetails?.length > 0) {

              let approverDetails = guideData?.approver_details?.filter((item) => item?.mail_sent_timestamp ===  approvalDetails[approvalDetails?.length -1]?.mail_sent_timestamp);
               if(approverDetails?.length > 0 &&  type === 'name') {
                  return approverDetails[approverDetails?.length - 1]?.name;
               }
           
    }
  } 

  const isGuideLatestStatusPending = () => {
           if(guideLatestStatus === GUIDE_APPROVAL_STATUS.PENDING) {
             return true;
           } else {
              return false;
           }
  }

  return (
    <div className="guide_preview_box">
      <Box display="flex" className="guide_preview_topBar">
        <img src={logoiv} className="iv-logo"/>
        <div className="navbar-text">INTERVIEW GUIDE</div>
        {!decodedToken?.approver_email && 
        <>
               { copyLinkVisibility && !isTemplate ? <div className="share-link" onClick={handleShareableLink}>
          <img src={copy} height="20px" width="20px" /> <p>Copy Link</p>
        </div> : <div></div>}
        {/* ----- Link copied popup ----*/}
       { shareLinkCopied?.open && <Snackbar  anchorOrigin={{ vertical, horizontal }} autoHideDuration={1000}
           open={open} onClose={()=>{setShareLinkCopied({ ...shareLinkCopied, open: false });}} > 
          <SnackbarContent style={{ backgroundColor:'green', marginTop:"30px", fontWeight:'600'}}
              message={<span id="client-snackbar">✅ Link copied to clipboard</span>}/>
        </Snackbar> }
        </>
        }     
      </Box>
      <div className="guide_preview_main_container">

        {((isGuideLatestStatusPending() && decodedToken?.approver_email) ) && 
             <div className="btnBox">
                 <Button className="rejectBtn commonBtn" onClick={() => setOpenRejectGuideModal(true)}>Reject</Button>
                 <Button className="approveBtn commonBtn" onClick={() => handleGuideApproval('approve')}>Approve</Button>
             </div>
         }  
   
  <div className='guideStatusParentBox'>
    { ((isOpsDashboard || isClientDashboard) && ((guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED || guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED)  ||  (approverHistoryCount > 1 && (isGuideLatestStatusPending() && checkGuidePreviousHistory())))) &&
            <div className={`guideApprovalStatusBox`}>
                   <div className={
                       (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) ? 'approvedBox' : 
                       (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'rejectedBox' :
                       guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'approvedBox' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED ?'rejectedBox'  : ''
                    }>
                           <div className="statusTextBox">
                             {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved')  === GUIDE_APPROVAL_STATUS.APPROVED) ||   guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED)  && <img src={approvedIcon} className="approvedIcon"/>}
                              <p className={`statusText ${
                                  (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved')  === GUIDE_APPROVAL_STATUS.APPROVED) ? 'whiteText' : 
                                  (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'redText' :
                                  guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'whiteText' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED  ? 'redText' : '' 
                                }`}>
                                  {(isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) ? 'Approved' : 
                                  (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'Rejected' :
                                  guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'Approved' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED ?  'Rejected' : '' }
                              </p>
                              {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ||   guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED) && <img src={guideRejectedIcon} className="rejectionIcon"/>}
                          </div>
                            {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ||   guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED) &&  
                                 <p className="reasonText">
                                  {
                                         (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 
                                                  guideApprovalPreviousStatusDetails('reason')
                                            : 
                                            guideData?.approval_history[approverHistoryCount - 1]?.reason
                                  }
                                 </p>
                            }
                           
                   </div>

                   <div className="detailBox">
                              <div className="detailSubBox mt-10">
                                <p className="detailLightText">{
                                         (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) ? 'Approved by:' : 
                                         (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED) ? 'Rejected by:' :
                                         guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED ? 'Approved by:' :  guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED ?'Rejected by:'  : ''
                                }</p>
                                <p className="detailBoldText mt-15">
                                  {((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) || (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED)) ? 
                                       guidePreviousApproverDetails('name') : 
                                     guideData?.approver_details[approverHistoryCount - 1]?.name
                                  }
                                </p>
                              </div>
                              <div className="detailSubBox mt-8">
                                <p className="detailLightText">Date & Time:</p>
                                <p className="detailBoldText mt-15">
                                  {
                                      ((isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.APPROVED) || (isGuideLatestStatusPending() && guideApprovalPreviousStatusDetails('isApproved') === GUIDE_APPROVAL_STATUS.REJECTED)) ?
                                       moment.unix(guideApprovalPreviousStatusDetails('timestamp')).format("DD MMM, YYYY | hh:mm A")
                                         :
                                        moment.unix(guideData?.approval_history[approverHistoryCount - 1]?.approval_timestamp).format("DD MMM, YYYY | hh:mm A")
                                    }
                                </p>
                              </div>
                              <div className="detailSubBox mt-8">
                                <p className="detailLightText">Guide Last Updated:</p>
                                <p className="detailBoldText mt-15">{moment.unix(guideData?.last_updated_timestamp).format("DD MMM, YYYY | hh:mm A")}</p>
                              </div>
                   </div>
           </div>         
    }       <div className={`guide_preview_container`}>
          <div>
            <Box display="flex" gap="15px">
            <p className="guide_preview_role">{guideData?.title}</p>
            { (jobDescriptionLink && Object?.keys(jobDescriptionLink)?.length !== 0) && 
              <Box className="jd-link" onClick={()=> window.open(jobDescriptionLink,"_blank")} >See JD <img src={redirectLink} width="12px"/></Box>}
            </Box>
            <p className="guide_preview_round_name">
              {guideData?.role} <a>- {guideData?.round_category} 
              {guideData?.min_experience && guideData?.max_experience? 
              <> ({guideData?.min_experience}-{guideData?.max_experience} yrs)</>:null}
              </a>
            </p>
          </div>
          <hr />
          <div className="guide_preview_details">
            <Box className="h2-tag">{dataPreviewList[0]?.name ? dataPreviewList[0]?.name : ""}</Box>
            {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[0]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[0]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[0]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
                isApprovalDetails={(guideLatestStatus === GUIDE_APPROVAL_STATUS.APPROVED) || (guidePreviousStatus == GUIDE_APPROVAL_STATUS.APPROVED && isGuideLatestStatusPending())}
                isRejectionDetails={(guideLatestStatus === GUIDE_APPROVAL_STATUS.REJECTED) || (guidePreviousStatus == GUIDE_APPROVAL_STATUS.REJECTED && isGuideLatestStatusPending())}
              />
             
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[0]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[0]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[0]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[0]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[0]?.type
                )
              }
              className={
                getDefaultCss(guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[0]?.type],  commentData?.data?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[0]?.type])
              }
            >
            <Box>
            <p className="guide_preview_heading">1. GENERIC INSTRUCTIONS </p>
            <ul className="guide_preview_box_1">
            { 
              dataPreviewList[0]?.sections[0]?.default?.map((data) => { 
                data?.selected && (data?.is_important ? importantGenericInstructions.push(data?.label) : normalGenericInstructions.push(data?.label)) })
            }
            { dataPreviewList[0]?.sections[0]?.extras?.map((data) => {
              data?.selected && (data?.is_important ? importantGenericInstructions.push(data?.label) : normalGenericInstructions.push(data?.label)) })            
            }
            { importantGenericInstructions.map((item)=> {
                return <li className = 'imp-points'>{item}</li>
              })
            }
            { normalGenericInstructions.map((item)=> {
                return <li>{item}</li>
              })
            }
            </ul>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[0]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[0]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[0]?.type
                    ] === "main-box dark-yellow-box"
                  }
                  isTemplate={isTemplate}
                />
            </Box>
            </Box>
           
            {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[1]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[1]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[1]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[1]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[1]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[1]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[1]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[1]?.type
                )
              }
              className={getDefaultCss(guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[1]?.type], commentData?.data?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[1]?.type])}
            >
            <Box>
              <p className="guide_preview_heading">2. RESULT CLARITY</p>
            
            <div className="guide_preview_content_box  mb-20p pml-20">
             <ResultClarity ratingsList={dataPreviewList[0]?.sections[1]?.values} isPreview={true}/>
          </div>
          </Box>
          <Box className="comment">
          <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[1]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[1]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[1]?.type
                    ] === "main-box dark-yellow-box"
                  }
                  isTemplate={isTemplate}
                />
          </Box>
</Box>
    

    {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[2]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[2]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[2]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[2]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[2]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[2]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[2]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[2]?.type
                )
              }
              className={getDefaultCss(guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[2]?.type], commentData?.data?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[2]?.type])}
            >
             <Box>
            <p className="guide_preview_heading">3. INTERVIEW SECTIONS</p>
            <ul className="guide_preview_box_1">
              {dataPreviewList[0]?.sections[2]?.values?.map((data) => {
                return (
                  <>
                  {data?.time?.value > 0 && <li>
                    {data?.label}
                    <a className="bold-600">&nbsp;({data?.time?.value}  {data?.time?.unit})</a>
                  </li>}
                  </>
                );
              })}
            </ul>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[2]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[2]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[2]?.type
                    ] === "main-box dark-yellow-box"
                  }
                  isTemplate={isTemplate}
                />
            </Box>
      </Box>     
     

      {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[3]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[3]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[3]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[3]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[3]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[3]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[3]?.type
                  ]?.length,
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[3]?.type
                )
              }
              className={getDefaultCss(guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[3]?.type], commentData?.data?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[3]?.type])}
            >
             <Box>
            <p className="guide_preview_heading">
              4. EVALUATION PARAMETERS FOR CANDIDATE PERFORMANCE
            </p>
            <div className="guide_preview_content_box guide_preview_box_2 mb-20p">
              
              {filterEvaluationObject?.high?.length === 0 && filterEvaluationObject?.medium?.length === 0 && filterEvaluationObject?.low?.length === 0 && 
              <div className="no-eval-param"> No evaluation parameters added</div>}
              
              {filterEvaluationObject?.high &&
                filterEvaluationObject?.high?.length > 0 ? (
                <div>
                  <span className="guide_preview_result fg-blue"> High </span>
                  <div className="guide_preview_arrow guide_preview_arrow_5"></div>
                  <div className="guide_preview_arrow_head_right"></div>
                  {filterEvaluationObject?.high.map((data) => {
                    return (
                      <div className="guide_preview_chip bg-blue">
                        {data.label}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              {filterEvaluationObject?.medium &&
                filterEvaluationObject?.medium?.length > 0 ? (
                <div>
                  <span className="guide_preview_result fg-lightblue2">Medium</span>
                  <div className="guide_preview_arrow guide_preview_arrow_6"></div>
                  <div className="guide_preview_arrow_head_right"></div>
                  {filterEvaluationObject?.medium.map((data) => {
                    return (
                      <div className="guide_preview_chip bg-lightblue fg-black">
                        {data.label}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              {filterEvaluationObject?.low &&
                filterEvaluationObject?.low?.length > 0 ? (
                <div>
                  <span className="guide_preview_result fg-gray2"> Low </span>
                  <div className="guide_preview_arrow guide_preview_arrow_7"></div>
                  <div className="guide_preview_arrow_head_right"></div>
                  {filterEvaluationObject?.low &&
                    filterEvaluationObject?.low.map((data) => {
                      return (
                        <div className="guide_preview_chip bg-gray fg-lightblack bold-400">
                          {data.label}
                        </div>
                      );
                    })}
                </div>
              ) : (
                ""
              )}
            </div>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[3]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[3]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[3]?.type
                    ] === "main-box dark-yellow-box"
                  }
                  isTemplate={isTemplate}
                />
            </Box>
            </Box>
           

            {commentData?.name &&
            commentData?.email &&
            guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
              dataPreviewList[0]?.sections[4]?.type
            ] === "main-box dark-yellow-box" ? (
              <RightCommentBox
                data={commentData}
                setData={setCommentData}
                comments={
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[4]?.type
                  ]
                }
                guideCommentAPIS={guideCommentAPIS}
                type={dataPreviewList[0]?.type}
                subType={dataPreviewList[0]?.sections[4]?.type}
                showNewCommentBox={showNewCommentBox}
                setShowNewCommentBox={setShowNewCommentBox}
              />
            ) : (
              ""
            )}
            <Box
              onMouseEnter={() =>
                handleMouseEnter(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[4]?.type,
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[4]?.type
                  ]?.length
                )
              }
              onMouseLeave={() =>
                handleMouseLeave(
                  dataPreviewList[0]?.type,
                  dataPreviewList[0]?.sections[4]?.type
                )
              }
              onClick={() =>
                handleMouseClick(
                  commentData?.data?.[dataPreviewList[0]?.type]?.[
                    dataPreviewList[0]?.sections[4]?.type
                  ]?.length,
                  [dataPreviewList[0]?.type],
                  dataPreviewList[0]?.sections[4]?.type
                )
              }
              className={getDefaultCss(guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[4]?.type], commentData?.data?.[dataPreviewList[0]?.type]?.[dataPreviewList[0]?.sections[4]?.type])}
            >
             <Box>
            <p className="guide_preview_heading">5. RESUME DISCUSSION</p>
            <ul className="guide_preview_box_1">
              {dataPreviewList[0]?.sections[4]?.default?.map((data) => {
                return data?.selected ? <li>{data?.label}</li> : "";
              })}
              {dataPreviewList[0]?.sections[4]?.extras.map((data) => {
                return data?.selected ? <li>{data?.label}</li> : "";
              })}
            </ul>
            </Box>
            <Box className="comment">
            <Comments
                  isDialogOpen={isProfileDialogOpen}
                  setIsDialogOpen={setIsProfileDialogOpen}
                  handleDefaultCss={() =>
                    handleDefaultCss(
                      dataPreviewList[0]?.type,
                      dataPreviewList[0]?.sections[4]?.type
                    )
                  }
                  data={commentData}
                  setData={setCommentData}
                  showNewCommentBox={showNewCommentBox}
                  setShowNewCommentBox={setShowNewCommentBox}
                  getCssClass={() =>
                    getCssClass(
                      commentData?.data?.[dataPreviewList[0]?.type]?.[
                        dataPreviewList[0]?.sections[4]?.type
                      ]?.length
                    )
                  }
                  isRightBoxOpen={
                    guideSectionCssEffect?.[dataPreviewList[0]?.type]?.[
                      dataPreviewList[0]?.sections[4]?.type
                    ] === "main-box dark-yellow-box"
                  }
                  isTemplate={isTemplate}
                />
            </Box>
      </Box>    
           
          </div>

          {
            dataPreviewList[1] &&

            ((dataPreviewList[1]?.sections[0]?.values &&
              (dataPreviewList[1]?.sections[0]?.values?.easy > 0 ||
                dataPreviewList[1]?.sections[0]?.values?.medium > 0 ||
                dataPreviewList[1]?.sections[0]?.values?.hard > 0))

              ||

              (dataPreviewList[1]?.sections[2]?.default?.length > 0 ||
                dataPreviewList[1]?.sections[2]?.external?.length > 0)
            )
            &&
            <>
              <hr />
              
              <div className="guide_preview_details">
              <Box display="flex" flexDirection="row" alignItems="flex-end" gap="10px">
                <Box className="h2-tag">Technical Questions</Box>
              </Box>  
              {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                    dataPreviewList[1]?.sections[0]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[1]?.type}
                      subType={dataPreviewList[1]?.sections[0]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />                  
                  ) : (
                    ""
                  )}
                  <Box
                    onMouseEnter={() =>
                      handleMouseEnter(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type,
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type
                      )
                    }
                    onClick={() =>
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]?.length,
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type
                      )
                    }
                    className={ getDefaultCss(guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[0]?.type], commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[0]?.type])}
                  >
             <Box>
                {
                  dataPreviewList[1]?.sections[0]?.values &&
                  (dataPreviewList[1]?.sections[0]?.values?.easy > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.medium > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.hard > 0) &&
                   
                  <div className="guide-questions-ask-label mb-10p">
                     <div className="guide-questions-ask-label bold-400 mb-5p">  Please ask {totalQuestionasked(dataPreviewList[1]?.sections[0]?.values)} questions in total.</div>
                  
                    <>
                      {dataPreviewList[1]?.sections[0]?.values &&
                        dataPreviewList[1]?.sections[0]?.values?.easy?.length !== 0 &&
                        dataPreviewList[1]?.sections[0]?.values?.easy !== 0
                        ? (
                          <p className="mb-5p">
                            <span className="bold-700">
                              {dataPreviewList[1]?.sections[0]?.values?.easy}
                            </span>
                            <div className="guide_preview_chip bg-green2">
                              Easy
                            </div>
                          </p>
                        ) : (
                          ""
                        )}
                      {dataPreviewList[1]?.sections[0]?.values &&
                        dataPreviewList[1]?.sections[0]?.values?.medium?.length !== 0 &&
                        dataPreviewList[1]?.sections[0]?.values?.medium !== 0 ? (
                        <p className="mb-5p">
                          <span className="bold-700">
                            {dataPreviewList[1]?.sections[0]?.values?.medium}
                          </span>
                          <div className="guide_preview_chip bg-gold2">
                            Medium
                          </div>
                        </p>
                      ) : (
                        ""
                      )}
                      {dataPreviewList[1]?.sections[0]?.values &&
                        dataPreviewList[1]?.sections[0]?.values?.hard?.length !== 0 &&
                        dataPreviewList[1]?.sections[0]?.values?.hard !== 0 ? (
                        <p>
                          <span className="bold-700">
                            {dataPreviewList[1]?.sections[0]?.values?.hard}
                          </span>
                          <div className="guide_preview_chip bg-red">Hard</div>
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                 
                }
               


                {
                   (dataPreviewList[1]?.sections[0]?.values?.easy > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.medium > 0 ||
                    dataPreviewList[1]?.sections[0]?.values?.hard > 0) && 
                    (dataPreviewList[1]?.sections[0]?.values?.question_topic) &&
                    Object.keys(dataPreviewList[1]?.sections[0]?.values?.question_topic)?.length > 0 &&

                    <div>
                        <div><p className="questionAskText">From the below topic list:</p></div>
                    <div> {topicList(technicalQuestionTopic)}</div>
                    </div>
                }
                </Box>
              <Box className="comment">
              <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[1]?.type,
                            dataPreviewList[1]?.sections[0]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[1]?.type]?.[
                              dataPreviewList[1]?.sections[0]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                            dataPreviewList[1]?.sections[0]?.type
                          ] === "main-box dark-yellow-box"
                        }
                        isTemplate={isTemplate}
                      />
              </Box>
              </Box>
              

              {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                    dataPreviewList[1]?.sections[2]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[2]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[1]?.type}
                      subType={dataPreviewList[1]?.sections[2]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}

                  <Box
                    onMouseEnter={() =>
                      handleMouseEnter(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[2]?.type,
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[2]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[2]?.type
                      )
                    }
                    onClick={() =>
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[2]?.type
                        ]?.length,
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[2]?.type
                      )
                    }
                    className={
                      getDefaultCss(dataPreviewList[1]?.sections[2]?.default.length === 0 && dataPreviewList[1]?.sections[2]?.external.length === 0?"" :
                          guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type], 
                          commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type])
                    }
                  >
              <Box>
                {dataPreviewList[1]?.sections[2]?.default?.length === 0 &&
                  dataPreviewList[1]?.sections[2]?.external?.length === 0 ? (
                  ""
                ) : (<>
                  <p className="fg-black2 font-12 bold-600 mb-5p">Questions List</p>
                  <p className="font-12 fg-gray3">
                    These are the questions suggested for you to ask.
                  </p>
                  
                </>)}

                {technicalQuestionTopic?.length === 0 &&
                  <>
                    {dataPreviewList[1]?.sections[2]?.default.map((data, index) => {
                      return (
                        <QuestionCard
                          section="Technical Question"
                          index={index + 1}
                          mainData={data}
                          downloadFile={downloadImage}
                          commentData={commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type]}
                        />
                      );
                    })}
                    {dataPreviewList[1]?.sections[2]?.external.map((data, index) => {
                      return (
                        <QuestionCard
                          section="Technical Question"
                          index={dataPreviewList[1]?.sections[2]?.default?.length + index + 1}
                          mainData={data}
                          downloadFile={downloadImage}
                          commentData={commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type]}
                        />
                      );
                    })}
                  </>}
                
                {technicalQuestionTopic?.length > 0 &&
                  <>
                    {
                      technicalQuestionTopic?.map(questionTopic => {
                        let filterArr = dataPreviewList[1]?.sections[2]?.default?.filter(e => { return e.topic === questionTopic?.key }).concat(dataPreviewList[1]?.sections[2]?.external.filter(e => { return e.topic === questionTopic?.key }));
                        return filterArr.length > 0 ? (
                          <Box className='topicContainer'>
                            <Typography className="questionTopic">{questionTopic?.key}</Typography>
                            <Box className="flexBox">
                               <Box className="noteBox">
                                    <Typography className="noteText">Note</Typography>
                                  </Box>
            
                               <Box style={{marginLeft: "5px"}}> 
                                <Typography className="ques-sub-heading">Ask not more than {questionTopic?.value} questions from this topic.</Typography> 
                              </Box>
                            </Box>
                          

                            {dataPreviewList[1]?.sections[2]?.default?.map((data, index) => {
                              if (data.topic === questionTopic?.key)
                                return <QuestionCard
                                  section="Technical Question"
                                  index={index + 1}
                                  mainData={data}
                                  downloadFile={downloadImage}
                                  commentData={commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type]}
                                />
                            })
                            }
                            {dataPreviewList[1]?.sections[2]?.external?.map((data, index) => {
                              if (data.topic === questionTopic?.key)
                                return <QuestionCard
                                  section="Technical Question"
                                  index={index + 1}
                                  mainData={data}
                                  downloadFile={downloadImage}
                                  commentData={commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type]}
                                />
                            })
                            }</Box>) : null
                      })
                    }

                    {dataPreviewList[1]?.sections[2]?.default?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) }).concat(dataPreviewList[1]?.sections[2]?.external?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) })).length > 0 &&
                      <Box className='topicContainer'>
                        <Typography className="questionTopic">General Questions</Typography>
                        {dataPreviewList[1]?.sections[2]?.default?.map((data, index) => {
                          if (data.topic === null || data.topic === undefined || !data.topic)
                            return <QuestionCard
                              section="Technical Question"
                              index={index + 1}
                              mainData={data}
                              downloadFile={downloadImage}
                              commentData={commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type]}
                            />
                        })
                        }
                        {dataPreviewList[1]?.sections[2]?.external?.map((data, index) => {
                          if (data.topic === null || data.topic === undefined || !data.topic)
                            return <QuestionCard
                              section="Technical Question"
                              index={index + 1}
                              mainData={data}
                              downloadFile={downloadImage}
                              commentData={commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[2]?.type]}
                            />
                        })
                        }</Box>}
                  </>
                }
                </Box>
                <Box className="comment">
                {
                  dataPreviewList[1]?.sections[2]?.default.length === 0 &&
                  dataPreviewList[1]?.sections[2]?.external.length === 0?"":
                  <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[1]?.type,
                            dataPreviewList[1]?.sections[2]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[1]?.type]?.[
                              dataPreviewList[1]?.sections[2]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                            dataPreviewList[1]?.sections[2]?.type
                          ] === "main-box dark-yellow-box"
                        }
                        isTemplate={isTemplate}
                      />
                }
                
                </Box>
      </Box>
    
              </div>
            </>}


          {
          ((isIndepthKnowledge) ||

          dataPreviewList[2] &&

          (dataPreviewList[2]?.sections[0]?.values?.total > 0
            ||
            (dataPreviewList[2]?.sections[1]?.default.length > 0 ||
              dataPreviewList[2]?.sections[1]?.external?.length > 0)
          )
               
          
          )
            
            &&
            <>
              <hr />
              <div className="guide_preview_details">
                <Box className="h2-tag">{isIndepthKnowledge ? "InDepth Knowledge" : 'Theoretical Questions'}</Box>
                {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                    dataPreviewList[2]?.sections[0]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[0]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[2]?.type}
                      subType={dataPreviewList[2]?.sections[0]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}
                  {isIndepthKnowledge && commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                    dataPreviewList[1]?.sections[0]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[1]?.type}
                      subType={dataPreviewList[1]?.sections[0]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    onMouseEnter={() =>
                      isIndepthKnowledge ?
                       handleMouseEnter(
                          dataPreviewList[1]?.type,
                         dataPreviewList[1]?.sections[0]?.type, 
                         commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]?.length
                       )
                        : 
                      handleMouseEnter(
                          dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[0]?.type,
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[0]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() => isIndepthKnowledge ? 
                      handleMouseLeave(dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type) : 
                        handleMouseLeave(dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[0]?.type)
                    }
                    onClick={() =>
                      isIndepthKnowledge ? 
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ]?.length,
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[0]?.type
                      )
                      :
                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[0]?.type
                        ]?.length,
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[0]?.type
                      )
                    }
                    className={ 
                      getDefaultCss(
                        isIndepthKnowledge ? 
                        (
                          guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                            dataPreviewList[1]?.sections[0]?.type]
                        ) 
                        : 
                        (
                          guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                            dataPreviewList[2]?.sections[0]?.type]
                        ),
                        isIndepthKnowledge ? 
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[0]?.type
                        ] 
                        :
                          commentData?.data?.[dataPreviewList[2]?.type]?.[
                            dataPreviewList[2]?.sections[0]?.type
                          ]
                      ) 
                    }
                  >

                  <Box>                
                {(dataPreviewList[1]?.sections[0]?.values?.total > 0 || dataPreviewList[2]?.sections[0]?.values?.total > 0) &&
                  <div className="guide-questions-ask-label mb-10p">
                    <div className="guide-questions-ask-label bold-400 mb-5p">Total Number of questions to ask: {" "}
                    <span className="bold-700" style={{fontSize: 13}}>
                      {isIndepthKnowledge ? dataPreviewList[1]?.sections[0]?.values?.total  : dataPreviewList[2]?.sections[0]?.values?.total}
                    </span>
                    </div>
                   </div>       
                }


                {dataPreviewList[2]?.sections[0]?.values?.total > 0 &&  dataPreviewList[2]?.sections[0]?.values?.question_topic && Object.keys(dataPreviewList[2]?.sections[0]?.values?.question_topic)?.length > 0  && !isIndepthKnowledge &&
                   <div style={{marginTop: 20}}>
                      <div><p className="questionAskText">From the below topic list:</p></div>
                      <div> {topicList(theoreticalQuestionTopics)}</div>
                   </div>
                }    
                </Box>
                <Box className="comment">
                <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          isIndepthKnowledge ? 
                          handleDefaultCss(
                            dataPreviewList[1]?.type,
                            dataPreviewList[1]?.sections[0]?.type
                          )
                          :
                          handleDefaultCss(
                            dataPreviewList[2]?.type,
                            dataPreviewList[2]?.sections[0]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          isIndepthKnowledge ? 
                          getCssClass(
                            commentData?.data?.[dataPreviewList[1]?.type]?.[
                              dataPreviewList[1]?.sections[0]?.type
                            ]?.length
                          )
                          :
                          getCssClass(
                            commentData?.data?.[dataPreviewList[2]?.type]?.[
                              dataPreviewList[2]?.sections[0]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={ isIndepthKnowledge ?
                           (
                            guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                              dataPreviewList[1]?.sections[0]?.type
                            ] === "main-box dark-yellow-box"
                           ) :
                           (
                            guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                              dataPreviewList[2]?.sections[0]?.type
                            ] === "main-box dark-yellow-box"
                           )
                         
                        }
                        isTemplate={isTemplate}
                      />
                </Box>
                </Box> 
            
                {commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                    dataPreviewList[2]?.sections[1]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[1]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[2]?.type}
                      subType={dataPreviewList[2]?.sections[1]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}

              {isIndepthKnowledge && commentData?.name &&
                  commentData?.email &&
                  guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                    dataPreviewList[1]?.sections[1]?.type
                  ] === "main-box dark-yellow-box" ? (
                    <RightCommentBox
                      data={commentData}
                      setData={setCommentData}
                      comments={
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[1]?.type
                        ]
                      }
                      guideCommentAPIS={guideCommentAPIS}
                      type={dataPreviewList[1]?.type}
                      subType={dataPreviewList[1]?.sections[1]?.type}
                      showNewCommentBox={showNewCommentBox}
                      setShowNewCommentBox={setShowNewCommentBox}
                    />
                  ) : (
                    ""
                  )}
                  <Box
                    onMouseEnter={() =>
                      isIndepthKnowledge ? 
                      handleMouseEnter(
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[1]?.type,
                        0
                      )
                      
                      :
                      handleMouseEnter(
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[1]?.type,
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[1]?.type
                        ]?.length
                      )
                    }
                    onMouseLeave={() =>
                      isIndepthKnowledge ? 
                      handleMouseLeave(dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[1]?.type) :
                      handleMouseLeave(
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[1]?.type
                      )
                    }
                    onClick={() =>
                      isIndepthKnowledge ? 
                       handleMouseClick(
                        commentData?.data?.[dataPreviewList[1]?.type]?.[
                          dataPreviewList[1]?.sections[1]?.type
                        ]?.length,
                        dataPreviewList[1]?.type,
                        dataPreviewList[1]?.sections[1]?.type
                       ):

                      handleMouseClick(
                        commentData?.data?.[dataPreviewList[2]?.type]?.[
                          dataPreviewList[2]?.sections[1]?.type
                        ]?.length,
                        dataPreviewList[2]?.type,
                        dataPreviewList[2]?.sections[1]?.type
                      )
                    }
                    className={
                      getDefaultCss(
                        isIndepthKnowledge ? 
                        (
                          dataPreviewList[1]?.sections[1]?.default.length === 0 &&
                          dataPreviewList[1]?.sections[1]?.external.length === 0?"":
                              guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                                dataPreviewList[1]?.sections[1]?.type
                              ]
                        ) : 
                        (
                          dataPreviewList[2]?.sections[1]?.default.length === 0 &&
                          dataPreviewList[2]?.sections[1]?.external.length === 0?"":
                              guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                                dataPreviewList[2]?.sections[1]?.type
                              ]
                        ) ,

                        isIndepthKnowledge ? 
                        commentData?.data?.[dataPreviewList[1]?.type]?.[dataPreviewList[1]?.sections[1]?.type] 
                           : 
                         commentData?.data?.[dataPreviewList[2]?.type]?.[dataPreviewList[2]?.sections[1]?.type]
                      )}
                  >
                <Box className="marginQstnBox">
                {dataPreviewList[2]?.sections[1]?.default.length === 0 &&
                  dataPreviewList[2]?.sections[1]?.external.length === 0 ? (
                  ""
                ) : (<>
                  <p className="fg-black2 font-12 bold-600">Questions List</p>


                  <p className="font-12 fg-gray3 nmt-10">
                  { (theoreticalQuestionTopics?.length === 0 && !isIndepthKnowledge) ?
                   <> These are the questions suggested for you to ask. Please ask any <span className="pml-5">{dataPreviewList[2]?.sections[0]?.values?.total}</span> questions from these.</>
                 : <> These are the topic-wise questions suggested for you to ask.</>
                  }
                  </p>

                    {(isIndepthKnowledge ? inDepthKnowledgeQuestionTopic?.length === 0 : theoreticalQuestionTopics?.length === 0) &&
                      <>
                        {(dataPreviewList[2]?.sections[1]?.default.length !== 0)
                          ? dataPreviewList[2]?.sections[1]?.default?.map(
                            (data, index) => {
                              return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            }
                          )
                          : ""}

                        {dataPreviewList[2]?.sections[1]?.external.length !== 0
                          ? dataPreviewList[2]?.sections[1]?.external?.map(
                            (data, index) => {
                              return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            }
                          )
                          : ""}

                          {/* indepthKnowledgeCode.... */}

                          {(isIndepthKnowledge && dataPreviewList[1]?.sections[1]?.default.length !== 0)
                          ? dataPreviewList[1]?.sections[1]?.default?.map(
                            (data, index) => {
                              return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            }
                          )
                          : ""}

                        {isIndepthKnowledge && dataPreviewList[1]?.sections[1]?.external.length !== 0
                          ? dataPreviewList[1]?.sections[1]?.external?.map(
                            (data, index) => {
                              return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            }
                          )
                          : ""}


                      </>}
                </>)}

                {((isIndepthKnowledge && inDepthKnowledgeQuestionTopic?.length > 0) || ( theoreticalQuestionTopics?.length > 0))
                  &&
                <>
                    {
                      (isIndepthKnowledge ?  inDepthKnowledgeQuestionTopic : theoreticalQuestionTopics)?.map(questionTopic => {
                            let filterArr = isIndepthKnowledge ? dataPreviewList[1]?.sections[1]?.default?.filter(e => { return e.topic === questionTopic?.key }).concat(dataPreviewList[1]?.sections[1]?.external.filter(e => { return e.topic === questionTopic?.key })) : dataPreviewList[2]?.sections[1]?.default?.filter(e => { return e.topic === questionTopic?.key }).concat(dataPreviewList[2]?.sections[1]?.external.filter(e => { return e.topic === questionTopic?.key }));
                            return filterArr.length > 0 ? (
                                <Box className='topicContainer'>
                                   
                                <Typography className="questionTopic">{questionTopic?.key}</Typography>
                                <Box className="flexBox">
                                   <Box className="noteBox">
                                    <Typography className="noteText">Note</Typography>
                                  </Box>
            
                                    <Box style={{marginLeft: "5px"}}> 
                                       <Typography className="ques-sub-heading">Ask not more than {questionTopic?.value} questions from this topic.</Typography> 
                                   </Box>
                               </Box>
                          
                                
                                {dataPreviewList[2]?.sections[1]?.default?.map((data, index) => {
                                    if (data?.topic === questionTopic?.key)
                                    return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                                })
                                }
                                {dataPreviewList[2]?.sections[1]?.external?.map((data, index) => {
                                    if (data?.topic === questionTopic?.key)
                                    return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                                })
                                }

                              {isIndepthKnowledge && dataPreviewList[1]?.sections[1]?.default?.map((data, index) => {
                                    if (data?.topic === questionTopic?.key)
                                    return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                                })
                                }
                                {isIndepthKnowledge && dataPreviewList[1]?.sections[1]?.external?.map((data, index) => {
                                    if (data?.topic === questionTopic?.key)
                                    return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                                })
                                }
                                </Box>
                                ) 
                                : null
                        })
                    }
                    
                    {(isIndepthKnowledge ?  (dataPreviewList[1]?.sections[1]?.default?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) }).concat(dataPreviewList[1]?.sections[1]?.external?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) })).length > 0 )  :   (dataPreviewList[2]?.sections[1]?.default?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) }).concat(dataPreviewList[2]?.sections[1]?.external?.filter(e => { return (e.topic === null || e.topic === undefined || !e.topic) })).length > 0)) &&
                        <Box className='topicContainer'>
                            <Typography className="questionTopic">General Questions</Typography>
                            {dataPreviewList[2]?.sections[1]?.default?.map((data, index) => {
                                if (data.topic === null || data.topic === undefined || !data.topic)
                                return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            })
                            }
                            {dataPreviewList[2]?.sections[1]?.external?.map((data, index) => {
                                if (data.topic === null || data.topic === undefined || !data.topic)
                                return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            })
                            }

                              {isIndepthKnowledge && dataPreviewList[1]?.sections[1]?.default?.map((data, index) => {
                                if (data.topic === null || data.topic === undefined || !data.topic)
                                return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            })
                            }
                            {isIndepthKnowledge && dataPreviewList[1]?.sections[1]?.external?.map((data, index) => {
                                if (data.topic === null || data.topic === undefined || !data.topic)
                                return renderTheoreticalRow(++questionIndex, data, index, commentDetails)
                            })
                            }
                            </Box>
                          }                    
                </>
            }
            </Box>
            <Box className="comment">
            {!isIndepthKnowledge && (dataPreviewList[2]?.sections[1]?.default.length === 0 &&
                  dataPreviewList[2]?.sections[1]?.external.length === 0?"":
                  <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[2]?.type,
                            dataPreviewList[2]?.sections[1]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[2]?.type]?.[
                              dataPreviewList[2]?.sections[1]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[2]?.type]?.[
                            dataPreviewList[2]?.sections[1]?.type
                          ] === "main-box dark-yellow-box"
                        }
                        isTemplate={isTemplate}
                      />
                  )}

          {isIndepthKnowledge && (dataPreviewList[1]?.sections[1]?.default.length === 0 &&
                  dataPreviewList[1]?.sections[1]?.external.length === 0?"":
                  <Comments
                        isDialogOpen={isProfileDialogOpen}
                        setIsDialogOpen={setIsProfileDialogOpen}
                        handleDefaultCss={() =>
                          handleDefaultCss(
                            dataPreviewList[1]?.type,
                            dataPreviewList[1]?.sections[1]?.type
                          )
                        }
                        data={commentData}
                        setData={setCommentData}
                        showNewCommentBox={showNewCommentBox}
                        setShowNewCommentBox={setShowNewCommentBox}
                        getCssClass={() =>
                          getCssClass(
                            commentData?.data?.[dataPreviewList[1]?.type]?.[
                              dataPreviewList[1]?.sections[1]?.type
                            ]?.length
                          )
                        }
                        isRightBoxOpen={
                          guideSectionCssEffect?.[dataPreviewList[1]?.type]?.[
                            dataPreviewList[1]?.sections[1]?.type
                          ] === "main-box dark-yellow-box"
                        }
                        isTemplate={isTemplate}
                      />
                  )}
            
            
            </Box>
</Box>


              </div>
            </>
          }
        </div>
        </div>
        {((isGuideLatestStatusPending() && decodedToken?.approver_email) ) && 
             <div className="btnBox bottomButton">
                 <Button className="rejectBtn commonBtn" onClick={() => setOpenRejectGuideModal(true)}>Reject</Button>
                 <Button className="approveBtn commonBtn" onClick={() => handleGuideApproval('approve')}>Approve</Button>
             </div>
         }  
      </div>   
      {openImageReferencePopUp &&  <ViewReferenceImage open={openImageReferencePopUp} handleClose={() => setOpenImageReferencePopUp(false)} data={referenceState} downloadFile={downloadImage}/>}
      <RejectModal 
         open={openRejectGuideModal}
         onClose={() => {
          setOpenRejectGuideModal(false)
          setGuideRejectionReason('')
         }}
         handleGuideApproval={handleGuideApproval}
         setGuideRejectionReason={setGuideRejectionReason}
         disable={isApprovalStatusBtnDisable}
      />
      <Snackbar
          open={(showGuideApprovedStatus === 'Rejected' || showGuideApprovedStatus === 'Approved')}
          autoHideDuration={5000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: 'top', 
            horizontal: 'center',
          }}
          style={{ top: 70, left: 850 }}

        >
           <SnackbarContent
             message={`Interview Guide ${showGuideApprovedStatus}`}
            className="emptyFieldSnackBar"
            action={action}
      />
          </Snackbar>
  </div>
  );
};

export default GuidePreview;