import React, { useState, useEffect } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { loadImage } from "./image";
import "react-lazy-load-image-component/src/effects/blur.css";
import profilePlaceholder from "../images/profile-user.svg";

const ImageLoad = ({ data, url }) => {
  const [image, setImage] = useState(data?.logo ? data?.logo : "");
  
  useEffect(() => {
    if (url) {
      loadingImage(url);
    }
  }, [url]);

  const loadingImage = (url) => {
    loadImage(url, (err, result) => {
      if (result) {
        setImage(result);
        try{
          data.logo = result;
        }
        catch(e)
        { console.log(e);}
      }
    });
  };
  
  return (
    <LazyLoadImage
      height="100%"
      width="100%"
      src={image ? image : profilePlaceholder}
      style={{ borderRadius: "50%" }}
    />
  );
};

export default ImageLoad;
