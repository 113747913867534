import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import axios from "../services/axios";
import swal from "sweetalert";
import user from "./reducers/User";
import auth from "./reducers/Auth";
import history from "./reducers/History";
import schedule from "./reducers/Schedule";
import requests from "./reducers/Requests";
import profile from "./reducers/Profile";
import availability from "./reducers/Availability";
import SwtAlert from "../utilis/sweetalert/SwtAlert";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
import {refreshAuth} from "../services/auth";
import cogoToast from "cogo-toast";

export default function configureStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const config = {
      key: "root",
      storage,
      blacklist: ['history', 'schedule', 'requests', 'profile', 'availability'],
      debug: true,
    };

  const appReducer = persistCombineReducers(config, {
    /* your app’s top-level reducers */
    auth: auth,
    user: user,
    history: history,
    schedule: schedule,
    requests: requests,
    profile: profile,
    availability: availability,
  });

  const rootReducer = (state, action) => {
    if (action.type === "persist/REHYDRATE") {
      const payload = action.payload;
      if (payload?.auth?.access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${payload.auth.access_token}`;
      } else {
        axios.defaults.headers.common['Authorization'] = null;
      }
    }

    if (action.type === "AUTH_LOGOUT_SUCCESS") {
      const { _persist } = state;
      state = { _persist };
      axios.defaults.headers.common['Authorization'] = null;
    }

    return appReducer(state, action);
  };

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  const { dispatch } = store; // direct access to redux store.
  let isRefreshing = false;
  let UNAUTHORIZED_REQUESTS_QUEUE = [];
  const UNAUTHORIZED = 401;

  const handleLogout = (type) => {
    axios.defaults.headers.common['Authorization'] = null;
    if(type !== 'refreshExpired') {
      swal("Unauthorized Access! Please login again", {
        icon: "warning",
        buttons: false,
        timer: 3000,
      });
    }
    dispatch({
      type: "SET_AUTH_USER",
      payload: {},
    });
    dispatch({
      type: "AUTH_LOGOUT_SUCCESS",
      payload: null,
    });
  };

  const handleAuthUpdate = (access_token, refresh_token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    dispatch({
      type: "AUTH_LOGIN_SUCCESS",
      payload: {
        access_token,
        refresh_token
      },
    });
  };

  const processUnauthorizedQueue = (error, token = null) => {
    UNAUTHORIZED_REQUESTS_QUEUE.forEach((p) => {
      if (error) {
        p.reject(error);
      } else {
        p.resolve(token);
      }
    });

    UNAUTHORIZED_REQUESTS_QUEUE = [];
  };

  axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const status = error?.response?.status;
        if (status === UNAUTHORIZED && !error?.config?.__isRetryRequest) {
          try {
            if (isRefreshing) {
              try {
                if (error?.config?.url === "/auth/refresh" && status === UNAUTHORIZED ){
                  // set time out time is set to 4 sec so that we can logout it after swtalert disappears.
                SwtAlert("Your login session has expired. Please login again...", 3000, "error", false);
                setTimeout(() => {
                  handleLogout('refreshExpired');
                },3000)
              }

                const token = await new Promise((resolve, reject) => {
                  UNAUTHORIZED_REQUESTS_QUEUE.push({ resolve, reject });
                });
                error.config.headers.Authorization = `Bearer ${token}`;
                return axios(error.config);
              }
              catch (e) {
                return e;
              }
            }

            const currentState = store.getState();
            const { refresh_token: current_refresh_token } = currentState.auth;

            if (!current_refresh_token) {
              cogoToast.error('You are not allowed to access this resource');
              handleLogout();
              return;
            }

            isRefreshing = true;
            error.config.__isRetryRequest = true;
            return new Promise(async (resolve, reject) => {
              try {
                const {access_token, refresh_token} = await refreshAuth(current_refresh_token);
                handleAuthUpdate(access_token, refresh_token);

                isRefreshing = false;
                error.config.headers.Authorization = `Bearer ${access_token}`;
                processUnauthorizedQueue(null, access_token);
                resolve(axios(error.config));
              } catch (e) {
                cogoToast.error('Failed to authenticate. Please login again!');
                processUnauthorizedQueue(e, null);
                reject(error.response);
              }
            });
          } catch(e) {
            cogoToast.error('You are not allowed to access this resource');
            handleLogout();
          }
        }

        return Promise.reject(error);
      }
  );

  const persistor = persistStore(store);

  return { persistor, store };
}

