export const DashboardType = {
  INTERVIEWER: "iv-interviewer-web-app",
  OPS: "iv-ops-web-app",
  CLIENT: "iv-client-web-app"
}

export const INTERVIEWER_WEB_APP = ""

export const InterviewFeedbackStatus = {
  PREVIEW: "PREVIEW",
  PENDING: "PENDING",
  DRAFT: "DRAFT",
  SUBMITTED: "SUBMITTED",
  CHANGES_REQUESTED: "CHANGES_REQUESTED",
  APPROVED: "APPROVED",
};

export const overallRecommendation = {
  STRONGLY_PROCEED: "STRONG_PROCEED",
  PROCEED: "PROCEED",
  REJECT: "REJECT",
  STRONGLY_REJECT: "STRONG_REJECT",
};

export const FeedbackType = {
  GENERIC: 'GENERIC',
  PROBLEM_SOLVING_CODING: 'PROBLEM_SOLVING_CODING',
  PROBLEM_SOLVING: 'PROBLEM_SOLVING',
  LOW_LEVEL_DESIGN: 'LOW_LEVEL_DESIGN',
  HIGH_LEVEL_DESIGN: 'HIGH_LEVEL_DESIGN',
};

export const CommonFieldsType = {
  INTEGER_TEXT: { label: "integer_text", defaultValue: null },
  DECIMAL_TEXT: { label: "decimal_text", defaultValue: null },
  RICH_TEXT: { label: "rich_text", defaultValue: '' },
  RADIO_BUTTON: { label: "radio_button", defaultValue: null },
  RADIO_BUTTON_WITH_TEXTAREA: { label: "radio_button_with_textarea", defaultValue: {question:'', value: null, response: '', editable: true}},
  SINGLE_LINE_WITH_ADD_MORE: { label: "single_line_with_add_more", defaultValue: [''] },
  SINGLE_LINE_DROPDOWN_WITH_ADD_MORE: { label: "single_line_dropdown_with_add_more", defaultValue: [''] },
  ORDERED_LIST_SECTION: { label: "ordered_list_section", defaultValue: null },
  DROP_DOWN: { label: "drop_down", defaultValue: null },
  UNORDERED_LIST_SECTION: { label: "unordered_list_section", defaultValue: null },
  NORMAL_TEXT: { label: "normal_text", defaultValue: '' },
  CODE_BLOCK: { label: "code_block", defaultValue: '' },
  CODE_BLOCK_IN_DIALOG_BOX: { label: "code_block_in_dialog_box", defaultValue: '' },
  CHECK_BOX: { label: "check_box", defaultValue: null },
  MULTILINE_TEXT : {label: 'multiline_text', defaultValue: ''},
  STATIC_BLOCK : {label: 'static_block' , defaultValue: '' },
  DROP_DOWN_WITH_SUBTEXT : { label: 'drop_down_with_subtext' , defaultValue: null },
  DROP_DOWN_WRAPPER : { label: 'drop_down_wrapper' , defaultValue: null },
  DROP_DOWN_WITH_GROUP : { label: 'grouping_drop_down_wrapper' , defaultValue: null },
  BUTTON: {label: 'button' , defaultValue: null },
};


export const getDataAccordingToFieldType = (fieldType, data) => {
  switch (fieldType) {
    case CommonFieldsType.SINGLE_LINE_WITH_ADD_MORE.label:
      let newData = [];

      if (data && data?.length > 0) {
        data?.map((item, index) => {
          if (item) {
            newData.push(item);
          }
        })
      }
      return newData;

    default:
      return data;
  }
}


export const validateDataAccordingToFieldType = (fieldType, data) => {
  switch (fieldType) {
    case CommonFieldsType.SINGLE_LINE_WITH_ADD_MORE.label:
      let newData = [];

      if (data && data?.length > 0) {
        data?.map((item, index) => {
          if (item) {
            newData.push(item);
          }
        })
      }
      return newData?.length > 0;

    case CommonFieldsType.ORDERED_LIST_SECTION.label:
    case CommonFieldsType.UNORDERED_LIST_SECTION.label:
      return data;

   case CommonFieldsType.INTEGER_TEXT.label:      
   case CommonFieldsType.DECIMAL_TEXT.label:
          return data === 0 || (data && data?.toString() && data?.toString() !== '');

    default:
      return data && data?.toString() && data?.toString() !== '';;
  }

}

export const isV2Version = (step) => {
  return step.version === 'v2' || step.version === 'v3';
}

export const isTheoryV2Version = (interviewDetails) => {
  return interviewDetails?.feedback_pages?.raw_form?.theory ;
}

export const isCodingV2Version = (interviewDetails) => {
  return interviewDetails?.feedback_pages?.raw_form?.coding ;
}

export const isHLDDesignV2Versions = (interviewDetails) => {
  return interviewDetails?.feedback_pages?.raw_form?.hld_design ;
}

export const isLLDDesignV2Versions = (interviewDetails) => {
  return interviewDetails?.feedback_pages?.raw_form?.lld_design ;
}

export const isInDepthKnowledgeV2Versions = (interviewDetails) => {
  return interviewDetails?.feedback_pages?.raw_form?.indepth_knowledge ;
}

export const isResumeV2Version = (interviewDetails) => {
  return interviewDetails?.feedback_pages?.raw_form?.resume ;
}


export const isResumeV3Version = (interviewDetails) => {
  const resumeOrderingData = interviewDetails?.feedback_pages?.ordering?.find((orderingData) => orderingData?.section === 'resume')
  return resumeOrderingData?.version === 'v3' ;
}
export const isSummaryV2Versions = (interviewDetails) => {
  return interviewDetails?.feedback_pages?.raw_form?.summary ;
}

export const isV2TheoryProcessedResponse = (interviewDetails) => {
  return Object.keys(interviewDetails?.feedback_pages?.processed_response?.theory || {})?.length > 0;
}


export const isV2CodingProcessedResponse = (interviewDetails) => {
  return Object.keys(interviewDetails?.feedback_pages?.processed_response?.coding || {} )?.length > 0;
}


export const isV2ResumeProcessedResponse = (interviewDetails) => {
  return Object.keys(interviewDetails?.feedback_pages?.processed_response?.resume || {})?.length > 0;
}

export const isV2SummaryProcessedResponse = (interviewDetails) => {
  return Object.keys(interviewDetails?.feedback_pages?.processed_response?.summary || {})?.length > 0;
}

export const isV2HldDesignProcessedResponse = (interviewDetails) => {
  return Object.keys(interviewDetails?.feedback_pages?.processed_response?.hld_design || {})?.length > 0;
}

export const isV2InDepthProcessedResponse = (interviewDetails) => {
  return Object.keys(interviewDetails?.feedback_pages?.processed_response?.indepth_knowledge || {})?.length > 0;
}
export const isV2LldDesignProcessedResponse = (interviewDetails) => {
  return Object.keys(interviewDetails?.feedback_pages?.processed_response?.lld_design || {})?.length > 0;
}

export const isGenericFeedback = (interviewDetails) => {
  return interviewDetails?.feedback_type === FeedbackType.GENERIC;
}

export const isFlipkartFeedbackDesign = (interviewDetails, codingQuesData) => {
  if (codingQuesData?.questionType) {
    return isFlipkartFeedbackQuestionType(codingQuesData?.questionType);
  } else {
    let data = interviewDetails?.feedback_steps?.find((data) => {
      if (data?.group_type === 'SYSTEM_DESIGN') {
        return true;
      }
    })
    return isFlipkartFeedbackQuestionType(data?.question_type);
  }
}

export const isFlipkartFeedbackQuestionType = (quesType) => {
  return quesType === 'FLIPKART_HIGH_LEVEL_DESIGN' || quesType === 'FLIPKART_LOW_LEVEL_DESIGN';
}

export const showCodingQuestionsTitle = (feedbackType) => {
  return feedbackType === FeedbackType.PROBLEM_SOLVING_CODING || feedbackType === FeedbackType.PROBLEM_SOLVING;
}

export const showCodingQuestions = (interviewDetails, codingQuesData) => {
  if (codingQuesData?.questionType) {
    return codingQuesData?.questionType === "GENERAL_PROBLEM_SOLVING_CODING" || codingQuesData?.questionType === "GENERAL_PROBLEM_SOLVING"
  } else {
    return interviewDetails?.feedback_type === FeedbackType.PROBLEM_SOLVING_CODING || interviewDetails?.feedback_type === FeedbackType.PROBLEM_SOLVING;
  }
}

export const showProblemSolvingCodingFields = (interviewDetails, codingQuesData) => {
  if (codingQuesData?.questionType) {
    return codingQuesData?.questionType === "GENERAL_PROBLEM_SOLVING_CODING"
  } else {
    return interviewDetails?.feedback_type === FeedbackType.PROBLEM_SOLVING_CODING;
  }
}

export const showDesignQuestions = (feedbackType) => {
  return feedbackType === FeedbackType.LOW_LEVEL_DESIGN || feedbackType === FeedbackType.HIGH_LEVEL_DESIGN;
}

export const isCodingNonMandatory = (feedbackType) => {
  return true;
  // return feedbackType === FeedbackType.PROBLEM_SOLVING;
}

export const isCodingQuestionsFilled = (codingQuestionsData, feedbackType, interviewDetails) => {
  let isCodingQuestionsFilled = false;

  codingQuestionsData?.questions?.map((data, index) => {

    if (isFlipkartFeedbackDesign(interviewDetails, data)) {
      if (!(data?.questionLink === "" && data?.questionAsked?.trim() === "" && data?.timeSpent === "" &&
        equateEmptyString(data?.assumptions) && equateEmptyString(data?.heuristics) && equateEmptyString(data?.entityModel) &&
        equateEmptyString(data?.apiDesign) && equateEmptyString(data?.concurrency) && equateEmptyString(data?.subcomponentBreakdown) &&
        equateEmptyString(data?.asyncSyncInteraction) && equateEmptyString(data?.designTradeoffs) && equateEmptyString(data?.familiarity) &&
        equateEmptyString(data?.correctness))) {
        isCodingQuestionsFilled = true;
      }
    } else if (showCodingQuestions(interviewDetails, codingQuestionsData)) {
      if (!(data?.questionLink === "" && data?.questionAsked?.trim() === "" && data?.timeSpent === "" && data?.spaceComplexity?.trim() === "" && data?.timeComplexity?.trim() === ""
        && equateEmptyArray(data?.approach) && equateEmptyArray(data?.positives) && equateEmptyArray(data?.neutrals) && equateEmptyArray(data?.negatives))) {
        isCodingQuestionsFilled = true;
      }
    } else if (showDesignQuestions(feedbackType)) {
      if (feedbackType === FeedbackType.HIGH_LEVEL_DESIGN) {
        if (!(data?.questionLink === "" && data?.questionAsked?.trim() === "" && data?.timeSpent === "" && equateEmptyArray(data?.designProcess) && equateEmptyArray(data?.requirementGathering)
          && equateEmptyArray(data?.highLevelDesign) && equateEmptyArray(data?.scalability) && equateEmptyArray(data?.dbchoices) && equateEmptyArray(data?.caching)
          && equateEmptyArray(data?.extraComments))) {
          isCodingQuestionsFilled = true;
        }
      }

      if (feedbackType === FeedbackType.LOW_LEVEL_DESIGN) {
        if (!(data?.questionLink === "" && data?.questionAsked?.trim() === "" && data?.timeSpent === "" && equateEmptyArray(data?.designProcess) && equateEmptyArray(data?.requirementGathering)
          && equateEmptyArray(data?.lowLevelDesign) && equateEmptyArray(data?.scalability) && equateEmptyArray(data?.dbchoices) && equateEmptyArray(data?.caching)
          && equateEmptyArray(data?.extraComments))) {
          isCodingQuestionsFilled = true;
        }
      }
    }
  })

  return isCodingQuestionsFilled;
}

const equateEmptyArray = (array) => {
  return JSON.stringify(array) === JSON.stringify([""]);
}
const equateEmptyString = (dataString) => {
  return dataString?.trim() === "";
}

export const isTheoryQuestionFilled = (theoryQuestionsData) => {
  let isTheoryQuesFilled = false;

  theoryQuestionsData?.questions?.map((data, index) => {
    if (!(data?.questionAsked?.trim() === "" && data?.candidateResponse?.trim() === "")) {
      isTheoryQuesFilled = true;
    }
  })

  return isTheoryQuesFilled;
}

export const isV2SectionFilled = (section, interviewDetails) => {
  let rawForm = interviewDetails?.feedback_pages?.raw_form?.[section];
  let rawResponse = interviewDetails?.feedback_pages?.raw_response?.[section];

  let isSectionFilled = false;

  rawForm?.summary?.field_order?.map((data) => {
    if (rawForm?.summary?.fields?.[data]?.nested) {
      rawForm?.summary?.fields?.[data]?.field_info?.nested_fields_order?.map((nestedField, nestedIndex) => {
        if (validateDataAccordingToFieldType(rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.field_type, rawResponse?.summary?.[data]?.[nestedField])) {
          isSectionFilled = true;
        }
      })

    } else if (validateDataAccordingToFieldType(rawForm?.summary?.fields?.[data]?.field_type, rawResponse?.summary?.[data])) {
      isSectionFilled = true;
    }
  })

  rawResponse?.questions?.map((questionData) => {
    rawForm?.questions?.field_order?.map((data) => {

      if (rawForm?.questions?.fields?.[data]?.nested) {

        rawForm?.questions?.fields?.[data]?.field_info?.nested_fields_order?.map((nestedField, nestedIndex) => {
          if (validateDataAccordingToFieldType(rawForm?.questions?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.field_type, questionData?.[data]?.[nestedField])) {
            isSectionFilled = true;
          }
        })

      } else if (validateDataAccordingToFieldType(rawForm?.questions?.fields?.[data]?.field_type, questionData?.[data])) {
        isSectionFilled = true;
      }
    })
  })

  return isSectionFilled;
}

export const CommentConstants = {
  OVERALL_COMMENTS: 'OVERALL_COMMENTS',
  FEEDBACK_SUMMARY: 'FEEDBACK_SUMMARY',
  RESUME_INTRO: 'RESUME_INTRO',
  CODING_QUESTIONS: 'CODING_QUESTIONS',
  THEORY_QUESTIONS: 'THEORY_QUESTIONS',
};

export const CommentsResolveStatus = {
  RESOLVED: 'RESOLVED',
  UNRESOLVED: 'UNRESOLVED',
};

export const StepperSteps = {
  RESUME_INTRO: 0,
  CODING_QUESTIONS: 1,
  THEORY_QUESTIONS: 2,
  FEEDBACK_SUMMARY: 3,
};

export const StepperKeyName = {
  RESUME_INTRO: 'resume',
  CODING_QUESTIONS: 'coding',
  DESIGN_QUESTIONS: 'design',
  HLD_QUESTIONS: 'hld_design',
  LLD_QUESTIONS: 'lld_design',
  THEORY_QUESTIONS: 'theory',
  FEEDBACK_SUMMARY: 'summary',
  INDEPTH_KNOWLEDGE: 'indepth_knowledge'
};

export const showExampleText = (feedbackStatus) => {
  return feedbackStatus === InterviewFeedbackStatus.PREVIEW || feedbackStatus === InterviewFeedbackStatus.PENDING || feedbackStatus === InterviewFeedbackStatus.DRAFT
}

export const feedbackRatings = {
  WORST: "WORST",
  BAD: "BAD",
  OKAY: "OKAY",
  GOOD: "GOOD",
  BEST: "BEST",
}

export const INTERVIEWER_TYPE = {
  IV_EXPERTS:"INTERVIEWVECTOR",
  CLIENT_INTERNAL:"CLIENT_INTERNAL",
  IV_EXPERTS_TEXT: "InterviewVector Expert",
  CLIENT_INTERNAL_TEXT: "Internal interviewers",
}

export const capitalize = (s) => {
  return (s && s[0].toUpperCase() + s.slice(1)) || ""
}