import React, {useState, useEffect} from 'react';
import CustomizedDialogs from "./ViewQuestion";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import "./QuestionCard.scss";
import quickLinkIcon from "../../../../assets/images/quick-link.svg";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from '@material-ui/core/Modal';
import ImageLoad from './ImageLoad';
import cogoToast from "cogo-toast";
import { isValidUrl } from '../../../atoms/Validations/LinkValidator';

export const QuestionCard = ({ index, mainData, downloadFile, commentData }) => {
const [showImageModal, setImageModal] = useState(false)
const [imageURL, setImageURL] = useState('')
const [isHighlightAutoCommentQstn, setIsHighlightAutoCommentQstn] = useState(false);


useEffect(() => {
  let questionIds = [];
   let filteredAutoComments = commentData?.filter((item) => item?.created_by?.commentor_name === 'AUTO_COMMENT')
   if(filteredAutoComments?.length > 0) {
    filteredAutoComments?.map((item, index) => {
        let objKeys = Object.keys(item?.extra_info);
        if(objKeys) {
          questionIds.push(parseInt(objKeys));
        }
    })
   }

  if(questionIds?.length > 0) {
        let findQstn = questionIds?.find((item) => item === mainData?.id)
        if(findQstn) {
          setIsHighlightAutoCommentQstn(true);
        }
  }
}, [commentData])

const viewImage = async (imageLink)=> {
  setImageModal(true)
  setImageURL(imageLink)
}

const handleQuestionLinkOpen = (link) => {
  if(isValidUrl(link)) {
    window.open(link);
  } else {
    cogoToast.error(`Can't Open Invalid Url : ${link}`,{
      position:"top-right"
    });
  }
}

  return (
  <>
    <Modal open={showImageModal} onClose={() => setImageModal(false)}>
      <Box className="guide-image-modal">
        <Box display='flex' alignItems='center' justifyContent='space-between' className='guide-image-nav'>
          <Box display='flex'>
            <Typography className='image-label'>Image Reference</Typography>
          </Box>
          <CloseIcon onClick={() => setImageModal(false)} className='close-icon' sx={{ color: 'white' }}/>
        </Box>

        <ImageLoad data={imageURL} url={imageURL} downloadAPI={downloadFile} />
      </Box>
    </Modal>


    <Box display='flex' flexDirection="column" className="question_card_preview">
      <Box display="flex" flexDirection='row'>
      <Box className="font-13 fg-black bold-600 mb-2p mr-5p">
        Q.
      </Box>
       <Box display='flex' className={`font-13 fg-black bold-500 mb-2p white-space quill-box ${isHighlightAutoCommentQstn && 'fg-red'}`} dangerouslySetInnerHTML={{__html: 
        mainData?.question?.name && mainData?.question?.name?.length > 0?mainData?.question?.name?.trim() :""}}>
       </Box>
       </Box>

      {  mainData?.question?.image_link && <Box className="font-13 fg-black bold-500 mb-2p white-space">
       {
        <div className='image-ref-button' onClick={() => viewImage(mainData?.question?.image_link)}>  Image reference 
        <VisibilityIcon sx={{ color: 'white', marginLeft:'4px', marginRight: '4px', fontSize:"16px" }} /></div>
       }
      
       </Box>}

      <Box marginLeft="20px">
       <p className="font-11">
        <span
          className={
            mainData?.difficulty_level?.toLowerCase() === "easy"
              ? "bold-600 fg-green td-none"
              : mainData?.difficulty_level?.toLowerCase() === "medium"
              ? "bold-600 fg-gold td-none"
              : "bold-600 fg-red td-none"
          }
        >
          {mainData?.difficulty_level?.toUpperCase()}
        </span>
        <a className="bold-900 fg-black td-none"> &#8226; </a>{" "}
        <a className="bold-400 fg-gray4 td-none">{mainData?.avg_time_taken?mainData?.avg_time_taken+" mins":""}</a>
        <a className="bold-900 fg-black td-none"> &#8226; </a>{" "}
        <CustomizedDialogs
          index={index}
          mainData={mainData}
          section={"Technical Question"}
        />
        {
          mainData?.question?.link && mainData?.question?.link?.length>0?(<>
            <a className="bold-900 fg-black td-none"> &#8226; </a>
        <a  onClick={()=>handleQuestionLinkOpen(mainData?.question?.link)} target="_blank" className='font-style question-link'>Question Link <img src={quickLinkIcon}/></a>
        </>
          ):""
        }
       
      </p>

      <p className="font-11 fg-gray4 tag">{mainData?.tags?.join(", ")}</p>


      <p className={`font-13 fg-black bold-400 mb-2p white-space quill-box ${isHighlightAutoCommentQstn && 'fg-red'}`} style={{marginTop: -13}}
       dangerouslySetInnerHTML={{__html: mainData?.question?.statement &&
        mainData?.question?.statement?.length > 0
        ? mainData?.question?.statement
        : ""}}
      >
      </p>
  
     
      </Box>
    </Box>
    </>
  );
};
