import {imageFile} from "../Services/file";

export const getS3Url = (imageUrl) => {
  if (!imageUrl) {
    return;
  }

  const params = new URLSearchParams(imageUrl);
  const bucket_name = params.get('bucket_name');
  const s3_key = params.get('s3_key');
  
  if (bucket_name && s3_key) {
    return `https://${bucket_name}.s3.amazonaws.com/${s3_key}`
  }
};

export const loadImage = (imageUrl,downloadAPI, cb) => {
  if (!imageUrl) {
    return cb && cb('skipped');
  }

  const load = async () => {
    const image = await downloadAPI(imageUrl);
    const file = new File([new Blob([image.data])], "name");

    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setTimeout(() => {
        cb && cb(null, reader.result);
      }, 200);
    }
  };

  load();
};
