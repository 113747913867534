import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const stylesSideComponent = StyleSheet.create({
  sideBoxMainWrapper: {
    width: 257,
    backgroundColor: "#ffffff",
    borderRadius: 22,
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 20,
    padding: 20,
  },

  sideBoxChildWrapper: {
    width: 224,
    backgroundColor: "#F3F5FA",
    borderRadius: "14",
    display: "flex",
    flexDirection: "column",
    padding: 20,
  },

  logo: {
    width: 220,
    height: 80,
    borderRadius: 12,
  },

  sidecompHeadings: {
    width: 126,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: 0.025,
    color: "#0f2359",
    marginTop: 5,
  },

  sidecompName: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 17,
    letterSpacing: 0.025,
    color: "#52659B",
    marginBottom: 8,
  },

  sidecompDate: {
    width: 230,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 0.025,
    color: "#7E94D0",
  },
});

const SideComponent = ({ data }) => {
  const interviewTimestamp =
    data?.audit_details?.interview_details?.scheduled_start_timestamp;

  return (
    <View style={[stylesSideComponent.sideBoxMainWrapper]} fixed>
      <View style={[stylesSideComponent.sideBoxChildWrapper]}>
        <View>
          <Text style={[stylesSideComponent.sidecompHeadings]}>
            CANDIDATE NAME
          </Text>
          <Text style={[stylesSideComponent.sidecompName]}>
            {data?.audit_details?.candidate_details?.name}
          </Text>
        </View>
        <View>
          <Text style={[stylesSideComponent.sidecompHeadings]}>ROLE</Text>
          <Text style={[stylesSideComponent.sidecompName, {marginBottom: 0}]}>
            {data?.audit_details?.position_details?.role}
          </Text>
          <Text style={[stylesSideComponent.sidecompName]}>
          {data?.audit_details?.position_details?.position_name}
          </Text>
        </View>
        <View>
          <Text style={[stylesSideComponent.sidecompHeadings]}>CATEGORY</Text>
          <Text style={[stylesSideComponent.sidecompName]}>
            {data?.audit_details?.round_details?.round_category}
          </Text>
        </View>
        <View>
          <Text style={[stylesSideComponent.sidecompHeadings]}>
            INTERVIEW TIME
          </Text>
          <Text style={[stylesSideComponent.sidecompName]}>
            {moment(interviewTimestamp * 1000).format("hh:mm A")}
          </Text>
          <Text style={[stylesSideComponent.sidecompDate]}>
            {moment(interviewTimestamp * 1000).format("MMM DD,YYYY")}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default SideComponent;
