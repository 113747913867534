import React from "react";
import { Text, View, Image } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import { stylesPDF, styleQuestions, stylesFeedbackSummary } from "./Style";

import {
    FeedbackType, showDesignQuestions, showCodingQuestions, showProblemSolvingCodingFields, isFlipkartFeedbackDesign
} from "./../../Constants/FeedbackConstants";

// images......
import completeSolutionImg from "../../../../../assets/images/download-feedback/complete-solution.png";
import incompleteSolutionImg from "../../../../../assets/images/download-feedback/incomplete-solution.png";
import optimalSolutionImg from "../../../../../assets/images/download-feedback/optimal-solution.png";
import notOptimalSolutionImg from "../../../../../assets/images/download-feedback/not-optimal-solution.png";
import { commonDownloadStyle } from "../../SectionsV2/Preview/Constant";


export const CodingQuestions = ({ interviewDetails, codingQuestionData, feedbackType }) => {
    const getTotalTimeSpent = () => {
        let totalTimeSpent = 0;
        for (let i = 0; i < codingQuestionData?.questions?.length; i++) {
            const data = codingQuestionData?.questions[i];
            if (data?.timeSpent !== "") {
                totalTimeSpent = totalTimeSpent + parseInt(data?.timeSpent);
            }
        };
        return totalTimeSpent;
    }

    const renderDesignFields = (data, name, label) => {
        return (
            <View style={[stylesPDF.rowstyleWrap, { margin: '8 0' }]}>
                <Text style={[styleQuestions.codingHldLldLabel]}>{label}</Text>
                <View style={[{ width: '75%' }]}>
                    {data[name]?.map((datum) => {
                        return <Text style={[styleQuestions.tableData]}>{"- " + datum + "\n"}</Text>
                    })}
                </View>
            </View>
        )
    }

    const renderFlipkartFieldHeading = (label) => {
        return (
            <Text style={[styleQuestions.flipkartPreviewHeading]}>{label}</Text>
        )
    }

    const renderFlipkartFields = (data, name, label) => {
        return (
            <View style={[stylesPDF.rowstyleWrap, { margin: '8 0' }]}>
                <Text style={[styleQuestions.codingHldLldLabel]}>{label}</Text>
                <View style={[{ width: '75%' }]}>
                    {data[name].split("\n").map((data) => {
                        return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                    })}
                </View>
            </View>
        )
    }
    return (
        <View style={[{ margin: 15, border: "1 solid #cdcdcd", backgroundColor: "#fff" }]}>
            <View style={[stylesPDF.rowstyle, { width: "100%", backgroundColor: "#C5D4FF", minHeight: 42, padding: 15 }]}>
                <Text style={[stylesFeedbackSummary.previewSubheaderTitle]}> {showCodingQuestions(interviewDetails, codingQuestionData) ? 'Coding Questions' : 'Design Questions'}</Text>
                <Text style={[stylesFeedbackSummary.previewSubheaderSubtitle, { margin: '0 15' }]}>  Time Spent: {getTotalTimeSpent()} Mins
                </Text>
            </View>

            <View style={[stylesPDF.columnstyle, { width: "100%", padding: 20 }]}>
                {codingQuestionData?.questions?.map((data, index) => {
                    return (
                        <View style={[stylesPDF.columnstyle, { border: "1 solid #C7C7C7", padding: 16, marginBottom: 16 }]}>
                            <View style={[stylesPDF.rowstyle, { justifyContent: 'space-between', flexWrap: 'wrap' }]}>
                                <View style={[stylesPDF.rowstyle, styleQuestions.theoryQuestionsUnformatted, { flex: 1 }]}>
                                    <Text style={[data?.questionAsked?.length > 0 && data?.questionAsked[0] === '<' ? { marginTop: '11' } : { marginTop: 0 }]}> {index + 1}. </Text>
                                    <Html style={[{ fontSize: 11 }]}>
                                        {data?.questionAsked && data?.questionAsked+commonDownloadStyle}
                                    </Html>
                                </View>
                                <View style={[stylesPDF.rowstyle, { marginLeft: 5 }]} >
                                    {data?.isQuestionCompleted &&
                                        <View style={[stylesPDF.rowstyle, styleQuestions.codingSolution, data?.isQuestionCompleted === "true" ? styleQuestions.codingSolutionTrue : styleQuestions.codingSolutionFalse]}>
                                            <Image src={data?.isQuestionCompleted === "true" ? completeSolutionImg : incompleteSolutionImg} style={[{ width: 6, height: 6 }]} />
                                            <Text style={[{ marginLeft: 4 }]} >{data?.isQuestionCompleted === "true" ? "Complete Solution" : "Incomplete Solution"}</Text>
                                        </View>
                                    }
                                    {data?.isSolutionOptimal &&
                                        <View style={[stylesPDF.rowstyle, { marginLeft: 5 }, styleQuestions.codingSolution, data?.isSolutionOptimal === "true" ? styleQuestions.codingSolutionTrue : styleQuestions.codingSolutionFalse]}>
                                            <Image src={data?.isSolutionOptimal === "true" ? optimalSolutionImg : notOptimalSolutionImg} style={[{ width: 12, height: 12 }]} />
                                            <Text style={[{ marginLeft: 0 }]} >{data?.isSolutionOptimal === "true" ? "Optimal" : "Not Optimal"}</Text>
                                        </View>
                                    }

                                    {(isFlipkartFeedbackDesign(interviewDetails, data) || showDesignQuestions(feedbackType)) &&
                                        <View style={[stylesPDF.rowstyle, { alignItems: "center", marginLeft: 5, marginRight: 5 }]}>
                                            <Text style={[styleQuestions.codingSolution]}>Time Spent: {data?.timeSpent} mins</Text>
                                        </View>
                                    }

                                </View>
                            </View>

                            <View style={[{ border: "1 solid #CDCDCD", margin: "16 -16" }]} />

                            {!isFlipkartFeedbackDesign(interviewDetails, data) && showCodingQuestions(interviewDetails, codingQuestionData) &&
                                <View style={[stylesPDF.columnstyle]}>
                                    <View style={[styleQuestions.codingQuestionsQuestions, stylesPDF.rowstyleWrap]}>
                                        <View style={[stylesPDF.rowstyle]}>
                                            <Text style={[{ marginRight: 8 }]}>Time Spent: {"  "}</Text>
                                            <Text>{data?.timeSpent} mins</Text>
                                        </View>
                                        {showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                                            <>
                                                <View style={[stylesPDF.rowstyle]}>
                                                    <Text style={[{ marginRight: 8, marginLeft: 32 }]}>Time Complexity:{"  "}</Text>
                                                    <Text>{data?.timeComplexity}</Text>
                                                </View>
                                                <View style={[stylesPDF.rowstyle]}>
                                                    <Text style={[{ marginRight: 8, marginLeft: 32 }]}>Space Complexity:{"  "}</Text>
                                                    <Text>{data?.spaceComplexity}</Text>
                                                </View>
                                            </>}
                                        {showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                                            <View style={[stylesPDF.rowstyle]}>
                                                <Text style={[{ marginRight: 8, marginLeft: 32 }]}>Language Used: {"  "}</Text>
                                                <Text>{data?.languageUsed}</Text>
                                            </View>
                                        }
                                    </View>

                                    <View style={[styleQuestions.tableContainer]}>
                                        <View style={[styleQuestions.tableHeader, { height: 35 }]}>
                                            <Text style={[styleQuestions.theoryTableHeaderComponent]}>Approach</Text>
                                            <Text style={[styleQuestions.theoryTableHeaderComponent]}>Positives</Text>
                                            <Text style={[styleQuestions.theoryTableHeaderComponent]}>Neutrals</Text>
                                            <Text style={[styleQuestions.theoryTableHeaderComponent, styleQuestions.theoryTableHeaderComponentLastChild]}>Negatives</Text>
                                        </View>

                                        <View style={[stylesPDF.rowstyle]}>
                                            <View style={[styleQuestions.tableRow, styleQuestions.codingTableRowContainer]}>
                                                {data?.approach?.map((data) => {
                                                    return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                                                })}
                                            </View>
                                            <View style={[styleQuestions.tableRow, styleQuestions.codingTableRowContainer]}>
                                                {data?.positives?.map((data) => {
                                                    return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                                                })}
                                            </View>
                                            <View style={[styleQuestions.tableRow, styleQuestions.codingTableRowContainer]}>
                                                {data?.neutrals?.map((data) => {
                                                    return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                                                })}
                                            </View>
                                            <View style={[styleQuestions.tableRow, styleQuestions.codingTableRowContainer]}>
                                                {data?.negatives?.map((data) => {
                                                    return <Text style={[styleQuestions.tableData]}>{"- " + data + "\n"}</Text>
                                                })}
                                            </View>
                                        </View>
                                    </View>

                                    <Text style={[(showProblemSolvingCodingFields(interviewDetails, codingQuestionData) && data?.solutionCode && data?.solutionCode?.trim() !== "") ? styleQuestions.codingPart : { height: "0px" }]}> {data?.solutionCode || ""} </Text>

                                </View>
                            }

                            {!isFlipkartFeedbackDesign(interviewDetails, data) && showDesignQuestions(feedbackType) &&
                                <>
                                    {renderDesignFields(data, 'designProcess', 'Design Process:')}
                                    {renderDesignFields(data, 'requirementGathering', 'Requirement Gathering:')}
                                    {feedbackType === FeedbackType.HIGH_LEVEL_DESIGN && renderDesignFields(data, 'highLevelDesign', 'High Level Design:')}
                                    {feedbackType === FeedbackType.LOW_LEVEL_DESIGN && renderDesignFields(data, 'lowLevelDesign', 'Low Level Design:')}
                                    {renderDesignFields(data, 'scalability', 'Scalability:')}
                                    {renderDesignFields(data, 'dbchoices', 'DB Choices:')}
                                    {renderDesignFields(data, 'caching', 'Caching:')}
                                    {renderDesignFields(data, 'extraComments', 'Extra Comments:')}
                                </>
                            }

                            {isFlipkartFeedbackDesign(interviewDetails, data) &&
                                <>
                                    {renderFlipkartFieldHeading('Requirement Gathering')}
                                    {renderFlipkartFields(data, 'assumptions', 'Assumptions:')}
                                    {renderFlipkartFields(data, 'heuristics', 'Heuristics:')}

                                    {renderFlipkartFieldHeading('Class Design')}
                                    {renderFlipkartFields(data, 'entityModel', 'Entity Model:')}
                                    {renderFlipkartFields(data, 'apiDesign', 'API Design:')}
                                    {renderFlipkartFields(data, 'concurrency', 'Concurrency:')}

                                    {renderFlipkartFieldHeading('System Decomposition')}
                                    {renderFlipkartFields(data, 'subcomponentBreakdown', 'Subcomponent Breakdown:')}
                                    {renderFlipkartFields(data, 'asyncSyncInteraction', 'Async/Sync Interaction:')}
                                    {renderFlipkartFields(data, 'designTradeoffs', 'Design TradeOffs:')}

                                    {renderFlipkartFieldHeading('Component Tech Choices')}
                                    {renderFlipkartFields(data, 'familiarity', 'Familiarity:')}
                                    {renderFlipkartFields(data, 'correctness', 'Correctness:')}
                                </>
                            }
                        </View>
                    );
                })}
            </View>
        </View>
    );
};
