import React, {useState, useEffect, forwardRef} from 'react'
import {PageView, HeaderView} from '../../components/Pages/Views'
import DetailedRequests from './DetailedRequests'
import Table from '../../components/Table/Table'
import Modal from '../../components/Models/RightSide'
import Select from 'react-select'
import {CheckBoxItem} from '../../components/Forms/InputFormGroup'
import Navbar from '../Nav'
import { uniqueBy } from '../../utilis/helpers'
import { useDispatch, useSelector } from 'react-redux';
import {fetchRequests} from '../../services/requests'
import {requestsAction} from '../../store/actions/Requests'
import './reqIndex.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarImg from '../Scheduled/calenderImg.svg';
import cogoToast from 'cogo-toast';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useWindowDimension from "../../utilis/windowDimension";
import FilterAndSort from "./mobile-comps/FilterAndSort"
import RequestList from './mobile-comps/RequestList'
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { getLocalTimeZone } from '../../utilis/TimeZone';

const Requests = (props) => {
    const dispatch = useDispatch();
    const fetchedRequests = useSelector((state) => state && state.requests && state.requests && state.requests.list && state.requests.list);
    const [requests, setRequests] = useState(fetchedRequests)
    const [selected, setSelected] = useState(null)
    const [viewSideModal, setViewSideModal] = useState(false);
    const [filters, setFilters] = useState(initFilters)
    const [options, setOptions] = useState({companies: [], positions: []}) // Options for filters
    const [accepting,setAccepting] = useState(false);
    const { width } = useWindowDimension()
    const [resetRequired, setResetRequired] = useState(false); // State to track if reset is required. If this is true, all reset functions must fire, which would perform resets and then set this to false.
    const currentTimezone = useSelector(state=>state?.user?.user_config?.timezone) || getLocalTimeZone();

    useEffect(() => {
        if(fetchedRequests.length === 0) fetchData();
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(!accepting) fetchData();
    },[accepting])
    
    // Changes sort options (Company and Position) and sets new Requests
    useEffect(() => {
        setRequests(fetchedRequests)
        setOptions({
            companies: [ {value: "All Companies", label: 'All Companies'}, ...uniqueBy(fetchedRequests.map(item => ({value: item.companyName, label: item.companyName})), 'value')],
            positions: [ {value: "All Positions", label: 'All Positions'}, ...uniqueBy(fetchedRequests.map(item => ({value: item.positionName, label: item.positionName})), 'value')],
        })
    }, [fetchedRequests])

    
    useEffect(() => {
        applyFilters()
        // eslint-disable-next-line
    }, [filters])

    
    const fetchData = async () => {
        try{
            const res = await fetchRequests()
            setRequests(res);
            dispatch(requestsAction(res));
        } catch(err){
        }
    }

    const editFilters = (value, name) => {
        setFilters(prev => ({...prev, [name]: value}))
    }

    // Function that gets invoked whenever `filters` changes; updates 'requests` state.
    const applyFilters = () => {
        const data = fetchedRequests
            .filter(item => {
            if(filters.companies !== "All Companies" && filters.positions === "All Positions"){
                return item.companyName === filters.companies
            }
            else if(filters.positions !== "All Positions" && filters.companies === "All Companies"){
                return item.positionName === filters.positions
            }
            else if(filters.positions !== "All Positions" && filters.companies !== "All Companies"){
                return item.companyName === filters.companies && item.positionName === filters.positions
            }
            else return true
            })
            .filter( item => {
                if(filters.fromDate && filters.toDate){
                    return (item.dateTime.value >= filters.fromDate && item.dateTime.value <= filters.toDate)
                }else{
                    return true;
                }
            })
            .filter(item => item.candidateName.toLowerCase().includes(filters.searchInput.toLowerCase()))
            .filter(item => {
               if(filters.isUrgent) return item.isUrgent
               else return true;
            })        
        setRequests(data);       
    }

    const removeRequest = (req) => {
        const newRequests = requests.filter((interview)=>interview.id !== req.id);
        setRequests(newRequests);
        // dispatch(requestsAction(newRequests)) // TODO: CHECK THIS
    }

    return (
        <div className = 'request'>
            <Navbar {...props} pageTitle="Requests" augmentElement={
                <Box display='flex' justifyContent="flex-end" alignItems="center">
                    <Button onClick={() => { setResetRequired(true); }} color="primary" variant="contained">
                        Restore Default
                    </Button>
                </Box>
            }/>
            <PageView>
                {/* Render UI based on screen width */}
                {width > 768 ?
                    <>
                        {/* Widescreen UI */}
                        <HeaderView title = 'Requests' OwnComp={<div className="fl-col headerComp">
                        </div>}/>
                        <Backdrop
                             style={{zIndex: '999999'}} 
                            sx={{ color: '#fff', zIndex: 999999 }}
                            open={accepting}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Toolbar 
                            filters = {filters}
                            setFilters ={setFilters}
                            editFilters = {editFilters} 
                            allCompanies = {options.companies}
                            allPositions = {options.positions}
                        />
                        <Table 
                            isFooter = {true}
                            handleRowClick = {(id) => {setSelected(id); setViewSideModal(true);}}
                            data = {requests}
                            headers = {tableHeaders}
                            isSortable = {true}
                            acceptReq= {(obj)=>{
                                setSelected(obj.id);
                                setViewSideModal(true);
                            }}
                        />
                        <Modal show = {viewSideModal} setShow = {setViewSideModal} >
                            {selected && <DetailedRequests 
                                data = {requests.filter(item => item.id === selected)[0]} 
                                setShow = {setViewSideModal}
                                selected={selected}
                                setSelected = {setSelected}
                                removeRequest={removeRequest}
                                accepting={accepting}
                                setAccepting={setAccepting}
                                fetchData={fetchData}
                                currentTimezone = {currentTimezone}
                            />}
                        </Modal>
                    </> :
                    <>
                        {/* Mobile UI */}
                        
                        {/* For filter and sort menu and modal */}
                        <FilterAndSort allCompanies={options.companies} allPositions={options.positions} editFilters={editFilters} setFilters={setFilters} filters={filters} setRequests={setRequests} resetRequired={resetRequired} setResetRequired={setResetRequired} initFilters={initFilters} fetchedRequests={fetchedRequests}/>

                        {/* List of Requests */}
                        <RequestList requests={requests} setRequests={setRequests}/>
                    </>
                }
            </PageView>    
        </div>
    )
}



const Toolbar = ({filters, setFilters,editFilters, allCompanies, allPositions}) => {
    const [startDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");

    useEffect(()=>{
        if(startDate && EndDate){
            let fromDate = moment(startDate).unix();
            let toDate = moment(EndDate).set({hour:23}).unix();
            if(fromDate > toDate){
                cogoToast.error('To Date should be greater than from date');
                setEndDate("");
                setFilters((prev)=>{
                    return {
                        ...prev,
                        fromDate: fromDate,
                        toDate: "",
                    }
                })
                return
            }
            setFilters((prev)=>{
                return {
                    ...prev,
                    fromDate: fromDate,
                    toDate: toDate,
                }
            })
        }
    },[startDate,EndDate]);

    const ExampleCustomInput = forwardRef(({ value, onClick , placeholder }, ref) => (
        <div   onClick={onClick} ref={ref}>
            <input readOnly className="date-pickeroWN" placeholder={placeholder} value={value} />
            <div className={"dateIcon"}>
                <img className={"calendarSize"} src={calendarImg} alt='calendar-icon'/>
            </div>
        </div>
      ));

      const handleRestoreBttn=()=>{
        setStartDate("");
        setEndDate("");
        setFilters(initFilters);
      }

    return (
        <div id = 'filters'>
        <div className = 'p-btm'>
            <div className = 'row align-items-center'>
                <div className = 'col-2'>
                    <Select 
                        options = {allCompanies} 
                        value = {allCompanies.filter(item => item.value === filters.companies)[0]} 
                        placeholder = 'All Companies'
                        onChange = {e => editFilters(e.value, 'companies')}
                        classNamePrefix = 'smallSelect'
                    />
                </div>
                <div className = 'col-2'>
                    <Select 
                        options = {allPositions} 
                        value = {allPositions.filter(item => item.value === filters.positions)[0]} 
                        placeholder = 'All Positions'
                        onChange = {e => editFilters(e.value, 'positions')}
                        classNamePrefix = 'smallSelect'
                    />
                </div>
                <div className = 'col-auto'>
                    <CheckBoxItem  description = {'Urgent'} value = {filters.isUrgent} setValue = {(value) => editFilters(value, 'isUrgent')} help = {1} isOverlay = {false}/>
                </div>

                <div className="col"></div>
                        <div className="pos-rel mr-10">
                            <DatePicker
                                popperPlacement="bottom-end"      
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                placeholderText="From date"
                                customInput={<ExampleCustomInput />}
                            />
                            
                        </div>
                        <div className="pos-rel mr-30">
                        <DatePicker
                                popperPlacement="bottom-end"      
                                selected={EndDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="To date"
                                className="date-picker"
                                customInput={<ExampleCustomInput />}
                            />
                        </div>
                        <div className="sideBorder mr-30"></div>
                        <button className="bttn mr-10" onClick={handleRestoreBttn}>Restore default</button>
            </div>
        </div>
    </div>
    
    )
}



export default Requests;



const tableHeaders = [
    {value: 'companyName', label: 'Company Name', active: true},
    {value: 'positionName', label: 'Position', active: true},
    {value: 'roundName', label: 'Round', active: true},
    {value: 'dateTime', label: 'Date and Time', active: true},
    {value: '', label: 'Action', active: true, }
]




const initFilters = {
    companies: "All Companies",
    positions: "All Positions",
    isUrgent: false,
    isReviewed: false,
    searchInput: "", 
    fromDate:"",
    toDate:"",
}