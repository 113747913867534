import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {DashboardView} from '../../components/Pages/Views'
import Navbar from '../Nav';
import TableActionCard from './Components/TableActionCard';
import TableCard from './Components/TableCard';
import {getInterviewerStatsRange, getCandidateFeedbackIndex, getTatFeedback} from '../../services/history'
import {fetchSchedule} from '../../services/schedule';
import { fetchProfile } from '../../services/profile'
import { useDispatch, useSelector } from 'react-redux';
import {scheduleAction} from '../../store/actions/Schedule'
import { fetchRequests } from '../../services/requests';
import { requestsAction } from '../../store/actions/Requests';
import Box from '@material-ui/core/Box';
import Card from "@material-ui/core/Card";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import passPercentInfoIcon from '../../images/pass-percent-indicator.svg';
import PassPercentIndicator from './Components/PassPercentIndicator';
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@mui/material/MenuItem';
import CustomModal from '../../components/Models/CustomModal';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import windowDimensions from '../../utilis/windowDimension';
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import KarmaScore from './Components/KarmaScore';
import bulbImg from '../../images/bulb.svg';
import "@brainhubeu/react-carousel/lib/style.css";

// Mobile Imports
import CountCard from '../../components/CountCard/CountCard';
import MultipleSlotCard  from '../../components/MultipleSlotCard/MultipleSlotCard';
import LabelSeperator from '../../components/LabelSeperator/LabelSeperator';
import Accordian from '../../components/Accordian/Accordian';
import Rating from '@material-ui/lab/Rating';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const useStyles = makeStyles({
    noBoxShadow: {
        boxShadow: 'none !important'
    }
});

const upcomingInterviewsHeaders = [
    {value: "companyName", label: "Company"},
    {value1: "positionName", value2: "roundName", label: "Position"},
    {value1: "date", value2:"time", label: "Date & Time"}
]
const requestsHeader = [
    {value: "companyName", label: "Company"},
    {value: "positionName", label: "Position"},
    {value: "roundName", label: "Round"},
    {value: "dateTime", label: "Date & Time"},
]
const initCardData = {
    completed:0,
    cancelled:0,
    proceeded:0,
    CompletedThisMonth:0,
    cancelledThisMonth:0
}
const passPercentageInitData = {
    interviewerPassPercentage:0,
    overallPassPercentage:0,
    passPercentageCompared:0
}

const Dashboard = (props) => {
    const {width} = windowDimensions();
    const classes = useStyles();
    const historyRef = useHistory();
    const user=useSelector(state=> state.user)
    const [cardsData, setCardsData] = useState(initCardData)
    const [passPercentageData, setPassPercentageData] = useState(passPercentageInitData)
    const [performanceData, setPerformanceData] = useState({})
    const [tatFeedbackData, setTatFeedbackData] = useState(0)
    const [requests,setRequests]=useState([]);
    const interviewerName = useSelector((state)=> state?.profile?.name );
    const [stats, setStats] = useState(null);
    const [scheduled,setScheduled]=useState([]);
    const [accepting,setAccepting] = useState(false);  
    const [showAvailabilityModal,setShowAvailabilityModal] = useState(false); 
    const [todaySchedule, setTodaySchedule] = useState([]);
    const dispatch = useDispatch()
    const absPassPercentageCompared = Math.abs(passPercentageData?.passPercentageCompared) || 0;
    const [value, setValue] = useState(0);
    const [monthArray, setMonthArray] = useState([]);
    const [selectedDate, setSelectedDate] = useState({from_date: moment().format('01-MM-YYYY'), to_date:moment().format('DD-MM-YYYY')}); // Set this month starting date as from date and current as to date
    const [karmaDetailsOpen, setKarmaDetailsOpen] = useState(false);
    const [karmaScoreData, setKarmaScoreData] =  useState("");

    function onChange(value) {
      setValue(value);
    }

    function daysInMonth (month, year) {
      return new Date(year, month, 0).getDate();
    }
    function getDate(day, month, year) {
      return moment(`${day} ${month} ${year}`, 'DD MM YYYY').format("DD-MM-YYYY");
    }

    function getDateStart(day, month) {
      return moment(`${day} ${month}`, 'DD MM').format("DD-MMM");
    }

    function getDateEnd(day, month, year) {
      return moment(`${day} ${month} ${year}`, 'DD MM YYYY').format("DD-MMM-YYYY");
    }
    

    // Function for calculating the last three months 
    const handleLastThreeMonth = ()=>{
      let months = [];
      let curr = new Date();
      for (let i=0;i<=3;i++){
        let mon = curr.getMonth()+1 - i;
        let year = curr.getFullYear()
        if (mon<=0){
          mon += 12;
          year --;
        }
        let days = daysInMonth(mon, year);
        let startingDate = getDate(1, mon, year);
        let endingDate = getDate(days, mon, year);

        let display_start = getDateStart(1, mon);
        let display_end = getDateEnd(days, mon, year);
        if (i === 0){
          endingDate = moment().format('DD-MM-YYYY');
          display_end = "Current";
        }
        months.push({from_date:startingDate, to_date:endingDate, display_start:display_start,display_end:display_end });
      }
      setMonthArray(months);
    }

    useEffect(()=>{
        if(stats && interviewerName && stats[interviewerName]){
            if(stats?.[interviewerName]?.stats?.COMPLETED?.TOTAL){
                let cards = [...cardsData];
                cards[0].number = stats?.[interviewerName]?.stats?.COMPLETED?.TOTAL;
                setCardsData(cards);
            }
        }
    },[stats , interviewerName])

    useEffect(() => {
      handleLastThreeMonth();
      const isFirstLogin = localStorage.getItem('firstLogin');
      if(isFirstLogin && JSON.parse(isFirstLogin)===true){
          setShowAvailabilityModal(true);
          localStorage.removeItem('firstLogin');
      }
      // eslint-disable-next-line
    }, [])

    useEffect(()=>{
      fetchData();
    },[selectedDate])

    useEffect(() => {
        if(scheduled) setTodaySchedule(scheduled.filter(item => item.date === moment().format('DD-MM-YY')).sort(sortFunction))
    }, [scheduled])

    const fetchData = async () => {
      try{
            const newCardsData = {...initCardData}
            const userName=user?.name;

            const interviewerStatsRange = await getInterviewerStatsRange(selectedDate?.from_date,selectedDate?.to_date);
            const monthlyStats = interviewerStatsRange[userName]?.stats;
            newCardsData.CompletedThisMonth = monthlyStats?.COMPLETED?.TOTAL || 0;
            newCardsData.cancelledThisMonth=monthlyStats?.CANCELLED || 0;            

            const performancePayload = {
                interviewer_id: user?.id,
                from_date: selectedDate?.from_date,
                to_date: selectedDate?.to_date,
            }
            const candidateFeedbackResponse = await getCandidateFeedbackIndex(performancePayload)
            if (candidateFeedbackResponse?.status === 200 && candidateFeedbackResponse?.data) {
                const response = candidateFeedbackResponse?.data?.overall;
                setPerformanceData(response)
            }

            const tatFeedbackPayload = {
                interviewer_user_id: user?.id,
                from_date: selectedDate?.from_date,
                to_date: selectedDate?.to_date,
            }
            const tatFeedbackResponse = await getTatFeedback(tatFeedbackPayload)
            if (tatFeedbackResponse?.status === 200 && tatFeedbackResponse?.data) {
                const response = tatFeedbackResponse?.data?.average_tat_feedback_in_seconds;
                setTatFeedbackData(response)
            }
            const karmaScoreResponse = await fetchProfile();
            setKarmaScoreData(karmaScoreResponse?.karma_score);

            setCardsData(newCardsData)
            const scheduleRes = await fetchSchedule();
            setScheduled(scheduleRes);
            const requestsRes = await fetchRequests();
            setRequests(requestsRes);           
        } catch(err){
        }
    }

    const removeRequest = (interview) => {
        const newRequests=requests.filter(req => req.id!==interview.id );
        setRequests(newRequests);
        dispatch(requestsAction(newRequests))
    }

    const addScheduled = (res) => {
        if(scheduled.length > 0){
            const newScheduled = [...scheduled];
            newScheduled.push(res);
            dispatch(scheduleAction(newScheduled));
        }
    }

    const Title = () => {
        return (
            <div className="header mt-md-0">
                <div className="header-body">
                    <div className="row align-items-center">
                        <div className="col">
                            <h1 className="header-title">Dashboard</h1>
                        </div>
                        <div className = 'col-auto'>
                            <h6 className="welcome-text color-3D3D3D">Welcome</h6>
                            <h5 className = 'color-3D3D3D date-text '>{moment().format('MMM DD YYYY, dddd')}</h5>
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
    const CardItem = ({title, subTitle ,flexJustify, cardClasses, children, className}) => {
        return (
            <Card className={`dashboard-card-complete-cancel card-item ${cardClasses ? cardClasses : ""} ${className}`}>
                <Box className="content" display="flex" flexDirection="row" justifyContent={flexJustify} alignItems="center">
                <Box>
                    <Typography className="title black-555555 mb-5 m">{title}</Typography>
                    {subTitle && <p className="sub-title mb-5">{subTitle}</p>}
                </Box>
                {children}
                </Box>
            </Card>
        )
    }
    function sortFunction(a,b){  
        return a.interview_details.scheduled_start_timestamp > b.interview_details.scheduled_start_timestamp ? 1 : -1;  
    };

    const renderCardSectionFullView = () => {

        return(
            <Box className="cards-container" display="flex" gridGap="20px">
            <Box className={classnames("pass-percent-full-view-wrapper dashboard-card-pass-percentage",{
                    "passPercent-red" : absPassPercentageCompared > 30,
                    "passPercent-yellow" : absPassPercentageCompared > 10 && absPassPercentageCompared <= 30,
                    [classes.noBoxShadow] : absPassPercentageCompared > 10
                })}  display='flex' flexDirection='column' justifyContent='center' >
                    <Typography className='percent-heading'>Pass Percentage</Typography>
                <PassPercentIndicator 
                    selectedDate={selectedDate}
                    passPercentageData={passPercentageData} 
                    setPassPercentageData={setPassPercentageData}
                /> 
            </Box>
        
        <Box display='flex' flexDirection='column' className='dashboard-card-complete-cancel height-cancel-complete' justifyContent="space-between">
            <CardItem
                title="Completed Interviews"
                flexJustify="space-between"
            >
                <Typography className = "number theme-green">{cardsData.CompletedThisMonth || 0}</Typography>
                {/* <Typography className = "description black-555555">{`${cardsData.CompletedThisMonth || 0} this month`}</Typography> */}
            </CardItem>

            <CardItem
                title="Cancelled Interviews"
                flexJustify="space-between"
            >
                <Typography className = "number theme-red">{cardsData.cancelledThisMonth || 0}</Typography>
                {/* <Typography className = "description black-555555">{`${cardsData.cancelledThisMonth || 0} this month`}</Typography> */}
            </CardItem>
            </Box>

            <Card className="card-item" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center"
              onMouseEnter={()=> setKarmaDetailsOpen(true)}
              onMouseLeave={()=> setKarmaDetailsOpen(false)}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    gridGap="20px"
                    justifyContent="center"
                    alignItems="center"
                    className="special-card-body"
                    zIndex="100"
                    position="relative"
                    maxWidth={karmaDetailsOpen ? "780px" :"250px"}
                >

                    <KarmaScore 
                        score={parseFloat(karmaScoreData).toFixed(2)}
                    />

                 { karmaDetailsOpen && <Box>
                     <Box color="blue" display="flex" gridGap="10px" >
                        <img src={bulbImg} alt='bulb-icon'/> Karma score is calculated on the basis of below parameters:
                     </Box>
                     <Box>
                      Avg. proceed candidate experience, avg. reject candidate experience, 
                      feedback received percentage, pass percentage,  avg. feedback TAT,
                      cancelled interview percentage, avg. audit interview rating, 
                      inappropriate interviews, total interviews.
                     </Box>
                    </Box>
                }

                </Box>
            </Card>

              {!karmaDetailsOpen && <Box display='flex' flexDirection='column' flexBasis="aut" className='dashboard-card-complete-cancel height-cancel-complete' justifyContent="space-between">
                <CardItem
                    title="Candidate Rating"
                    subTitle={`(${performanceData?.total_no_of_feedback || 0} ratings)`}
                    flexJustify="space-between"
                >
                    <Box>
                        <Box display="flex" className="ratings-value" alignItems="baseline">
                            <Typography className="size--big">{performanceData?.happiness_index || 0}</Typography>
                            <Typography className="size--normal">/5</Typography>
                        </Box>

                        <Box display="flex" className='ratings' justifyContent="space-between" alignItems="center">
                            <Rating name="half-rating" className='ratings-bar' value={performanceData?.happiness_index || 0} precision={0.1} readOnly marginLeft="20px" />
                        </Box>
                    </Box>
                </CardItem>

                <CardItem
                    title="Turn Around Time"
                    subTitle="Average Turn Around Time"
                    flexJustify="space-between"
                >
                    {renderTATString()}
                </CardItem>
                </Box>}
            </Box>

        )
    }
    
    const renderTATString = () => {
        let TATHour = Math.floor(tatFeedbackData / 3600);
        let TATMinute = Math.floor((tatFeedbackData % 3600) / 60);

        return tatFeedbackData ?
            <Typography className='tat-feedback-box'>
                {TATHour !== 0 && <><span className="content size--big">{TATHour}</span> <span className='normal'>hr{TATHour > 1 && <>s</>}</span></>}
                {" "}
                {TATMinute !== 0 && <><span className="content">{TATMinute}</span> <span className='normal'>min{TATMinute > 1 && <>s</>}</span></>}
            </Typography>
            : <Typography>-</Typography>
    }
    
    const renderCountCardCarousel = () => {
        return(
            <>
            <Carousel
            value={value}
            onChange={onChange}
            arrows
            infinite
            slidesPerPage={1}
        >
                    <CountCard
                        text={'Completed Interviews'}
                        // subtext={`${cardsData.CompletedThisMonth || 0} this month`}
                        count={cardsData.CompletedThisMonth || 0}
                        color='color-green'
                    />

                    <CountCard
                        text={'Cancelled Interviews'}
                        // subtext={`${cardsData.cancelledThisMonth || 0} this month`}
                        count={cardsData.cancelledThisMonth || 0}
                        color='color-red'
                    />

                    <CountCard
                        text={'Karma Score'}
                        count={parseFloat(karmaScoreData).toFixed(2)}
                        color='color-blue'
                    />

                    <CountCard
                        text={'Candidate Rating'}
                        count={`${performanceData?.happiness_index || 0}/5`}
                        subtext={`${performanceData?.total_no_of_feedback || 0} ratings`}
                        color='color-blue'
                    />

                    <CountCard
                        text={'Turn Around Time'}
                        count={renderTATString()}
                        subtext={'Average Turn Around Time'}
                        color='color-blue'
                    />

        </Carousel>
        <Dots value={value} onChange={onChange} number={5} />
        </>
        )
    }
    const renderCardSectionMobileView = () => {
        return(
            <Box className='mobile-view-card-wrapper'>
                {renderPassPercentageMobile()}
                {renderCountCardCarousel()}
            </Box>
        )
    }

    const handleSelectedDate =  (value) => {
      setSelectedDate(value);
    }

    const renderBottomSectionMobileView = (data) => {
        return(
            <Box>
                    <LabelSeperator 
                labelLeft={'Interview Requests'}
                labelRight={'View All'}
                onClick={() => historyRef.push('/requests')}
                />
                <Box>
                    <MultipleSlotCard
                        data={data}
                        handleSelectedDate={handleSelectedDate} 
                        />
                </Box>
                <LabelSeperator 
                    labelLeft={'Interviews Scheduled'}
                    labelRight={'View All'}
                    onClick={() => historyRef.push('/scheduled')}
                />
                <Accordian
                data={todaySchedule}
                />
            </Box>
        )
    }
    const renderPassPercentageMobile = () => {
        return(
            <Box className='pass-percent-wrapper' display='flex' justifyContent='space-between' alignItems='center'>
                <Box display='flex' flexDirection='column'>
                <Typography className='pass-percent-text'>Pass</Typography>
                <Typography className='pass-percent-text'>Percentage</Typography>
                </Box>
                <Box className='width-60'>
                <PassPercentIndicator 
                    selectedDate={selectedDate}
                    passPercentageData={passPercentageData} 
                    setPassPercentageData={setPassPercentageData}
                />
                </Box>
            </Box>
        )
    }
    return (
        <div>
          <Navbar {...props} pageTitle="Dashboard" />
          <DashboardView>
              <Title />
              <Backdrop
                  style={{zIndex: '999999'}} 
                  sx={{ color: '#fff', zIndex: 999999 }}
                  open={accepting}
              >
                  <CircularProgress color="inherit" />
              </Backdrop>

              {/* Availability modal */}
              <CustomModal
                  open={showAvailabilityModal}
                  onClose={()=>setShowAvailabilityModal(false)}
              >
                  <Box className={width > 850 ? "availability-modal" : "availability-modal width-85"} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                      <Box className="close-icon" onClick={()=>setShowAvailabilityModal(false)} display="flex" justifyContent="flex-end"> 
                          <CloseIcon />
                      </Box>
                      <Typography className='modal-description' id="modal-description" >
                          Hey, please update your avaialbilty for the coming week!
                      </Typography>
                      <Button 
                          onClick={()=>historyRef.push('/availability')} 
                          className="availabilityPage-link"
                          variant="contained"
                          color="primary"
                      >
                          Add Availability
                      </Button>
                  </Box>
              </CustomModal>

              <Box Box display="flex">
                {absPassPercentageCompared > 10 && 
                  <Box className="passPercentage-comparator">
                    <Typography className="compare-text">
                        <img src={passPercentInfoIcon} alt="info" className="pass-percent-infoIcon"/>
                        Hey, your pass percentage is 
                        { passPercentageData?.passPercentageCompared > 30 
                        ? ' very high' 
                        : passPercentageData?.passPercentageCompared > 10 
                        ? ' high'
                        : passPercentageData?.passPercentageCompared < -30
                        ? ' very low'
                        : ' low' } 
                    </Typography>
                    <div className="triangle"></div>
                  </Box>}

                  <FormControl sx={{minWidth: 160, marginLeft: 'auto' }} size="small">

                      <InputLabel id="demo-simple-select-disabled-label">Month</InputLabel>
                      <Select
                          labelId="demo-simple-select-disabled-label"
                          id="demo-simple-select-disabled"
                          label="Month"
                          value={`${selectedDate?.from_date} - ${selectedDate?.to_date}`}
                      >
                        {
                          monthArray?.length>0 && monthArray.map((item, index)=>(

                            <MenuItem key={index} onClick={()=>handleSelectedDate(item)} value={`${item?.from_date} - ${item?.to_date}`} style={{fontSize:'12px', color:'grey'}} >
                                {item?.display_start} | {item?.display_end}  
                                </MenuItem>
                          ))
                        }
                      </Select>
                  </FormControl>

              </Box>

              {/* cards section for pass percentage and  performance */}
              {width > 850  ? renderCardSectionFullView()  : renderCardSectionMobileView()}
              {
                  (width > 850 && requests?.length>0) && <Typography className="requests-title">{requests.length} interview {requests.length === 1?"request is":"requests are"} pending, let’s help hire the right folks!</Typography>
              }
              {/* Bottom Section */}
              {width > 850 ? <div className = 'dashboard-container'>
                  <Box 
                      className = 'dashboard-container-left'
                      display="flex"
                      flexDirection="column"
                  >
                      <TableActionCard accepting={accepting} setAccepting={setAccepting} id = "requests" title = "Interview Requests" tableHeaders = {requestsHeader} requests = {requests} removeRequest={removeRequest} addScheduled={addScheduled}/>
                  </Box>
                  <div className = 'dashboard-container-right'>
                      <TableCard id = "scheduled" title = "Interviews Scheduled" tableHeaders = {upcomingInterviewsHeaders} tableData = {todaySchedule}/>
                  </div>
              </div>
              :
              <>
              {renderBottomSectionMobileView(requests)}
              </>
              }
          </DashboardView>
        </div>
    )
}

export default Dashboard;