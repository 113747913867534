import React,{ useState } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";

import "./../../feedback.scss";
import "./../../SectionsV1/Preview/preview.scss";
import ratingReject6 from "../../../../../assets/images/feedback/rating_6_reject.svg";

import { ratingToSVG } from '../../Components/Preview'
import lightBulb from "../../../../../assets/images/feedback/lightbulb.svg";

import {
  overallRecommendation, CommentConstants, INTERVIEWER_TYPE,
} from "../../Constants/FeedbackConstants";

const Details = ({
  interviewDetails,
  codingQuestionData,
  feedbackSummaryData,
  feedbackType,
  showFeedbackComments,
  renderAddCommentSection,
  renderViewUnresolvedCommentSection
}) => {
  const interviewerType = interviewDetails?.interview_details?.interviewer_type === INTERVIEWER_TYPE.CLIENT_INTERNAL ?
   INTERVIEWER_TYPE.CLIENT_INTERNAL_TEXT : INTERVIEWER_TYPE.IV_EXPERTS_TEXT ;

  return (
    <>
    <Box className="interviewer-box">
        <img src={lightBulb} className="bulb-icon"/>
        {interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL_TEXT ? 
          <span className="interviewer-text">This interview was conducted by client's <span className="interviewer-type">{interviewerType}.</span></span>
          : <span className="interviewer-text c-44598D">This interview was conducted by an <span className="interviewer-type">{interviewerType}.</span></span>
        }
    </Box>
    <Box
      display="flex"
      className="width-100 mtb-20"
      justifyContent="space-between"
    >
      <Box display="flex" id="add-comment-parent" className={`details-box-style ${feedbackSummaryData?.recommendation ===
          overallRecommendation.STRONGLY_PROCEED
          ? "strong-proceed-bg"
          : feedbackSummaryData?.recommendation ===
            overallRecommendation.PROCEED
            ? "proceed-bg"
            : feedbackSummaryData?.recommendation ===
              overallRecommendation.REJECT
              ? "reject-bg"
              : feedbackSummaryData?.recommendation ===
                overallRecommendation.STRONGLY_REJECT
                ? "strong-reject-bg"
                : ""
        }`}>
        <Box display="flex" flexDirection="column">
          <div className="overall-recommendation-heading">
            Overall Recommendation and Rating
          </div>

          <hr style={{ width: "100%" }} />

          <div
            className={`overall-recommendation-text  
           ${feedbackSummaryData?.recommendation ===
                overallRecommendation.STRONGLY_PROCEED
                ? "strongly-proceed-color"
                : feedbackSummaryData?.recommendation ===
                  overallRecommendation.PROCEED
                  ? "proceed-color"
                  : feedbackSummaryData?.recommendation ===
                    overallRecommendation.REJECT
                    ? "reject-color"
                    : feedbackSummaryData?.recommendation ===
                      overallRecommendation.STRONGLY_REJECT
                      ? "strongly-reject-color"
                      : ""
              }`}
          >
            {feedbackSummaryData?.recommendation
              ? feedbackSummaryData?.recommendation ===
                overallRecommendation.STRONGLY_PROCEED
                ? "STRONG PROCEED"
                : feedbackSummaryData?.recommendation ===
                  overallRecommendation.STRONGLY_REJECT
                  ? "STRONG REJECT"
                  : feedbackSummaryData?.recommendation
              : "NA"}
          </div>
        </Box>
        <img
          src={(feedbackSummaryData?.rating===6 && feedbackSummaryData?.recommendation === overallRecommendation.REJECT)
             ? ratingReject6 : ratingToSVG[`${feedbackSummaryData?.rating ? feedbackSummaryData?.rating : 0}`]}
          style={{ marginLeft: "20px", width: '87px', height: '88px', alignSelf: "center" }}
        />
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.OVERALL_COMMENTS)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.OVERALL_COMMENTS)}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        className="details-box-style width-75"
        style={{ marginLeft: "20px" }}
      >
      <div className="details-box-title">Interview DETAILS</div>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
      >
        <Box display="flex" flexDirection="column" className="width-50">

          <div className="row-style">
            <div className="details-box-label-value">Candidate Name:</div>
            <div className="details-box-label-value details-value">
              {interviewDetails?.candidate_details?.name}
            </div>
          </div>
         
          <div className="row-style">
            <div className="details-box-label-value">Interview Date:</div>
            <div className="details-box-label-value details-value">
              {interviewDetails?.interview_details?.scheduled_at
                ? moment(
                  interviewDetails?.interview_details?.scheduled_at * 1000
                ).format("MMM DD, YYYY | hh:mm a")
                : "NA"}
            </div>
          </div>
        </Box>

        {/* <Box display="flex" className="" > */}
        <div className="vertical" />
        <Box display="flex" flexDirection="column" className="mrl-20 width-50" >
          <div className="row-style">
            <div className="details-box-label-value">Position:</div>
            <div className="details-box-label-value details-value">
              {interviewDetails?.interview_details?.position_name}
            </div>
          </div>
          <div className="row-style">
            <div className="details-box-label-value">Round:</div>
            <div className="details-box-label-value details-value">
              {interviewDetails?.interview_details?.round_name}
            </div>
          </div>
         
          {/* { showProblemSolvingCodingFields(interviewDetails, codingQuestionData) && 
            <div className="row-style">
              <div className="details-box-label-value">Language:</div>
              <div className="details-box-label-value details-value">
                {codingQuestionData?.language || "NA"}
              </div>
            </div>} */}
        </Box>
        {/* </Box> */}
      </Box>
      </Box>
    </Box>
    </>
  );
};



export default Details;