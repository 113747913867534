import IconButtonMUI from '@mui/material/IconButton';
import AddCommentIconMUI from '@mui/icons-material/AddComment';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import { Tooltip } from '../../../../atoms/Tooltip/Tooltip';
import { Modal } from '../../../../atoms/Modal/Modal';
import {useState} from "react";
import "./styles.scss";
import SwtAlert from '../../../../../Utils/SwtAlert';
import validator from "validator";
const AddCommentIcon = styled(AddCommentIconMUI)({
   fontSize:"32px",
   transform:"rotateY(180deg)",
});

const IconButton = styled(IconButtonMUI)({
    "&:hover":{
      background:"#D8E5FF"
    }
});
export const Comments = ({
  isDialogOpen,
  setIsDialogOpen,
  handleDefaultCss,
  data,
  setData,
  showNewCommentBox,
  setShowNewCommentBox,
  getCssClass,
  isRightBoxOpen,
  isTemplate=false
}) => {
    const handleOpen = (e) => {
      e.stopPropagation();
      setIsDialogOpen(true)
    }
    const handleClose = (e,type) => {
      e.stopPropagation();
      setIsDialogOpen(false)
      if(type!=='submit')
      {
        handleDefaultCss();
      }
    }

    const handleShowNewComment = (e) => {
      
      setShowNewCommentBox(prev => ({...prev, status: !prev.status}));
    }

    if(isTemplate)
    {
      return <></>
    }
    return <Box className="comment-icon-main-container">
              <Tooltip title="Add Comments" placement="top-start">
                <IconButton onClick={handleShowNewComment} aria-label="comment">
                  <AddCommentIcon className={getCssClass()}/>
                </IconButton>
              </Tooltip>
              {
                isRightBoxOpen?
                <Modal
              open={isDialogOpen}
              onClose={handleClose}
              title={<ModalTitle/>}
              body={
                      <ModalBody 
                        handleOpen={handleOpen} 
                        handleClose={handleClose} 
                        data={data} 
                        setData={setData}/>}
              height="fit-content"
              width="600px"
            />
                :""
              }
            
        </Box>
}


const ModalTitle = () => {
  const titleStyle = {
    fontFamily: 'Poppins',
    fontWeight: "600",
    fontSize: "18px",
    letterSpacing: "0.005em",
    color: "#3D3D3D",

  }
  return <Typography sx={titleStyle}>Information Required</Typography>
}

const ModalBody = ({handleOpen,handleClose,data,setData,}) => {
  const [tempData,setTempData] = useState({...data});

  const handleCancel = (e) => {
    setTempData({...data});
    handleClose(e);
}
const handleSubmit = (e) => {
  let isError = false;
  if(!tempData?.name || tempData?.name?.trim()?.length===0)
  {
    tempData['name'] = "";
    tempData.isError["name"] = true;
    isError = true;
  }
  if(!tempData?.email || !validator?.isEmail(tempData?.email))
  {
    tempData['email'] = "";
    tempData.isError["email"] = true;
    isError = true;
  }
  if(isError)
  {
    setTempData({...tempData})
    return;
  }
  const obj = {
    name : tempData?.name,
    email : tempData?.email,
  }
  localStorage.setItem("guide_commenter_details",JSON.stringify(obj));
  setData({...tempData});
  handleClose(e,"submit");
}
const handleInputChange = (e) => {
  e.stopPropagation();
  const {name,value} = e?.target;
  if(!value)
  {
    tempData[name] = value;
    tempData.isError[name] = true;
    setTempData({...tempData});
    return;
  }
  tempData[name] = value;
  tempData.isError[name] = false;
  setTempData({...tempData});
  
}
  return <Box className='common-shared-comment-body' display="flex" flexDirection="column" justifyContent="space-evenly" gap="10px">
            <Box>
                <Typography className='text-1'>To add comments please enter your name and email ID</Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap="4px">
              <Typography className='text-2'>Name</Typography>
              <TextField
              className='input'
              placeholder='Please add your name'
              name="name"
              value={tempData?.name}
              onChange={handleInputChange}
                sx={{
                    padding: "0px",
                    "& legend": {
                        display: "none"
                    },

                }}
                inputProps={{
                    style: {
                        padding: "8px",
                    },
                }}
              />
              {tempData?.isError?.name?<Typography className='email-error'>Please enter valid name</Typography>:""}
            </Box>
            <Box  display="flex" flexDirection="column" gap="4px">
              <Typography className='text-2'>Email ID</Typography>
              <Box display="flex">
              <TextField
              className='input input-2'
              placeholder='Please add your email ID '
              name="email"
              value={tempData?.email}
              onChange={handleInputChange}
                sx={{
                    padding: "0px",
                    "& legend": {
                        display: "none"
                    },

                }}
                inputProps={{
                    style: {
                        padding: "8px",
                    },
                }}
              />
              </Box>
              {tempData?.isError?.email?<Typography className='email-error'>Please enter valid user email ID</Typography>:""}
              
            </Box>
            <Box className='btn-group'>
                <Box>
                  <Button className="btn btn-1" onClick={handleCancel}>Cancel</Button>
                </Box>
                <Box>
                  <Button className="btn btn-2" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Box>
        </Box>
}