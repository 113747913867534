import axios from "./axios";

export const getInterviewDetail = async (c_id, i_id) => {
  try {
    const apiEndpoint = "/interview/" + c_id + "/" + i_id;
    const resp = await axios.get(apiEndpoint);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const sendCandidateFeedbackMail = async (payload) => {
  return axios.post('/interviewer/send-candidate-feedback',payload)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  });
};


export const notifyClientCandidateNotJoined = async (payload) => {
  return axios.post('/interviewer/send-client-message',payload)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  });
};


export const transfer_interview = (payload)=>{
  return axios
    .post('/interviewer/transfer-interview', payload)
      .then((response)=>{
        return response?.status;
      })
      .catch((err)=>{
        return err?.response?.status;
      })
}

export const edit_interview = (payload) => {
  return axios
    .post(`/interview/edit/${payload?.id}`, payload)
    .then(response => {
      return response;
    }).catch(err => {
      return err.response;
    })
};