import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from "react-redux";
import PrescreeningReport from '@iv/shared/components/pages/PreScreening/PrescreeningReport/PreScreeningReport';
import { getPrescreenReportData } from '../../services/Prescreening';
import { fileDownload } from '../../services/file';

const PreScreeningReport = ()  => {

  const currentState = useSelector((state) => state);
  const [state, setState] = useState({});
  const [isDownloadBtnDisabled, setIsDownloadBtnDisabled] = useState(true);
  const history = useHistory();
  const urlParams = new URLSearchParams(history?.location?.search);
  const clientId = parseInt(urlParams.get("client_id"));
  const prescreenRoundId = parseInt(urlParams.get("quickscreen_round_id"));
  const urlToken = urlParams.get("token");
  const jwtToken = currentState?.auth?.access_token;
  let sessionToken = sessionStorage.getItem("temporary_access_token");


const fetchPrescreenReportDetails = async () => {
  const reportData = await getPrescreenReportData(clientId, prescreenRoundId, urlToken);
  if(reportData?.status === 200){
    setState(reportData?.data);
    setTimeout(() => {
      setIsDownloadBtnDisabled(false);
    }, 15000)
  }
}

useEffect(() => {
  if(!jwtToken)
        {
            if(!urlToken && sessionToken)
            {
                window.location.href = `${window.location.href}&token=${sessionToken}`;
                return;
            }
            else if(urlToken && !sessionToken)
            {
                history.push(`/screening/report?token=${urlToken}`);
                return;
            }
        }
  fetchPrescreenReportDetails();
},[]);



  return (
    <div>
      { jwtToken || (urlToken && sessionToken)?
      (
        Object.keys(state).length > 0 &&
            <PrescreeningReport state={state} handleFileDownload={fileDownload} isDownloadBtnDisabled={isDownloadBtnDisabled}/>
      )
      : ''
    }
     </div>
  )
}

export default PreScreeningReport