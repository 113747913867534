import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from "@material-ui/core";
import "./RequestModal.scss";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment'
import fileIcon from '../../../images/file-icon.svg';
import { confirmRequest } from '../../../services/requests';
import Swal from 'sweetalert2';
import cogoToast from 'cogo-toast';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '90%',
        minHeight: '350px',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #f2f2f2',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: "28px",
    }
}));

const RequestModal = ({ show, setShow, request, selectedTimeSlot, setRequestDetailModalShow, removeRequestFromUi }) => {

    const [checked, setChecked] = useState(false); // State to track T&C acceptance state

    const classes = useStyles();

    const acceptInterview = async () => {
        try {
            setShow(false);
            const response = await Swal.fire({
                title: "Are you sure you want to accept the request?",
                icon: 'info',
                iconColor: '#123FBC',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                customClass: {
                    cancelButton: 'btnCancelSwal',
                    confirmButton: 'btnConfirmSwal'
                }
            });
            if (response.isConfirmed) {
                const payload = {
                    "interview_id": request.interview_details.id,
                    "client_id": request.company_name.id,
                    "scheduled_start_timestamp": selectedTimeSlot,
                    "scheduled_end_timestamp": selectedTimeSlot + 3600,
                    "last_updated_timestamp": request.interview_details.last_updated_timestamp,
                    "create_calendar_invite": true
                }
                await confirmRequest(payload);
                setRequestDetailModalShow(false);
                removeRequestFromUi();
                cogoToast.success(`Interview has been scheduled for ${request.candidate_name.name} at ${moment(selectedTimeSlot * 1000).format('MMM DD, YYYY | hh:mm A')}`);
            }
        } catch (err) {
        }
    }

    return (
        <Modal
            open={show}
            onClose={() => { setShow(false) }}
            className={classes.modal}
            aria-labelledby="download-interview-history-label"
            aria-describedby="download-interview-history-description"
        >
            <Box className={classes.paper}>
                <Box display="flex" flexDirection="column" justifyContent="space-between" className="modal-content">
                    <Typography d="modal-title" className="modal-title">Please go through the interview guide before accepting the interview.</Typography>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <img className="guide-img" src={fileIcon} alt="Guide icon" />
                        <a href={request?.round?.interview_guide_link} target="_blank" className="guide-link" rel="noreferrer">View Guide</a>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Checkbox color="primary" checked={checked} onChange={() => setChecked(!checked)} />
                            <Typography className="check-text">I have read the interview guideline</Typography>
                        </Box>
                        <Box className="time-slot-box">
                            <Typography class='slot-text'>Selected Slot:</Typography>
                            <Typography class='slot-text'>{moment(selectedTimeSlot * 1000).format('MMM DD, YYYY | hh:mm A')}</Typography>
                        </Box>
                        <Box className="modal-action-buttons-box">
                            <Button
                                variant="outlined"
                                className="btn btnPrimary modal-btn"
                                color="primary"
                                onClick={() => { setShow(false) }}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={acceptInterview}
                                disabled={!checked}
                                className={`btn modal-btn ${checked ? "modal-btn--enabled" : "modal-btn--disabled"}`}>
                                Accept
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default RequestModal;