import React from "react";
import { Text, View, Image, Svg, Path } from '@react-pdf/renderer';

import { stylesPDF, stylesFeedbackSummary } from "../../SectionsV1/Download/Style";
import { ratingToImage } from '../../Components/Download';

import { InterviewFeedbackStatus, isV2SummaryProcessedResponse } from "../../Constants/FeedbackConstants";

// images......
import highlightsImg from "../../../../../assets/images/download-feedback/feedback-highlights.png";
import lowLightsImg from "../../../../../assets/images/download-feedback/feedback-lowlights.png";
import feedbackScopeImprovementImg from "../../../../../assets/images/download-feedback/feedback-scope-improvement.png";
import ratingReject6 from "../../../../../assets/images/download-feedback/rating_6_reject.png";


export const FeedbackSummary = ({ interviewDetails, feedbackStatus, feedbackSummaryData }) => {
  const highlightsArray = feedbackSummaryData?.highlights;
  const lowlightsArray = feedbackSummaryData?.lowlights;
  const scopeOfImprovementArray = feedbackSummaryData?.scopeOfImprovement;
  const extraCommentsArray = isV2SummaryProcessedResponse(interviewDetails) ? interviewDetails?.feedback_pages?.processed_response?.summary?.summary?.extra_comments : feedbackSummaryData?.extraComments;
  const skillEvaluationsArray = feedbackSummaryData?.skillEvaluation;
  const ratingType6 = interviewDetails?.feedback_pages?.raw_form?.summary?.summary?.fields?.rating_and_recommendation?.data?.[2]?.type;

  const groupEvaluationSkills = (list, key) => {
    return list?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

    const groupEvaluationSkillsV2 = (list, key) => {
    const arr = [
      {key:"HIGH",data:[],label:"High"},
      {key:"MEDIUM", data:[],label:"Medium"},
      {key:"LOW",data:[],label:"Low"},
      {key:"null",data:[],label:"Other"}
    ];
    list?.forEach(item1=>{
      let index = -1;
      arr.find((item2,index2)=>{
        if(item2.key===item1[key])
        {
          index = index2;
        }
      });
      index = index !== -1 ? index:3;
      if(arr[index])
      {
        arr[index].data.push(item1)
      }
    });
    return arr;
  };


  const groupEvaluationSkillsForObject = (object) => {
    const list = []
    Object.keys(object).map((data, index) => {
      const objData = { ...object[data] }
      objData.type = data
      list.push(objData)
    })

    return list
  }


  const renderSkillEvaluationOlderVersion = () => {
    return (
      <View>
        <View style={[stylesPDF.columnstyle, { width: "50%", paddingRight: 15 }]}>

          <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { marginTop: 0, color: "#209E14" }]}>HIGHLIGHTS</Text>

          {highlightsArray?.map((data) => {
            return (
              <View style={[stylesPDF.rowstyle]}>
                <Image
                  src={highlightsImg}
                  style={[{ marginRight: 8, width: 12, height: 12 }]}
                />
                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
              </View>
            );
          })}

          <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { color: "#984141" }]}>LOWLIGHTS</Text>

          {lowlightsArray?.map((data) => {
            return (
              <View style={[stylesPDF.rowstyle]}>
                <Image
                  src={lowLightsImg}
                  style={[{ marginRight: 8, width: 12, height: 12 }]}
                />
                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
              </View>
            );
          })}

          <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { color: "#011C67" }]}>SCOPE OF IMPROVEMENT</Text>

          {scopeOfImprovementArray?.map((data) => {
            return (
              <View style={[stylesPDF.rowstyle]}>
                <Image
                  src={feedbackScopeImprovementImg}
                  style={[{ marginRight: 8, width: 12, height: 12 }]}
                />
                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
              </View>
            );
          })}


          {extraCommentsArray?.length > 0 && <Text style={[stylesFeedbackSummary.feedbackSummarySubheading]}>EXTRA COMMENTS</Text>}

          {extraCommentsArray?.map((data, index) => {
            return (
              <View style={[stylesPDF.rowstyle]}>
                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>
                  {index + 1}. {data || 'NA'}</Text>
              </View>
            );
          })}
        </View>

        <View style={[stylesPDF.columnstyle, { width: "50%", paddingLeft: 15 }]}>
          <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { marginTop: 0, marginLeft: 15 }]}>SKILL EVALUATION - RATING</Text>

          <View style={[stylesPDF.columnstyle]}>


            {Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.map((data, index) => {
              return (
                <View style={[stylesPDF.columnstyle]}>
                  {data !== 'undefined' && <Text style={[stylesFeedbackSummary.fieldHeader, { marginLeft: 16 }]}>{data}</Text>}

                  <View style={[stylesPDF.rowstyleWrap, { margin: '4 0' }]}>
                    {groupEvaluationSkills(skillEvaluationsArray, 'group')[data]?.map((data) => {
                      if (!(feedbackStatus === InterviewFeedbackStatus.APPROVED && !data?.rating)) {
                        return (
                          <View style={[stylesPDF.columnstyle, {
                            width: "25%",
                            alignItems: "center",
                            marginTop: 16,
                            justifyContent: "top"
                          }]}>
                            <Image style={[{ width: "75%" }]}
                              src={(data?.rating === 6 && ratingType6 === "REJECT")
                                ? ratingReject6 : ratingToImage[`${data?.rating ? data?.rating?.toString() : "0"}`]} />
                            <Text style={[stylesFeedbackSummary.feedbackSummaryRatingText, {textAlign: 'center'}]}>
                              {data?.type}
                            </Text>
                          </View>
                        )
                      }
                    })}
                  </View>

                  {index < Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length - 1
                    && <View style={[stylesFeedbackSummary.feedbackEvalSeparator, stylesFeedbackSummary.horizontalDivider]} />}
                </View>
              )
            })}
          </View>
        </View>
      </View>

    )
  }

  const renderSkillEvaluationNewerVersion = () => {
    // This will render skill evaluation newer version where skills are mapped with highlights and lowlights and will show separately.
    const star = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

    const renderRatings = (rating) => {

      let color = '#15A90A'
      if (rating < 6 || (rating === 6 && ratingType6 === "REJECT")) {
        color = '#DD2626'
      }

      return <View style={[stylesPDF.rowstyle, { alignItems: "center" }]}>
        {
          star?.map((item, index) => {
            if ((index + 1) <= rating) {
              return <View>
                <Svg width="20" height="19" viewBox="0 0 20 19" fill={color} xmlns="http://www.w3.org/2000/svg">
                  <Path d="M10 0L12.8919 6.01964L19.5106 6.90983L14.6792 11.5204L15.8779 18.0902L10 14.92L4.12215 18.0902L5.3208 11.5204L0.489435 6.90983L7.1081 6.01964L10 0Z" fill={color} />
                </Svg>
              </View>
            }
            else {
              return <View>  <Svg width="20" height="19" viewBox="0 0 20 19" fill={'#e0e0e0'} xmlns="http://www.w3.org/2000/svg">
                  <Path d="M10 0L12.8919 6.01964L19.5106 6.90983L14.6792 11.5204L15.8779 18.0902L10 14.92L4.12215 18.0902L5.3208 11.5204L0.489435 6.90983L7.1081 6.01964L10 0Z" fill={'#e0e0e0'} />
                </Svg></View>
            }
          })
        }
      </View>
    }

     // Function to organize data into rows
  const organizeDataIntoRows = (data, itemsPerRow) => {
    const rows = [];
    for (let i = 0; i < data.length; i += itemsPerRow) {
      rows.push(data.slice(i, i + itemsPerRow));
    }
    return rows;
  };

    return (
      <View style={[stylesPDF.columnstyle]}>

        <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { marginTop: 0, marginLeft: 15 }]}>SKILL EVALUATION - RATING</Text>

        {
          groupEvaluationSkillsV2(groupEvaluationSkillsForObject(skillEvaluationsArray), 'priority')?.map((item, index) => {
            if(item?.data?.length > 0)
            return (
              <View style={[stylesPDF.columnstyle, {width:'100%'}]}>
                <View className="priority-heading">
                  <Text style={[stylesFeedbackSummary.fieldHeader, { marginLeft: 16 }]} 
                  className={`${item?.key?.toLocaleUpperCase() === 'HIGH' ? 'high' : 
                  item?.key?.toLocaleUpperCase() === 'MEDIUM' ? 'medium' : 'low'}`}>
                    {(item?.key !== 'undefined' && item?.key != null && item?.key !='null')  ? 
                    item?.label : "OTHER"} Priority
                  </Text>
                </View>
                <View style={[stylesPDF.columnstyle, { margin: '4 0', width: '100%' }]}>
                  { 
                    organizeDataIntoRows(item?.data, 2)?.map((row, childIndex) => (
  
                      <View style={[stylesPDF.rowstyle]}>
                      {row.map((data) => {
                        if (!(feedbackStatus === InterviewFeedbackStatus.APPROVED && !data?.rating && !data?.value)) {
                        return (
                        <View style={[stylesPDF.columnstyle, {
                          width: "48%",
                          margin: "8px",
                          border: '1px solid #CFDFF9',
                          borderRadius: '12px',
                          padding: '10px',
                          justifyContent: "top"
                        }]}
                        >

                          <View>
                            <Text style={[stylesFeedbackSummary.feedbackSummaryRatingText]}>{data?.type}</Text>
                          </View>


                          <View style={[stylesPDF.rowstyle, { marginBottom: 4 }]}>
                            {data?.rating && renderRatings(data?.rating) } 
                            <Text style={[{fontSize:'12px', marginLeft:'5px', marginTop:'4px'}]}>{data?.rating || '--'}</Text>
                          </View>

                          {data.highlights?.map((data) => {
                             if(data)
                            return (
                            <View style={[stylesPDF.rowstyle]}>
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>&#8226; </Text>
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
                              </View>
                            );
                          })}

                          {data.lowlights?.map((data) => {
                            if(data)
                            return (
                              <View style={[stylesPDF.rowstyle]}>
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>&#8226; </Text>
                                <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
                              </View>
                            );
                          })}

                          { data?.value &&
                            <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data?.value}</Text>
                          }
                        </View>
                      )}
                     })}
                  </View>))
                  }
               
                </View>

                {index < (groupEvaluationSkillsV2(groupEvaluationSkillsForObject(skillEvaluationsArray), 'priority'))?.length - 1
                  && <View style={[stylesFeedbackSummary.feedbackEvalSeparator, stylesFeedbackSummary.horizontalDivider]} />}
              </View>
            )
          })
        }

        <View style={[stylesPDF.rowstyle]}>

          <View style={[stylesPDF.columnstyle, { width: '45%' }]}>

            <Text style={[stylesFeedbackSummary.feedbackSummarySubheading, { color: "#011C67" }]}>SCOPE OF IMPROVEMENT</Text>
            {scopeOfImprovementArray?.map((data) => {
              return (
                <View style={[stylesPDF.rowstyle]}>
                  <Image
                    src={feedbackScopeImprovementImg}
                    style={[{ marginRight: 8, width: 12, height: 12 }]}
                  />
                  <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>{data}</Text>
                </View>
              );
            })}

          </View>

          <View style={[stylesPDF.columnstyle, { width: '45%', marginLeft: '5%' }]}>

            {extraCommentsArray?.length > 0 && <Text style={[stylesFeedbackSummary.feedbackSummarySubheading]}>INTERVIEWER’S COMMENTS</Text>}

            {extraCommentsArray?.map((data, index) => {
              return (
                <View style={[stylesPDF.rowstyle]}>
                  <Text style={[stylesFeedbackSummary.feedbackSummaryValue]}>
                    {index + 1}. {data || 'NA'}</Text>
                </View>
              );
            })}
          </View>

        </View>
      </View>

    )

  }

  return (
    <View style={[{ margin: "15", border: "1 solid #cdcdcd", backgroundColor: "#fff" }]}>

      <View style={[stylesPDF.rowstyle, { width: "100%", backgroundColor: "#C5D4FF", minHeight: 42, padding: "15" }]}>
        <Text style={[stylesFeedbackSummary.previewSubheaderTitle]}> Feedback Summary</Text>
      </View>

      <View style={[stylesPDF.columnstyle, { width: "100%", padding: 20 }]}>

        {isV2SummaryProcessedResponse(interviewDetails) && interviewDetails?.feedback_pages?.processed_response?.['summary']?.summary?.suspicion_of_cheating
          && <Text style={[stylesFeedbackSummary.suspicionCheating]}>Suspicion of Cheating</Text>}

        {Array.isArray(skillEvaluationsArray) ? renderSkillEvaluationOlderVersion() : renderSkillEvaluationNewerVersion()}
      </View>

    </View>
  );
};