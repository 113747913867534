import React from "react";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./../../feedback.scss";
import "./../../SectionsV1/Preview/preview.scss";

import feedbackHighlights from "../../../../../assets/images/feedback/feedback-highlights.svg"
import feedbackLowlights from "../../../../../assets/images/feedback/feedback-lowlights.svg"
import feedbackScopeImprovement from "../../../../../assets/images/feedback/feedback-scope-improvement.svg"
import ratingReject6 from "../../../../../assets/images/feedback/rating_6_reject.svg";
import feedbackScopeImprovementBulb from "../../../../../assets/images/feedback/lightbulb.svg";
import feedbackInterviewerComment from "../../../../../assets/images/feedback/interviewer-comment.svg";
import starIcon from "../../../../../assets/images/feedback/star-icon.svg";
import fadeStarIcon from "../../../../../assets/images/feedback/fade-star-icon.svg";

import { ratingToSVG } from '../../Components/Preview'
import { AccordionSummary, AccordionDetails } from '../../Components/Accordion'

import {
  InterviewFeedbackStatus, CommentConstants, isV2SummaryProcessedResponse,
} from "../../Constants/FeedbackConstants";

const FeedbackSummary = ({ interviewDetails, feedbackStatus, feedbackSummaryData, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection }) => {
  const highlightsArray = feedbackSummaryData?.highlights;
  const lowlightsArray = feedbackSummaryData?.lowlights;
  const scopeOfImprovementArray = feedbackSummaryData?.scopeOfImprovement;
  const extraCommentsArray = isV2SummaryProcessedResponse(interviewDetails) ? interviewDetails?.feedback_pages?.processed_response?.summary?.summary?.extra_comments : feedbackSummaryData?.extraComments;
  const skillEvaluationsArray = feedbackSummaryData?.skillEvaluation;
  const ratingType6 = interviewDetails?.feedback_pages?.raw_form?.summary?.summary?.fields?.rating_and_recommendation?.data?.[2]?.type;

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const groupEvaluationSkills = (list, key) => {
    const arr = list?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    return arr;
  };

  const groupEvaluationSkillsV2 = (list, key) => {
    const arr = [
      {key:"HIGH",data:[],label:"High"},
      {key:"MEDIUM", data:[],label:"Medium"},
      {key:"LOW",data:[],label:"Low"},
      {key:"null",data:[],label:"Other"}
    ];
    list?.forEach(item1=>{
      let index = -1;
      arr.find((item2,index2)=>{
        if(item2.key===item1[key])
        {
          index = index2;
        }
      });
      index = index !== -1 ? index:3;
      if(arr[index])
      {
        arr[index].data.push(item1)
      }
    });
    return arr;
  };

  const groupEvaluationSkillsForObject = (object) => {
    const list = []
    Object.keys(object).map((data, index) => {
      const objData = { ...object[data] }
      objData.type = data
      list.push(objData)
    })
    return list
  }


  const renderSkillEvaluationOlderVersion = () => {
    // This will render skill evaluation older version where skills are not mapped with highlights and lowlights
    return (
      <Box
        display="flex"
        flexDirection="row"
        style={{ width: "100%" }}
      >
        <Box display="flex" flexDirection="column" style={{ width: "50%", paddingRight: '20px' }}>


          {isV2SummaryProcessedResponse(interviewDetails) && interviewDetails?.feedback_pages?.processed_response?.['summary']?.summary?.suspicion_of_cheating && <div className="suspicion-cheating">Suspicion of Cheating</div>}

          <div className="feedback-summary-subheading" style={{ marginTop: "0px", color: "#209E14" }}>HIGHLIGHTS</div>

          {highlightsArray?.map((data) => {
            return (
              <div className="feedback-summary-value" style={{ textIndent: "-26px", paddingLeft: "28px" }}>
                <img
                  src={feedbackHighlights}
                  style={{ marginRight: "10px" }}
                />
                {data}
              </div>
            );
          })}

          <div className="feedback-summary-subheading" style={{ color: "#984141" }}>LOWLIGHTS</div>
          {lowlightsArray?.map((data) => {
            return (
              <div className="feedback-summary-value" style={{ textIndent: "-28px", paddingLeft: "24px" }}>
                <img
                  src={feedbackLowlights}
                  style={{ marginRight: "10px" }}
                />
                {data}
              </div>
            );
          })}

          <div className="feedback-summary-subheading" style={{ color: "#011C67" }}>
            SCOPE OF IMPROVEMENT
          </div>
          {scopeOfImprovementArray?.map((data) => {
            return (
              <div className="feedback-summary-value" style={{ textIndent: "-28px", paddingLeft: "24px" }}>
                <img
                  src={feedbackScopeImprovement}
                  style={{ marginRight: "10px" }}
                />
                {data}
              </div>
            );
          })}

          {extraCommentsArray?.length > 0 && <div className="feedback-summary-subheading">EXTRA COMMENTS</div>}
          {extraCommentsArray?.map((data, index) => {
            return (
              <div className="feedback-summary-value" style={{ textIndent: "-13px", paddingLeft: "15px" }}>
                {index + 1}. {"  "}
                {data || 'NA'}
              </div>
            );
          })}
        </Box>

        <Box display="flex" flexDirection="column" style={{ width: "50%", paddingLeft: '20px' }}>
          <div className="feedback-summary-subheading" style={{ marginTop: "0px", marginLeft: "20px" }}>
            SKILL EVALUATION - RATING
          </div>

          <Box display='flex' flexDirection='column'>
            {Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.map((data, index) => {
              return (
                <Box display='flex' flexDirection='column'>
                  {Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length === 1 && (data !== 'undefined' && data != null) && <div className="field-header ml-20">{data}</div>}
                  {Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length > 1 && <div className="field-header ml-20">{(data !== 'undefined' && data != null) ? data : "OTHER"}</div>}

                  <div className="form-row-style-10">
                    {groupEvaluationSkills(skillEvaluationsArray, 'group')[data]?.map((data) => {
                      if (!(feedbackStatus === InterviewFeedbackStatus.APPROVED && !data?.rating)) {
                        return (
                          <Box
                            display="flex"
                            flexDirection="column"
                            style={{
                              width: "25%",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                            justifyContent="top"
                          >
                            <img src={(data?.rating === 6 && ratingType6 === "REJECT")
                              ? ratingReject6 : ratingToSVG[`${data?.rating ? data?.rating : 0}`]} />

                            <div className="feedback-summary-rating-text">
                              {data?.type}
                            </div>
                          </Box>
                        )
                      }
                    })}
                  </div>
                  {index < Object.keys(groupEvaluationSkills(skillEvaluationsArray, 'group'))?.length - 1
                    && <hr className="feedback-eval-separator" />}
                </Box>
              )
            })
            }
          </Box>
        </Box>
      </Box>

    )
  }

  const renderSkillEvaluationNewerVersion = () => {
    // This will render skill evaluation newer version where skills are mapped with highlights and lowlights and will show separately.
    const star = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

    const renderRatings = (rating) => {

      let color = '#15A90A'
      if (rating < 6   || (rating === 6 && ratingType6 === "REJECT")) {
        color = '#DD2626'
      }

      return <Box display="flex" flexDirection="row" alignItems="center">
        {
          star?.map((item, index) => {
            if ((index + 1) <= rating) {
              return <Box>
                <svg width="20" height="19" viewBox="0 0 20 19" fill={color} xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0L12.8919 6.01964L19.5106 6.90983L14.6792 11.5204L15.8779 18.0902L10 14.92L4.12215 18.0902L5.3208 11.5204L0.489435 6.90983L7.1081 6.01964L10 0Z" fill={color} />
                </svg>
              </Box>
            }
            else {
              return <Box><img src={fadeStarIcon} /></Box>
            }
          })
        }
      </Box>
    }


    const renderSkillEvaluation = (data) => {
      return                             <Box
      display="flex"
      flexDirection="column"
      style={{
        width: "48%",
        margin: "8px",
        border: '1px solid #CFDFF9',
        borderRadius: '12px',
        padding:'10px',
      }}
      justifyContent="top"
    >

      <div className="feedback-summary-rating-text width-max-cont">
        {data?.type}
      </div>


      <Box display={'flex'} gap={2} marginBottom={'10px'}>
      {data?.rating ? renderRatings(data?.rating) : '--'} {data?.rating}
      </Box>



      {data.highlights?.map((data) => {
        if(data)
        return (
          <div className="feedback-summary-value" style={{ textIndent: "-10px", paddingLeft: "20px" }}>

            <span> &#8226; </span> {data}
          </div>
        );
      })}

      {data.lowlights?.map((data) => {
        if(data)
        return (
          <div className="feedback-summary-value" style={{ textIndent: "-10px", paddingLeft: "20px" }}>

            <span> &#8226; </span>{data}
          </div>
        );
      })}
      {data.value}
    </Box>
    }


    return (
      <Box
        display="flex"
        flexDirection="column"
        style={{ width: "100%" }}
      >
        {isV2SummaryProcessedResponse(interviewDetails) && interviewDetails?.feedback_pages?.processed_response?.['summary']?.summary?.suspicion_of_cheating && <div className="suspicion-cheating">Suspicion of Cheating</div>}

        <Box display="flex" flexDirection="column">
          <div className="feedback-summary-subheading" style={{ marginTop: "0px" }}>
            SKILL EVALUATION - RATING
          </div>

          <Box display='flex' flexDirection='column'>
            {
              (groupEvaluationSkillsV2(groupEvaluationSkillsForObject(skillEvaluationsArray), 'priority'))?.map((item, index) => {
                if(item?.data?.length > 0)
                return (
                  <Box display='flex' flexDirection='column'>
                    <div className="priority-heading">
                      <div className={`${item?.key?.toLocaleUpperCase()==='HIGH' ? 
                      'high' :  item?.key?.toLocaleUpperCase()==='MEDIUM' ? 
                      'medium' : 'low'
                      }`}>
                        {item?.label} Priority
                      </div>
                    </div>  
                    <div className="form-row-style-10">
                      {item?.data?.map((data) => {
                        if (!(feedbackStatus === InterviewFeedbackStatus.APPROVED && !data?.rating && !data?.value )) {
                          return renderSkillEvaluation(data)
                        }
                      })}
                    </div>
                    {index < (groupEvaluationSkillsV2(groupEvaluationSkillsForObject(skillEvaluationsArray), 'priority'))?.length - 1
                      && <hr className="feedback-eval-separator" />}
                  </Box>
                )
              })
            }
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box display={'flex'} flexDirection='column' width={'50%'}>
            <div className="feedback-summary-subheading" style={{ color: "#011C67" }}>
              Scope of improvement:
            </div>
            {scopeOfImprovementArray?.map((data) => {
              return (
                <div className="feedback-summary-value" style={{ textIndent: "-28px", paddingLeft: "24px" }}>
                  <img
                    src={feedbackScopeImprovementBulb}
                    style={{ marginRight: "10px" }}
                  />
                  {data}
                </div>
              );
            })}
          </Box>

          <Box display={'flex'} flexDirection='column' width={'50%'}>
            {extraCommentsArray?.length > 0 && <div className="feedback-summary-subheading">Interviewer’s Comments:</div>}
            {extraCommentsArray?.map((data, index) => {
              return (
                <div className="feedback-summary-value" style={{ textIndent: "-13px", paddingLeft: "15px" }}>
                  <img
                    src={feedbackInterviewerComment}
                    style={{ marginRight: "10px" }}
                  />
                  {data || 'NA'}
                </div>
              );
            })}
          </Box>
        </Box>
      </Box>
    )
  }

  // id="add-comment-parent" is the hover trigger for adding comments
  return (
    <Accordion id="add-comment-parent" expanded={expanded} className="mtb-20" onChange={handleChange('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" style={{ width: "100%", alignItems: "center" }}>
          <div className="preview-subheader-title"> Feedback Summary</div>
        </Box>
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.FEEDBACK_SUMMARY)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.FEEDBACK_SUMMARY)}
      </AccordionSummary>

      <AccordionDetails>
        {Array.isArray(skillEvaluationsArray) ? renderSkillEvaluationOlderVersion() : renderSkillEvaluationNewerVersion()}
      </AccordionDetails>
    </Accordion>
  );
};

export default FeedbackSummary;