import React, { useState, useEffect } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { loadImage } from "../../../../Utils/image";
import "react-lazy-load-image-component/src/effects/blur.css";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import './GuidePreview.scss'

const ImageLoad = ({ data, url, downloadAPI }) => {
  const [image, setImage] = useState(data ? data : "");

  useEffect(() => {
    if (url) {
      loadingImage(url);
    }
  }, []);

  const loadingImage = (url) => {
    loadImage(url, downloadAPI, (err, result) => {
      if (result) {
        setImage(result);
        data = result;
      }
    });
  };
  
  if(image && image!==url)
  {
    return (
      <LazyLoadImage
        src={image ? image : ""}
        className='image-load'
      />
    );
  }
  else
  {
    return <Box marginTop="20px">
      <center><CircularProgress size="26px"  /></center>
      </Box>
  }
};

export default ImageLoad;
