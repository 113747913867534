import React from "react";
// Material Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import downloadArrow from "../images/downloadArrow.svg";
import  { PDFDownloadLink} from '@react-pdf/renderer';
import Download from '../AuditDownload';
import snakeCase from 'lodash/snakeCase';

const NavBar = ({ headerValue, data, canDownload }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      className="audit-report-main-wrapper"
    >
      <Typography className="heading-nav">{headerValue}</Typography>
      <Box className="download-Box">
         { canDownload  ?
          <PDFDownloadLink
              document={<Download
              data={data}
              />}
              fileName={`Audit Report - ${snakeCase(data?.audit_details?.candidate_details?.name)}`}
          >
               {({ loading }) => (loading ? (<Typography className="download-box-typography">loading....</Typography>) : (<Box display="flex">
                 <img
                 className="download-arrow"
                  src={downloadArrow}
                  alt="download-arrow"
                 />
                 <Typography className="download-box-typography">Download Audit Report</Typography>
               </Box>
                  ))}
              
          </PDFDownloadLink>   
          :
          <></>           
}
        </Box>
    </Box>
  );
};

export default NavBar;
