import { useState, useRef, useEffect,  useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import RSelect from "react-select";

import DeleteIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@material-ui/core/Tooltip';

import "./commonComponents.scss";

import { CommonFieldsType, capitalize } from "../../Constants/FeedbackConstants";
import FeedbackTextEditor from "../../Components/FeedbackTextEditor";
import TextEditor from "../../Components/TextEditor";
import CodeBlockPopup from "../../Popups/CodeBlockPopup";
import ResultClarity from "../../../InterviewGuides/Components/ResultClarity";
import { renderToolTip } from "../../Components/RenderToolTip";
import styled from "styled-components";

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

import { withStyles, makeStyles } from "@material-ui/core/styles";

const NoPaddingAutocomplete = withStyles({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 5
    }
  },
  input: {}
})(Autocomplete);

const ReactSelect = styled(RSelect)`
  div:nth-child(3) > div > div>div:first-child
  {
    color: #3D3D3D;
    font-size: 13px;
    font-weight: 900;
    background: #e3e3e3;
    padding: 4px;
  }
`;

const tooltipStyles = makeStyles({
    tooltip: {
      background: 'transparent',
    },
  });

const CommonComponents = ({
    fieldKey,
    fieldInfo,
    response,
    showValidationError,
    handleChange,
    parentInfo,
    childIndex,
    editable = true,
    showHint = true, 
    setEvaluationSkillHints, 
    backendValidation,
    backendValidationType,
    handleDeleteReiterableQuestion,
    uploadFileV2,
}) => {

    const [isFocused, setFocused] = useState(false);
    const [isCodeDialogVisible, setCodeDialogVisibility] = useState(false);
    const updatedValue = useRef(response);
    const tooltipClass = tooltipStyles();
    //Capture the input value on change and store it in state
    useEffect(() => {
       if(setEvaluationSkillHints) setEvaluationSkillHints(fieldInfo?.hints)
    }, [isFocused]);
    
    const handleHover = (boolean) => {
        setFocused(boolean);
    };

    const handleOnKeyDown = (e) => {
        if (e?.key === 'Enter' || e?.type === 'blur') {
            handleChange(updatedValue.current);
        }
    }

    const handleOnChange = (value) => {
        updatedValue.current = value;
    }

    const renderCheckbox = () => {
        const onChange = (e) => {
            handleChange(e.target.checked ? true : false)
        }
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        sx={{ padding: '0px 9px' }}
                        size="small"
                        color="primary"
                        checked={response ? true : false}
                        onChange={onChange}
                    />
                }
                label={<Typography>{fieldInfo?.question}{fieldInfo?.required && <span className="asterisk">*</span>}</Typography>}
            />
        );
    };

    const renderRatingStaticBox = () => {
        return (<Box className="rating-static-block">
            <Typography alignSelf={'center'} className='mt-1 heading'>{fieldInfo?.heading}</Typography>

            <Typography alignSelf={'center'} className='mt-1 sub-heading'>{fieldInfo?.sub_heading}</Typography>

            <div className="ratings-bg mt-2">
                <ResultClarity ratingsList={fieldInfo?.data} isPreview={true}/>
            </div>
        </Box>)
    }

    return (<>

        {isCodeDialogVisible && <CodeBlockPopup response={response} handleChange={handleChange} setModalVisibility={setCodeDialogVisibility} />}

        {fieldInfo?.field_type === CommonFieldsType.STATIC_BLOCK.label && fieldInfo?.sub_type === 'Rating' ? renderRatingStaticBox()

            : <Box display="flex" className="common-component-container mt-3">
                
                <Typography style={{ width: fieldInfo?.question_width || '50%' }} className={`question ${showValidationError && !response && fieldInfo?.required ? "validation-error" : ""}`}>
                    {childIndex != null && parentInfo?.field_type === CommonFieldsType.ORDERED_LIST_SECTION.label && `${childIndex + 1}. `}

                    {
                        fieldInfo?.field_type === CommonFieldsType.CHECK_BOX.label
                            ? renderCheckbox()
                            : <>
                               {
                                   !fieldInfo?.question && fieldInfo?.re_iterable && (!response?.hasOwnProperty('editable') || (response?.hasOwnProperty('editable') && response?.editable === true)) ?
                               
                               (
                                   <Box className="textFieldBox">
                                        <TextField
                                            key={`${fieldKey}-${response?.question}-${childIndex}`}
                                            name={fieldKey}
                                            placeholder={fieldInfo?.placeholder}
                                            variant="outlined"
                                            className={"textFieldStyle"}
                                            sx={{ "& legend": { display: "none" }, height: '2px'}}
                                            inputProps={{ style: { fontSize: 12 } }}
                                            InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                                            defaultValue={response?.question}
                                            onChange={(e) => handleOnChange({question: capitalize(e.target.value)})}
                                            onKeyDown={handleOnKeyDown}
                                            onBlur={handleOnKeyDown}
                                            disabled={!editable}
                                        />
                                        <DeleteIcon onClick={(e) => handleDeleteReiterableQuestion(e)} className="marginTop"/>
                                     </Box>
                               )
                              
                               : 
                                    <>
                                    {   fieldInfo?.re_iterable && response?.question ? response?.question :  (fieldInfo?.question || fieldInfo?.placeholder)}
                                     {fieldInfo?.required && <span className="asterisk">*</span>}:&nbsp;
                                     {  showHint && 
                                        fieldInfo?.hints 
                                        && true //isFocused 
                                        && (
                                        <Tooltip 
                                        classes={tooltipClass}
                                        title={<Box width="50vw" className="hint"><div dangerouslySetInnerHTML={{ __html: fieldInfo?.hints }} /></Box>}>
                                            <InfoIcon fontSize="small" sx={{color:"#123FBC"}} />
                                        </Tooltip>
                                     )}
                                     </>
                               }
                               </>
                       
                    }

                </Typography>

                <Box display="flex" flexDirection="column" style={{ width: fieldInfo?.field_width || '50%' }} className={`response ${parentInfo?.nested ? 'alignItemsEnd' : ''}`}>
                    <Box display="flex" className='w-100'>
                        {renderResponseFields(
                            fieldKey,
                            fieldInfo,
                            response,
                            showValidationError,
                            handleChange,
                            handleHover,
                            setCodeDialogVisibility,
                            handleOnKeyDown,
                            handleOnChange,
                            updatedValue,
                            editable,
                            parentInfo,
                            childIndex,
                            backendValidation,
                            backendValidationType,
                            uploadFileV2,
                        )}

                        <Typography alignSelf={'center'} className='ml-2'>{fieldInfo?.field_info?.["post_label"]}</Typography>
                    </Box>
                </Box>
            </Box>
        }
    </>
    );
};

export default CommonComponents;

const renderResponseFields = (
    fieldKey,
    fieldInfo,
    response,
    showValidationError,
    handleChange,
    handleHover,
    setCodeDialogVisibility,
    handleOnKeyDown,
    handleOnChange,
    updatedValue,
    editable,
    parentInfo,
    childIndex,
    backendValidation,
    backendValidationType,
    uploadFileV2,
) => {
    const handleIntChange = (value) => {
        const re = /^[0-9\b]+$/;

        if (value === "" || re?.test(value)) {
            handleChange(Number(value));
        }
    };

    const renderIntegerTextField = () => {
        return (
            <TextField
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                defaultValue={response}

                onChange={(e) => handleIntChange((e.target.value))}
                // onKeyDown={handleOnKeyDown}
                // onBlur={handleOnKeyDown}
            />
        );
    };

    const renderDecimalTextField = () => {
        return (
            <TextField
                name={fieldKey}
                type="number"
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                defaultValue={response}

                onChange={(e) => handleChange(parseFloat(e.target.value))}
                // onKeyDown={handleOnKeyDown}
                // onBlur={handleOnKeyDown}
            />
        );
    };

    const renderRichTextField = () => {
        return (
            <FeedbackTextEditor
                width='w-100'
                id="outlined-size-small"
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                uploadFileV2={uploadFileV2}
                value={response || ''}
                onChange={handleOnChange}
                onBlur={handleOnKeyDown}
                onKeyUp={handleOnKeyDown}
                readOnly={!editable}
            />
        );
    };

    const renderSingleLineWithAddMore = () => {
        const resp = response && response?.length > 0 ? [...response] : [''];


        const handleKeypress = (e) => {
            if (e?.key === "Enter") {
                // handleAddMore();

                const dupResponse = [...updatedValue.current];
                dupResponse.push("");
                updatedValue.current = dupResponse;
            }

            handleOnKeyDown(e);
        };
        const handleAddMore = () => {
            const dupResponse = [...resp];
            dupResponse.push("");
            handleChange(dupResponse);
        };

        const handleDelete = (index) => {
            const dupResponse = [...resp];
            dupResponse.splice(index, 1);
            handleChange(dupResponse);
        };

        const handleField = (e, index) => {
            const dupResponse = [...resp];
            dupResponse[index] = capitalize(e);
            handleOnChange(dupResponse);
        };

        return (
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                {resp?.map((data, index) => {
                    return (
                        <Box display="flex" sx={{ m: 0.5, gap: 1 }}>
                            <TextField
                                key={`${index + fieldKey + data}`}
                                size="small"
                                className="w-100"
                                name={fieldKey}
                                placeholder={fieldInfo?.field_info?.placeholder}
                                inputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                                onMouseEnter={() => handleHover(true)}
                                onMouseLeave={() => handleHover(false)}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}

                                defaultValue={data}

                                onChange={(e) => handleField(capitalize(e.target.value), index)}
                                onKeyDown={handleKeypress}
                                onBlur={handleOnKeyDown}
                            />

                            {resp?.length > 1 && (
                                <Button
                                    size="small"
                                    className="delete-button"
                                    onClick={(e) => handleDelete(index)}
                                >
                                    <DeleteIcon />
                                </Button>
                            )}

                            {index === resp?.length - 1 && (
                                <Button
                                    size="small"
                                    className="add-more"
                                    onClick={(e) => handleAddMore()}
                                >
                                    <AddIcon />
                                </Button>
                            )}
                        </Box>
                    );
                })}
            </Box>
        );
    };


    const renderSingleLineDropdownWithAddMore = () => {
        const resp = response && response?.length > 0 && Array.isArray(response) ? response 
        : response && response?.length > 0 ? [...response] : [''];

        const handleAddMore = () => {
            const dupResponse = [...resp];
            dupResponse.push(null);
            handleChange(dupResponse);
        };

        const handleDelete = (index) => {
            const dupResponse = [...resp];
            dupResponse.splice(index, 1);
            handleChange(dupResponse);
        };

        const handleField = (e, index, isCapitalised=false) => {
            const dupResponse = [...resp];
            dupResponse[index] = (isCapitalised ? capitalize(e) : e) || null;
            handleChange(dupResponse);
        };

        return (
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                {resp?.map((data, index) => {
                    return (
                        <Box display="flex" alignItems="center" sx={{ m: 0.5, gap: 1 }} >
                            
                      
                            <NoPaddingAutocomplete
                                freeSolo
                                name={fieldKey}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    width: '100%'
                                }}
                                onMouseEnter={() => handleHover(true)}
                                onMouseLeave={() =>{ handleHover(false); handleField(data, index, true)}}
                                placeholder={fieldInfo?.field_info?.placeholder}
                                value={data}


                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        handleField(newValue, index)

                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        handleField(newValue.inputValue, index)

                                    } else {
                                        handleField(newValue, index)
                                    }
                                }}
                                // filterOptions={(options, params) => {
                                //     const filtered = filter(options, params);

                                //     const { inputValue } = params;

                                //     console.log(options, params)
                                //     // Suggest the creation of a new value
                                //     const isExisting = options.some((option) => inputValue === option);
                                //     if (inputValue !== '' && !isExisting) {
                                //         filtered.push(
                                //             {
                                //                 title: `Add ${inputValue}`,
                                //                 inputValue
                                //             }
                                //         );
                                //     }

                                //     return filtered;
                                // }}
                                // selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                                options={fieldInfo?.field_info?.values || []}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input                                    
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    if (typeof option === 'object') {
                                        return option?.value;
                                    }

                                    // Regular option
                                    return option;
                                }}
                                renderOption={(props, option) => <li {...props}>{typeof option === 'object' && option?.title ? option?.title : option}</li>}
                                renderInput={(params) => (
                                    <TextField  {...params} sx={{ padding: '0px' }} multiline placeholder={fieldInfo?.field_info?.placeholder} onChange={(e) => handleField(e.target.value, index)} />
                                )}
                            />

                            
                            {/* <TextField
                                key={`${index + fieldKey + data}`}
                                size="small"
                                className="w-100"
                                name={fieldKey}
                                placeholder={fieldInfo?.field_info?.placeholder}
                                inputProps={{ style: { fontSize: 12 } }}
                                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                                onMouseEnter={() => handleHover(true)}
                                onMouseLeave={() => handleHover(false)}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}

                                defaultValue={data}

                                onChange={(e) => handleField(e.target.value, index)}
                                onKeyDown={handleKeypress}
                                onBlur={handleOnKeyDown}
                            /> */}

                            {resp?.length > 1 && (
                                <Button
                                    size="small"
                                    className="delete-button"
                                    onClick={(e) => handleDelete(index)}
                                >
                                    <DeleteIcon />
                                </Button>
                            )}

                            {index === resp?.length - 1 && (
                                <Button
                                    size="small"
                                    className="add-more"
                                    onClick={(e) => handleAddMore()}
                                >
                                    <AddIcon />
                                </Button>
                            )}
                        </Box>
                    );
                })}
            </Box>
        );
    };


    const renderRadioButton = () => {
        const handleClick = (event) => {
            if(fieldInfo?.re_iterable){
                if (event.target.value === response.value && !fieldInfo?.required) {
                    handleChange("");
    
                } else {
                    handleChange({value: event.target.value});
                }
            }else{
                 if (event.target.value === response && !fieldInfo?.required) {
                handleChange("");

            } else {
                handleChange(event.target.value);
            } 
            }
          
        }

        return (
            <RadioGroup
                row
                name={fieldKey}
                value={fieldInfo?.re_iterable ? response?.value : response || ''}
                // onChange={(e) => handleChange(e.target.value)}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                disabled={!editable && fieldKey === 'difficulty_level'}
            >
                {fieldInfo?.field_info?.values?.map((data) => {
                    return (
                        <FormControlLabel value={data} control={<Radio  disabled={!editable&& fieldKey === 'difficulty_level'} onClick={handleClick} />} label={data} />
                    );
                })}
            </RadioGroup>
        );
    };

    const renderRadioButtonWithTextArea = () => {
        const handleClick = (event) => {
            if (event.target.value === response?.value && !fieldInfo?.required) {
                handleChange({value: ''});
            } else {
                handleChange({value: event?.target?.value});
            }
        }

        return (
            <Box display={'flex'} flexDirection='column' width={'100%'}>

            <RadioGroup
                row
                name={fieldKey}
                value={response?.value || ''}
                // onChange={(e) => handleChange(e.target.value)}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                disabled={!editable && fieldKey === 'difficulty_level'}
            >
                {fieldInfo?.field_info?.values?.map((data) => {
                    return (
                        <FormControlLabel value={data} control={<Radio  disabled={!editable && fieldKey === 'difficulty_level'} onClick={handleClick} />} label={data} />
                    );
                })}
            </RadioGroup>

             <TextField
                key={`fieldKey-${response?.response}`}
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder || 'Please share candidate response'}
                variant="outlined"
                multiline
                minRows={2}
                maxRows={5}
                className={'responseTextField'}
                sx={{
                    padding: "0px",
                    marginLeft: '-103%',
                    "& legend": { display: "none" },
                }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                defaultValue={response?.response || ''}

                onChange={(e) => handleOnChange({response: capitalize(e.target.value)})}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnKeyDown}
            />
            </Box>
        );
    };

    const renderCheckBox = () => {
        return (
            <></>
        )
    }
    const renderDropDown = () => {
        let result = fieldInfo?.field_info?.values.find(({ value }) => value == response);

        if(!result){
            result = fieldInfo?.field_info?.values.find((value) => value == response);
        }

        return (
            <Box display='flex' flexDirection='row' width={'100%'}  alignItems='center'>
            <Select
                size="small"
                defaultValue="none"
                value={response || 'none'}
                displayEmpty={true}
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                onChange={(e) => handleChange(e.target.value)}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    width: '100%'
                }}
            >

              <MenuItem style={{ display: 'flex' }} disabled value="none" >{fieldInfo?.field_info?.placeholder}</MenuItem> 

            { response && !result && <MenuItem style={{ display: 'flex' }} value={response}>{response}</MenuItem> }

                {
                    fieldInfo?.field_info?.values?.map((data) => {
                        return typeof (data) === 'object' ? <MenuItem style={{ display: 'flex' }} value={data?.value}><div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: data?.value || ""}}></div></MenuItem> : <MenuItem style={{ display: 'flex' }} value={data}><div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: data || ""}}></div></MenuItem>
                    })
                }
            </Select>
        {
              result && result?.tooltip_text && renderToolTip(result?.tooltip_text)
         }   
                </Box>
     
                
        );
    };

    const renderDropDownWithSubText = () => {
        const result = fieldInfo?.field_info?.values.find(({ value }) => value == response);

        return (
            <Box display='flex' flexDirection='column'>
             <Box display='flex' flexDirection='row'>
               
                <Select
                    size="small"
                    value={response || ''}
                    name={fieldKey}
                    placeholder={fieldInfo?.field_info?.placeholder}
                    onChange={(e) => handleChange(e.target.value)}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        height: 'fit-content'
                    }}
                >
                    {
                        fieldInfo?.field_info?.values?.map((data) => {
                            return typeof (data) === 'object' ? <MenuItem style={{ display: 'flex' }} value={data?.value}><div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: data?.value || ""}}></div></MenuItem> : <MenuItem style={{ display: 'flex' }} value={data}><div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: data || ""}}></div></MenuItem>
                        })
                    }
                </Select>

                   {backendValidation && backendValidationType && <Box display='flex' flexDirection='column' style={{ marginLeft: '10px', width: '600px', marginBottom: '5px' }}>
                         {renderHtmlText(backendValidation[backendValidationType === 'warning' ? 'warning_msg' : 'err_msg'])}
                    </Box>
                  }
            </Box>
                {
                    result && <Box> <div className='dropdown-text mt-1' style={{ color: result.color }}>{result?.text}</div> <div className='dropdown-subtext mt-1'>{result?.sub_text}</div></Box>
                }

          
            </Box>
        );
    };

    const renderHtmlText = (data) => {
        if(Array.isArray(data)){
            return data?.map((item, index) => {

                if(item){
                const cssStyleString = item?.background;
                // Parse the CSS style string into an object
                const cssStyle = cssStringToObject(cssStyleString);
                return <Box style={cssStyle} marginBottom='5px'>
                    {item?.image == 'bulb' ?  <TipsAndUpdatesOutlinedIcon sx={{marginRight:'5px'}} color={item?.image_color}/>: <WarningRoundedIcon color={item?.image_color} sx={{marginRight:'5px'}}/>  }
                    {item?.text && <div dangerouslySetInnerHTML={{ __html: item?.text + '' }} />}
                </Box>   
            }
            })
        }else if(typeof(data) === 'object') {
            const cssStyleString = data?.background;
            // Parse the CSS style string into an object
            const cssStyle = cssStringToObject(cssStyleString);
            return <Box style={cssStyle} marginBottom='5px'>
                {data?.image == 'bulb' ?  <TipsAndUpdatesOutlinedIcon color={data?.image_color} sx={{marginRight:'5px'}}/>: <WarningRoundedIcon color={data?.image_color}  sx={{marginRight:'5px'}}/>  }
                {data?.text && <div dangerouslySetInnerHTML={{ __html: data?.text + '' }} />}
            </Box>   
        }else return <></>
        
    }


      // Helper function to convert CSS style string to an object
const cssStringToObject = (cssString) => {
    const styleObject = {};
  
    if (cssString) {
    cssString.split(";").forEach((declaration) => {
      const [property, value] = declaration.split(":");
      if (property.trim() && value.trim()) {
        styleObject[property.trim()] = value.trim();
      }
    });
  }
  
    return styleObject;
  };

    const renderDropDownWrapper = () => {
        const handleChangeDropDown = (e) => {
            let result = fieldInfo?.field_info?.values?.find(data => (data?.question_asked || data?.question_statement || data?.value) == e);
           
            if (response != null) {
                if (result.hasOwnProperty('lowlights') && response && response.hasOwnProperty('lowlights')) {
                    result.lowlights = response.lowlights
                }

                if (result.hasOwnProperty('highlights') && response && response.hasOwnProperty('highlights')) {
                    result.highlights = response.highlights
                }
            }

            handleChange({...result})
        }

        return (
            <Box display='flex' flexDirection='column' className="select-wrapper">
                <Select
                    className="dropdown"
                    displayEmpty
                    size="small"
                    value={response?.question_asked || response?.question_statement || response?.value || ''}
                    name={fieldKey}
                    onChange={(e) => handleChangeDropDown(e.target.value)}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                    sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0, },
                    }}
                >
                    <MenuItem disabled value=""><em>{fieldInfo?.field_info?.placeholder}</em></MenuItem>
                    {
                        fieldInfo?.field_info?.values?.map((data) => {
                            return typeof (data) === 'object' ? <MenuItem style={{ display: 'flex' }} value={data?.question_asked  || data?.question_statement || data?.value}><div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: data?.question_asked || data?.question_statement || data?.value}}></div></MenuItem> : <MenuItem style={{ display: 'flex' }} value={data}><div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: data || ""}}></div></MenuItem>
                        })
                    }
                </Select>

            </Box>
        );
    };

    const renderDropDownWithGroup = () => {
        let options = useMemo(() => {
            let otherOptionsObj = {};
            fieldInfo?.field_info?.values?.forEach((option) => {
                if (otherOptionsObj[option?.group] === undefined) {
                  otherOptionsObj[option?.group] = {
                    id: option?.group,
                    label: option?.group,
                    options: [{ label: <div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: option?.question_asked ?  option?.question_asked : option?.question_statement}}></div>, value: option }]
                  };
                } else {
                  otherOptionsObj[option.group].options.push({
                    label: <div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: option?.question_asked ? option?.question_asked : option?.question_statement}}></div> ,
                    value: option
                  });
                }
              });
            let otherOptions = Object.keys(otherOptionsObj)?.map((key) => otherOptionsObj[key]);
            return otherOptions;
        }, [fieldInfo.field_info.values]);
        
        const handleChangeDropDown = (e) => {
            let result = e?.value;
            handleChange({...result});
        }
        
        const regex = /(<([^>]+)>)/gi;  // It will parse and removed all the tags, only text will visible
        let quesAsked = response?.question_asked?.replace(regex, "");
        let quesStatement = response?.question_statement?.replace(regex, "");
        
          return (
            <ReactSelect 
                className="dropdown dropdown-group"
                value={response?.question_asked?{label:<div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: quesAsked || ""}}></div>,value:response?.question_asked} :
                   response?.question_statement?{label:<div className="quill-dropdown-box" dangerouslySetInnerHTML={{__html: quesStatement || ""}}></div>,value:response?.question_statement} : options?.[0]?.options?.[0]}
                onChange={handleChangeDropDown}
                options={options}
                placeholder={options?.[0]?.label}
            />
          );
    }

    const renderNormalTextField = () => {
        return (
            <TextField
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                sx={{
                    width: "100%",
                    padding: "0px",
                    "& legend": { display: "none" },
                }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                defaultValue={response}

                onChange={(e) => handleOnChange(capitalize(e.target.value))}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnKeyDown}
                disabled={!editable}
            />
        );
    };

    const renderMultiline = () => {
        return (
            <TextField
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                variant="outlined"
                multiline
                minRows={2}
                maxRows={5}
                sx={{
                    width: "100%",
                    padding: "0px",
                    "& legend": { display: "none" },
                }}
                inputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12, color: "GrayText" } }}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}

                defaultValue={response}

                onChange={(e) => handleOnChange(capitalize(e.target.value))}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnKeyDown}
            />
        );
    }

    const renderCodeBlockField = () => {
        return (
            <TextEditor
                className='w-100'
                name={fieldKey}
                placeholder={fieldInfo?.field_info?.placeholder}
                value={response || ''}
                setValue={(value) => handleChange(value)}
            />
        );
    };


    const renderCodeBlockInDialogField = () => {
        return (
            <>
                <Button className="add-more-question mb-10" style={{ marginTop: '0px', marginRight: '5px', borderRadius: '0px' }}
                    onClick={() => setCodeDialogVisibility(true)}>
                    {response && response?.trim() !== "" ? "Edit" : "Add"} Solution Code
                </Button>

                {!response && <Typography style={{ fontStyle: 'italic' }}> (if any)</Typography>}
            </>
        );
    };

    switch (fieldInfo?.field_type) {
        case CommonFieldsType.INTEGER_TEXT.label:
            return renderIntegerTextField();

        case CommonFieldsType.DECIMAL_TEXT.label:
            return renderDecimalTextField();
    
        case CommonFieldsType.RICH_TEXT.label:
            return renderRichTextField();

        case CommonFieldsType.RADIO_BUTTON.label:
            return renderRadioButton();

        case CommonFieldsType.RADIO_BUTTON_WITH_TEXTAREA.label:
            return renderRadioButtonWithTextArea();

        case CommonFieldsType.SINGLE_LINE_WITH_ADD_MORE.label:
                return renderSingleLineWithAddMore();
        
        case CommonFieldsType.SINGLE_LINE_DROPDOWN_WITH_ADD_MORE.label:
                return renderSingleLineDropdownWithAddMore();
            
        case CommonFieldsType.DROP_DOWN.label:
            return renderDropDown();

        case CommonFieldsType.DROP_DOWN_WITH_SUBTEXT.label:
            return renderDropDownWithSubText();

        case CommonFieldsType.DROP_DOWN_WRAPPER.label:
            return renderDropDownWrapper();

        case CommonFieldsType.DROP_DOWN_WITH_GROUP.label:
            return renderDropDownWithGroup();
        
        case CommonFieldsType.NORMAL_TEXT.label:
            return renderNormalTextField();

        case CommonFieldsType.CODE_BLOCK.label:
            return renderCodeBlockField();

        case CommonFieldsType.CODE_BLOCK_IN_DIALOG_BOX.label:
            return renderCodeBlockInDialogField();

        case CommonFieldsType.CHECK_BOX.label:
            return renderCheckBox();


        case CommonFieldsType.MULTILINE_TEXT.label:
            return renderMultiline();

        case CommonFieldsType.STATIC_BLOCK.label:
        case CommonFieldsType.BUTTON.label:
            return <></>;

        case CommonFieldsType.ORDERED_LIST_SECTION.label:
        case CommonFieldsType.UNORDERED_LIST_SECTION.label:
            return <></>

        default:
            break;
    }
};