import React, { Fragment, useEffect, useState } from 'react'
import boxImg from './empty-box.svg';
import './Table.scss';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import useMediaQuery from '../../utilis/useMediaQuery';
import HistoryList from '../MobileComponents/HistoryList.js';
import Typography from '@mui/material/Typography';
import { INTERVIEWER_TYPE } from '../../views/User/Constant';

const Table = ({
        data, 
        headers,  
        handleRowClick, 
        isFooter, 
        isSortable,
        currentTimezone,
        acceptReq=()=>{},
        fromPage,
        feedbackPreview = () => {},
        onCandidateFeedbackShare = () => {},
        interviewerType,
    }) => {
    const mobileView = useMediaQuery('(max-width:700px)');
    const [tabelHeaders, setTableHeaders] = useState(headers.map(item => ({...item, sort: null, selected: false})));
    const [tableData, setTableData] = useState(data);
    const [renderData, setRenderData] = useState([]);
    const [tablePage, setTablePage] = useState(1);
    const rowPerPage = 8;
    useEffect(() => {
        setTableHeaders(headers.map(item => ({...item, sort: null, selected: false})))
    }, [headers])
    
    useEffect(() => {
        setTableData(data);
    }, [data])

    useEffect(() => {
        const newData = tableData.filter((row, index) => (index < (tablePage * rowPerPage) && index >= ((tablePage-1) * rowPerPage)));
        setRenderData(newData);
    },[tableData, tablePage])


    const sortTable = (value) => {
        if(!isSortable) return;
        let arr = [...tabelHeaders];
        const index = arr.findIndex(item => item.value === value);
        if(arr[index].sort === -1 || arr[index].sort === 0){
            arr = arr.map(item => ({...item, sort: 0}))
            arr[index].sort = 1
            setTableData(prev => prev.sort(compareValues(arr[index].value, 'asc')))
        } else {
            arr = arr.map(item => ({...item, sort: 0}))
            arr[index].sort = -1
            setTableData(prev => prev.sort(compareValues(arr[index].value, 'desc')))
        }
        setTableHeaders(arr);
    }

    const acceptReqHandler = (e,obj)=>{
        e.stopPropagation();
        acceptReq(obj);
    }

    const sendFeedbackMail = async (e, data, index) => {
        e.stopPropagation();
        onCandidateFeedbackShare(data, index)  
    }
    
    const handlePreview = async (e, data) => {
        e.stopPropagation();
        feedbackPreview(data);
    } 

    const colorText = (data)=>{
      let colorData = {
        REJECT:{
          value:"Reject",
          color:'red',
        },
        PROCEED:{
          value:"Proceed",
          color:'#12BC93',
        },
        STRONG_REJECT:{
          value:"Strong Reject",
          color:'red',
        },
        STRONG_PROCEED:{
          value:"Strong Proceed",
          color:'#12BC93',
        }
      }
      return (<span style={{color:colorData[data]?.color, fontWeight:'500'}}> {colorData[data]?.value} </span>);
    }

    const renderRow = ()=>{
      let AllRows = renderData.map((row, index) => {
          const getDate = (epocTimeStamp) => {
            return moment(epocTimeStamp*1000)?.tz(currentTimezone)?.format("MMMM DD, YYYY")
          }
          const getTime = (epocTimeStamp) => {
            return moment(epocTimeStamp*1000)?.tz(currentTimezone)?.format("h:mm a")
          }
          return (<tr key = {index} onClick = {() => handleRowClick(row.id)} className={row?.interview_details?.extra_info?.transfer_interview_flag?"disabled_row":"notdisabled_row"} >
                              {tabelHeaders.map((header, index2) => {
                                  if (header.value === "status"){
                                  return <td className={index%2!==0?" table-row-odd":""} style={{position:'relative'}}>
                                    {(row?.interview_details?.extra_info?.transfer_interview_flag)?"Cancelled":"Scheduled"}
                                  </td>
                                }
                                if(header.value === 'dateTime' && typeof row[header.value]?.label?.$$typeof != 'symbol'){
                                    if(row[header?.value]?.label?.length>1){
                                        return <td className={index%2!==0?" table-row-odd":""} style={{position:'relative'}}>Multiple Slots</td>
                                    }else{
                                        return <td className={index%2!==0?" table-row-odd":""} style={{position:'relative'}}>{moment(row[header.value].label*1000).format('MMM DD, YYYY | hh:mm A')}</td>
                                    }
                                }
                                if (header.value === 'audit_status'){
                                    return (<td className={index%2!==0?" table-row-odd":""} style={{position:'relative'}}>
                                    {colorText(row[header?.value])}
                                  </td>)
                                }
                                // ------- Future use -------
                                // return <td key = {index2} onClick = {e => {}}>
                                //     { header.label === 'Candidate Feedback Form' && row[header.value]?.value === 'Send' ? <button onClick={(e)=>sendFeedbackMail(e,row, index)} className="sendFeedbackBtn">Send</button> : 
                                //         row[header.value] ? (row[header.value].label ? row[header.value].label : row[header.value]) : <button onClick={(e)=>acceptReqHandler(e,row)} className="acceptBtn">Accept</button>}
                                // </td>
                                if(header.value === 'dateTime')
                                {
                                    return (<td key={index2}>{getDate(row[header?.value]?.value)} 
                                                <Typography fontFamily="Poppins" fontSize="13px" color="#6E6893">
                                                    {getTime(row[header?.value]?.value)}
                                                </Typography>
                                            </td>)
                                }
                                return <td key = {index2} onClick = {e => {}}>
                                { header.value === 'resultPreview' && row[header.value]?.value === 'Submit' ?
                                   <button onClick={(e)=>handlePreview(e,row)} disabled={interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL} 
                                   className={`sendFeedbackBtn ${interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL && "not-available"}`}>Submit</button>:  
                                    row[header.value] ? (row[header.value].label ? row[header.value].label : row[header.value]) : "--"}
                                </td>
                              })}
                          </tr>)
                      })
    return AllRows;
    }

    const renderHeader = ()=>{
      let RenderHeader = tabelHeaders.map((item, index) => {
        if(item.active === true){
        return (<th onClick = {() => sortTable(item.value)} className = "pointer tableHeadingCol font-weight-bold text-dark text-decoration-none" key = {item.value}>
                {item.label}
                {isSortable && <i className="fas fa-sort ml-2"></i>}
            </th>)
        } else return (<Fragment key = {item.value}></Fragment>)
    })
    return RenderHeader;
    }

    return(
    <Fragment>
    <div className="TableComp" style = {{marginBottom: '0px'}}>
        {(fromPage !== 'Scheduled' || !mobileView) &&  <div className="table-responsive" style = {{minHeight: '55vh'}}> 
            <table className="table table-sm table-nowrap table-hover">
                <thead >
                    <tr className = 'table-heading py-3'>
                        {renderHeader()}
                    </tr>
                </thead>
                <tbody className="list table-content">
                    {renderRow()}
                    { tableData?.length === 0 && 
                        <tr>
                            <td colspan="6">
                                <img className={"mt-6 mb-3 horizontal-center"} src={boxImg} alt="img"/>
                                <p className={"mb-6 text-center nothing-display"}>You don’t have any interviews scheduled</p>
                            </td>
                        </tr>
                    }
                </tbody>
            </table> 
        </div>}
        {(fromPage === 'Scheduled' && mobileView) && 
          <>
            <HistoryList
                data = {renderData}
                totalDataCount = {renderData?.length}
                handleRowClick = {handleRowClick}
                headers = {headers}
                isSortable = {true}
            />
            </>
        }
        {isFooter && tableData?.length > 0 && <div className={(fromPage === 'Scheduled' && mobileView) ? "mt-10 mb-60" : "mb-5"}>
            <Pagination count={(1+ Math.floor((tableData?.length-1)/rowPerPage))} page={tablePage} onChange={(e , pageNum)=>setTablePage(pageNum)}
                variant="outlined" shape="rounded"/>
        </div>}

    </div>
    </Fragment>
    )
}



const compareValues = (key, order = 'asc') => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      if(!a[key].value){
        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];
    
        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
      } else{
        const varA = (typeof a[key].value === 'string')
            ? a[key].value.toUpperCase() : a[key].value;
        const varB = (typeof b[key].value === 'string')
            ? b[key].value.toUpperCase() : b[key].value;
    
        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
      }

    };
}


 

export default Table;

