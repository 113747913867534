import React,{useEffect, useState} from 'react';

import Radio from '@material-ui/core/Radio';
import accordianUp from '../../../images/accordian-arrow-up.svg';
import accordianDown from '../../../images/accordian-arrow-down.svg';
import moment from 'moment';


import './style.css';

const TableRow=({item,index,multiValue,tableHeaders,setInterviewSlot,setViewSideModal,setSelected, accepting, selectedId})=>{

    const [openAccordian,setOpenAccordian]=useState(false);
    const [Multiple,setMultiple]=useState('');
    const [selectedSlot,setSelectedSlot]=useState('');

    useEffect(()=>{
      tableHeaders.forEach((header)=>{
        if(header.value === multiValue){
          if(item[header.value].label.length>1) setMultiple(header.value);
        }
      })
    },[]);

    const isRadioButtonDisabled = (dateTime) => {
      let isDisableSlot = false;
      if(dateTime < new Date().setHours(23,59,59)){
          isDisableSlot = true;
      }
      return isDisableSlot;
    }

    return (
      <>
        <tr>
          {tableHeaders.map((header) => {
                  if(header.value === 'dateTime' && item[header.value].label.length === 1) {
                    return <td key = {header.value} className={index%2 === 0?"":"table-row-odd"}>{moment(item[header.value].label*1000).format('MMM DD, YYYY | hh:mm A')}</td>
                  }
                  if(item[header.value].label){
                      return <>
                        { item[header.value]?.label && item[header.value].label.length>1?
                          <td className={index%2 !== 0?" table-row-odd":""} >Multiple Slots 
                           <img className="accordian-icon"  onClick={()=>setOpenAccordian(!openAccordian)}  src={openAccordian?accordianUp:accordianDown} alt='accordian-icon'/>
                         
                          </td>:
                          <td key = {header.value} className={index%2 === 0?"":"table-row-odd"}>{item[header.value].label[0]}</td>
                        }
                      </>
                  }else{
                      return <td key = {header.value} className={index%2 === 0?"":"table-row-odd"}>{item[header.value]}</td>
                  }                                      
              }
          )}
          <td className={`text-center ${index%2 === 0?"":"table-row-odd"}`}>
              <div className = {(accepting && selectedId === item?.id)? 'btn btn-disabled btn-sm' : 'btn btn-primary btn-sm'} onClick = {() => {setViewSideModal(true); setInterviewSlot(selectedSlot); setSelected(item)}}>
                  {(accepting && selectedId === item?.id) ? "Accepting..." : "Accept"}
              </div>
          </td>
        </tr>
        { openAccordian && 
          item[Multiple].label.map((value)=>{
            return <tr className={`date-row ${isRadioButtonDisabled(value*1000) ? "disable-text" : ""}`}>
                {tableHeaders.map((header) => 
                <>
                  {header.value === Multiple && <td><Radio color="primary" disabled={isRadioButtonDisabled(value*1000)} onClick={(e)=>setSelectedSlot(e.target.value)} id={value} value={value} name="date" checked={selectedSlot === value}/>{moment(value*1000).format('MMM DD, YYYY | hh:mm A')}</td>}
                  {header.value !== Multiple && <td></td>}
                </>
                )}
            </tr>
          })
        }
      </>
    )
}

export default TableRow;