import React, {useState, useEffect, forwardRef} from 'react'
import {PageView, HeaderView} from '../../components/Pages/Views'
import Table from './Table'
import Select from 'react-select'
import NavBar from '../Nav'
import moment from 'moment'
import './index.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarImg from '../Scheduled/calenderImg.svg';
import cogoToast from 'cogo-toast';
import useMediaQuery from '../../utilis/useMediaQuery';
import Pagination from '@material-ui/lab/Pagination';
import FilterToolbar from '../../components/MobileComponents/FilterToolbar';
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Mydrawer from "./Mydrawer"
import Mymodal from "./Mymodal"
import axios from '../../services/axios'
import { useSelector } from 'react-redux'
import { getLocalTimeZone } from '../../utilis/TimeZone'

const tableHeaders = [
  {value: 'candidate_name', label: "Candidate Name", active: true},
  {value: 'companyName', label: 'Company Name', active: true},
  {value: 'position', label: 'Position', active: true},
  {value: 'round', label: 'Round', active: true},
  {value: 'dateTime', label: 'Assigned Date and Time', active: true},
  {value: 'audit_status', label: 'Interview Result', active: true},
  // {value: 'feedback', label: 'Feedback', active: true},
]

const initFilters = {
  companies: "",
  positions: "",
  candidate_name: "",
  fromDate:"",
  toDate:"",
  sortBy: "",
  order: ""
}

const Toolbar = ({setFilters, editFilters, filters, allCompanies, allPositions,clientPosData}) => {
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const mobileView = useMediaQuery('(max-width:700px)');

  useEffect(()=>{
      if(startDate && EndDate){
          let fromDate = moment(startDate).unix();
          let toDate = moment(EndDate).set({hour:23}).unix();
          if(fromDate > toDate){
              cogoToast.error('To Date should be greater than from date');
              setEndDate("");
              setFilters((prev)=>{
                  return {
                      ...prev,
                      fromDate: moment(startDate).format('DD-MM-YYYY'),
                      toDate: "",
                  }
              })
              return
          }

          setFilters((prev)=>{
              return {
                  ...prev,
                  fromDate: moment(startDate).format('DD-MM-YYYY'),
                  toDate: moment(EndDate).format('DD-MM-YYYY'),
              }
          })
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[startDate,EndDate]);

  const ExampleCustomInput = forwardRef(({ value, onClick , placeholder }, ref) => (
      <div   onClick={onClick} ref={ref}>
          <input readOnly className="date-pickeroWN" placeholder={placeholder} value={value} />
          <div className={"dateIcon"}>
              <img className={"calendarSize"} src={calendarImg} alt="calender img" />
          </div>
      </div>
    ));

    const handleRestoreBttn=()=>{
      setStartDate("");
      setEndDate("");
      setFilters(initFilters);
    }
  if(mobileView){
      return <FilterToolbar
          title="Audit"
          ExampleCustomInput={ExampleCustomInput}
          startDate={startDate}
          setStartDate={setStartDate}
          EndDate={EndDate}
          setEndDate={setEndDate}
          handleRestoreBttn={handleRestoreBttn}
          allCompanies={allCompanies}
          allPositions={allPositions}
          editFilters={editFilters}
          filters={filters}
          clientPosData={clientPosData}
      />
  }
  return (
      <div className = '' id = 'filters'>
          <div className = 'p-btm'>
              <div className = 'row align-items-center'>
                  <div className = 'col-2'>
                      <Select
                          options = {allCompanies}
                          value={filters.companies}
                          placeholder = 'All Companies'
                          onChange = {e => {editFilters(e, 'companies');}}
                          classNamePrefix = 'smallSelect'
                      />
                  </div>
                  <div className = 'col-2'>
                      <Select
                      id = "pos_select"
                          options = {allPositions}
                          value={filters.positions}
                          placeholder = 'All Positions'
                          onChange = {e => {editFilters(e, 'positions');}}
                          classNamePrefix = 'smallSelect'
                      />
                  </div>
                  <div className="col"></div>
                      <div className="pos-rel mr-10">
                          <DatePicker
                              popperPlacement="bottom-end"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              placeholderText="From date"
                              customInput={<ExampleCustomInput />}
                          />

                      </div>
                      <div className="pos-rel mr-30">
                      <DatePicker
                              popperPlacement="bottom-end"
                              selected={EndDate}
                              onChange={(date) => setEndDate(date)}
                              placeholderText="To date"
                              className="date-picker"
                              customInput={<ExampleCustomInput />}
                          />
                      </div>
                      <div className="sideBorder mr-30"></div>
                      <button className="bttn mr-10" onClick={handleRestoreBttn}>Restore default</button>
              </div>
          </div>
      </div>
  )
}


const Audit = (props) => {
    const mobileView = useMediaQuery('(max-width:700px)');
    const [selected, setSelected] = useState(null)
    const [viewSideModal, setViewSideModal] = useState(false);
    const [filters, setFilters] = useState(initFilters)
    const [options, setOptions] = useState({companies: [], positions: []})
    const [clientPosData, setClientPosData] = useState({})

    const [totalCount, setTotalCount] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [viewDescription, setViewDescription] = useState(false);
    const [auditList, setAuditList] = useState([]);
    const [fetchData, setFetchData] = useState([]);
    const [initialList, setInitialList] = useState([]);
    const [data, setData] = useState({});
    const currentTimezone = useSelector(state=>state?.user?.user_config?.timezone) || getLocalTimeZone();
    const PAGE_SIZE = 2;

    const fetchAudit =async ()=>{
      let params = {status:"PENDING"};
      let res = await axios.get('/interview_audit/all',{params:params});
      setFetchData(res?.data?.data);
      setTotalCount(res?.data?.metadata?.total);
      setInitialList(res?.data?.data);
      addingData(res?.data?.data);
    }

    useEffect(() => {
        fetchAudit();
    }, [])


    useEffect(()=>{
      // Function for adding the values in dropdown
      let audit_list = fetchData;
      let list = [];
      audit_list.forEach((item)=>{
        let obj = {
          id:item?.audit_details?.candidate_details?.id,
          candidate_name : item?.audit_details?.candidate_details?.name,
          companyName:item?.audit_details?.client_details?.client_name,
          position:item?.audit_details?.position_details?.position_name,
          round:item?.audit_details?.round_details?.name,
          dateTime:{
            label:item?.audit_details?.created_at,
          },
          audit_status:item?.audit_details?.interview_details?.interview_result,
        }
        list.push(obj);
      })
      setAuditList(list);
    },[fetchData]);

    let checkPresent =(list, item)=>{
      let flag = 1;
      for (let i=0;i<list?.length;i++){
        if (list[i]?.id === item)
        flag = 0;
      }
      return flag;
    }
    // this function call for set the values in options
    const addingData = (list)=>{
      let clientList = [];
      let positionList = [];
      list.forEach((item)=>{
        if (checkPresent(clientList, item?.audit_details?.client_details?.id))
        clientList.push({label:item?.audit_details?.client_details?.client_name,
                          value:item?.audit_details?.client_details?.client_name,
                          id:item?.audit_details?.client_details?.id});
        if (checkPresent(positionList, item?.audit_details?.position_details?.id))
        positionList.push({label:item?.audit_details?.position_details?.position_name,
                            value:item?.audit_details?.position_details?.position_name,
                            id:item?.audit_details?.position_details?.id});
      })
      clientList.push({label:'All Companies', value:''});
      positionList.push({label:'All Positions', value:''});
      clientList.sort((a,b)=>a.value >= b.value ? 1 : -1);
      positionList.sort((a, b)=>a.value >= b.value ? 1 : -1);
      let obj = {
        companies:clientList,
        positions:positionList,
      }
      setOptions(obj);
    }

    function checkObjectValues(obj){
      let values = Object.values(obj);
      values.forEach((item)=>{
        if (item?.length >0)
        return 0;
      })
      return 1;
    }

    useEffect(()=>{
      if (checkObjectValues(filters)){
        fetchDataWithFilters();
      }
      else
      fetchAudit();
    },[currentPage])


    const editFilters = (value, name) => {
      setFilters(prev => ({...prev, [name]: value}));
    }

    const fetchDataWithFilters = async ()=>{
      let params = {
        status:"PENDING",
        page_size:PAGE_SIZE,
        page_number:currentPage,
        client_id:filters?.companies?.id,
        position_id:filters?.positions?.id,
        from_date:filters?.fromDate,
        to_date:filters?.toDate,
        timezone:'Asia/Kolkata'
      };
      let res = await axios.get('/interview_audit/all',{params:params});
      setFetchData(res?.data?.data);
      setTotalCount(res?.data?.metadata?.total);
    }

    useEffect(()=>{
      fetchDataWithFilters();
    },[filters])

    const handleRestoreBttn=()=>{
        setFilters(initFilters);
    }

    const handleClick = (id)=>{
      setSelected(1);
      setViewSideModal(true);
      fetchData?.forEach((item)=>{
        if (item?.audit_details?.candidate_details?.id === id){
          setData(item);
        }
      })
    }
    return (
        <div className="AuditComp">
            <NavBar {...props} pageTitle="Audit" augmentElement={
                <Box display='flex' justifyContent="flex-end" alignItems="center">
                    <Button onClick={handleRestoreBttn} color="primary" variant="text">
                        Restore Default
                    </Button>
                </Box>
            }/>
            <PageView>
                {!mobileView && <HeaderView title = 'Audit' OwnComp={<div className="fl-col headerComp"></div>}/> }

                <Toolbar
                    editFilters = {editFilters}
                    setFilters ={setFilters}
                    filters = {filters}
                    allCompanies = {options.companies}
                    allPositions = {options.positions}
                    clientPosData={clientPosData}
                />
                <Table
                    isFooter = {false}
                    handleRowClick = {handleClick}
                    data = {auditList}
                    headers = {tableHeaders}
                    isSortable = {true}
                    currentTimezone={currentTimezone}
                    acceptReq= {(obj)=>{
                        setSelected(obj[1]);
                        setViewSideModal(true);
                    }}
                    setViewSideModal={setViewSideModal}
                />

                {totalCount > 0 && <div className="pagination-wrapper">
                <Pagination count={(1+ Math.floor((totalCount-1)/PAGE_SIZE))} page={currentPage} onChange={(e , pageNum)=>setCurrentPage(pageNum)}
                variant="outlined" shape="rounded"/>
                </div>}

                {viewSideModal ? 
                  <Mydrawer 
                    demoData={data} 
                    setViewDescription={setViewDescription} 
                    setViewSideModal={setViewSideModal} 
                    currentTimezone={currentTimezone}/> 
                  : ''}
                {viewDescription ? <Mymodal demoData={data} setViewDescription={setViewDescription}/> : ''}


            </PageView>
        </div>
    )
}

export default Audit;

