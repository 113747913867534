import React, {useState, useEffect, forwardRef} from 'react'
import {PageView, HeaderView} from '../../components/Pages/Views'
import Table from './Table'
import Modal from '../../components/Models/RightSide'
import Select from 'react-select'
import NavBar from '../Nav'
import { fetchHistoryWithPagination} from '../../services/history'
import {getClientsAndPositions} from "./../../services/history"
import { getFeedback } from '../../services/feedback'
import moment from 'moment'
import { getFileURL } from '../../services/file'
import './index.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarImg from '../Scheduled/calenderImg.svg';
import cogoToast from 'cogo-toast';
import useMediaQuery from '../../utilis/useMediaQuery';
import Pagination from '@material-ui/lab/Pagination';
import FilterToolbar from '../../components/MobileComponents/FilterToolbar';
import Button from "@material-ui/core/Button"
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import axios from '../../services/axios'
import { useSelector } from 'react-redux';
import { INTERVIEWER_TYPE } from '../User/Constant'
import DetailedSchedule from '../Scheduled/DetailedScheduled'
import { getLocalTimeZone } from '../../utilis/TimeZone'

const tableHeaders = [
  {value: 'companyName', label: 'Company Name', active: true},
  {value: 'position', label: 'Position', active: true},
  {value: 'round', label: 'Round', active: true},
  {value: 'dateTime', label: 'Interview Date and Time', active: true},
  {value: 'feedbackDateTime', label: 'Feedback Date & Time', active: true},
  {value: 'status', label: 'Status', active: true},
  {value: 'result', label: 'Interview Result', active: true},
]

const auditHeaders = [
  {value: 'companyName', label: 'Company Name', active: true},
  {value: 'position', label: 'Position', active: true},
  {value: 'round', label: 'Round', active: true},
  {value: 'dateTime', label: 'Interview Date and Time', active: true},
  {value: 'assignedDateTime', label: 'Assigned Date & Time', active: true},
  {value: 'auditDateTime', label: 'Audit Date & Time', active: true},
  {value: 'auditStatus', label: 'Audit Status', active: true},
]

const initFilters = {
  companies: "",
  positions: "",
  candidate_name: "",
  fromDate:"",
  toDate:"",
  sortBy: "",
  order: ""
}

const History = (props) => {
    const mobileView = useMediaQuery('(max-width:700px)');
    const interviewerType = useSelector((state) => state && state.profile?.interviewer_type);
    const [history, setHistory] = useState([])
    const [audit, setAudit] = useState([]);
    const [selected, setSelected] = useState(null)
    const [viewSideModal, setViewSideModal] = useState(false);
    const [filters, setFilters] = useState(initFilters)
    const [options, setOptions] = useState({companies: [], positions: []})
    const [clientPosData, setClientPosData] = useState({})
    const [totalCountAudit, setTotalCountAudit] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [value, setValue] = React.useState('1');
    const [startDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const currentTimezone = useSelector(state=>state?.user?.user_config?.timezone) || getLocalTimeZone();
    const [feedbackData, setFeedbackData] = useState({});

    const PAGE_SIZE = 8;

    useEffect(() => {
        fetchData();
        fetchdAuditData();
    }, [])

    useEffect(() => {
        if(value === '1')
          fetchInterviewData();
        else
          fetchdAuditData();
        setCurrentPage(1);
    }, [value])

    useEffect(()=>{
      if(value === '1')
        fetchInterviewData();
      else
        fetchdAuditData();
    },[currentPage])

    useEffect(() => {
        if (value === '1') {
            fetchInterviewData();
        } else {
            fetchdAuditData();
        }
        // eslint-disable-next-line
    }, [filters])

    const handleChange = (event,val) => {
        setStartDate(null)
        setEndDate(null)
        setValue(val);
    };

    const fetchdAuditData = async ()=>{
      let params ={
        status:"COMPLETED",
        page_number:currentPage,
        page_size:PAGE_SIZE,
      };
      if(startDate && EndDate)
      {
        params["from_date"] = (moment(startDate).format("DD-MM-YYYY"))?.toString();
        params["to_date"] = (moment(EndDate).format("DD-MM-YYYY"))?.toString();
        params["timezone"] = currentTimezone;
      }
      try
      {
      let res = await axios.get('interview_audit/all',{params:params});
      setTotalCountAudit(res?.data?.metadata?.count?.status_count?.COMPLETED);
      if (res?.status === 200){
        let data = res?.data?.data?.map((item)=>{
            let obj = {
              companyName:item?.audit_details?.client_details?.client_name,
              position:item?.audit_details?.position_details?.position_name,
              round:item?.audit_details?.round_details?.name,
              dateTime:moment(item?.audit_details?.interview_details?.scheduled_start_timestamp * 1000).format('llll') ,
              assignedDateTime:moment(item?.audit_details?.created_at* 1000).format('llll'),
              auditDateTime:moment(item?.audit_details?.completed_at * 1000).format('llll') ,
              auditStatus:item?.audit_details?.status,
            }
            return obj;
        })
        setAudit([...data]);
      }
      else{
        cogoToast.error("Didn't get the data of auditors",{position:"top-right"});
      }
    }
    catch(error)
    {
        cogoToast.error(
            error?.data?.msg || 
            error?.data || 
            error || 
            "Didn't get the data of auditors",{position:"top-right"});
    }
    }

    const fetchData = async () => {
        try{
            const clientPositionData = await getClientsAndPositions();
            setClientPosData(clientPositionData);

            const companyFilter = Object.entries(clientPositionData).map((item => {
                return { value: item[0], label: item[1].client_name }
            }))


            setOptions({
                companies: [ {value: "", label: 'All Companies'}, ...companyFilter],
                positions: []
                    // {value: "0", label: 'All Positions'}, (fetchedHistory.map(item => ({value: item.position, label: item.position})), 'value')],
            })

        } catch(err){
        }
    }


    const fetchInterviewData = async () => {
        let fromDate = filters?.fromDate ? moment(filters?.fromDate  * 1000).format("DD-MM-YYYY") : '';
        let toDate = filters?.toDate ? moment(filters?.toDate  * 1000).format("DD-MM-YYYY") : '';
        let payload = {
            // sort_by : ‘candidate_name’ or ‘scheduled_start_timestamp’ (default: scheduled_start_timestamp)
            // sort_order: ‘DESC’ or ‘ASC’
            client_id: filters?.companies?.value ? filters?.companies?.value : "" ,
            position_id: filters?.positions?.value ? filters?.positions?.value : "",
            candidate_name: filters.candidate_name ,
            page_number: currentPage,
            page_size: PAGE_SIZE,
            from_date: fromDate || '',
            to_date: toDate || '',
        };
        if(filters.sortBy !== "" && filters.order !== ""){
            payload.sort_by = filters.sortBy;
            payload.sort_order = filters.order;
        }
        const res = await fetchHistoryWithPagination(payload);

        if (res?.metadata_dict) {
            setTotalCount(res?.metadata_dict?.total);
        }

        const data = res?.interview_list.map(item => ({
            ...item,
            candidateName: item.candidate_details.name,
            companyName: item.client_details.client_name,
            position: item.position_details.position_name,
            round: item.round_details.name,
            date: item?.interview_details?.scheduled_start_timestamp ? moment.unix(item?.interview_details?.scheduled_start_timestamp).format('DD-MM-YY') : "NA",
            time: item?.interview_details?.scheduled_start_timestamp ? moment.unix(item?.interview_details?.scheduled_start_timestamp).format('hh:mm a') : "NA",
            dateTime:item?.interview_details?.scheduled_start_timestamp ?  { value: item?.interview_details?.scheduled_start_timestamp || '', label: item?.interview_details?.scheduled_start_timestamp ? moment.unix(item?.interview_details?.scheduled_start_timestamp).format('MMM DD, YYYY | hh:mm a') : '' } : "",
            id: item.interview_details.id,
            isReviewd: true,
            status: statusMapper(item.interview_details),
            result: interviewResult(item.interview_details),
            // interviewResul: item.interview_details.interview_result,
            feedback: { value: item?.interview_details?.feedback_report_link || '', label: item?.interview_details?.feedback_report_link ? <div className = 'my-3 btn-link pointer' onClick = {(e) => handleUrl(e, item?.interview_details?.feedback_report_link)}>View</div> : <div></div> },
            feedbackDateTime: item?.interview_event_details?.feedback_first_submitted_at ? moment.unix(item?.interview_event_details?.feedback_first_submitted_at).format('MMM DD, YYYY | hh:mm a') :'',
        }))
        setHistory(data);
    }

    const handleUrl = async (e, url) => {
        let data = await getFileURL(url);
        window.open(data, "_blank");
        e.preventDefault();
      };

    const handleCompanySelection = (value) => {
        let posFilter = [];
        if (value !== "") {
            posFilter = Object.entries(clientPosData[value].position_dict).map((item => {
                return { value: item[0], label: item[1] }
            }));
        }
        setOptions({
            ...options,
            positions: [{ value: "", label: 'All positions' }, ...posFilter]
        })
       setFilters(prev => ({...prev, "positions": null}))
    }

    const editFilters = (value, name) => {
        setFilters(prev => ({...prev, [name]: value}))
    }

    function statusMapper(interview_details){
        if(interview_details.interview_status === 'COMPLETED'){
           return {value: 'Completed', label: <div>Completed</div>}
        }
        else if(interview_details.interview_status === 'CANCELLED'){
            return {value: 'Cancelled', label: <div>Cancelled (Chargeable)</div>}
        }
        else if(interview_details.interview_status === 'DELETED'){
            return {value: 'Deleted', label: <div>Cancelled (Non-Chargeable)</div>}
        }else{
            return {value: '--', label: <div>--</div>}
        }
    }

    function interviewResult(interview_details){
        if(interview_details.interview_result === 'STRONG_PROCEED'){
           return {value: 'Strong Proceed', label: <div>Strong Proceed</div>}
        }
        else if(interview_details.interview_result === 'PROCEED'){ 
            return {value: 'Proceed', label: <div>Proceed</div>}
        }
        else if(interview_details.interview_result === 'REJECT'){
            return {value: 'Reject', label: <div>Reject</div>}
        }
        else if(interview_details.interview_result === 'STRONG_REJECT'){
            return {value: 'Strong Reject', label: <div>Strong Reject</div>}
        }else{
            return {value: '--', label: <div>--</div>}
        }
    }

    const handleRowSelected =  (id, interviewStatus) => {
        if(interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL){
            setSelected(id); 
            setViewSideModal(true);
            if(interviewStatus === 'COMPLETED') {
            getFeedback(id)
            .then((res)=>{
                if(res)
                    setFeedbackData(res);
                else cogoToast.error(res?.data?.message || res?.message || "Unable to fetch interview feedback!");
            })
            .catch((err)=>{
                cogoToast.error(err?.data?.message || err?.message || "Unable to fetch interview feedback!");
            })
            }
        }
    }

    const handleRestoreBttn=()=>{
        setFilters(initFilters);
    }
    return (
        <div className="HistoryComp">
            <NavBar {...props} pageTitle="History" augmentElement={
                <Box display='flex' justifyContent="flex-end" alignItems="center">
                    <Button onClick={handleRestoreBttn} color="primary" variant="text">
                        Restore Default
                    </Button>
                </Box>
            }/>
            <PageView>
                {!mobileView && <HeaderView title = 'History'OwnComp={<div className="fl-col headerComp">
                </div>}/>}

                <Toolbar
                    editFilters = {editFilters}
                    setFilters ={setFilters}
                    handleCompanySelection = {handleCompanySelection}
                    filters = {filters}
                    allCompanies = {options.companies}
                    allPositions = {options.positions}
                    clientPosData={clientPosData}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    EndDate={EndDate}
                    setEndDate={setEndDate}
                />
 
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: "1px solid #e3ebf6", borderColor: 'divider' , marginBottom:"20px"}}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label={"Interviews ("+(totalCount)+")"} value={'1'} />
                                <Tab label={"Audits ("+(totalCountAudit)+")"} value={'2'} 
                                    disabled={interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL} />
                            </TabList>
                            </Box>
                        </TabContext>
                    </Box>

               { value === '1'? <Table
                    data = {history}
                    totalDataCount={totalCount}
                    headers = {tableHeaders}
                    isSortable = {false}
                    handleRowClick =  {(id, interviewStatus)=>handleRowSelected(id, interviewStatus)}
                />
                :
                <Table
                    data = {audit}
                    totalDataCount={totalCountAudit}
                    headers = {auditHeaders}
                    isSortable = {false}
                />  
            }
 
        {/* ----------Show right drawer on history tab for internal interviewers---------- */}
               <Modal show = {viewSideModal} setShow = {setViewSideModal}>
                    {(selected)  && <DetailedSchedule
                        data = {history.find(item => item.id === selected)} 
                        setShow = {setViewSideModal}
                        setSelected = {setSelected}
                        fetchData ={fetchData}
                        schedule={history}
                        page="History"
                        feedbackData={feedbackData}
                    />}
                </Modal> 

                {totalCount > 0 && value === '1' && <div className="pagination-wrapper">
                <Pagination count={(1+ Math.floor((totalCount-1)/PAGE_SIZE))} page={currentPage} onChange={(e , pageNum)=>setCurrentPage(pageNum)}
                variant="outlined" shape="rounded"/>
                </div>}
                {totalCount > 0 && value === '2' && <div className="pagination-wrapper">
                <Pagination count={(1+ Math.floor((totalCountAudit-1)/PAGE_SIZE))} page={currentPage} onChange={(e , pageNum)=>setCurrentPage(pageNum)}
                variant="outlined" shape="rounded"/>
                </div>}

                {/*  <Modal show = {viewSideModal} setShow = {setViewSideModal}>
                    {selected && <DetailedHistory
                        data = {history.filter(item => item.id === selected)[0]}
                        setShow = {setViewSideModal}
                    />}
                </Modal> */}

            </PageView>
        </div>
    )
}




export default History;




const Toolbar = ({
    handleCompanySelection,
    setFilters, 
    editFilters, 
    filters, 
    allCompanies, 
    allPositions,
    clientPosData,
    startDate,
    setStartDate,
    EndDate,
    setEndDate
}) => {

    const mobileView = useMediaQuery('(max-width:700px)');

    useEffect(()=>{
        if(startDate && EndDate){
            let fromDate = moment(startDate).unix();
            let toDate = moment(EndDate).set({hour:23}).unix();
            if(fromDate > toDate){
                cogoToast.error('To Date should be greater than from date');
                setEndDate("");
                setFilters((prev)=>{
                    return {
                        ...prev,
                        fromDate: fromDate,
                        toDate: "",
                    }
                })
                return
            }
            setFilters((prev)=>{
                return {
                    ...prev,
                    fromDate: fromDate,
                    toDate: toDate,
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[startDate,EndDate]);

    const ExampleCustomInput = forwardRef(({ value, onClick , placeholder }, ref) => (
        <div   onClick={onClick} ref={ref}>
            <input readOnly className="date-pickeroWN" placeholder={placeholder} value={value} />
            <div className={"dateIcon"}>
                <img className={"calendarSize"} src={calendarImg} alt="calender img" />
            </div>
        </div>
      ));

      const handleRestoreBttn=()=>{
        setStartDate("");
        setEndDate("");
        setFilters(initFilters);
      }
    if(mobileView){
        return <FilterToolbar
            title="History"
            ExampleCustomInput={ExampleCustomInput}
            startDate={startDate}
            setStartDate={setStartDate}
            EndDate={EndDate}
            setEndDate={setEndDate}
            handleRestoreBttn={handleRestoreBttn}
            allCompanies={allCompanies}
            allPositions={allPositions}
            handleCompanySelection={handleCompanySelection}
            editFilters={editFilters}
            filters={filters}
            clientPosData={clientPosData}
        />
    }
    return (
        <div className = '' id = 'filters'>
            <div className = 'p-btm'>
                <div className = 'row align-items-center'>
                    <div className = 'col-2'>
                        <Select
                            options = {allCompanies}
                            value={filters.companies}
                            placeholder = 'All Companies'
                            onChange = { e => {handleCompanySelection(e?.value); editFilters(e, 'companies')}}
                            classNamePrefix = 'smallSelect'
                        />
                    </div>
                    <div className = 'col-2'>
                        <Select
                        id = "pos_select"
                            options = {allPositions}
                            value={filters.positions}
                            placeholder = 'All Positions'
                            onChange = {e => editFilters(e, 'positions')}
                            classNamePrefix = 'smallSelect'
                        />
                    </div>
                    <div className="col"></div>
                        <div className="pos-rel mr-10">
                            <DatePicker
                                popperPlacement="bottom-end"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                placeholderText="From date"
                                customInput={<ExampleCustomInput />}
                            />

                        </div>
                        <div className="pos-rel mr-30">
                        <DatePicker
                                popperPlacement="bottom-end"
                                selected={EndDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="To date"
                                className="date-picker"
                                customInput={<ExampleCustomInput />}
                            />
                        </div>
                        <div className="sideBorder mr-30"></div>
                        <button className="bttn mr-10" onClick={handleRestoreBttn}>Restore default</button>
                </div>
            </div>
        </div>
    )
}
