import React from 'react';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import PreScreenHeader from "../../../atoms/PreScreenHeader";
import PreScreenHeader from "../../../atoms/PrescreenHeader/PreScreenHeader";
import SideComponent from './SideComponent';
import ReportSection from './ReportSection';
import './PreScreeningReport.scss';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router';
import SwtAlert from "../../../../Utils/SwtAlert";

const PreScreeningReport = ({state, handleFileDownload, isDownloadBtnDisabled})  =>{
  const [captureImage, setCaptureImage] =  useState([]);
  const [prescreenQuestion,setPrescreenQuestion] = useState();
  const [prescreenQuesImage, setPrescreenQuesImage] = useState({});

  

  const toDataUrl = (url, callback) => {
    return new Promise((resolve,reject)=>{    
      try
      {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result);
          }
          reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }
    catch(error)
    {
      reject(error)
    }
    })
  }

  const downloadAllImages = async(arr) => {
    return await Promise.all(arr?.map(async (item) => {
      if(item?.question_image)
      {
      let res = await handleFileDownload(item?.question_image);
      return res?.data;
      }
      return null;
      }
      ));
  }

  const handleImageShot = async() => {
    if(state?.data?.candidate_screening?.extra_info?.camera_shots?.length>0){
    let results = await Promise.all(state?.data?.candidate_screening?.extra_info?.camera_shots?.map(async (item) => {
    let res = await handleFileDownload(item?.imgSrc?item?.imgSrc:item);
    return {
              imgSrc:res?.data,
              timestamp:item?.timestamp
            };
    }));
    let arr = await Promise.all(results?.map(async (item) => {
      let res = await toDataUrl(item?.imgSrc);
      return {
                imgSrc:res,
                timestamp:item?.timestamp
              };
    }));
    setCaptureImage(arr);
    }
  }

  useEffect(()=>{
    if(prescreenQuestion)
    {
      let quesTopic = {};
      Object.entries(prescreenQuestion)?.map(async ([topic, questions])=> {
      let results = await downloadAllImages(questions);
      let arr = await Promise.all(results?.map(async (item) => {
        return await toDataUrl(item);
      }));
      prescreenQuesImage[topic] = arr;

      setPrescreenQuesImage({...prescreenQuesImage});
    })
    }
  },[prescreenQuestion])

  useEffect(()=>{
    handleImageShot();
  },[state?.data?.candidate_screening?.extra_info?.camera_shots])

  return (
    <Box className='prescreeningReport-parent'>
        <Box>
          <PreScreenHeader 
            value={"QUICKSCREEN REPORT"}
            candidateDetails={state?.data?.candidate}
            roundDetails={state?.data?.round}
            positionDetails={state?.data?.pre_screening}
            position={state?.data?.position}
            clientLogo={state?.data?.client?.client_logo}
            candidatePrescreeningRoundDetails={state?.data?.candidate_screening}
            reportQuestions={state?.data?.asked_questions}
            captureImage={captureImage}
            prescreenQuestion={prescreenQuestion}
            isDownloadBtnDisabled={isDownloadBtnDisabled}
            prescreenQuesImage={prescreenQuesImage}
            />
          </Box>
        <Box className='prescreening-report-body'>
                     <SideComponent 
                        candidateDetails={state?.data?.candidate}
                        roundDetails={state?.data?.round}
                        positionDetails={state?.data?.position}
                        clientLogo={state?.data?.client?.client_logo}
                        captureImage={captureImage}
                     />
                       <ReportSection 
                            positionDetails={state?.data?.pre_screening}
                            candidatePrescreeningRoundDetails={state?.data?.candidate_screening}
                            reportQuestions={state?.data?.asked_questions}
                            prescreeningDetails={state?.data?.pre_screening}
                            prescreenQuestion={prescreenQuestion}
                            setPrescreenQuestion={setPrescreenQuestion}
                            prescreenQuesImage={prescreenQuesImage}
                       />
        </Box>
    </Box>
  )
}

export default PreScreeningReport;