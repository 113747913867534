import axios from "./axios";

export const getPrescreenReportData = (client_id, prescreen_round_id, jwtToken) => {
    const headers = jwtToken ? {
      'X-Client-Request-Access-Token': jwtToken,
    } : {};
    return axios.get(`/candidate_screening/${client_id}/${prescreen_round_id}/report`,{
      headers: headers
    })
    .then((response) => response)
    .catch((err) => {
        return err.response;
    });
}