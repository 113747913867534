import React from "react";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import './previewSection.scss';

import { AccordionSummary, AccordionDetails } from './../../Components/Accordion'

import { CommentConstants } from "../../Constants/FeedbackConstants";

import { downloadFile2 } from "../../../../../Services/file";
import starIcon from "../../../../../assets/images/feedback/star-icon.svg";
import fadeStarIcon from "../../../../../assets/images/feedback/fade-star-icon.svg";
import eyeIcon from "../../../../../assets/images/eye-black-icon.svg";
import { CommonTable } from "./CommonData";
import { ResumeTable, ResumeTableV3 } from "./Constant";
import tickIcon from "../../../../../assets/images/feedback/check-mark.svg";
import wrongIcon from "../../../../../assets/images/feedback/wrong-icon.svg";

import {isResumeV3Version} from '../../Constants/FeedbackConstants';
const ResumeSection = ({ interviewDetails, data, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection, role,
  user,
  auth, }) => {

    
  const star = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
  const renderTable = ()=>{

    let copyData = {}

    if(isResumeV3Version(interviewDetails)){
       copyData = JSON.parse(JSON.stringify(ResumeTableV3));

       const projectsArray = [];
       projectsArray.push({ imgType: 'icon', imgSrc: tickIcon, data: data?.positives })
       projectsArray.push({ imgType: 'unicode', imgSrc: '', data: data?.neutrals })
       projectsArray.push({ imgType: 'icon', imgSrc: wrongIcon, data: data?.negatives })
     
     copyData["RESUME_DISCUSSION"].data = projectsArray;
     copyData["EXPERIENCE"].data = data?.experience;


    }else{
       copyData = JSON.parse(JSON.stringify(ResumeTable));

       const projectsArray = [];
       projectsArray.push({ imgType: 'icon', imgSrc: tickIcon, data: data?.comment?.positives })
       projectsArray.push({ imgType: 'unicode', imgSrc: '', data: data?.comment?.neutrals })
       projectsArray.push({ imgType: 'icon', imgSrc: wrongIcon, data: data?.comment?.negatives })
       projectsArray.push({ imgType: 'hiphen', imgSrc: '', data: data?.relevant_project })
     
     copyData["PROJECTS"].data = projectsArray;
     copyData["EXPERIENCE"].data = data?.experience;
     copyData["TECH_STACK"].data = data?.tech_stack_knowledge;
    }

     
  
    return <Box>
              <CommonTable tableData={copyData}/>
          </Box>
  }

  const renderRatings = (rating)=>{
    return <Box display="flex" flexDirection="row" alignItems="center">
         {
           star?.map((item,index)=>{
               if((index+1) <=rating)
               {
                 return <Box><img src={starIcon}/></Box>
               }
               else
               {
                 return <Box><img src={fadeStarIcon}/></Box>  
               }
           })
         }
    </Box>
 }

  const renderResumeContent = () =>{
    return <Box display="flex" flexDirection="column" gridGap="26px" width="100%" className="feedback-resume-section">
              <Box display="flex" flexDirection="column" gridGap="8px" className="rating-box">
                <Box>Resume Rating</Box>
                  <Box display="flex" flexDirection="row" gridGap="24px">
                    <Box display="flex" flexDirection="row" gridGap="6px">
                      {renderRatings(data?.rating)}
                      <Box>({data?.rating}/10)</Box>
                    </Box>
                    <Box>
                    </Box>
                  </Box>
              </Box>
              {renderTable()}
             { data?.comment?.comment && data?.comment?.comment?.length > 0 && <Box display="flex" flexDirection="row" gridGap="16px"  className="extra-comment">
                  <Box>Interviewer’s Comments: :</Box>
                  <Box display="flex" flexDirection="column">
                  {data?.comment?.comment?.map((data) => {
                      return  <Box>- {data}</Box>

                  })}
                  </Box>
                </Box>}
          </Box>
  }

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion className="mtb-20" id="add-comment-parent"  expanded={expanded} onChange={handleChange('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" style={{ width: "100%", alignItems: "center" }}>
          <div className="preview-subheader-title"> Resume - Intro</div>

          <div className="preview-subheader-subtitle mrl-20">
            {" "}
            Time Spent: {data?.time_spent} Mins
          </div>
        </Box>
        {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.RESUME_INTRO)}
        {showFeedbackComments && renderAddCommentSection(CommentConstants.RESUME_INTRO)}
      </AccordionSummary>

      <AccordionDetails>
        {renderResumeContent()}
      </AccordionDetails>
    </Accordion>
  );
};

export default ResumeSection