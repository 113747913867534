import React from "react";
import {
    Text,
    View,
    StyleSheet,
    Font,
    Image,
} from "@react-pdf/renderer";

import completeIcon from "../../../../../assets/images/feedback/feedback-complete-icon.png"
import incompleteIcon from "../../../../../assets/images/feedback/feedback-incomplete-icon.png"
import starGolden from "../../../../../assets/images/feedback/star-golden.png"
import starGray from "../../../../../assets/images/feedback/star-grey.png"
import grayDot from "../../../../../assets/images/feedback/dot-blue.png"
import dot from "../../../../../assets/images/feedback/dot-black.png"

import {isResumeV3Version} from '../../Constants/FeedbackConstants';

Font.register({
    family: "Poppins",
    src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    fonts: [
        {
            src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
        },
        {
            src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
            fontWeight: "thin",
        },
        {
            src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
            fontWeight: "medium",
        },
        {
            src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
            fontWeight: "semibold",
        },
        {
            src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
            fontStyle: "italic",
        },
        {
            src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
            fontWeight: "bold",
            fontStyle: "italic",
        },
        {
            src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
            fontWeight: "bold",
        },
    ],
});

Font.registerHyphenationCallback((word) => [word]);

const ResumeVersion = StyleSheet.create({
    rowstyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    previewSubheaderTitle: {
        fontFamily: "Poppins",
        fontWeight: "bold",
        fontSize: 13.5,
        letterSpacing: 1.1,
        textTransform: "uppercase",
        color: "#0d3270",
    },

    previewSubheaderSubtitle: {
        fontFamily: "Poppins",
        fontWeight: "bold",
        fontSize: 11,
        lineHeight: "117.4%",
        letterSpacing: 0.3,
        color: "#374671",
    },

    borderBox: {
        borderRadius: 8,
        width: 1063,
        marginTop: 30,
        marginLeft: 20,
        paddingBottom: 25,
    },


    ratingImageBox: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "10",
    },


    ratingNumber: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 13,
        letterSpacing: 0.025,
        marginLeft: 10,
        marginTop: 5,
    },


    explanationParentBox: {
        display: "flex",
        flexDirection: "row",
    },

    explanationBox: {
        marginTop: 17,
        width: 300,
        paddingRight: 5,
    },

    explanationBoxNoWidth: {
        marginTop: 17,
        paddingRight: 5,
    },
    explanationBoxLongText: {
        marginTop: 17,
        paddingRight: 5,
        maxWidth: "600px",
    },
    explanationBoxInternal: {
        display: "flex",
        flexDirection: "row",
    },

    explanationText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 12,
        letterSpacing: 0.025,
        color: "#3D3D3D",
        marginLeft: 10,
    },

    headingBox: {
        display: "flex",
        flexDirection: "row",
        width: 1021,
        height: 41,
        backgroundColor: "#F5F8FF",
        marginLeft: 20,
        borderRadius: 6,
        marginTop: 25,
        alignItems: "center",
    },

    headingText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 0.025,
        color: "#374671",
    },

    vertical: {
        borderRight: "1px solid #BCC7E6",
        height: 40,
    },

    resumeRatingHeadingText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: 0.025,
        color: "#374671",
        marginLeft: 35
    },

    commentBox: {
        display: "flex",
        flexDirection: "row",
        width: 990,
        marginLeft: 15,
        marginTop: 40,
    },

    commentTextBox: {
        width: 900,
        paddingRight: 25
    },

    timeText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 11,
        letterSpacing: 0.025,
        color: "#3D3D3D",
    },

    width33: {
        width: "33%",
    },
    width60: {
        width: "60%",
    }

});

const ResumeSection = ({interviewDetails, resumeData }) => {
    const rating = (data) => {
        let rating = data;
        let count = 0;
        let arr = [];

        if (count < 10) {
            while (count < rating) {
                arr.push(
                    <View>
                        <Image
                            src={starGolden}
                            style={[{ width: 20, height: 20, marginLeft: 5 }]}
                        />
                    </View>
                );
                count++;
            }

            while (count < 10) {
                arr.push(
                    <View>
                        <Image
                            src={starGray}
                            style={[{ width: 20, height: 20, marginLeft: 5 }]}
                        />
                    </View>
                );
                count++;
            }
        }
        return arr;
    };

    //  table starts
    const table = (data, status) => {
        return (
            <View>
                {data?.map((items, index) => {
                    return (
                        <View
                            style={[
                                ResumeVersion.explanationBoxInternal,
                                { marginTop: 10 },
                            ]}
                        >
                            <View>
                                {status ? <Image
                                    src={
                                        status === "Positives"
                                            ? completeIcon
                                            : status === "Neutrals"
                                                ? dot
                                                : incompleteIcon
                                    }
                                    style={
                                        status === "Positives"
                                            ? [{ width: 15, height: 15 }, { marginTop: 3, marginRight: 2 }]
                                            : status === "Neutrals"
                                                ? [{ width: 3, height: 3 }, { marginTop: 8, marginRight: 2  }]
                                                : [{ width: 8, height: 8 }, { marginTop: 6, marginRight: 2  }]
                                    }
                                /> :
                                    <Text> - </Text>}
                            </View>

                            <View>

                            {typeof(items) === 'object' ?  
                                    
                                    <View>
                                    <Text style={[{color:'#374671', fontSize:'14px', marginBottom: 2}]}>{items?.question}</Text>

                                    <Text style={[{ fontSize:'13px', marginBottom: 4}]}>{items?.response}</Text>
                                    </View>
                                    :
                                <Text style={[ResumeVersion.explanationText]}>
                                    {items}
                                </Text>
                            }
                            </View>
                        </View>
                    );
                })}
            </View>
        );
    };
    //  table ends


    // comments starts
    const comments = (data) => {
        return (
            <View>
                {data?.map((item) => {
                    return (
                        <View style={[{ display: "flex", flexDirection: "row", marginTop: 10 }]}>
                            <Text style={[ResumeVersion.explanationText, { marginLeft: 20 }]}>-</Text>
                            <Text style={[ResumeVersion.explanationText]}>
                                {item}
                            </Text>
                        </View>
                    );
                })}
            </View>
        );
    };
    // comments ends

    return (
        <View
            style={[
                {
                    margin: 15,
                    border: "1 solid #cdcdcd",
                    backgroundColor: "#fff",
                    paddingBottom: "20px",
                },
            ]}
        >
            {/* heading section */}
            <View
                style={[
                    ResumeVersion.rowstyle,
                    {
                        width: "100%",
                        backgroundColor: "#C5D4FF",
                        minHeight: 42,
                        padding: 15,
                    },
                ]}
            >
                <Text style={[ResumeVersion.previewSubheaderTitle]}>
                    {" "}
                    Resume Intro
                </Text>
                <Text
                    style={[
                        ResumeVersion.previewSubheaderSubtitle, { marginLeft: 45 }
                    ]}
                >
                    {" "}
                    Time Spent:{" "}
                </Text>
                <Text style={[ResumeVersion.timeText, { marginLeft: 10 }]}>
                    {resumeData?.time_spent}
                </Text>
                <Text style={[ResumeVersion.timeText, { marginLeft: 5 }]}>Mins</Text>
            </View>


            {/* content border box starts */}
            <View style={[ResumeVersion.borderBox]}>
                <View style={[ResumeVersion.resumeRatingHeadingText]}><Text>Resume Rating</Text></View>


                {/* rating box starts */}
                <View
                    style={[
                        ResumeVersion.rowstyle,
                        { marginTop: 10, marginLeft: 20 },
                    ]}
                >

                    <View style={[ResumeVersion.ratingImageBox]}>
                        {rating(resumeData?.rating)}
                    </View>
                    <View style={[ResumeVersion.ratingNumber]}>
                        <Text>
                            ({resumeData?.rating}/
                            <span style={[{ color: "#8F8F8F" }]}>10</span>)
                        </Text>
                    </View>
                    {/* rating box ends */}
                </View>

            <View>
                {/* heading section starts */}
                <View style={[ResumeVersion.headingBox]}>
                    <View>
                        <Text
                            style={[
                                ResumeVersion.headingText,
                                { marginLeft: "15" },
                            ]}
                        >
                            Experience
                        </Text>
                    </View>
                    <View
                        style={[
                            ResumeVersion.vertical,
                            { marginLeft: "250" },
                        ]}
                    />

                    {isResumeV3Version(interviewDetails) ? 
                            <>
                                <View>
                                    <Text
                                        style={[
                                            ResumeVersion.headingText,
                                            { marginLeft: "10" },
                                        ]}
                                    >
                                        Resume Discussion
                                    </Text>
                                </View>
                            </>

                     : <>
                    <View>
                        <Text
                            style={[
                                ResumeVersion.headingText,
                                { marginLeft: "10" },
                            ]}
                        >
                            Tech Stack Knowledge
                        </Text>
                    </View>
                    <View
                        style={[
                            ResumeVersion.vertical,
                            { marginLeft: "230" },
                        ]}
                    />
                    <View>
                        <Text
                            style={[
                                ResumeVersion.headingText,
                                { marginLeft: "10" },
                            ]}
                        >
                            Relevant  Projects
                        </Text>
                    </View>

                    </>

                    }
                   
                    {/* heading section ends */}
                </View>


                {/* explanation box starts */}
                <View style={[ResumeVersion.explanationParentBox]}>

                    {/* Experience starts */}
                    <View style={[ResumeVersion.width33]}>
                        <View
                            style={[
                                ResumeVersion.explanationBox,
                                { marginLeft: 35 },
                            ]}
                        >
                            {table(resumeData?.experience, "")}
                        </View>
                        {/* Experience ends */}
                    </View>

  
                    {isResumeV3Version(interviewDetails) 
                    ? 
                    <>                    
                    {/* Relevant starts */}
                    <View style={[ResumeVersion.width60]}>
                        <View
                            style={[
                                ResumeVersion.explanationBoxLongText,
                                { marginLeft: 60 },
                            ]}
                        >
                            {table(resumeData?.positives, "Positives")}
                            {table(resumeData?.neutrals, "Neutrals")}
                            {table(resumeData?.negatives, "Negatives")}

                        </View>
                        {/* Relevant ends */}
                    </View>

                    </>
                    : 
                    <>
                                           {/* TechStack starts */}
                    <View style={[ResumeVersion.width33]}>
                        <View
                            style={[
                                ResumeVersion.explanationBox,
                                { marginLeft: 30 },
                            ]}
                        >
                            {table(resumeData?.tech_stack_knowledge, "")}
                        </View>
                        {/* TechStack ends */}
                    </View>

                    {/* Relevant starts */}
                    <View style={[ResumeVersion.width33]}>
                        <View
                            style={[
                                ResumeVersion.explanationBox,
                                { marginLeft: 60 },
                            ]}
                        >
                            {table(resumeData?.comment?.positives, "Positives")}
                            {table(resumeData?.comment?.neutrals, "Neutrals")}
                            {table(resumeData?.comment?.negatives, "Negatives")}
                            {table(resumeData?.relevant_project, "")}

                        </View>
                        {/* Relevant ends */}
                    </View>

                    </>}
 
                    {/* explanation Box ends */}
                </View>

            </View>

                {/* comment box starts  */}
                <View style={[ResumeVersion.commentBox]}>
                    <View>
                        <Text style={[ResumeVersion.headingText, { marginTop: 10 }]}>
                            {resumeData?.comment?.comment?.length > 0 ? "Interviewer's Comments:" : ""}
                        </Text>
                    </View>
                    <View style={[ResumeVersion.commentTextBox]}>{comments(resumeData?.comment?.comment)}</View>

                    {/* comment box ends  */}
                </View>



                {/* content border box ends */}
            </View>
        </View>
    );
};

export default ResumeSection;