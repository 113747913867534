
import axios from "./axios";
// import from 'react-xml-parser';

export const downloadFile = (url, fileName) => {
  axios({
    url: `/download_file?${url}`, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    // var file = new File([new Blob([response.data])], "name");
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};


export const imageFile = (url) => {
  return axios({
    url: `/download_file?${url}`, //your url
    method: "GET",
    responseType: "blob", // important
  });
};

export const fileDownload = (url, HeaderValue) => {
  return axios(
    {
      method: "POST",
      url:`/utils/file/download?${url}`,
      data: {
        headers: HeaderValue,
      },
    }
  )
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err?.response || err;
  });
};


export const uploadFileV2 = async (file, payload) => {
  try {
    const response = await axios({
      url: `/utils/file/upload/${file.name}`, //your url
      method: "get",
      params: payload,
    });

    var formData = new FormData();

    Object.keys(response.data.fields).forEach((key) => {
      formData.append(key, response.data.fields[key]);
    });

    formData.append("file", file);

    const upload = await axios({
      url: response.data.url,
      method: "post",
      data: formData,
      headers: null
    });

    if (upload.status === 201) {
      return response;
    }
  } catch (err) {
  }
};

export const getFileURL = async (url) => {
  const response = await axios({
    url: `/utils/file/download?${url}`, //your url
    method: "GET",
  });
  return response.data;
};



export const openResume = async (link) =>  {
  try{
      if(link.includes('bucket_name')){
        await getFileURL(link).then((response)=>{
          window.open(response, '_blank')
        })
      }else{
        if(link.includes('www')){
          if(link.slice(0, 4) === 'http'){
            window.open(link, '_blank')
          } else{
            window.open('https://'+link, '_blank')
          }
        }
      }
  } catch(err){
  }
}
