import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import Html from 'react-pdf-html';

import completeIcon from "../../../../../assets/images/feedback/feedback-complete-icon.png"
import incompleteIcon from "../../../../../assets/images/feedback/feedback-incomplete-icon.png"
import starGolden from "../../../../../assets/images/feedback/star-golden.png"
import starGray from "../../../../../assets/images/feedback/star-grey.png"
import grayDot from "../../../../../assets/images/feedback/dot-blue.png"
import dot from "../../../../../assets/images/feedback/dot-black.png"
import { commonDownloadStyle } from "../Preview/Constant";


Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const TheoryQuestionsVersion = StyleSheet.create({
  rowstyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  previewSubheaderTitle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 13.5,
    letterSpacing: 1.1,
    textTransform: "uppercase",
    color: "#0d3270",
  },

  previewSubheaderSubtitle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11,
    lineHeight: "117.4%",
    letterSpacing: 0.3,
    color: "#374671",
  },

  borderBox: {
    border: " 1px solid #C7C7C7",
    borderRadius: 8,
    width: 1063,
    marginTop: 30,
    marginLeft: 20,
    paddingBottom: 25,
  },

  questionAskedColumn: {
    width: 1063,
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #C7C7C7",
  },

  questionText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: '12px',
    letterSpacing: 0.055,
    color: "#3D3D3D",
    marginLeft: "20px",
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    paddingRight: '10px',
    width: "95%",
  },

  problemLevelEasyBox: {
    width: 100,
    height: 19,
    backgroundColor: "#3EAD2C",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  problemLevelMediumBox: {
    width: 124,
    height: 19,
    backgroundColor: "#3D3D3D",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  problemLevelHardBox: {
    width: 101,
    height: 19,
    backgroundColor: "#B62C2C",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  problemLevelText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#FEFEFE",
  },

  ratingImageBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10",
  },

  statusBoxComplete: {
    width: 226,
    height: 25,
    border: "1px solid #3EAD2C",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 40,
  },

  statusBoxIncomplete: {
    width: 226,
    height: 25,
    border: "1px solid #B62C2C",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 40,
  },

  statusBoxPartial: {
    width: 273,
    height: 25,
    border: "1px solid #3D3D3D",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 40,
  },

  ratingNumber: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: 0.025,
    marginLeft: 10,
    marginTop: 5,
  },

  statusTextComplete: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#3EAD2C",
    marginLeft: 3,
  },

  statusTextIncomplete: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#B22B2B",
    marginLeft: 5,
  },

  statusTextPartial: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#3D3D3D",
    marginLeft: 6,
  },

  explanationParentBox: {
    display: "flex",
    flexDirection: "row",
  },

  explanationBox: {
    marginLeft: 35,
    marginTop: 17,
    width: 330,
    paddingRight: 18
  },

  explanationBoxInternal: {
    display: "flex",
    flexDirection: "row",
  },

  explanationText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#3D3D3D",
    marginLeft: 10,
  },

  explanationTextNone: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: 0.025,
    color: "#8FAECF",
    marginLeft: 10,
  },

  headingBox: {
    display: "flex",
    flexDirection: "row",
    width: 1021,
    height: 41,
    backgroundColor: "#F5F8FF",
    marginLeft: 20,
    borderRadius: 6,
    marginTop: 25,
    alignItems: "center",
  },

  headingText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#374671",
  },

  vertical: {
    borderRight: "1px solid #BCC7E6",
    height: 40,
  },

  codingPart: {
    fontFamily: "Poppins",
    fontStyle: 'italic',
    backgroundColor: '#000',
    fontSize: 10,
    lineHeight: '132%',
    letterSpacing: 0.3,
    color: '#fff',
    padding: 16,
    margin: '10',
    fontSize: '13px',
    fontWeight: 600,
},
  width33: {
    width: "33%",
  }
});

const TheorySection = ({ theoryData }) => {

  const rating = (data) => {
    let rating = data;
    let count = 0;
    let arr = [];

    if (count < 10) {
      while (count < rating) {
        arr.push(
          <View>
            <Image
              src={starGolden}
              style={[{ width: 20, height: 20, marginLeft: 5 }]}
            />
          </View>
        );
        count++;
      }

      while (count < 10) {
        arr.push(
          <View>
            <Image
              src={starGray}
              style={[{ width: 20, height: 20, marginLeft: 5 }]}
            />
          </View>
        );
        count++;
      }
    }
    return arr;
  };

  const table = (data, status) => {
    return (
      <View>
        {data.length == 0 ? (
          <View style={[TheoryQuestionsVersion.explanationBoxInternal, { marginLeft: -15 }]}>
            <Image
              src={grayDot}
              style={[{ width: 5, height: 5, marginTop: 12 }]}
            />
            <Text
              style={[
                TheoryQuestionsVersion.explanationTextNone,
                { marginTop: 5 },
              ]}
            >
              None
            </Text>
          </View>
        ) : (
          <>
            {data.map((pos, index) => {
              const objKeys = Object.keys(pos);

              const objValue = Object.values(pos);

              return (
                <View>
                  <View style={[TheoryQuestionsVersion.explanationBoxInternal, { marginLeft: -10 }]}>
                    <Image
                      src={
                        status === "positives"
                          ? completeIcon
                          : status === "neutrals"
                            ? dot
                            : incompleteIcon
                      }
                      style={
                        status === "positives"
                          ? [{ width: 15, height: 15 }, { marginTop: 5 }]
                          : status === "neutrals"
                            ? [{ width: 5, height: 5 }, { marginTop: 12 }]
                            : [{ width: 8, height: 8 }, { marginTop: 10 }]
                      }
                    />
                    <Text
                      style={[
                        TheoryQuestionsVersion.explanationText,
                        { marginTop: 5 },
                      ]}
                    >
                      {objKeys}
                    </Text>
                  </View>
                  <View>
                    {objValue[0].map((item) => {
                      if(item && item?.length>0)
                      return (
                        <View style={[{marginLeft: 20, display : "flex", flexDirection: "row"}]}>
                          <View><Text >-</Text></View>

                          <View style={[{marginTop: 3}]}>
                              <Text
                                style={[
                                  TheoryQuestionsVersion.explanationText]}
                              >
                                {item}
                              </Text>
                          </View>
                        
                        
                        </View>
                      );
                    })}
                  </View>
                </View>
              );
            })}
          </>
        )}
      </View>
    );
  };

  return (
    <View
      style={[
        {
          margin: 15,
          border: "1 solid #cdcdcd",
          backgroundColor: "#fff",
          paddingBottom: 100,
        },
      ]}
    >
      {/* heading section */}

      <View
        style={[
          TheoryQuestionsVersion.rowstyle,
          {
            width: "100%",
            backgroundColor: "#C5D4FF",
            minHeight: 42,
            padding: 15,
          },
        ]}
      >
        <Text style={[TheoryQuestionsVersion.previewSubheaderTitle]}>
          {" "}
          Theory Questions
        </Text>
        <Text
          style={[
            TheoryQuestionsVersion.previewSubheaderSubtitle,
            { margin: "0 15" },
          ]}
        >
          {" "}
          Time Spent:{" "}
          {theoryData?.summary?.time_spent} mins
        </Text>
      </View>

      <View>
        {theoryData?.questions &&
          theoryData?.questions?.map(
            (data, index) => {
              return (
                //    map return parent view box starts
                <View>
                  {/* questions area parentBox starts */}
                  <View>
                    <View style={[TheoryQuestionsVersion.borderBox]}>
                      {/* question aske box starts */}
                      <View
                        style={[TheoryQuestionsVersion.questionAskedColumn]}
                      >

                        <View style={[TheoryQuestionsVersion.questionText]}>
                          <Text style={[(data?.question_statement || data?.question_asked)?.length > 0 && (data?.question_statement || data?.question_asked)[0] === '<' ? { marginTop: '12px' } : { marginTop: 0 }]}> {index + 1}. </Text>
                          <View style={{width:"70%"}}>
                          <Html style={{ fontSize: "12px" }}>
                            {data?.question_statement ? data?.question_statement+commonDownloadStyle : data?.question_asked ? data?.question_asked+commonDownloadStyle : ''}
                          </Html>
                          </View>
                        </View>

                        {/* question asked box ends */}
                      </View>

                      {/* rating box  starts*/}
                      <View
                        style={[
                          TheoryQuestionsVersion.rowstyle,
                          { marginTop: 25, marginLeft: 20 },
                        ]}
                        wrap= {false}
                      >
                        <View
                          style={
                            data?.difficulty?.tag === "easy"
                              ? [TheoryQuestionsVersion.problemLevelEasyBox]
                              : data?.difficulty?.tag === "medium"
                                ? [TheoryQuestionsVersion.problemLevelMediumBox]
                                : [TheoryQuestionsVersion.problemLevelHardBox]
                          }
                        >
                          <Text
                            style={[TheoryQuestionsVersion.problemLevelText]}
                          >
                            {data?.difficulty?.text}
                          </Text>
                        </View>
                        <View style={[TheoryQuestionsVersion.ratingImageBox]}>
                          {rating(data?.rating)}
                        </View>
                        <View style={[TheoryQuestionsVersion.ratingNumber]}>
                          <Text>
                            ({data?.rating}/
                            <span style={[{ color: "#8F8F8F" }]}>10</span>)
                          </Text>
                        </View>
                        <View
                          style={
                            data?.comment?.tag === "positive"
                              ? [TheoryQuestionsVersion.statusBoxComplete]
                              : data?.comment?.tag === "negative"
                                ? [TheoryQuestionsVersion.statusBoxIncomplete]
                                : [TheoryQuestionsVersion.statusBoxPartial]
                          }
                        >
                          <View>
                            <Image
                              src={
                                data?.comment?.tag === "positive"
                                  ? completeIcon
                                  : data?.comment?.tag === "negative"
                                    ? incompleteIcon
                                    : dot
                              }
                              style={
                                data?.comment?.tag === "positive"
                                  ? [{ width: 15, height: 15 }]
                                  : data?.comment?.tag === "negative"
                                    ? [{ width: 8, height: 8 }]
                                    : [{ width: 5, height: 5 }]
                              }
                            />
                          </View>
                          <View>
                            <Text
                              style={
                                data?.comment?.tag === "positive"
                                  ? [TheoryQuestionsVersion.statusTextComplete]
                                  : data?.comment?.tag === "negative"
                                    ? [
                                      TheoryQuestionsVersion.statusTextIncomplete,
                                    ]
                                    : [TheoryQuestionsVersion.statusTextPartial]
                              }
                            >
                              {data?.comment?.text}
                            </Text>
                          </View>
                        </View>
                        {/* rating box ends */}
                      </View>
                      
                    <View wrap = {false}>
                      {/* heading section starts */}
                      <View style={[TheoryQuestionsVersion.headingBox]}>
                        <View>
                          <Text
                            style={[
                              TheoryQuestionsVersion.headingText,
                              { marginLeft: "15" },
                            ]}
                          >
                            Positives
                          </Text>
                        </View>
                        <View
                          style={[
                            TheoryQuestionsVersion.vertical,
                            { marginLeft: "270" },
                          ]}
                        />
                        <View>
                          <Text
                            style={[
                              TheoryQuestionsVersion.headingText,
                              { marginLeft: "10" },
                            ]}
                          >
                            Neutrals
                          </Text>
                        </View>
                        <View
                          style={[
                            TheoryQuestionsVersion.vertical,
                            { marginLeft: "300" },
                          ]}
                        />
                        <View>
                          <Text
                            style={[
                              TheoryQuestionsVersion.headingText,
                              { marginLeft: "10" },
                            ]}
                          >
                            Negatives
                          </Text>
                        </View>
                        {/* heading section ends */}
                      </View>

                      {/* explanation box starts */}
                      <View
                        style={[TheoryQuestionsVersion.explanationParentBox]}
                      >
                        {/* positive starts */}
                        <View style={[TheoryQuestionsVersion.width33]}>
                          <View style={[TheoryQuestionsVersion.explanationBox]}>
                            {table(data?.positives, "positives")}
                          </View>
                          {/* positive ends */}
                        </View>

                        {/* neutral  starts*/}
                        <View style={[TheoryQuestionsVersion.width33]}>
                          <View style={[TheoryQuestionsVersion.explanationBox]}>
                            <View>{table(data?.neutrals, "neutrals")}</View>
                          </View>
                          {/* neutral ends*/}
                        </View>

                        {/* negative starts*/}
                        <View style={[TheoryQuestionsVersion.width33]}>
                          <View style={[TheoryQuestionsVersion.explanationBox]}>
                            <View>{table(data?.negatives, "negatives")}</View>
                          </View>
                          {/* negative ends */}
                        </View>

                        {/* explanation box ends */}
                      </View>
                    </View>  

                    <Text style={[( data?.solution_code && data?.solution_code?.trim() !== "") ? TheoryQuestionsVersion.codingPart : { height: "0px" }]}> {data?.solution_code || ""} </Text>

                    </View>

                    {/* questions area parentBox ends */}
                  </View>

                  {/* map return parent view box ends */}
                </View>
              );
            }
          )}
      </View>
    </View>
  );
};

export default TheorySection;