import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from "react-select";
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import './ResultClarity.scss'

const removeSeparator = (provided, state) => ({
    ...provided,
    display: 'none', // Hide the separator
  });

// Inline style object to set the height
const customSelectStyle = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '30px',
      height: '20px',
      paddingBottom: '10px'
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: '200px', 
      border: 'none'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#ACACAC',
        fontSize: '14px', 
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#ACACAC',
        paddingBottom: '5px',
        fontSize: '14px', 
      }),
    indicatorSeparator: removeSeparator, 
  };

const ResultClarity = ({ ratingsList, handleRating, canChangeRatingLabel = false , image, handleResultCriteria, isPreview}) => {

    const [rating, setRating] = useState({ label: ratingsList[2]?.label, value: ratingsList[2]?.type });
    const options = [{
        label: 'Proceed', value: 'PROCEED'
    }, {
        label: 'Reject', value: 'REJECT'
    }];
    const handleBorderlineChange = (e) => {
        const copyData = [...ratingsList];
        copyData[2].label = e?.label;
        copyData[2].type = e?.value;
        handleRating(copyData);
        setRating(e);
    }

    function ratingBackground(type) {
        if (type === "STRONG_PROCEED") {
            return "proceed-box";
        } else if (type === "PROCEED") {
            return "proceed-box";
        } else if (type === "REJECT") {
            return "reject-box";
        } else if (type === "STRONG_REJECT") {
            return "reject-box"
        }
    }

    function ratingColor(type) {
        if (type === "STRONG_PROCEED") {
            return "result-text green-color width-18";
        } else if (type === "PROCEED") {
            return "result-text lime-color width-18";
        } else if (type === "REJECT") {
            return "result-text yellow-color width-18";
        } else if (type === "STRONG_REJECT") {
            return "result-text red-color width-18"
        }
    }

    return (
        <>
            <Box className='result-clarity-container'>
                <Box className='result-display'>
                    <Typography className='head-text width-10'>Rating</Typography>
                    <Typography className='head-text width-18'>Recommendation</Typography>
                    <Typography className='head-text'>Description</Typography>
                    {!isPreview &&  <img src={image} onClick={handleResultCriteria} alt="edit-icon" className='edit-result-clarity'/>}
                   
                </Box>
                {Object.values(ratingsList)?.map((resultData) => {
                    return (
                        <>

                            {(canChangeRatingLabel && resultData?.range?.from === 6) ?
                                <Box className='select-box'>
                                    <Typography className='result-text black-color mt-20'>{getRatingRange(resultData?.range)}</Typography>
                                    <Box className='width-18'>
                                        <Typography className='select-text'>Please assign a category for this borderline case</Typography>
                                        <Select
                                        styles={customSelectStyle}
                                            className='select-rating'
                                            options={options}
                                            value={{ label: rating?.label, value: rating?.value }}
                                            onChange={(e) => handleBorderlineChange(e)}
                                        />
                                    </Box>
                                    <Typography className='description-label mt-10'>{resultData?.hint}</Typography>
                                </Box>
                                :
                                <Box className={ratingBackground(resultData?.type)}>
                                    <Typography className='result-text black-color'>{getRatingRange(resultData?.range)}</Typography>
                                    <Typography className={ratingColor(resultData?.type)}>{resultData?.label}</Typography>
                                    <Typography className='description-label'>{resultData?.hint}</Typography>
                                </Box>
                            }

                        </>
                    )
                })}
            </Box>
        </>
    );
}
export default ResultClarity;

export const getRatingRange = (range) => {
    let copyRange = { ...range };
    if (!copyRange.from) {
        copyRange.from = 1
        if (copyRange.to) {
            return (copyRange.to + "=>")
        }
    }
    if (!copyRange.to) {
        copyRange.to = 10
    }
    let rangeArray = [];
    for (let i = copyRange?.from; i <= copyRange?.to; i++) {
        // converting array to readable form 
        if ((copyRange?.from + 1 === copyRange?.to) || (i !== copyRange?.from && i === (copyRange?.to - 1))) {
            rangeArray.push(`${i + 1}, ${i}`);
            i++;
        }
        else rangeArray.push(i)
    }
    return rangeArray.toString();
}