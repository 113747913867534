import React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "./SideComponent.scss";

const SideComponent = ({
  candidateDetails,
  roundDetails,
  positionDetails,
  clientLogo,
  captureImage,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box className="side-component-parent">
        <Box className="logo-section">
          <img src={clientLogo} className="logo-section" />
        </Box>
        <Box className="details-section">
          <Typography className="heading">CANDIDATE NAME</Typography>
          <Typography className="details">{candidateDetails?.name}</Typography>
          <Typography className="heading mt-20">POSITION</Typography>
          <Typography className="details">
            {positionDetails?.position_name}
          </Typography>
          {roundDetails?.name &&
          <>
          <Typography className="heading mt-20">ROUND</Typography>
          <Typography className="details">{roundDetails?.name}</Typography>
          </>
          }
        </Box>
      </Box>
      {captureImage?.length > 0 && (
        <Box className="side-component-image-section">
          <Typography className="heading">Camera Shot</Typography>
          {captureImage?.map((data, index) => {
            return (
              <Box
                borderBottom={
                  index !== captureImage.length - 1 ? "1px solid #dadada" : ""
                }
                paddingBottom="16px"
                display="flex"
                flexDirection="column"
                gap="4px"
                marginTop={index === 0 ? "8px" : "16px"}
                marginBottom="4px"
              >
                {data?.timestamp ? (
                  <Typography
                    marginLeft="8px"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="500"
                  >
                    {moment(data?.timestamp * 1000).format("hh:mm A")}
                  </Typography>
                ) : null}
                <Box>
                  <img src={data?.imgSrc || data} className="camera-shot" />
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SideComponent;
