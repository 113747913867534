import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const stylesSkillsRatingBody = StyleSheet.create({
  SkillsHeading: {
    marginTop: 25,
    width: 310,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: 0.005,
    color: "#123FBC",
  },

  skillsRowHeading: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0.005,
  },

  skillsType: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: 0.005,
    color: "#3D3D3D",
    marginTop: 17,
  },

  skillsRating: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: 0.005,
    marginTop: 17,
  },

  tableRowsHeading: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  tableRowsColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const SkillRatings = ({ data }) => {
  const giveInitialRating = (evaluation_ratings, data) => {
    let rating = "";

    data?.audit_report?.skill_ratings?.forEach((item) => {
      if (item?.name === evaluation_ratings) {
        rating = item?.value;
      }
    });

    if (rating !== "" && rating !== null) {
      return rating;
    } else {
      return "NA";
    }
  };

  return (
    <View
      style={[
        {
          border: "1 solid #cdcdcd",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          paddingBottom: 50,
        },
      ]}
    >
      <View>
        <Text style={[stylesSkillsRatingBody.SkillsHeading]}>
          Skill Ratings
        </Text>
      </View>
      
      <View style={[stylesSkillsRatingBody.tableRowsHeading]}>
        <Text style={[  stylesSkillsRatingBody.skillsRowHeading, { color: "#848484",  width: 350}]}>
          Skills
        </Text>
        <Text style={[ stylesSkillsRatingBody.skillsRowHeading, { color: "#848484",  width: 150, textAlign:'center'}]}>
          Initial Ratings
        </Text>
        <Text style={[ stylesSkillsRatingBody.skillsRowHeading, { color: "#6F84B4", width: 150, textAlign:'center'}]}>
          Ratings after Audit
        </Text>
      </View>

      <View>
          {Array.isArray(data?.audit_details?.interviewer_feedback?.evaluation_ratings) 
          ? data?.audit_details?.interviewer_feedback?.evaluation_ratings?.map(
            (item, index) => {
              return (
                <>
                {(item?.rating !== '' && item?.rating !== null) &&
                <View style={[stylesSkillsRatingBody.tableRowsColumns]}>
                  <Text style={[stylesSkillsRatingBody.skillsType , {width: 350}]}>
                      {item?.type}
                  </Text>
                  <Text style={[ stylesSkillsRatingBody.skillsRating, {width: 150, textAlign:'center'}]} >
                      {item?.rating ? item?.rating : "NA"}
                  </Text>
                  <Text style={[  stylesSkillsRatingBody.skillsRating,  {color: "#123FBC" , width: 150, textAlign:'center'}]}>
                      {giveInitialRating(item, data)}
                  </Text>
                </View>
                }
                </>
              );
            }
          )
          : 

          Object.keys(data?.audit_details?.interviewer_feedback?.evaluation_ratings || {})?.map(
            (item, index) => {
              return (
                <>
                {(data?.audit_details?.interviewer_feedback?.evaluation_ratings?.[item]?.rating !== '' && data?.audit_details?.interviewer_feedback?.evaluation_ratings?.[item]?.rating !== null) &&
                <View style={[stylesSkillsRatingBody.tableRowsColumns]}>
                  <Text style={[stylesSkillsRatingBody.skillsType , {width: 350}]}>
                      {item}
                  </Text>
                  <Text style={[ stylesSkillsRatingBody.skillsRating, {width: 150, textAlign:'center'}]} >
                      {data?.audit_details?.interviewer_feedback?.evaluation_ratings?.[item]?.rating ? data?.audit_details?.interviewer_feedback?.evaluation_ratings?.[item]?.rating : "NA"}
                  </Text>
                  <Text style={[  stylesSkillsRatingBody.skillsRating,  {color: "#123FBC" , width: 150, textAlign:'center'}]}>
                      {giveInitialRating(item, data)}
                  </Text>
                </View>
                }
                </>
              );
            }
          )
          
          }
      </View>
      {/* skills ratings ends here */}
    </View>
  );
};

export default SkillRatings;
