import Box from "@material-ui/core/Box";
import Lottie from "react-lottie";
import loader from "./loader-default.json";
import "./defaultLoader.scss";

const loaderDimension = "300px" // Loader is bound in a square

const DefaultLoader = ({ text, LoadingAnimation, isLoading }) => {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation || loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    isLoading ?
      <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' className="loader-wrapper">
        <Lottie
          options={LoadingOptions}
          height={loaderDimension}
          width={loaderDimension}
          isClickToPauseDisabled
        />
        {text && <Box className="default-loader-text">{text}</Box>}
      </Box>
      : null
  )
};

export default DefaultLoader;
