import React, { useState } from "react";
import cogoToast from "cogo-toast";
import {resetPassword} from '../../services/auth'

const ResetPassword = (props) => {

  const forgot_password_uuid = props.computedMatch.params.id;
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [showPassword, setShowPassword] = useState([false, false]);
  const [errorPassword, setErrorPassword] = useState(['', '']);
  const [loading, setLoading] = useState(false);

  const changePassword = async (e) => {
    e.preventDefault();
    if(errorPassword[0] !== '' || errorPassword[1] !== ''){
      return;
    }
    if(password !== password1) return;
    if(password.length < 8) return;
    try {
      setLoading(true);
      const req_body = {
        email,
        forgot_password_uuid,
        new_password: password,
      }
      await resetPassword(req_body)
      cogoToast.success('Your password has been successfully changed')
      setLoading(false);
      props.history.push('/login')
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
            <div className="text-center">
              <img
                src="/assets/img/illustrations/happiness.svg"
                alt="..."
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-10 col-md-5 col-xl-4 order-md-1 my-5">
            <h1 className="display-4 text-center mb-3">Reset Password</h1>
            <p className="text-muted text-center mb-5">
              Please enter a new password.
            </p>
            <form>
            <div className="form-group">
              <input
                className = 'form-control'
                value = {email}
                onChange = {e => setEmail(e.target.value)}
              />
            </div>
            
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>New Password</label>
                </div>
              </div>
              
              <div className="input-group input-group-merge">
                <input
                  type={showPassword[0] ? 'text' : 'password'}
                  className={`form-control form-control-appended ${errorPassword[0] !== '' ? "is-invalid" : ""}`}
                  placeholder={"New Password"}
                  value={password}
                  onChange={(e) => {
                    setErrorPassword(['', ''])
                    setPassword(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text" onClick={() => {const arr = [...showPassword]; arr[0] = !arr[0]; setShowPassword(arr)}}>
                    {!showPassword[0] ? (<i className="far pointer fa-eye"></i>) : (<i className="far pointer fa-eye-slash"></i>)}
                  </span>
                </div>
                <div className="invalid-feedback">{errorPassword[0]}</div>
              </div>
            </div>



  
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>Re-enter New Password</label>
                </div>
              </div>
              
              <div className="input-group input-group-merge">
                <input
                  type={showPassword[1] ? 'text' : 'password'}
                  className={`form-control form-control-appended ${errorPassword[1] !== '' ? "is-invalid" : ""}`}
                  placeholder={"New Password"}
                  value={password1}
                  onChange={(e) => {
                    setErrorPassword(['', ''])
                    setPassword1(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text" onClick={() => {const arr = [...showPassword]; arr[1] = !arr[1]; setShowPassword(arr)}}>
                    {!showPassword[1] ? (<i className="far pointer fa-eye"></i>) : (<i className="far pointer fa-eye-slash"></i>)}
                  </span>
                </div>
                <div className="invalid-feedback">{errorPassword[1]}</div>
              </div>
            </div>
            

              <button disabled = {errorPassword[0] !== '' || errorPassword[1] !== ''} className="btn btn-lg btn-block btn-primary mb-3" onClick={(e) => changePassword(e)}>
                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Reset Password
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
