import React, {useState, useCallback, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';

const MyDropzone = ({onFileChange, height, isMulti, type}) => {
    
    const [fileName, setFileName] = useState();
    
    const onDrop = useCallback(acceptedFiles => {
        setFileName(acceptedFiles.map(item => item.path).join(', '))
        onFileChange(acceptedFiles)
    }, [onFileChange])
    
    const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({onDrop, accept: {
        'application/pdf': ['.doc', '.pdf', '.docx']
      }, isMulti})

    const style = useMemo(() => {
        return {
            ...{...baseStyle, minHeight: height || '20vh'},
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }
        // eslint-disable-next-line
    }, [ isDragActive, isDragReject, isDragAccept ]);

    return(
        <div className = 'form-group mb-0 drag-drop'  {...getRootProps({style})}>
            <input  {...getInputProps()} className={`dropzonee`}/>
                {isDragActive ?
                    <p className = 'mb-0'>Drop your resume here ...</p> :
                    fileName ? fileName : <> <BackupOutlinedIcon fontSize="large"/> <p className = 'mb-0 ml-3'> Drag 'n' drop your resume here, or click to select file [PDF, Doc, Docx format]</p> </>
                }
        </div>
    )
}

export default MyDropzone;


  
const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};
  
const activeStyle = {
    borderColor: '#2196f3'
};
  
const acceptStyle = {
    borderColor: '#00e676'
};
  
const rejectStyle = {
    borderColor: '#ff1744'
};