import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import "./PreScreenHeader.scss";
import ivLogo from "../../../assets/images/iv-logo-white.svg";
import PrescreenReportDownload from '../../pages/PreScreening/PrescreenReportDownload/PrescreenReportDownload';
import {PDFDownloadLink} from "@react-pdf/renderer";
import DownloadIcon from '@mui/icons-material/Download';
const PreScreenHeader = ({
  value,
  candidateDetails,
  clientLogo,
  position,
  positionDetails,
  roundDetails,
  candidatePrescreeningRoundDetails,
  reportQuestions,
  captureImage,
  prescreenQuestion,
  isDownloadBtnDisabled,
  prescreenQuesImage,
}) => {
  return (
        <Box className='header-parent'>
              <Box width="fit-content">
                <img src={ivLogo} alt="Interview Vector logo" className='image' />
              </Box>  
              <Typography className='header-text'>{value}</Typography>
              {
                candidatePrescreeningRoundDetails?.candidate_result_info?.result ?
             <Box width="fit-content"  className={`${isDownloadBtnDisabled && "disable-btn"}`}>
                <PDFDownloadLink
                document={<PrescreenReportDownload
                  candidateDetails={candidateDetails}
                  roundDetails={roundDetails}
                  position={position}
                  positionDetails={positionDetails}
                  clientLogo={clientLogo}
                  captureImage={captureImage}
                  candidatePrescreeningRoundDetails={candidatePrescreeningRoundDetails}
                  reportQuestions={reportQuestions}
                  prescreenQuestion={prescreenQuestion}
                  prescreenQuesImage={prescreenQuesImage}
                  />} 
                  fileName={`${candidateDetails?.name}-quickscreen-report.pdf`}>
                {({ blob, url, loading, error }) => {
                    return <>
                      {
                        !loading ? ( 
                          <Button className='download-btn'>
                          Download Report&nbsp;<DownloadIcon fontSize="small"/>
                        </Button> ) :
                          <Box display="flex" justifyContent="flex-end">
                           <Button className='download-btn' disabled>
                           Download Report&nbsp;<DownloadIcon fontSize="small"/>
                          </Button>               
                </Box>
                      }
                                            
                    </>
                  }
                }
              </PDFDownloadLink>
              </Box>
              :null
}
        </Box>

  )
}

export default PreScreenHeader;