import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './ResultPreviewPopup.scss';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { addInterviewPreview } from '../../services/schedule';
import { useState } from 'react';

export default function ResultPreviewPopup({item, previewOpen, setPreviewOpen, setShowScheduleData}) {
  const [resultSelected, setResultSelected] = useState('');
  const handleClose = () => { setResultSelected('');  setPreviewOpen(false);}

  const handleSeletedButton = (newValue) => {
    setResultSelected(newValue);
  }
  const confirmSubmit = () => {
    if(resultSelected !== ''){
      fetchPreview();
      handleClose();
    }
  }

  const fetchPreview = async() => {
    try{
      const resp = await addInterviewPreview(item?.interview_details?.id,resultSelected);
      setShowScheduleData(true);
    }
    catch(err){
    }
  }
 
  return (
    <div className='popUpBox'>
      <Modal
        open={previewOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='resultPopup'>
          <Box  className='resultHeading'>
            <Typography className='headingText' variant="h6" component="h2">
              Submit the tentative Interview Result (Preview)
            </Typography>
            <CloseRoundedIcon onClick={handleClose}/>
          </Box>
          <Box className='resultBody'>
            <Typography className='resultText'>
               If this interview has successfully ended, please share below the tentative result of the candidate ASAP.
            </Typography>
            <Typography className='warningText'>
              <Typography  className='resultRedText' style={{fontWeight:'600'}}>*Note:</Typography>
              <Typography className='resultRedText'> 
                This is just an initial preview of the result, please fill the detailed feedback
                within 0-2 hrs.
              </Typography> 
            </Typography>
            <Box  className='selectBox'>
               <Box className={ resultSelected === 'STRONG_REJECT' ? 'buttonBox darkRedBox' : 'buttonBox grayBox'} onClick={()=> handleSeletedButton("STRONG_REJECT")}>Strong Reject</Box>
               <Box className={ resultSelected === 'REJECT' ? 'buttonBox redBox' : 'buttonBox grayBox' } onClick={()=> handleSeletedButton("REJECT")}>Reject</Box> 
               <Box className={ resultSelected === 'PROCEED' ? 'buttonBox greenBox' : 'buttonBox grayBox' } onClick={()=> handleSeletedButton("PROCEED")}>Proceed</Box>
               <Box className={ resultSelected === 'STRONG_PROCEED' ? 'buttonBox darkGreenBox' : 'buttonBox grayBox' } onClick={()=> handleSeletedButton("STRONG_PROCEED")}>Strong Proceed</Box>
            </Box>
            
            {resultSelected === '' ? <Button disabled className='resultConfirm blur'>Confirm</Button> : 
            <Button variant="contained" className='resultConfirm' onClick={confirmSubmit}>Confirm</Button>}

          </Box>

          
          
        </Box>
      </Modal>
    </div>
  );
}