import {
  FeedbackType, showCodingQuestions, isCodingNonMandatory, isCodingQuestionsFilled,
  isTheoryQuestionFilled, showProblemSolvingCodingFields, isFlipkartFeedbackDesign, isV2SectionFilled, validateDataAccordingToFieldType, isCodingV2Version, isResumeV2Version, isSummaryV2Versions, isHLDDesignV2Versions, isLLDDesignV2Versions,isInDepthKnowledgeV2Versions, isTheoryV2Version, StepperKeyName, isV2Version
} from "./../Constants/FeedbackConstants";
import { stepperStatus } from "./../Components/Stepper";

export const Validation = (
  resumeIntroData,
  codingQuestionData,
  theoryQuestionsData,
  feedbackSummaryData,
  steps,
  setSteps,
  feedbackType,
  interviewDetails
) => {
  let errorMessage = "";
  let theoryCodingBothEmptyMessage = "";


  steps?.map((step, index) => {
    switch (step?.section) {
      case StepperKeyName.RESUME_INTRO:
        errorMessage += validateResumeIntro(interviewDetails, resumeIntroData, steps, index, setSteps);
        break;

      case StepperKeyName.CODING_QUESTIONS:
        errorMessage += validateCodingQuestion(interviewDetails, codingQuestionData, steps, index, setSteps, feedbackType, step?.section);
        break;

      case StepperKeyName.DESIGN_QUESTIONS:
      case StepperKeyName.HLD_QUESTIONS:
      case StepperKeyName.LLD_QUESTIONS:
      case StepperKeyName.INDEPTH_KNOWLEDGE:
        errorMessage += validateCodingQuestion(interviewDetails, codingQuestionData, steps, index, setSteps, feedbackType, step?.section);
        break;

      case StepperKeyName.THEORY_QUESTIONS:
        errorMessage += validateTheoryQuestion(interviewDetails, theoryQuestionsData, steps, index, setSteps);
        break;

      case StepperKeyName.FEEDBACK_SUMMARY:
        errorMessage += validateFeedbackSummary(interviewDetails, feedbackSummaryData, steps, index, setSteps);
        break;
    }
  })

  theoryCodingBothEmptyMessage =
    !((isTheoryV2Version(interviewDetails))
      ? isV2SectionFilled('theory', interviewDetails)
      : isTheoryQuestionFilled(theoryQuestionsData, feedbackType))
      &&
      !(isCodingV2Version(interviewDetails) ? isV2SectionFilled('coding', interviewDetails)
      : isHLDDesignV2Versions(interviewDetails) ? isV2SectionFilled('hld_design', interviewDetails)
      : isLLDDesignV2Versions(interviewDetails) ? isV2SectionFilled('lld_design', interviewDetails)
      : isInDepthKnowledgeV2Versions(interviewDetails) ? isV2SectionFilled('indepth_knowledge', interviewDetails)
      : isCodingQuestionsFilled(codingQuestionData, feedbackType, interviewDetails))
      ? "Please fill at least one of theory questions or coding questions section." : ""

  if (errorMessage) {
    return "Please fill the following fields before final submission: \n" + errorMessage + (theoryCodingBothEmptyMessage ? "\n\n" + theoryCodingBothEmptyMessage : "");
  } else return (theoryCodingBothEmptyMessage ? theoryCodingBothEmptyMessage : false);
};

export const validateResumeIntro = (interviewDetails, resumeIntroData, steps, index, setSteps) => {
  let resumeIntroError = false;
  let errorMessage = "\nResume Intro - ";


  if (isResumeV2Version(interviewDetails) && isV2Version(steps[index])) {
    const error = validateV2Section('resume', interviewDetails, resumeIntroError, errorMessage)
    resumeIntroError = error?.sectionQuestionError
    errorMessage = error?.errorMessage

  } else {
  
  if (resumeIntroData?.timeSpent === "") {
    resumeIntroError = true;
    errorMessage += "\n \u2022 Time Spent";
  }

  for (let i = 0; i < resumeIntroData?.experience?.length; i++) {
    const data = resumeIntroData?.experience[i];
    if (data?.trim() === "") {
      resumeIntroError = true;
      errorMessage += "\n \u2022 Experience";
    }
  }

  for (let i = 0; i < resumeIntroData?.projects?.length; i++) {
    const data = resumeIntroData?.projects[i];
    if (data?.trim() === "") {
      resumeIntroError = true;
      errorMessage += "\n \u2022 Projects";
    }
  }

  if (resumeIntroData?.techStack === "") {
    resumeIntroError = true;
    errorMessage += "\n \u2022 Tech Stack";
  }

  for (let i = 0; i < resumeIntroData?.extraComments?.length; i++) {
    const data = resumeIntroData?.extraComments[i];
    if (data?.trim() === "") {
      resumeIntroError = true;
      errorMessage += "\n \u2022 Extra Comments";
    }
  }

  }

  if (resumeIntroError) {
    let stepData = [...steps];
    stepData[index].status = stepperStatus.ERROR;
    setSteps(stepData);
    errorMessage += `\n`;
  }

  return resumeIntroError ? errorMessage : "";
};

export const validateCodingQuestion = (interviewDetails, codingQuestionData, steps, index, setSteps, feedbackType, section) => {
  let codingQuestionError = false;
  let errorMessage = "";

  if (showCodingQuestions(interviewDetails, codingQuestionData)) {
    errorMessage += "\nCoding Questions - ";
  } else {
    errorMessage += "\nDesign Questions - ";
  }

  let isCodingQuesFilled = section === StepperKeyName.CODING_QUESTIONS && isCodingV2Version(interviewDetails) && isV2Version(steps[index]) ? isV2SectionFilled('coding', interviewDetails)
  : (section === StepperKeyName.DESIGN_QUESTIONS || section === StepperKeyName.HLD_QUESTIONS) && isHLDDesignV2Versions(interviewDetails) && isV2Version(steps[index]) ? isV2SectionFilled('hld_design', interviewDetails)
  : (section === StepperKeyName.DESIGN_QUESTIONS || section === StepperKeyName.LLD_QUESTIONS) && isLLDDesignV2Versions(interviewDetails) && isV2Version(steps[index]) ? isV2SectionFilled('lld_design', interviewDetails)
  : (section === StepperKeyName.DESIGN_QUESTIONS || section === StepperKeyName.INDEPTH_KNOWLEDGE) && isInDepthKnowledgeV2Versions(interviewDetails) && isV2Version(steps[index]) ? isV2SectionFilled('indepth_knowledge', interviewDetails)
  : isCodingQuestionsFilled(codingQuestionData, feedbackType, interviewDetails);

  if ( isCodingQuesFilled) {

    if (section === StepperKeyName.CODING_QUESTIONS && isCodingV2Version(interviewDetails) && isV2Version(steps[index]) ) {
      const error = validateV2Section('coding', interviewDetails, codingQuestionError, errorMessage)
      codingQuestionError = error?.sectionQuestionError
      errorMessage = error?.errorMessage

    } else  if ((section === StepperKeyName.DESIGN_QUESTIONS || section === StepperKeyName.HLD_QUESTIONS) && isHLDDesignV2Versions(interviewDetails) && isV2Version(steps[index])) {
      const error = validateV2Section('hld_design', interviewDetails, codingQuestionError, errorMessage)
      codingQuestionError = error?.sectionQuestionError
      errorMessage = error?.errorMessage

    } else  if ((section === StepperKeyName.DESIGN_QUESTIONS || section === StepperKeyName.LLD_QUESTIONS) && isLLDDesignV2Versions(interviewDetails) && isV2Version(steps[index])) {
      const error = validateV2Section('lld_design', interviewDetails, codingQuestionError, errorMessage)
      codingQuestionError = error?.sectionQuestionError
      errorMessage = error?.errorMessage

    } else  if ((section === StepperKeyName.DESIGN_QUESTIONS || section === StepperKeyName.INDEPTH_KNOWLEDGE) && isInDepthKnowledgeV2Versions(interviewDetails) && isV2Version(steps[index])) {
      const error = validateV2Section('indepth_knowledge', interviewDetails, codingQuestionError, errorMessage)
      codingQuestionError = error?.sectionQuestionError
      errorMessage = error?.errorMessage

    } else {

    for (let i = 0; i < codingQuestionData?.questions?.length; i++) {
      const data = codingQuestionData?.questions[i];
      let languageUsedError, spaceComplexityError, timeComplexityError, positiveError, negativeError, neutralsError, approachError, designProcessError, requirementGatheringError, highLevelDesignError, lowLevelDesignError,
        scalabilityError, dbchoicesError, cachingError, extraCommentsError = false;

      //Flipkart feedback error
      let assumptionsError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.assumptions.trim() === "" : false,
        heuristicsError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.heuristics.trim() === "" : false,
        entityModelError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.entityModel.trim() === "" : false,
        apiDesignError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.apiDesign.trim() === "" : false,
        concurrencyError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.concurrency.trim() === "" : false,
        subcomponentBreakdownError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.subcomponentBreakdown.trim() === "" : false,
        asyncSyncInteractionError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.asyncSyncInteraction.trim() === "" : false,
        designTradeoffsError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.designTradeoffs.trim() === "" : false,
        familiarityError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.familiarity.trim() === "" : false,
        correctnessError = isFlipkartFeedbackDesign(interviewDetails, data) ? data?.correctness.trim() === "" : false;


      if (!isFlipkartFeedbackDesign(interviewDetails, data) && showCodingQuestions(interviewDetails, codingQuestionData)) {

        if (showProblemSolvingCodingFields(interviewDetails, codingQuestionData)) {
          spaceComplexityError = data?.spaceComplexity.trim() === "";
          timeComplexityError = data?.timeComplexity.trim() === "";
        }

        if (showProblemSolvingCodingFields(interviewDetails, codingQuestionData)) {
          languageUsedError = (!data?.languageUsed || data?.languageUsed.trim() === "");
        }

        positiveError = validateArrayIfEmpty(data?.positives);
        negativeError = validateArrayIfEmpty(data?.negatives);
        neutralsError = validateArrayIfEmpty(data?.neutrals);
        approachError = validateArrayIfEmpty(data?.approach);

      } else if (!isFlipkartFeedbackDesign(interviewDetails, data)) {

        designProcessError = validateArrayIfEmpty(data?.designProcess);
        requirementGatheringError = validateArrayIfEmpty(data?.requirementGathering);
        scalabilityError = validateArrayIfEmpty(data?.scalability);
        dbchoicesError = validateArrayIfEmpty(data?.dbchoices);
        cachingError = validateArrayIfEmpty(data?.caching);
        extraCommentsError = validateArrayIfEmpty(data?.extraComments);

        if (feedbackType === FeedbackType.HIGH_LEVEL_DESIGN) {
          highLevelDesignError = validateArrayIfEmpty(data?.highLevelDesign);
        } else if (feedbackType === FeedbackType.LOW_LEVEL_DESIGN) {
          lowLevelDesignError = validateArrayIfEmpty(data?.lowLevelDesign);
        }
      }

      if (
        data?.questionAsked.trim() === "" ||
        data?.timeSpent === "" || (!data?.isQuestionCompleted || data?.isQuestionCompleted.trim() === "") || (!data?.isSolutionOptimal || data?.isSolutionOptimal.trim() === "") ||
        languageUsedError || spaceComplexityError || timeComplexityError || positiveError || negativeError || neutralsError || approachError
        || designProcessError || requirementGatheringError || highLevelDesignError || lowLevelDesignError || scalabilityError || dbchoicesError || cachingError || extraCommentsError
        || assumptionsError || heuristicsError || entityModelError || apiDesignError || concurrencyError || subcomponentBreakdownError
        || asyncSyncInteractionError || designTradeoffsError || familiarityError || correctnessError
      ) {
        codingQuestionError = true;
        errorMessage += `\n \u2022 Question #${i + 1} - Some fields are missing`;
      }

    }
  }

  if (codingQuestionError) {
    let stepData = [...steps];
    stepData[index].status = stepperStatus.ERROR;
    setSteps(stepData);
    errorMessage += `\n`;
  }
}
  return codingQuestionError ? errorMessage : "";
};

const validateArrayIfEmpty = (array) => {
  let error = false;
  for (let i = 0; i < array?.length; i++) {
    if (array[i]?.trim() === "") {
      error = true;
    }
  }
  return error;
}

export const validateTheoryQuestion = (interviewDetails, theoryQuestionsData, steps, index, setSteps) => {
  let theoryQuestionError = false;

  let errorMessage = "\nTheory Questions - ";

  let isTheoryQuesFilled = isTheoryV2Version(interviewDetails) && isV2Version(steps[index])
    ? isV2SectionFilled('theory', interviewDetails)
    : isTheoryQuestionFilled(theoryQuestionsData);

  if (isTheoryQuesFilled) {

    if (isTheoryV2Version(interviewDetails) && isV2Version(steps[index])) {
      const error = validateV2Section('theory', interviewDetails, theoryQuestionError, errorMessage)
      theoryQuestionError = error?.sectionQuestionError
      errorMessage = error?.errorMessage

    } else {
      if (theoryQuestionsData?.timeSpent === "") {
        theoryQuestionError = true;
        errorMessage += `\n \u2022 Time Spent`;
      }

      for (let i = 0; i < theoryQuestionsData?.questions?.length; i++) {
        const data = theoryQuestionsData?.questions[i];
        if (
          data?.questionAsked.trim() === "" ||
          data?.candidateResponse.trim() === "") {
          theoryQuestionError = true;
          errorMessage += `\n \u2022 Question #${i + 1} - Some fields are missing`;
        }
      }
    }

    if (theoryQuestionError) {
      let stepData = [...steps];
      stepData[index].status = stepperStatus.ERROR;
      setSteps(stepData);
      errorMessage += `\n`;
    }
  }
  return theoryQuestionError ? errorMessage : "";
};

export const validateFeedbackSummary = (interviewDetails, feedbackSummaryData, steps, index, setSteps) => {
  let feedbackSummaryError = false;
  let errorMessage = "\nFeedback Summary - ";


  if (isSummaryV2Versions(interviewDetails) && isV2Version(steps[index])) {
    const error = validateV2Section('summary', interviewDetails, feedbackSummaryError, errorMessage)
    feedbackSummaryError = error?.sectionQuestionError
    errorMessage = error?.errorMessage

  } else {
  

  for (let i = 0; i < feedbackSummaryData?.highlights?.length; i++) {
    const data = feedbackSummaryData?.highlights[i];
    if (data?.trim() === "") {
      feedbackSummaryError = true;
      errorMessage += "\n \u2022 Highlights";
    }
  }


  for (let i = 0; i < feedbackSummaryData?.lowlights?.length; i++) {
    const data = feedbackSummaryData?.lowlights[i];
    if (data?.trim() === "") {
      feedbackSummaryError = true;
      errorMessage += "\n \u2022 Lowlights";
    }
  }

  for (let i = 0; i < feedbackSummaryData?.scopeOfImprovement?.length; i++) {
    const data = feedbackSummaryData?.scopeOfImprovement[i];
    if (data?.trim() === "") {
      feedbackSummaryError = true;
      errorMessage += "\n \u2022 Scope Of Improvement";
    }
  }

  for (let i = 0; i < feedbackSummaryData?.extraComments?.length; i++) {
    const data = feedbackSummaryData?.extraComments[i];
    if (data?.trim() === "") {
      feedbackSummaryError = true;
      errorMessage += "\n \u2022 Extra Comments";
    }
  }

  let skillsSelected = false;
  for (let i = 0; i < feedbackSummaryData?.skillEvaluation?.length; i++) {
    if (feedbackSummaryData?.skillEvaluation[i]?.rating) {
      skillsSelected = true;
    }
  }

  if (!skillsSelected) {
    feedbackSummaryError = true;
    errorMessage += `\nFill at least one skill from skill evaluation \n`;
  }

  if (feedbackSummaryData?.rating === '') {
    feedbackSummaryError = true;
    errorMessage += `\n Fill the Rating first`;
  }

 }

  if (feedbackSummaryError) {
    let stepData = [...steps];
    stepData[index].status = stepperStatus.ERROR;
    setSteps(stepData);
  }

  return feedbackSummaryError ? errorMessage : "";
};

const validateV2Section = (section, interviewDetails, sectionQuestionError, errorMessage) => {
  let rawForm = interviewDetails?.feedback_pages?.raw_form?.[section];
  let rawResponse = interviewDetails?.feedback_pages?.raw_response?.[section];

  rawForm?.summary?.field_order?.map((data) => {
    if (rawForm?.summary?.fields?.[data]?.nested) {
      rawForm?.summary?.fields?.[data]?.field_info?.nested_fields_order?.map((nestedField, nestedIndex) => {
        if (rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.required && !validateDataAccordingToFieldType(rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.field_type, rawResponse?.summary?.[data]?.[nestedField])) {
          sectionQuestionError = true;
          errorMessage += `\n \u2022 ${rawForm?.summary?.fields[data]?.field_info?.heading} - ${rawForm?.summary?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.question}`;
        }
      })
    } else if (rawForm?.summary?.fields[data]?.required && !validateDataAccordingToFieldType(rawForm?.summary?.fields?.[data]?.field_type, rawResponse?.summary?.[data])) {
      sectionQuestionError = true;
      errorMessage += `\n \u2022 ${rawForm?.summary?.fields[data]?.question}`;
    }
  })

  rawResponse?.questions?.map((questionData, index) => {
    let isQuestionError = false;

    rawForm?.questions?.field_order?.map((data) => {
      if (rawForm?.questions?.fields?.[data]?.nested) {
        rawForm?.questions?.fields?.[data]?.field_info?.nested_fields_order?.map((nestedField, nestedIndex) => {
          if (rawForm?.questions?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.required && !validateDataAccordingToFieldType(rawForm?.questions?.fields?.[data]?.field_info?.nested_fields?.[nestedField]?.field_type, questionData?.[data]?.[nestedField])) {
            isQuestionError = true;
          }
        })
      } else if (rawForm?.questions?.fields[data]?.required && !validateDataAccordingToFieldType(rawForm?.questions?.fields?.[data]?.field_type, questionData?.[data])) {
        isQuestionError = true;
      }
    })

    if (isQuestionError) {
      sectionQuestionError = true;
      errorMessage += `\n \u2022 Question #${index + 1} - Some fields are missing`;
    }
  })

  return {sectionQuestionError, errorMessage }

}