export const PRESCREENING_TYPE = {
    TEST_VALIDITY : "testValidity",
    TEST_START_TIMESTAMP : "testStartTimestamp",
}

export const PRESCREENING_RESULT = {
    CORRECT: "CORRECT",
    INCORRECT: "INCORRECT",
    UNATTEMPTED: "UNATTEMPTED",
    PROCEED : "PROCEED",
    REJECT : "REJECT",
}

export const PRESCREEN_TOPIC = {
    OTHERS : "OTHERS_QUESTION_LIST",
}