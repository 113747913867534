import React , {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import moment from "moment-timezone";
import "./MultipleSlotCard.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EmptyBox from "../EmptyBox/EmptyBox";
import SlotModal from '../../components/SlotsModal/SlotModal';
import { confirmRequest } from '../../services/requests';
import Swal from 'sweetalert2';
import cogoToast from 'cogo-toast';

const MultipleSlotCard = ({ data}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateInterviewId, setSelectedDateInterviewId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [isMobileSlotModalVisible, setIsMobileSlotModalVisible] = useState(false);
  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 40,
    },
  };

  const onDateSelected = (items, date) => {
    setSelectedDateInterviewId(items?.interview_details?.id)
    setSelectedDate(date) 
  }

  const handleModal = (items) => {
    setIsMobileSlotModalVisible(true)
    setSelectedData(items)
  }
 
  const acceptInterview = async () => {
if(!selectedData){
  return;
}

    try {
      setIsMobileSlotModalVisible(false)
        const response = await Swal.fire({
            title: "Are you sure you want to accept the request?",
            icon: 'info',
            iconColor: '#123FBC',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
                cancelButton: 'btnCancelSwal',
                confirmButton: 'btnConfirmSwal'
            }
        });
        console.warn("(response.isConfirmed)", response.isConfirmed);
        console.warn("data", data);
        if (response.isConfirmed) {
            const payload = {
                "interview_id": selectedData?.interview_details?.id,
                "client_id": selectedData?.company_name?.id,
                "scheduled_start_timestamp": parseInt(selectedDate),
                "scheduled_end_timestamp": parseInt(selectedDate) + 3600,
                "last_updated_timestamp": selectedData?.interview_details?.last_updated_timestamp,
                "create_calendar_invite": true
            }
            console.warn("payload", payload);
            await confirmRequest(payload);
            cogoToast.success(`Interview has been scheduled for ${selectedData?.candidate_name?.name} at ${moment(selectedDate * 1000).format('MMM DD, YYYY | hh:mm A')}`);
        }
    } catch (err) {
    }
}
  return (
    <>
      { data?.length > 0 ?
      <Carousel
        partialVisbile
        deviceType={"mobile"}
        itemClass="image-item"
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        showDots={false}
      >
        {data.map((items, index) => {
          return (
            <Box className="multiple-slot-wrapper" key={index}>
              <Typography className="heading">
                {items?.positionName} | {items?.roundName}
              </Typography>
              <Typography className="heading client-name">
                {items?.companyName}
              </Typography>

              <Box display="flex" flexDirection="column">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="one"
                    name="radio-buttons-group"
                  >
                    {items?.dateTime?.label?.map((item, index) => {
                      console.warn("item", item);
                      return (
                        <FormControlLabel
                          className="heading"
                          value={item}
                          id={item}
                          control={<Radio checked={selectedDate === item && selectedDateInterviewId === items?.interview_details?.id} />}
                          label={<Typography className="heading">{moment(item * 1000).format(
                            "MMM DD, YYYY | hh:mm A"
                          )}</Typography>}
                          onChange={(e) => { onDateSelected(items, e.target.value)}}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <Button
                  variant="contained"
                  className="accept-btn"
                  disabled={selectedDate && selectedDateInterviewId && selectedDateInterviewId === items?.interview_details?.id ? false :true}
                  onClick={() => handleModal(items)}
                >
                  Accept
                </Button>
              </Box>
              <SlotModal
                openModal={isMobileSlotModalVisible}
                closeModal={() => setIsMobileSlotModalVisible(false)}
                date={selectedDate}
                data={items}
                onConfirm={acceptInterview}
                />
            </Box>
          );
        })}
      </Carousel>
      :
      <EmptyBox text={"You don’t have any interviews requests."} />}
    </>
  );
};

export default MultipleSlotCard;
