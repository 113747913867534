import React, { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import axios from "../../services/axios";
import { loginUser } from '../../services/auth';
import { setUser } from '../../store/actions/User';
import { loginSuccess } from '../../store/actions/Auth';
import { validateEmail } from '../../utilis/validation';
// Material
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import bannerImg from '../../images/bannerImg.svg';
import mobileBannerImg from '../../images/mobileBannerImg.svg'
import ivLogo from "@iv/shared/assets/images/iv-logo.svg"
import windowDimensions from '../../utilis/windowDimension';
import './Login.scss';

import ReCAPTCHA from "react-google-recaptcha";
import cogoToast from 'cogo-toast';

const Login = (props) => {
  const {width} = windowDimensions();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const recaptcha = useRef();
	const recaptchaSiteKey = '6LfPXEUpAAAAALfPFekzaSPw0ITh1sBj7xCW3e5r';

  useEffect(()=> {
    localStorage.setItem('firstLogin', true);
  },[]);

  const validateInput = () => {
    let isValid = true;
    
    if (!validateEmail(email)) {
      setErrorEmail(true);
      isValid = false;
    } else {
      setErrorEmail(false);
    }
    
    if (password === '') {
      setErrorPassword(true);
      isValid = false;
    } else {
      setErrorPassword(false);
    }
    
    return isValid;
  };

  const handleClickShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };




const extractAndShowError = (htmlString) => {
  // Create a DOM parser
  const parser = new DOMParser();
  
  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, 'text/html');
  
  // Extract the title and the message
  const title = doc.querySelector('title').textContent;
  const message = doc.querySelector('p').textContent;
  
  // Combine title and message
  const errorMessage = `${title}: ${message}`;
  
  // Display the error message using cogoToast
  cogoToast?.error(errorMessage, { heading: 'Error' });
};

  const signIn = async (e) => {
    const captchaValue = recaptcha.current.getValue();

		if (!captchaValue) {
             alert("Please verify the reCAPTCHA!");
	         return;
        } 

    e.preventDefault();
    if(!validateInput()) return;
    const resp = await loginUser({email, password});
    const user = resp?.user
    const access_token = resp?.access_token
    const refresh_token = resp?.refresh_token
    // window.zipy.identify(`USER-${user?.id.toString()}`);
    try {
      setLoading(true);
      if(access_token){
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          dispatch(setUser(user));
          dispatch(loginSuccess(access_token, refresh_token));
          setLoading(false);
      } else{
        extractAndShowError(resp?.data)
        setLoading(false);
      }
    } catch (e) {
    //  here, we are not showing error because we are throwing error from api try catch block. So, if we show here than the error is coming two times on screen.
      setLoading(false);
    }
  };

  return (
       <Box className="login-parent-wrapper">
             <Box className="formSectionWrapper">
                  <Box className="formSectionParent">
                        <img src={ivLogo} alt= 'iv-logo' className="ivLogo"/>
                        <Typography className="loginInterviewerText">Log In as Interviewer</Typography>


                           <Box className="inputFieldBox">
                                 <Typography className="textFieldLabel">Email Id</Typography>
                                  <Input
                                    disableUnderline
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                      setErrorEmail(false);
                                    }}
                                    className={`textFieldStyles ${errorEmail && 'warningBorder'}`}
                                    placeholder="Enter your email id"
                                    variant="outlined"
                                    sx={{ "& legend": { display: "none" } }}
                                  />
                            </Box>


                            <Box className="inputFieldBox passwordMargins">
                                  <Typography className="textFieldLabel">Password</Typography>

                                      <Input 
                                          disableUnderline
                                          placeholder="Enter your password"
                                          className={`textFieldStyles ${errorPassword && 'warningBorder'}`}
                                          onChange={(e) => {
                                            setPassword(e.target.value);
                                            setErrorPassword(false);
                                          }}
                                          type={isPasswordVisible ? 'text' : 'password'}
                                      />

                               </Box>

                            <Box className="showPasswordBox">
                                 <input type="checkbox" id='showPassword'  onClick={handleClickShowPassword}/>
                                 <label for='showPassword' className="showPassword"> Show Password</label>
                            </Box>

                            <Box className="captchaContainer">
                               <ReCAPTCHA ref={recaptcha}  sitekey={recaptchaSiteKey} className='captchaAlignment'/>
                            </Box>

                            <Button
                              className="sign-in-btn"
                              variant="contained"
                              color="primary"
                              onClick={(e) => signIn(e)}
                              disabled={loading}
                            >
                                  Login
                            </Button>

                            <Typography className="forgot-password">
          
                                <Link
                                  to="/forgot-password"
                                  className="linkText"
                                >
                                    Forgot your password?
                                </Link>
                            </Typography>
                  </Box>
                        
             </Box>

             <Box className="imageSection">
                    <img src={ width > 400 ? bannerImg : mobileBannerImg} alt='banner-img' className="bannerImage"/>
             </Box>
       </Box>
  );
};

export default Login;