import React from 'react';
import { useState, useEffect } from 'react';
import  Box  from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import  Typography  from '@mui/material/Typography';
import "./ViewReferenceImage.scss";
import ImageLoad from './ImageLoad';

const ViewReferenceImage = ({open, handleClose, data, downloadFile}) => {
    const [imageURL, setImageURL] = useState('');

    useEffect(() => {
        if(data?.question?.image_link) {
            setImageURL(data?.question?.image_link);
        }
    },[data])
  return (
    <Dialog 
       open={open} 
        onClose={handleClose}
        PaperProps={{
            style: {
              width: '1084px', 
              height: '727px',
            },
          }}
     >
       <Box className='parentBoxImageReference'>
            <Box className="headerBox">
                <Typography className='headerText'> <span className='font600'>Q.</span> <span className='font400'>{data?.question?.statement} ?</span></Typography>
                <Typography className='closeDialog' onClick={handleClose}>X</Typography>
            </Box>

            <Box className='imageBox'>
                <ImageLoad data={imageURL} url={imageURL} downloadAPI={downloadFile} />
            </Box>

            <Box className='detailBox'>
                <Typography className='indepthText lightGray'>InDepth Knowledge - </Typography>
                <Typography className={`difficultyText ${data?.difficulty_level == "HARD" ? "textRed" :data?.difficulty_level == "MEDIUM"?  "textYellow" : "" }`}>{data?.difficulty_level}</Typography>
                <Typography className='indepthText darkGray'></Typography>
            </Box>

           

            <Box className='horizontalLine'></Box>

            {data?.things_to_check?.length > 0 &&
                         <Box className='thingsToCheckBox'>
                         <Typography className='checkHeading commonHeading'>Things to Check</Typography>
                         <Typography className='commonText checkMargins'>
                            <ul>
                                {data?.things_to_check?.map((item, index) => {
                                    return(
                                        <li>
                                            {item}
                                        </li>
                                    )
                                })}
                            </ul>
                         </Typography>
                    </Box>
            }

           

            <Box className='answersBox'>
                    <Typography className='commonHeading ansHeading'>Answer:</Typography>
                    <Typography className='commonText ansMargin'>{data?.solution?.solution1?.approach}</Typography>
            </Box>
       </Box>
    </Dialog>
  )
}

export default ViewReferenceImage