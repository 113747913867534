import cogoToast from 'cogo-toast';

export const getResumeAcceptedFormats = () => {
    return '.pdf, .doc, .docx'
}

export const getImageAcceptedFormats = () => {
    return "image/png, image/jpeg, image/jpg"; //".png, .jpg, .jpeg"
}

export const isResumeFileTypeAllowed = (file) => {
    let ext = file?.name?.split(".");
    ext = ext?.[ext?.length-1];
    if(!ext || ext?.length == 0 || (ext!=="pdf" && ext!=="doc" && ext!=="docx"))
    {
        return false;
    }
    return true;
}

export const isImageFileTypeAllowed = (file) => {
    let ext = file?.name?.split(".");
    ext = ext?.[ext?.length-1];
    if(!ext || ext?.length == 0 || (ext.toLowerCase()!=="png" && ext.toLowerCase()!=="jpg" && ext.toLowerCase()!=="jpeg"))
    {
        return false;
    }
    return true;
}

export const isCSVFileTypeAllowed = (file) => {
    let ext = file?.name?.split(".");
    ext = ext?.[ext?.length-1];
    if(!ext || ext?.length == 0 || (ext!=="csv"))
    {
        return false;
    }
    return true;
}

export const validateImageFile = (file) => {
    if(!isImageFileTypeAllowed(file)){
      cogoToast.error('Please upload correct image file type. Accepted formats are .png, .jpg, .jpeg')
      return false;
    } else return true;
}