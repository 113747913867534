import React from 'react';
import './CommonButton.scss';
import CircularProgress from '@mui/material/CircularProgress';


const CommonButton = ({buttonName, buttonActionName, styleObject, onClick, isDisable, styleClassName, isLoading, loaderStyleObject, loaderSize, image, imgPosition, imgStyleName})  => {
  return (
    <div className='commonButton'>
        <button  
          style={styleObject}
          onClick={onClick}
          name={buttonActionName ?  buttonActionName : buttonName}
          className={`${isDisable && 'disableButton'} ${styleClassName}`}
        > 
        {image && imgPosition === 'before' && <img src={image} alt="button icon" className={imgStyleName}/>}
        {buttonName}
        {isLoading && <span><CircularProgress style={loaderStyleObject} size={loaderSize}/></span>}  
        {image && imgPosition === 'after' && <img src={image} alt="button icon" className={imgStyleName}/>}
        </button>
    </div>
  )
}

export default CommonButton;