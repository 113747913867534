import React, {useState, useEffect, forwardRef} from 'react'
import {PageView, HeaderView} from '../../components/Pages/Views'
import DetailedSchedule from './DetailedScheduled'
import Table from '../../components/Table/Table'
import Modal from '../../components/Models/RightSide'
import Select from 'react-select'
import NavBar from '../Nav'
import {fetchSchedule} from '../../services/schedule'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {scheduleAction} from '../../store/actions/Schedule'
import { uniqueBy } from '../../utilis/helpers'
import './index.scss';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarImg from './calenderImg.svg';
import cogoToast from 'cogo-toast';
import useMediaQuery from '../../utilis/useMediaQuery'
import FilterToolbar from '../../components/MobileComponents/FilterToolbar'
import { getClientsAndPositions } from '../../services/history'
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button";
import {sendCandidateFeedbackMail, transfer_interview, edit_interview} from '../../services/interview';
import BeatLoader from "react-spinners/BeatLoader";
import Cancel_Interview_Cross from '../../images/sideBarRevamp/cancel_interview_cross.svg';
import ResultPreviewPopup from './ResultPreviewPopup';
import { INTERVIEWER_TYPE } from '../User/Constant';
import { getLocalTimeZone } from '../../utilis/TimeZone'

const Requests = (props) => {
    const fetchedSchedule = useSelector((state) => state && state.schedule && state.schedule && state.schedule.list && state.schedule.list);
    const currentTimezone = useSelector(state=>state?.user?.user_config?.timezone) || getLocalTimeZone();   
    const interviewerType = useSelector((state) => state && state.profile?.interviewer_type);
    const search = useLocation().search;
    const selectedInterviewId = new URLSearchParams(search).get("interview_id");
    const dispatch = useDispatch();
    const mobileView = useMediaQuery('(max-width:700px)');
    const [schedule, setSchedule] = useState([]);
    const [selected, setSelected] = useState(null)
    const [viewSideModal, setViewSideModal] = useState(false);
    const [filters, setFilters] = useState(initFilters)
    const [options, setOptions] = useState({companies: [], positions: []})
    const [clientPosData, setClientPosData] = useState({})
    const [showLoader, setLoaderVisibility] = useState(false);
    const [previewOpen, setPreviewOpen] =  useState(false);
    const [previewRowData, setPreviewRowData] =  useState({});

    const [popup, setOpenPopUp] = React.useState(false);
    const [desc, setDesc] = React.useState('');
    const [extraText, setExtraText] = useState('');

    useEffect(() => {
      // if(fetchedSchedule.length === 0) 
      fetchData();
      // eslint-disable-next-line
    }, [interviewerType])

    useEffect(() => {
      if(selectedInterviewId){
        setViewSideModal(true);
        setSelected(selectedInterviewId);
      }
    }, [selectedInterviewId])

    useEffect(() => {

 fetchedSchedule.sort(function (a, b) {
            let TotalA = a?.interview_details?.scheduled_start_timestamp ? a?.interview_details?.scheduled_start_timestamp : 0;
            let TotalB = b?.interview_details?.scheduled_start_timestamp ? b?.interview_details?.scheduled_start_timestamp : 0;
            if (TotalA > TotalB) {
              return 1;
            } else if (TotalA < TotalB) {
              return -1;
            }
            return 0;
        });
        setSchedule(fetchedSchedule)

        setOptions({
            companies: [ {value: "All Companies", label: 'All Companies'}, ...uniqueBy(fetchedSchedule.map(item => ({value: item.companyName, label: item.companyName})), 'value')],
            positions: [ {value: "All Positions", label: 'All Positions'}, ...uniqueBy(fetchedSchedule.map(item => ({value: item.positionName, label: item.positionName})), 'value')],
        })
        // eslint-disable-next-line
    }, [fetchedSchedule])


    useEffect(() => {
        applyFilters()
        // eslint-disable-next-line
    }, [filters])

    const fetchData = async () => {
        try{
            const res = await fetchSchedule(null, interviewerType);
            dispatch(scheduleAction(res));

            const clientPositionData = await getClientsAndPositions();
            setClientPosData(clientPositionData);
        } catch(err){
        }
    }

    const editFilters = (value, name) => {
        setFilters(prev => ({...prev, [name]: value}))
    }

    const applyFilters = () => {
        let data = fetchedSchedule;
        if(filters.companies !== "All Companies" && filters.positions === "All Positions"){
            data = data.filter(item => item.companyName === filters.companies)
        }
        else if(filters.positions !== "All Positions" && filters.companies === "All Companies"){
            data = data.filter(item => item.positionName === filters.positions)
        } 
        else if(filters.positions !== "All Positions" && filters.companies !== "All Companies"){
            data = data.filter(item => item.companyName === filters.companies && item.positionName === filters.positions)
        }
        if(filters.fromDate && filters.toDate){
            data = data.filter(item => item.dateTime.value >= filters.fromDate && item.dateTime.value <= filters.toDate)
        } 
        data = data.filter(item => item.candidateName.toLowerCase().includes(filters.searchInput.toLowerCase()))

        data.sort(function (a, b) {
            let TotalA = a?.interview_details?.scheduled_start_timestamp ? a?.interview_details?.scheduled_start_timestamp : 0;
            let TotalB = b?.interview_details?.scheduled_start_timestamp ? b?.interview_details?.scheduled_start_timestamp : 0;
            if (TotalA > TotalB) {
              return (filters.order === 'ASC') ? 1 : -1;
            } else if (TotalA < TotalB) {
              return (filters.order === 'ASC') ? -1 : 1;
            }
            return 0;
        });

        if(filters.feedbackStatus?.length > 0){
            data = data.filter(item => item.feedbackStatus?.value?.toLowerCase()?.includes(filters.feedbackStatus?.toLowerCase()))
        }
        setSchedule(data)
    }

    const handleRestoreBttn=()=>{
        setFilters(initFilters);
    }

    const sendFeedbackMail = async (data, index) => {
        setLoaderVisibility(true)
        try {
            const payload = {
                "interview_id": data?.interview_details?.id,
                "client_id": data?.client_details?.id,
            }
            const resp = await sendCandidateFeedbackMail(payload);
            if (resp?.status === 200) {
                cogoToast.success(resp?.data);
                fetchData()
            } else {
                cogoToast.error(resp?.data);
            }

        } catch (err) {
            cogoToast.error("Some error occured!!");
        }
        setLoaderVisibility(false)
    }

    const handlePopupPreview = async (data) => {   
      setPreviewRowData(data);
      setPreviewOpen(true);
    }
    
    const handleClickOpen = () => {
      setViewSideModal(false);
      setOpenPopUp(true);
    };
    const popUpData = schedule.filter(item => item?.id === selected);
    return (
        <div className="Scheduled">

          { showLoader &&  <div className='loader-bg'>
                <BeatLoader size={15} color={"#2c7be5"} loading={showLoader} />
            </div>}

            <NavBar {...props}  pageTitle="Scheduled" augmentElement={
                <Box display='flex' justifyContent="flex-end" alignItems="center">
                    <Button onClick={handleRestoreBttn} color="primary" variant="text">
                        Restore Default
                    </Button>
                </Box>
            }/>
            <PageView>
                {!mobileView && <HeaderView title = 'Schedule' OwnComp={<div className="fl-col headerComp">
                </div>}/>}
                <Toolbar 
                    filters = {filters} 
                    editFilters = {editFilters} 
                    setFilters ={setFilters}
                    allCompanies = {options.companies}
                    allPositions = {options.positions}
                    clientPosData={clientPosData}
                />
                <Table 
                    isFooter = {true}
                    handleRowClick = {(id) => {setSelected(id); setViewSideModal(true);}}
                    data = {schedule}
                    headers = {tableHeaders}
                    isSortable = {true}
                    fromPage = 'Scheduled'
                    currentTimezone={currentTimezone}
                    feedbackPreview = { (obj)=>{
                       handlePopupPreview(obj)
                    }}
                    onCandidateFeedbackShare = {(obj, index)=>{
                        sendFeedbackMail(obj, index)
                    }}
                    interviewerType = {interviewerType}
                />
                <Modal show = {viewSideModal} setShow = {setViewSideModal}>
                    {(selected || selectedInterviewId) && <DetailedSchedule 
                        data = {schedule.find(item => item.id === selected)} 
                        setShow = {setViewSideModal}
                        setSelected = {setSelected}
                        fetchData ={fetchData}
                        setOpenPopUp={setOpenPopUp}
                        handleClickOpen={handleClickOpen}
                        currentTimezone={currentTimezone}
                        schedule={schedule}
                    />}
                </Modal>
                <ResultPreviewPopup item={previewRowData}  previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} setShowScheduleData={() => fetchData()}/> 
            </PageView>

              <DialogSelect 
                popup={popup} 
                desc={desc} 
                setDesc={setDesc} 
                setOpenPopUp={setOpenPopUp} 
                setExtraText={setExtraText} 
                extraText={extraText}
                data = {popUpData.length>0 ? popUpData[0]:''} 
                fetchData = {fetchData}
                interviewerType = {interviewerType}
              />

        </div>  
    )
}


const Toolbar = ({filters, editFilters, setFilters, allCompanies, allPositions, clientPosData}) => {
    const [startDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const mobileView = useMediaQuery('(max-width:700px)');

    useEffect(()=>{
        if(startDate && EndDate){
            let fromDate = moment(startDate).unix();
            let toDate = moment(EndDate).set({hour:23}).unix();
            if(fromDate > toDate){
                cogoToast.error('To Date should be greater than from date');
                setEndDate("");
                setFilters((prev)=>{
                    return {
                        ...prev,
                        fromDate: fromDate,
                        toDate: "",
                    }
                })
                return
            }
            setFilters((prev)=>{
                return {
                    ...prev,
                    fromDate: fromDate,
                    toDate: toDate,
                }
            })
        }
    },[startDate,EndDate]);

    const ExampleCustomInput = forwardRef(({ value, onClick , placeholder }, ref) => (
        <div   onClick={onClick} ref={ref}>
            <input readOnly className="date-pickeroWN" placeholder={placeholder} value={value} />
            <div className={"dateIcon"}>
                <img className={"calendarSize"} src={calendarImg} alt='calendar-icon'/>
            </div>
        </div>
      ));

      const handleRestoreBttn=()=>{
        setStartDate("");
        setEndDate("");
        setFilters(initFilters);
      }
      
      const feedbackOptions = [
        {value: 'Yet to fill', label: <div class = "text-empty">Yet to Fill</div>},
        { value: 'Feedback Approved', label: <div class = "text-approved">Feedback Approved</div> },
        {value: 'Changes Requested', label: <div class = "text-changesRequested">Changes Requested</div>},
        {value: 'Not Approved', label: <div class = "text-not-approved">Yet to Approve</div>},
        {value: 'Saved in Draft', label: <div class = "text-draft">Saved in Draft</div>}
      ]

      if(mobileView){
        return <FilterToolbar
            title="Scheduled"
            ExampleCustomInput={ExampleCustomInput}
            startDate={startDate}
            setStartDate={setStartDate}
            EndDate={EndDate}
            setEndDate={setEndDate}
            handleRestoreBttn={handleRestoreBttn}
            allCompanies={allCompanies}
            allPositions={allPositions}
            feedbackOptions={feedbackOptions}
            handleCompanySelection={(val) => setFilters(prev => ({...prev, companies: val}))}
            editFilters={editFilters}
            filters={filters}
            clientPosData={clientPosData}
        />
    }

    const allCompaniesValues = allCompanies.filter(item => item?.value === filters?.companies);
    const allPositionsValues = allPositions.filter(item => item?.value === filters?.positions);
    return (
        <div className = '' id = 'filters'>
                <div className = 'p-btm'>
                    <div className = 'row align-items-center'>
                        <div className = 'col-2'>
                            <Select 
                                options = {allCompanies} 
                                value = {allCompaniesValues?.length>0?allCompaniesValues[0] : ''} 
                                placeholder = 'All Companies'
                                onChange = {e => setFilters(prev => ({...prev, companies: e.value}))}
                                classNamePrefix = 'smallSelect'
                            />
                        </div>
                        <div className = 'col-2'>
                            <Select 
                                options = {allPositions} 
                                value = {allPositionsValues?.length>0 ? allPositionsValues[0] : ''} 
                                placeholder = 'All Positions'
                                onChange = {e => setFilters(prev => ({...prev, positions: e.value}))}
                                classNamePrefix = 'smallSelect'
                            />
                        </div>
                        
                        <div className="col"></div>
                        <div className="pos-rel mr-10">
                            <DatePicker
                                popperPlacement="bottom-end"      
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                placeholderText="From date"
                                customInput={<ExampleCustomInput />}
                            />
                            
                        </div>
                        <div className="pos-rel mr-30">
                        <DatePicker
                                popperPlacement="bottom-end"      
                                selected={EndDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="To date"
                                className="date-picker"
                                customInput={<ExampleCustomInput />}
                            />
                        </div>
                        <div className="sideBorder mr-30"></div>
                        <button className="bttn mr-10" onClick={handleRestoreBttn}>Restore default</button>
                    </div>
                </div>
            </div>

    )
}
export default Requests;
const tableHeaders = [
    {value: 'companyName', label: 'Company Name', active: true}, 
    {value: 'positionAndRound', label: 'Position & Round', active: true},
    {value: 'dateTime', label: 'Date & Time', active: true},
    {value: 'resultPreview', label: 'Result Preview', active: true},
    // {value: 'status', label: 'Status', active: true},
    {value: 'feedbackStatus', label: 'Feedback Status', active: true},
    // {value: 'candidateFeedbackMailOption', label: 'Candidate Feedback Form', active: true}

]
const initFilters = {
    companies: "All Companies",
    positions: "All Positions",
    searchInput: "", 
    fromDate:"",
    toDate:"",
    sortBy: "",
    order: "",
    feedbackStatus: ""
}


// PopUP



const DialogSelect = ({popup, desc, setDesc, setOpenPopUp, setExtraText,extraText, data, fetchData, interviewerType}) => {

  const handleChange = (event) => {
    setDesc(event?.target?.value || '');
  };
  const cancelInterview = async () => {
    const req_body = {
        interview_id: data.interview_details?.id,
        client_id: data.client_details?.id,
        last_updated_timestamp: data.interview_details.last_updated_timestamp,
        comment:extraText || desc,
    }
    const cancelPayload = {
      id: data.interview_details?.id,
      client_id: data.client_details?.id,
      last_updated_timestamp: data.interview_details.last_updated_timestamp,
      comments:extraText || desc,
      interview_status : "CANCELLED",
    }

    if(interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL){
        if (!desc && !extraText) {
            cogoToast.error("Please add reason before submitting");
            return;
        }
        const client_interview_cancel = await edit_interview(cancelPayload);
        if (client_interview_cancel?.status === 200){
          cogoToast.success("Successfully Cancelled");
          setOpenPopUp(false);
          setDesc("");
          setExtraText("");
          fetchData();
        }
        else{
          cogoToast.error("You can cancel the interview before the 30 min from the starting time");
          setOpenPopUp(false);
          setDesc("");
          setExtraText("");
        }
    }
    else {
    const interview_cancel = await transfer_interview(req_body);
    if (interview_cancel === 200){
      cogoToast.success("Successfully Cancelled");
      setOpenPopUp(false);
      setDesc("");
      setExtraText("");
      fetchData();
    }
    else{
      cogoToast.error("You can cancel the interview before the 30 min from the starting time");
      setOpenPopUp(false);
      setDesc("");
      setExtraText("");
    }
    }
  }

  const handleClose =(checker) => {
    if (checker){
      cancelInterview();
    }
    else{
      setOpenPopUp(false);
      setDesc("");
      setExtraText("");
    }
  };
  return (
      <>
      {
        popup?(
          <div>
            <div className='popup-main-box'></div>
            <div open={popup} onClose={handleClose}>
              <div className="popup_box">
                <div className="cancel_interview_popup_heading">
                  <p className="popup_heading">
                    Cancel Interview
                  </p>
                  <img src={Cancel_Interview_Cross} alt="cross" onClick={()=>handleClose(false)} />
                </div>
                <hr />
                <p className="reason">Cancellation Reason</p>
                <select id="Reason" value={desc} onChange={handleChange}>
                  <option value="">Please select the cancellation reason</option>
                  <option value="Adhoc Office meetings">Adhoc Office meetings</option>
                  <option value="Sudden plan">Sudden plan</option>
                  <option value="Power cut">Power cut</option>
                  <option value="Network issue">Network issue</option>
                  <option value="Medical Emergency">Medical Emergency </option>
                  <option value="Other">Other</option>
                </select>
                {
                  desc === "Other" && <ExtraDesc setExtraText={setExtraText} extraText={extraText} />
                }
                <Box marginTop='20px'>
                  Please mark the timeslot as unavailable.
                  <Box>{moment(data.interview_details.scheduled_start_timestamp * 1000).format("hh:mm a") + 
                  " - " + moment(data.interview_details.scheduled_end_timestamp * 1000).format("hh:mm a") +
                  ", " + moment(data.interview_details.scheduled_start_timestamp * 1000).format("dddd")}</Box>
                  </Box>
                <div class="buttons">
                  <button onClick={()=>handleClose(false)} className="Bg3" >Dismiss</button>
                  <button 
                    onClick={()=>handleClose(true)} 
                    className={(desc?.length === 0 || (desc === "Other" && extraText?.length === 0)) ?"Background_none":"Background_color"}
                    disabled={desc?.length === 0 || (desc === "Other" && extraText?.length === 0)}
                  > Cancel Interview </button>
                </div>
              </div>
          </div>
        </div>
        ):''
      }
    </>
  );
}

const ExtraDesc = ({setExtraText, extraText})=>{
  return (
    <div className="extra-desc">
      <p>Please type the reason here. <span style={{color:"red"}}>*</span> </p>
      <textarea className='cancel-textarea' value={extraText} cols="37" rows="2" onChange={(e)=>setExtraText(e?.target?.value)} ></textarea>
    </div>
  );
}