import React, { Fragment, useEffect, useState } from 'react'
import boxImg from '../../components/Table/empty-box.svg';
import useMediaQuery from '../../utilis/useMediaQuery';
import HistoryList from '../../components/MobileComponents/HistoryList';

const Table = ({
        data, 
        totalDataCount,
        headers,  
        isSortable,
        handleRowClick
    }) => {
    const [tabelHeaders, setTableHeaders] = useState(headers.map(item => ({...item, sort: null, selected: false})));
    const [tableData, setTableData] = useState([...data]);
    const [tableDataLength, setTableDataLength] = useState(totalDataCount);
    const tablePage=1;
    const rowPerPage = 8;
    const mobileView = useMediaQuery('(max-width:700px)');

    useEffect(() => {
        setTableHeaders(headers.map(item => ({...item, sort: null, selected: false})))
    }, [headers])
    
    useEffect(() => {
        setTableData(data);
    }, [data])

    useEffect(() => {
        setTableDataLength(totalDataCount);
    }, [totalDataCount])

    if(mobileView){
        return <HistoryList
            data = {data}
            totalDataCount = {totalDataCount}
            headers = {headers} 
            isSortable
        />
    }
    
    return(
    <Fragment>
    <div className="historyTable " style = {{marginBottom: '0px'}}>
        <div className="table-responsive" style = {{minHeight: '55vh'}}> 
            <table className="table table-sm table-nowrap table-hover">
                <thead >
                    <tr className = 'py-3'>
                        {tabelHeaders.map((item, index) => {
                            if(item.active === true){
                            return (<th className = "pointer tableHeadingCol font-weight-bold text-dark text-decoration-none" key = {item.value}>
                                    {item.label}<i className="fas fa-sort ml-2"></i>
                                    {/* {isSortable && <i className="fas fa-sort ml-2"></i>} */}
                                </th>)
                            } else return (<Fragment key = {item.value}></Fragment>)
                        })}
                    </tr>
                    
                </thead>
                <tbody className="list table-content">
                    { tableDataLength !== 0 &&  
                        tableData.map((row, index) => {
                        if(index < (tablePage * rowPerPage) && index >= ((tablePage-1) * rowPerPage)){
                            return (<tr key = {index} className = 'pointer' 
                            onClick={()=>{
                                handleRowClick(row?.id, row?.interview_details?.interview_status)
                            }}
                            >
                                {tabelHeaders.map((header, index2) => {
                                    return <td key = {index2} onClick = {e => {
                                    }}>{row[header.value] ? (row[header.value].label ? row[header.value].label : row[header.value]) : '-'}</td>
                                })}
                            </tr>)
                        }
                        else return (<Fragment key = {index}></Fragment>)
                    })}
                    { tableDataLength === 0 && 
                        <tr>
                            <td colspan="6">
                                <img className={"mt-6 mb-3 horizontal-center"} src={boxImg} alt="box img" />
                                <p className={"mb-6 text-center"}>You don't have any interviews scheduled</p>
                            </td>
                        </tr>
                    }
                </tbody>
            </table> 
        </div>
    </div>
    </Fragment>
    )
}

export default Table;