import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { makeStyles } from "@material-ui/core/styles";

import "./../../feedback.scss";
import "./form.scss";

import TextEditor from "../../Components/TextEditor";
import FeedbackTextEditor from "../../Components/FeedbackTextEditor";
import { renderToolTip } from "../../Components/RenderToolTip";

import { commonMessageForInfoIcon, codingQuestionsOptionalMessage } from "../../Constants/ComonStrings";
import { FeedbackType, showCodingQuestions, showCodingQuestionsTitle, showDesignQuestions, isCodingQuestionsFilled, isCodingNonMandatory, showProblemSolvingCodingFields, isFlipkartFeedbackDesign, isGenericFeedback, capitalize } from "../../Constants/FeedbackConstants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginLeft: '10px'
  },
}));

const genericFeedbackOptions = [
  { value: "GENERAL_PROBLEM_SOLVING_CODING", label: "Coding Questions" },
  { value: "GENERAL_PROBLEM_SOLVING", label: "Non Coding Questions" },
];

const CodingQuestions = ({ error, codingQuestionData, setCodingQuestionData, feedbackType, interviewDetails, uploadFileV2 }) => {
  const classes = useStyles();

  const showGenericFeedbackChoice = () => {
    return isGenericFeedback(interviewDetails) && codingQuestionData?.questionType === "";
  }

  const showCodingError = () => {
    return error && (!isCodingNonMandatory(feedbackType) || isCodingQuestionsFilled(codingQuestionData, feedbackType, interviewDetails));
  }
  const showAsterisk = () => {
    return !isCodingNonMandatory(feedbackType) ? "*" : "";
  }

  const handleQuestionType = (e) => {
    const { name, value } = e.target;
    setCodingQuestionData({ ...codingQuestionData, [name]: value });
  };

  const handleLanguageUsed = (e) => {
    const { name, value } = e.target;
    setCodingQuestionData({ ...codingQuestionData, [name]: value });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let copyData = [...codingQuestionData.questions];
    copyData[index][name] = capitalize(value);
    setCodingQuestionData({ ...codingQuestionData, questions: copyData });
  };


  const handleTimeSpent = (e, index) => {
    const re = /^[0-9\b]+$/;

    const { name, value } = e.target;
    if (value === "" || re?.test(value)) {
      handleChange(e, index);
    }
  };

  const handleDelete = (name, index) => {
    let copyData = [...codingQuestionData[name]];
    copyData.splice(index, 1);
    setCodingQuestionData({ ...codingQuestionData, [name]: copyData });
  };

  const handleAddMore = () => {
    let copyData = [...codingQuestionData.questions];
    copyData.push({
      questionAsked: "",
      questionLink: "",
      questionType: "",
      timeSpent: "",
      isQuestionCompleted: "",
      isSolutionOptimal: "",
      //Problem Solving Coding //Problem solving 
      languageUsed: "",
      solutionCode: "",
      spaceComplexity: "",
      timeComplexity: "",
      approach: [""],
      positives: [""],
      neutrals: [""],
      negatives: [""],
      //HLD, LLD
      designProcess: [""],
      requirementGathering: [""],
      highLevelDesign: [""],
      lowLevelDesign: [""],
      scalability: [""],
      dbchoices: [""],
      caching: [""],
      extraComments: [""],

      //Flipkart Feedback
      assumptions: "",
      heuristics: "",
      entityModel: "",
      apiDesign: "",
      concurrency: "",
      subcomponentBreakdown: "",
      asyncSyncInteraction: "",
      designTradeoffs: "",
      familiarity: "",
      correctness: "",
    });
    setCodingQuestionData({ ...codingQuestionData, questions: copyData });
  };

  const handleQuestionCompleted = (e, index) => {
    const value = e?.target?.value;
    let copyData = [...codingQuestionData?.questions];
    copyData[index]["isQuestionCompleted"] = value;
    setCodingQuestionData({ ...codingQuestionData, questions: copyData });
  }

  const handleSolutionOptimal = (e, index) => {
    const value = e?.target?.value;
    let copyData = [...codingQuestionData?.questions];
    copyData[index]["isSolutionOptimal"] = value;
    setCodingQuestionData({ ...codingQuestionData, questions: copyData });
  }

  const renderArrayFields = (parentIndex, fieldName, labelName, placeholder) => {

    const handleChange = (e, index) => {
      const { name, value } = e.target;
      let copyData = [...codingQuestionData?.questions];
      copyData[parentIndex][fieldName][index] = capitalize(value);
      setCodingQuestionData({ ...codingQuestionData, questions: copyData });
    }

    const handleDelete = (fieldName, index) => {
      let copyData = [...codingQuestionData?.questions];
      copyData[parentIndex][fieldName].splice(index, 1);
      setCodingQuestionData({ ...codingQuestionData, questions: copyData });
    }

    const handleKeypress = (e) => {
      //it triggers by pressing the enter key
      if (e?.key === 'Enter') {
        handleAddMore(e?.target?.name);
      }
    };

    const handleAddMore = (fieldName) => {
      let copyData = [...codingQuestionData?.questions];
      copyData[parentIndex][fieldName].push("");
      setCodingQuestionData({ ...codingQuestionData, questions: copyData });
    }

    const showError = (name) => {
      let isError = false;
      for (let i = 0; i < codingQuestionData?.questions[parentIndex][name]?.length; i++) {
        const data = codingQuestionData?.questions[parentIndex][name][i];
        if (data?.trim() === "") {
          isError = true;
        }
      }
      return isError;
    }


    return (
      <div className="form-row-style">
        <div className={`label ${showCodingError() && showError(fieldName) ? "error-color" : ""} `}>{labelName}<span className="asterisk">{showAsterisk()}</span> {!isCodingNonMandatory(feedbackType) && renderToolTip()}:</div>

        <Box display="flex" flexDirection="column" className="width-value-field">
          {codingQuestionData?.questions[parentIndex][fieldName].map((data, index) => {
            return (
              <Box display="flex" style={{ marginBottom: "5px" }}>
                <TextField
                  autoFocus
                  key={`${fieldName}-${index}`}
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  placeholder={placeholder}
                  className="form-value"
                  value={data}
                  name={fieldName}
                  onChange={(value) => handleChange(value, index)}
                  onKeyPress={handleKeypress}
                />

                {index !== 0 && (
                  <button
                    className="delete-bg"
                    onClick={(e) => handleDelete(fieldName, index)}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                )}

                {index === codingQuestionData?.questions[parentIndex][fieldName]?.length - 1 && (
                  <button
                    className="add-more"
                    onClick={(e) => handleAddMore(fieldName)}
                  >
                    +
                  </button>
                )}
              </Box>
            );
          })}
        </Box>
      </div>
    )
  }

  const renderTextAreaFields = (parentIndex, parentData, fieldName, labelName, placeholder) => {
    const handleChange = (e) => {
      const { name, value } = e.target;
      let copyData = [...codingQuestionData.questions];
      copyData[parentIndex][fieldName] = value;
      setCodingQuestionData({ ...codingQuestionData, questions: copyData });
    }

    return (
      <div className="form-row-style">
        <div className={`label ${showCodingError() && parentData[fieldName]?.trim() === "" ? "error-color" : ""} `}>
          {labelName}<span className="asterisk">{showAsterisk()}</span> {!isCodingNonMandatory(feedbackType) && renderToolTip()}:
        </div>
        <TextField
          id="outlined-size-small"
          size="small"
          type="textarea"
          variant="outlined"
          placeholder={placeholder}
          className="form-value"
          value={parentData[fieldName]}
          name={fieldName}
          multiline
          rows={2}
          rowsMax={5}
          onChange={handleChange}
        />
      </div>
    )
  }
  return (
    <div className="feedback-form-bg">

      <div className="form-row-style">
        <div className="heading">{isGenericFeedback(interviewDetails) ? "Practical Questions" : (showCodingQuestionsTitle(feedbackType) ? 'Coding Questions' : 'Design Questions')}{" "}{renderToolTip(isCodingNonMandatory(feedbackType) ? codingQuestionsOptionalMessage : commonMessageForInfoIcon)}</div>
      </div>

      {
        isGenericFeedback(interviewDetails) &&
        <div className="form-row-style" style={{ width: "100%", alignItems: "center" }} >
          Question Type:

          <FormControl
            variant="outlined"
            className={`${classes.formControl}`}
          >
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="questionType"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={codingQuestionData?.questionType}
              placeholder="Select Question Type"
              onChange={(value) => handleQuestionType(value)}
            >
              <MenuItem value="" hidden>
                Select Question Type
              </MenuItem>

              {genericFeedbackOptions?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>
      }

      {!showGenericFeedbackChoice() &&
        <>
          {codingQuestionData.questions.map((data, index) => {
            return (
              <Box display="flex" flexDirection="column" key={index}>
                <div
                  className="form-row-style"
                  style={{ width: "100%", alignItems: "center" }}
                >
                  Question {index + 1}.
                  {index !== 0 && (
                    <IconButton
                      style={{ marginLeft: "auto" }}
                      onClick={(e) => handleDelete("questions", index)}
                    >
                      {" "}
                      <DeleteOutlinedIcon />{" "}
                    </IconButton>
                  )}
                </div>

                <Box display="flex" flexDirection="column" className="questions-bg">
                  <div className="form-row-style">
                    <div className={`label ${showCodingError() && data?.questionAsked?.trim() === "" ? "error-color" : ""}`}>Question Asked<span className="asterisk">{showAsterisk()}</span> :</div>

                    <FeedbackTextEditor
                      id="outlined-size-small"
                      value={data.questionAsked}
                      onChange={(value) => handleChange({ target: { name: "questionAsked", value: value } }, index)}
                      uploadFileV2={uploadFileV2}
                      placeholder="Enter the question asked here..."
                      name="questionAsked"
                    />

                    {/* <TextField
                  id="outlined-size-small"
                  size="small"
                  type="textarea"
                  variant="outlined"
                  placeholder="Enter the question asked here..."
                  className="form-value"
                  value={data.questionAsked}
                  name="questionAsked"
                  multiline
                  rows={2}
                  rowsMax={5}
                  onChange={(value) => handleChange(value, index)}
                /> */}
                  </div>
                  <div className="form-row-style">
                    <div className={`label`}>Question Link<span className="asterisk">{showAsterisk()}</span> :</div>

                    <TextField
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      placeholder="Please add question link here if any..."
                      className="form-value"
                      value={data?.questionLink}
                      name="questionLink"
                      onChange={(value) => handleChange(value, index)}
                    />
                  </div>
                  <div className="form-row-style">
                    <div className={`label ${showCodingError() && data?.timeSpent === "" ? "error-color" : ""}`}>Time Spent<span className="asterisk">{showAsterisk()}</span> :</div>

                    <TextField
                      id="outlined-size-small"
                      size="small"
                      placeholder="Enter Numbers"
                      value={data.timeSpent}
                      name="timeSpent"
                      onChange={(value) => handleTimeSpent(value, index)}
                    />
                    <div style={{ margin: "10px" }}>in minutes</div>
                  </div>

                  {
                    showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                    <div className="form-row-style">
                      <div className={`label ${showCodingError() && (!data?.languageUsed || data?.languageUsed?.trim() === "") ? "error-color" : ""}`}>Language Used<span className="asterisk">{showAsterisk()}</span> :</div>

                      <TextField
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        placeholder="Enter the language used"
                        className="form-value width-30"
                        value={data?.languageUsed}
                        name="languageUsed"
                        onChange={(value) => handleChange(value, index)}
                      />

                      {/* <button className="add-more-text-button ml-10" onClick={(e) => addSolutionCode(e, index)}>
                  {data?.solutionCode && data?.solutionCode?.trim() !== "" ?  "Edit" : "Add" } Solution Code
                </button>
                {data?.solutionCode && data?.solutionCode?.trim() !== "" &&
                  <Box display="flex" ml={1} alignItems="end">
                    <img src="assets/img/feedback/code-added.svg" />
                    <div className="code-added">Added</div>
                  </Box>
                } */}

                    </div>}

                  <div className="form-row-style">
                    <div className={`label ${showCodingError() && (!data?.isQuestionCompleted || data?.isQuestionCompleted?.trim() === "") ? "error-color" : ""}`}>Was the question completed?<span className="asterisk">{showAsterisk()}</span> :</div>
                    <RadioGroup className='radio-button' row aria-label="question_completed" defaultValue="" value={data?.isQuestionCompleted} onChange={(value) => handleQuestionCompleted(value, index)} style={{ marginTop: "-5px" }}>
                      <FormControlLabel className='radio-button' value='true' control={<Radio color="primary" />} label="Yes" />
                      <FormControlLabel className='radio-button' value='false' control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                  </div>
                  <div className="form-row-style">
                    <div className={`label ${showCodingError() && (!data?.isSolutionOptimal || data?.isSolutionOptimal?.trim() === "") ? "error-color" : ""}`}>Was the solution optimal?<span className="asterisk">{showAsterisk()}</span> :</div>
                    <RadioGroup className='radio-button' row aria-label="solution_optimal" defaultValue="" value={data?.isSolutionOptimal} onChange={(value) => handleSolutionOptimal(value, index)} style={{ marginTop: "-5px" }}>
                      <FormControlLabel className='radio-button' value='true' control={<Radio color="primary" />} label="Yes" />
                      <FormControlLabel className='radio-button' value='false' control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                  </div>
                  {
                    isFlipkartFeedbackDesign(interviewDetails, data) &&
                    <>
                      <Box display="flex" flexDirection="column" style={{ backgroundColor: "#F3F6FF", margin: "10px -30px 20px -30px", padding: "30px" }}>
                        <div className="field-header">Requirement Gathering</div>
                        {renderTextAreaFields(index, data, "assumptions", "Assumptions", "Enter the candidate's assumption... \n(Use line break to add multiple points.)")}
                        {renderTextAreaFields(index, data, "heuristics", "Heuristics", "Enter the candidate's heuristic skills... \n(Use line break to add multiple points.)")}
                      </Box>

                      <Box display="flex" flexDirection="column" style={{ backgroundColor: "#F3F6FF", margin: "10px -30px 20px -30px", padding: "30px" }}>
                        <div className="field-header">Class Design</div>
                        {renderTextAreaFields(index, data, "entityModel", "Entity Model", "Enter points about candidate’s entity model skills... \n(Use line break to add multiple points.)")}
                        {renderTextAreaFields(index, data, "apiDesign", "API Design", "Enter points about candidate’s API design skills... \n(Use line break to add multiple points.)")}
                        {renderTextAreaFields(index, data, "concurrency", "Concurrency", "Enter points about candidate’s concurrency skills... \n(Use line break to add multiple points.)")}
                      </Box>

                      <Box display="flex" flexDirection="column" style={{ backgroundColor: "#F3F6FF", margin: "10px -30px 20px -30px", padding: "30px" }}>
                        <div className="field-header">System Decomposition</div>
                        {renderTextAreaFields(index, data, "subcomponentBreakdown", "Subcomponent Breakdown", "Enter points about candidate’s subcomponent breakdown... \n(Use line break to add multiple points.)")}
                        {renderTextAreaFields(index, data, "asyncSyncInteraction", "Async/Sync Interaction", "Enter points about candidate’s async/sync interaction... \n(Use line break to add multiple points.)")}
                        {renderTextAreaFields(index, data, "designTradeoffs", "Design TradeOffs", "Enter points about candidate’s design teadeoffs skills... \n(Use line break to add multiple points.)")}
                      </Box>

                      <Box display="flex" flexDirection="column" style={{ backgroundColor: "#F3F6FF", margin: "10px -30px -20px -30px", padding: "30px" }}>
                        <div className="field-header">Component Tech Choices</div>
                        {renderTextAreaFields(index, data, "familiarity", "Familiarity", "Enter the points about candidate’s familiarity... \n(Use line break to add multiple points.)")}
                        {renderTextAreaFields(index, data, "correctness", "Correctness", "Enter the candidate’s correctness... \n(Use line break to add multiple points.)")}
                      </Box>
                    </>
                  }

                  {
                    !isFlipkartFeedbackDesign(interviewDetails, data) && showCodingQuestions(interviewDetails, codingQuestionData) &&
                    <>

                      {showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                        <>
                          <div className="form-row-style">
                            <div className={`label ${showCodingError() && data?.spaceComplexity?.trim() === "" ? "error-color" : ""}`}>Space complexity<span className="asterisk">{showAsterisk()}</span> :</div>

                            <TextField
                              id="outlined-size-small"
                              size="small"
                              type="textarea"
                              variant="outlined"
                              placeholder="Space complexity of the problem.."
                              className="form-value width-30"
                              value={data.spaceComplexity}
                              name="spaceComplexity"
                              onChange={(value) => handleChange(value, index)}
                            />
                          </div>
                          <div className="form-row-style">
                            <div className={`label ${showCodingError() && data?.timeComplexity?.trim() === "" ? "error-color" : ""}`}>Time complexity<span className="asterisk">{showAsterisk()}</span> :</div>

                            <TextField
                              id="outlined-size-small"
                              size="small"
                              type="textarea"
                              variant="outlined"
                              placeholder="Time complexity of the problem.."
                              className="form-value width-30"
                              value={data.timeComplexity}
                              name="timeComplexity"
                              onChange={(value) => handleChange(value, index)}
                            />
                          </div>
                        </>
                      }
                      {renderArrayFields(index, "approach", "Approach", "Enter the approach about candidate’s approach..")}
                      {renderArrayFields(index, "positives", "Positives", "Enter the positives about candidate’s approach..")}
                      {renderArrayFields(index, "neutrals", "Neutrals", "Enter the neutrals about candidate’s approach..")}
                      {renderArrayFields(index, "negatives", "Negatives", "Enter the negatives about candidate’s approach..")}

                    </>
                  }

                  {
                    !isFlipkartFeedbackDesign(interviewDetails, data) && showDesignQuestions(feedbackType) &&
                    <>
                      {renderArrayFields(index, "designProcess", "Design Process", "Enter the candidate’s design process here...")}
                      {renderArrayFields(index, "requirementGathering", "Requirement Gathering", "Enter about requirement gatherings here...")}
                      {
                        feedbackType === FeedbackType.HIGH_LEVEL_DESIGN &&
                        renderArrayFields(index, "highLevelDesign", "High Level Design", "Enter about the high level design here..")
                      }
                      {
                        feedbackType === FeedbackType.LOW_LEVEL_DESIGN &&
                        renderArrayFields(index, "lowLevelDesign", "Low Level Design", "Enter about the low level design here..")
                      }
                      {renderArrayFields(index, "scalability", "Scalability", "Enter about the scalability here..")}
                      {renderArrayFields(index, "dbchoices", "DB Choices", "Enter about the database choices here..")}
                      {renderArrayFields(index, "caching", "Caching", "Enter about the caching choices here..")}
                      {renderArrayFields(index, "extraComments", "Extra Comments", "Enter extra comments here...")}
                    </>
                  }

                  {showProblemSolvingCodingFields(interviewDetails, codingQuestionData) &&
                    <div className="form-row-style">
                      <div className={`label`}>Solution Code :</div>
                      <div className={`width-value-field`}>
                        <TextEditor
                          placeholder="Paste the code done by the candidate..."
                          value={data?.solutionCode || ""}
                          name="solutionCode"
                          setValue={(value) => handleChange({ target: { name: "solutionCode", value: value } }, index)}
                        
                        />
                      </div>
                    </div>}
                </Box>
              </Box>
            );
          })}

          <button className="add-more-text-button" style={{ margin: "20px 0px" }} onClick={(e) => handleAddMore()}>
            Add more Questions
          </button>

        </>


      }
    </div>
  );
};

export default CodingQuestions;
