import cogoToast from "cogo-toast";
import React, { useState } from "react";
import { forgotPassword } from "../../services/auth";
import { validateEmail } from "../../utilis/validation";
import { useHistory } from "react-router-dom";
// Material
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import windowDimensions from "../../utilis/windowDimension";
import bannerImg from "../../images/lost.svg";
import ivLogoImg from "../../images/ivLogo.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./ForgotPassword.scss";
const ConfirmAccount = (props) => {
  const history = useHistory();
  const { width } = windowDimensions();
  const [email, setEmail] = useState("");
  // const [error, setError] = useState(false);
  // const [loading, setLoading] = useState(false);

  const saveResponse = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) return;

    try {
      await forgotPassword({ email: email.trim().toLowerCase() });
      cogoToast.success(
        "An email has sent to your account with reset password instructions"
      );
    } catch (err) {
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={width > 850 ? "row-reverse" : "column"}
      justifyContent={width > 850 ? "space-around" : "center"}
      alignItems="center"
      className="forgot-password-wrapper"
    >
      {width < 850 && (
        <Box className="back-arrow" onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </Box>
      )}
      <Box display="flex" justifyContent="center" className="width-50">
        <img
          className={width > 850 ? "banner-img-full" : "banner-img"}
          src={bannerImg} alt="banner img"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className="width-50"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography className="sign-in-text">
            Password Reset Request
          </Typography>
          <Typography className="access-dashboard-text">
            Enter your email address below and we will mail you the password
            reset instructions
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <TextField
            onBlur={() => {
              // if (!validateEmail(email)) setError(true);
            }}
            onChange={(e) => {
              // setError(false);
              setEmail(e.target.value);
            }}
            className={width > 850 ? "input-full mb-24" : "input-mobile mb-24"}
            label="Email Address"
            variant="outlined"
          />

          <Button
            className="sign-in-btn"
            variant="contained"
            color="primary"
            onClick={(e) => saveResponse(e)}
          >
            {/* {loading && <span className="spinner-border spinner-border-sm mr-2"></span>} */}
            Request
          </Button>
        </Box>

        <Box
          className="bottom-logo"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img className='iv-logo' src={ivLogoImg} alt="iv logo img" />
          <Typography className='company-logo-text'>InterviewVector</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmAccount;
