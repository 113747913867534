import React from "react";
import { Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Html from "react-pdf-html";

import completeIcon from "../../../../../assets/images/feedback/feedback-complete-icon.png";
import incompleteIcon from "../../../../../assets/images/feedback/feedback-incomplete-icon.png";
import starGolden from "../../../../../assets/images/feedback/star-golden.png";
import starGray from "../../../../../assets/images/feedback/star-grey.png";
import dot from "../../../../../assets/images/feedback/dot-black.png";
import grayDot from "../../../../../assets/images/feedback/dot-blue.png";
import { isHLDDesignV2Versions, isLLDDesignV2Versions } from "../../Constants/FeedbackConstants";
import { commonDownloadStyle } from "../Preview/Constant";

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const CodingQuestionsVersion = StyleSheet.create({
  rowstyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  previewSubheaderTitle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 13.5,
    letterSpacing: 1.1,
    textTransform: "uppercase",
    color: "#0d3270",
  },

  previewSubheaderSubtitle: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 11,
    letterSpacing: 0.3,
    color: "#374671",
  },

  borderBox: {
    border: " 1px solid #C7C7C7",
    borderRadius: 8,
    width: 1063,
    marginTop: 30,
    marginLeft: 20,
    paddingBottom: 25,
  },

  questionAskedColumn: {
    width: 1063,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #C7C7C7",
    position: "relative",
  },

  timeBox: {
    position: "absolute",
    marginLeft: "80%",
    height: 20,
  },

  timeBoxText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#3D3D3D",
    position: "absolute",
    marginLeft: "45%",
  },

  questionText: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: '12px',
    letterSpacing: 0.055,
    color: "#3D3D3D",
    marginLeft: "20px",
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    paddingRight: '10px',
    width: "95%",
  },

  problemLevelEasyBox: {
    width: 100,
    height: 19,
    backgroundColor: "#3EAD2C",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  problemLevelMediumBox: {
    width: 124,
    height: 19,
    backgroundColor: "#3D3D3D",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  problemLevelHardBox: {
    width: 101,
    height: 19,
    backgroundColor: "#B62C2C",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  problemLevelText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#FEFEFE",
  },

  ratingImageBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10",
    width: 220,
  },

  statusBoxComplete: {
    width: 226,
    height: 25,
    border: "1px solid #3EAD2C",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 40,
  },

  statusBoxIncomplete: {
    width: 226,
    height: 25,
    border: "1px solid #B62C2C",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 40,
  },

  statusBoxPartial: {
    width: 273,
    height: 25,
    border: "1px solid #3D3D3D",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 40,
  },

  ratingNumber: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: 0.025,
    marginLeft: 10,
    marginTop: 5,
  },

  statusTextComplete: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#3EAD2C",
    marginLeft: 3,
  },

  statusTextIncomplete: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#B22B2B",
    marginLeft: 5,
  },

  statusTextPartial: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#3D3D3D",
    marginLeft: 6,
  },

  complexity: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#3D3D3D",
    marginLeft: 40,
  },

  explanationParentBox: {
    display: "flex",
    flexDirection: "row",
  },

  explanationBox: {
    marginTop: 17,
    width: 350,
    display: "flex",
    flexWrap: "wrap",
  },

  explanationBoxInternal: {
    display: "flex",
    flexDirection: "row",
    width: 350,
    paddingRight: 20,
  },

  explanationText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#3D3D3D",
    marginLeft: 7,
  },

  explanationTextNone: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: 0.025,
    color: "#8FAECF",
    marginLeft: 10,
  },

  headingBox: {
    display: "flex",
    flexDirection: "row",
    width: 1021,
    height: 41,
    backgroundColor: "#F5F8FF",
    marginLeft: 20,
    borderRadius: 6,
    marginTop: 25,
    alignItems: "center",
  },

  headingText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#374671",
  },

  vertical: {
    borderRight: "1px solid #BCC7E6",
    height: 40,
  },

  commentBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 15,
    marginTop: 20,
    paddingRight: 120
  },

  codingPart: {
    fontFamily: "Poppins",
    fontStyle: 'italic',
    backgroundColor: '#000',
    fontSize: 10,
    lineHeight: '132%',
    letterSpacing: 0.3,
    color: '#fff',
    padding: 16,
    margin: '10'
},

designHeading: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '132%',
    letterSpacing: 0.025,
    textTransform:'uppercase',
    color: '#0D3270',
    marginBottom: '5',
    marginTop:'10',
}, 

designLabel: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '132%',
    letterSpacing: 0.025,
    color: '#3D3D3D',
    width: '20%',
    marginBottom: '5'
},


designValue: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '122%',
    letterSpacing: 0.025,
    color: '#3D3D3D',
    whiteSpace:'pre-wrap',
    width:'80%'
},
  width33: {
    width: "33%",
  }
});

const DesignSection = ({ interviewDetails, codingData }) => {
  // rating starts
  const rating = (data) => {
    let rating = data;
    let count = 0;
    let arr = [];

    if (count < 10) {
      while (count < rating) {
        arr.push(
          <View>
            <Image
              src={starGolden}
              style={[{ width: 20, height: 20, marginLeft: 5 }]}
            />
          </View>
        );
        count++;
      }

      while (count < 10) {
        arr.push(
          <View>
            <Image
              src={starGray}
              style={[{ width: 20, height: 20, marginLeft: 5 }]}
            />
          </View>
        );
        count++;
      }
    }
    return arr;
  };
  //   rating ends

  // comments starts
  const comments = (data) => {
    return (
      <View>
        {data?.map((item) => {
          if(item && item?.length>0)
          return (
            <View style={[{ display: "flex", flexDirection: "row" , marginTop: 0}]}>
              <Image
                src={dot}
                style={[{ height: 3, width: 3, marginTop: 8, marginLeft: 15 }]}
              />
              <Text style={[CodingQuestionsVersion.explanationText]}>
                {item || ''}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };
  // comments ends

  //  table starts
  const table = (data, status) => {
    return (
      <View>
        {data.length == 0 ? (
          <View
            style={[
              CodingQuestionsVersion.explanationBoxInternal,
              { marginLeft: -15 },
            ]}
          >
            <Image
              src={grayDot}
              style={[{ width: 5, height: 5, marginTop: 12, marginLeft: 15 }]}
            />
            <Text
              style={[
                CodingQuestionsVersion.explanationTextNone,
                { marginTop: 5 },
              ]}
            >
              None
            </Text>
          </View>
        ) : (

            typeof (data) === 'string' ?
              <View>
                {!data ? <></> :
                  <View
                    style={[
                      CodingQuestionsVersion.explanationBoxInternal,
                      { marginLeft: -10, marginTop: 5 },
                    ]}
                  >
                    <View>
                      <Image
                        src={
                          status === "approach"
                            ? dot
                            : status === "positives"
                              ? completeIcon
                              : status === "neutrals"
                                ? dot
                                : incompleteIcon
                        }
                        style={
                          status === "positives"
                            ? [{ width: 15, height: 15 }, { marginTop: 3 }]
                            : status === "neutrals"
                              ? [{ width: 5, height: 5 }, { marginTop: 6 }]
                              : status === "approach"
                                ? [{ width: 3, height: 3 }, { marginTop: 8 }]
                                : [{ width: 8, height: 8 }, { marginTop: 6 }]
                        }
                      />
                    </View>

                    <View>
                      <Text style={[CodingQuestionsVersion.explanationText]}>
                        {data}
                      </Text>
                    </View>
                  </View>
                }
              </View>
          :

          <View>
            {data?.map((items, index) => {
              return (
                <>
                  {!items ? <></> : 
                    <View
                      style={[
                        CodingQuestionsVersion.explanationBoxInternal,
                        { marginLeft: -10, marginTop: 5 },
                      ]}
                    >
                      <View>
                        <Image
                          src={
                            status === "approach"
                              ? dot
                              : status === "positives"
                                ? completeIcon
                                : status === "neutrals"
                                  ? dot
                                  : incompleteIcon
                          }
                          style={
                            status === "positives"
                              ? [{ width: 15, height: 15 }, { marginTop: 3 }]
                              : status === "neutrals"
                                ? [{ width: 5, height: 5 }, { marginTop: 6 }]
                                : status === "approach"
                                  ? [{ width: 3, height: 3 }, { marginTop: 8 }]
                                  : [{ width: 8, height: 8 }, { marginTop: 6 }]
                          }
                        />
                      </View>

                      <View>
                        <Text style={[CodingQuestionsVersion.explanationText]}>
                          {items}
                        </Text>
                      </View>
                    </View>
                  }
                </>
               
              );
            })}
          </View>
        )}
      </View>
    );
  };
  //  table ends

  //   time function starts
  const totalTime = (data) => {
    let totalTime = 0;
    data.map((item) => {
      totalTime += item?.time_spent;
    });

    return totalTime;
  };
  //  time function ends...


  const renderLabelValue = (label, value) => {
    var array = (value && value?.split(/\n/)) || [];
    var newArray = [];
    array?.forEach(function (element) {
     
      if(element && element?.length>0){
        newArray.push("- " + element);
      }

    });

    return (
      value ?
        <View style={[CodingQuestionsVersion.rowstyle, {marginBottom: '10px'}]}>
          <Text style={[CodingQuestionsVersion.designLabel, {alignSelf:'flex-start'}]}>{label}:</Text>

          <View>
          {newArray?.map((data, index) => { return (<Text style = {[CodingQuestionsVersion.designValue]}>{data}</Text>) })
          }
          </View>
        </View>
        : <></>
    )
  }

  return (
    <View
      style={[
        {
          margin: 15,
          border: "1 solid #cdcdcd",
          backgroundColor: "#fff",
          paddingBottom: 100,
        },
      ]}
    >
      {/* heading section */}

      <View
        style={[
          CodingQuestionsVersion.rowstyle,
          {
            width: "100%",
            backgroundColor: "#C5D4FF",
            minHeight: 42,
            padding: 15,
          },
        ]}
      >
        <Text style={[CodingQuestionsVersion.previewSubheaderTitle]}>
          {" "}
          Design Questions
        </Text>
        <Text
          style={[
            CodingQuestionsVersion.previewSubheaderSubtitle,
            { marginLeft: 20 },
          ]}
        >
          {" "}
          Time Spent: {totalTime(codingData?.questions)} mins
        </Text>
      </View>

      <View>
        {codingData?.questions &&
          codingData?.questions?.map((data, index) => {
            return (
              //    map return parent view box starts
              <View>
                {/* questions area parentBox starts */}
                <View>
                  {/* question parent outer border box starts*/}
                  <View style={[CodingQuestionsVersion.borderBox]}>
                    {/* question asked box starts */}
                    <View style={[CodingQuestionsVersion.questionAskedColumn]}>

                    <View style={[CodingQuestionsVersion.questionText,  { maxWidth: 790, marginLeft: 10, marginTop: 10 }]}>
                          <Text style={[(data?.question_statement || data?.question_asked)?.length > 0 && (data?.question_statement || data?.question_asked)[0] === '<' ? { marginTop: '10' } : { marginTop: 0 }]}> {index + 1}. </Text>
                          <Html style={{ fontSize: "12px" }}>
                            {( data?.question_statement ? data?.question_statement+commonDownloadStyle : data?.question_asked ? data?.question_asked+commonDownloadStyle : '')}
                          </Html>
                      </View>

                      <View style={[CodingQuestionsVersion.timeBox]}>
                        <Text style={[CodingQuestionsVersion.headingText]}>
                          Time Spent:
                        </Text>
                        <Text style={[CodingQuestionsVersion.timeBoxText]}>
                          {data?.time_spent}
                        </Text>
                        <Text
                          style={[
                            CodingQuestionsVersion.timeBoxText,
                            { marginLeft: "55%" },
                          ]}
                        >
                          Mins
                        </Text>
                      </View>

                      {/* </View> */}

                      {/* question asked box ends */}
                    </View>

                    {/* rating box  starts*/}
                    <View
                      style={[
                        CodingQuestionsVersion.rowstyle,
                        { marginTop: 25, marginLeft: 20, flexWrap:'wrap' },
                      ]}
                      wrap={false}
                    >
                      <View
                        style={
                          data?.difficulty?.tag === "easy"
                            ? [CodingQuestionsVersion.problemLevelEasyBox]
                            : data?.difficulty?.tag === "medium"
                            ? [CodingQuestionsVersion.problemLevelMediumBox]
                            : [CodingQuestionsVersion.problemLevelHardBox]
                        }
                      >
                        <Text style={[CodingQuestionsVersion.problemLevelText]}>
                          {data?.difficulty?.text}
                        </Text>
                      </View>
                      <View style={[CodingQuestionsVersion.ratingImageBox]}>
                        {rating(data?.rating || 0)}
                      </View>
                      <View style={[CodingQuestionsVersion.ratingNumber]}>
                        <Text>
                          ({data?.rating || 0}/
                          <span style={[{ color: "#8F8F8F" }]}>10</span>)
                        </Text>
                      </View>

                      {/* rating box ends */}
                    </View>

            {/* wrap box start */}
             <View wrap={false}>
                    {/* heading section starts */}
                    <View style={[CodingQuestionsVersion.headingBox]}>
                     
                      <View
                        style={[
                          CodingQuestionsVersion.vertical,
                        ]}
                      />

                      <View>
                        <Text
                          style={[
                            CodingQuestionsVersion.headingText,
                            { marginLeft: "15" },
                          ]}
                        >
                          Positives
                        </Text>
                      </View>

                      <View
                        style={[
                          CodingQuestionsVersion.vertical,
                          { marginLeft: "300"},
                        ]}
                      />
                      <View>
                        <Text
                          style={[
                            CodingQuestionsVersion.headingText,
                            { marginLeft: "15" },
                          ]}
                        >
                          Neutral
                        </Text>
                      </View>
                      <View
                        style={[
                          CodingQuestionsVersion.vertical,
                          { marginLeft: "300"},
                        ]}
                      />
                      <View>
                        <Text
                          style={[
                            CodingQuestionsVersion.headingText,
                            { marginLeft: "15" },
                          ]}
                        >
                          Negatives
                        </Text>
                      </View>
                      {/* heading section ends */}
                    </View>

                    {/* explanation box starts */}
                    <View style={[CodingQuestionsVersion.explanationParentBox]}>
                      {/* positive starts */}
                      <View style={[CodingQuestionsVersion.width33]}>
                        <View
                          style={[
                            CodingQuestionsVersion.explanationBox,
                            { marginLeft: 50 },
                          ]}
                        >
                          {table(data?.positives, "positives")}
                        </View>
                        {/* positive ends */}
                      </View>

                      {/* neutral starts */}
                      <View style={[CodingQuestionsVersion.width33]}>
                        <View
                          style={[
                            CodingQuestionsVersion.explanationBox,
                            { marginLeft: 50 },
                          ]}
                        >
                          {table(data?.neutrals, "neutrals")}
                        </View>
                        {/* neutral ends */}
                      </View>

                      {/* negative starts */}
                      <View style={[CodingQuestionsVersion.width33]}>
                        <View
                          style={[
                            CodingQuestionsVersion.explanationBox,
                            { marginLeft: 50 },
                          ]}
                        >
                          {table(data?.negatives, "negatives")}
                        </View>
                        {/* negative ends */}
                      </View>

                      {/* explanation Box ends */}
                    </View>
                  {/* wrap box ends */}
              </View>  


          {
            isHLDDesignV2Versions(interviewDetails) && <>{
            data?.requirement_gathering && Object.keys(data?.requirement_gathering)?.length > 0 &&
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>Requirement Gathering</Text>
              {renderLabelValue('Functional', data?.requirement_gathering?.functional)}
              {renderLabelValue('Non-Functional', data?.requirement_gathering?.non_functional)}
            </View>
          }

          {
            data?.estimation && Object.keys(data?.estimation)?.length > 0 &&
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>Estimation</Text>
              {renderLabelValue('Assumptions', data?.estimation?.assumptions)}
              {renderLabelValue('Traffic Estimation', data?.estimation?.traffic_estimation)}
              {renderLabelValue('Capacity Planning', data?.estimation?.capacity_planning)}
            </View>
          }


          {
            data?.high_level_design && Object.keys(data?.high_level_design)?.length > 0 &&
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>High Level Design</Text>
              {renderLabelValue('Architecture', data?.high_level_design?.architecture)}
              {renderLabelValue('DB Choices', data?.high_level_design?.db_choices)}
              {renderLabelValue('Load Balancing & Caching', data?.high_level_design?.caching)}

            </View>
          }


          {
            data?.performance_and_security && Object.keys(data?.performance_and_security)?.length > 0 &&
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>Performance & Security</Text>
              {renderLabelValue('Sharding', data?.performance_and_security?.sharding)}
            </View>
          }

          {
            data?.additional_information && Object.keys(data?.additional_information)?.length > 0 && 
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>Additional info</Text>
              {renderLabelValue('Data Modeling & APIs', data?.additional_information?.data_modeling)}
              {renderLabelValue('Auth', data?.additional_information?.auth)}
              {renderLabelValue('Rate limiter & WAF', data?.additional_information?.rate_limit)}
            </View>
          }</>}

          {
            isLLDDesignV2Versions(interviewDetails) && <>
          {
            data?.requirement_gathering && Object.keys(data?.requirement_gathering)?.length > 0 &&
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>Requirement Gathering</Text>
              {renderLabelValue('Functional Requirements', data?.requirement_gathering?.functional_requirements)}
              {renderLabelValue('Edge Cases', data?.requirement_gathering?.edge_cases)}
            </View>
          }

          {
            data?.low_level_design && Object.keys(data?.low_level_design)?.length > 0 &&
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>Low Level Design</Text>
              {renderLabelValue('Entity Model', data?.low_level_design?.entity_model)}
              {renderLabelValue('Class Design', data?.low_level_design?.class_design)}
            </View>
          }

          {
            data?.api_design && Object.keys(data?.api_design)?.length > 0 &&
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>API Design</Text>
              {renderLabelValue('API Design', data?.api_design?.api_design)}
            </View>
          }

          {
            data?.db_schema && Object.keys(data?.db_schema)?.length > 0 && 
            <View style={[{padding:'15'}]}>
              <Text style={[CodingQuestionsVersion.designHeading]}>DB Schema</Text>
              {renderLabelValue('DB Schema', data?.db_schema?.db_schema)}
            </View>
          }</>}

                    {/* comment box starts  */}
                    <View style={[CodingQuestionsVersion.commentBox]}>
                      <View>
                        <Text style={[CodingQuestionsVersion.headingText]}>
                          {data?.comment?.length > 0 ? "Extra Comments:" : ""}
                        </Text>
                      </View>
                      <View>{comments(data?.comment)}</View>

                      {/* comment box ends  */}
                    </View>

                    {/* question parent outer border box starts*/}
                  </View>

                  {/* questions area parentBox ends */}
                </View>
                {/* map return parent view box ends */}
              </View>
            );
          })}
      </View>

      <View></View>
    </View>
  );
};

export default DesignSection;
