import React, { useState } from "react";
import moment from "moment";
import { Text, View, Image } from '@react-pdf/renderer';

import { stylesPDF } from "../../SectionsV1/Download/Style";
import { ratingToImage } from '../../Components/Download';
import ratingReject6 from "../../../../../assets/images/download-feedback/rating_6_reject.png";
import lightbulb from "../../../../../assets/images/download-feedback/lightbulb.png";
import { INTERVIEWER_TYPE, overallRecommendation } from "./../../Constants/FeedbackConstants";


export const Details = ({ interviewDetails, feedbackSummaryData }) => {
  const interviewerType = interviewDetails?.interview_details?.interviewer_type === INTERVIEWER_TYPE.CLIENT_INTERNAL ?
   INTERVIEWER_TYPE.CLIENT_INTERNAL_TEXT : INTERVIEWER_TYPE.IV_EXPERTS_TEXT;

    return (
        <>
        <View style={[stylesPDF.interviewerBox]}>
           <Image src={lightbulb} style={[stylesPDF.bulbIcon]}/>
           { interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL_TEXT ? 
            <Text style={[stylesPDF.interviewerText]}>This interview was conducted by client's <span style={[stylesPDF.interviewerType]}>{interviewerType}.</span></Text>
            :<Text style={[stylesPDF.interviewerTextBlue]}>This interview was conducted by an <span style={[stylesPDF.interviewerType]}>{interviewerType}.</span></Text>
           }
        </View>
        <View style={[stylesPDF.rowstyle, { margin: '15', justifyContent: "space-between" }]}>

            <View style={[stylesPDF.rowstyle, stylesPDF.detailsBoxStyle, { width: "35%", backgroundColor: "#fff" },
            feedbackSummaryData?.recommendation === overallRecommendation.STRONGLY_PROCEED
                ? stylesPDF.strongProceedBg : feedbackSummaryData?.recommendation === overallRecommendation.PROCEED
                    ? stylesPDF.proceedBg : feedbackSummaryData?.recommendation === overallRecommendation.REJECT
                        ? stylesPDF.rejectBg : feedbackSummaryData?.recommendation === overallRecommendation.STRONGLY_REJECT
                            ? stylesPDF.strongRejectBg : stylesPDF.strongProceedBg]}>

                <View style={[stylesPDF.columnstyle]}>
                    <Text style={[stylesPDF.overallRecommendationHeading]}>
                        Overall Recommendation and Rating
                    </Text>

                    <View style={[stylesPDF.horizontalDivider]} />

                    <Text style={[stylesPDF.overallRecommendationText,
                    feedbackSummaryData?.recommendation === overallRecommendation.STRONGLY_PROCEED
                        ? stylesPDF.strongProceedColor : feedbackSummaryData?.recommendation === overallRecommendation.PROCEED
                            ? stylesPDF.proceedColor : feedbackSummaryData?.recommendation === overallRecommendation.REJECT
                                ? stylesPDF.rejectColor : feedbackSummaryData?.recommendation === overallRecommendation.STRONGLY_REJECT
                                    ? stylesPDF.strongRejectColor : stylesPDF.strongProceedColor]}  >
                        {feedbackSummaryData?.recommendation
                            ? feedbackSummaryData?.recommendation === overallRecommendation.STRONGLY_PROCEED
                                ? "STRONG PROCEED" : feedbackSummaryData?.recommendation === overallRecommendation.STRONGLY_REJECT
                                    ? "STRONG REJECT" : feedbackSummaryData?.recommendation
                            : "NA"}
                    </Text>
                </View>

                <Image
                    src={(feedbackSummaryData?.rating===6 && feedbackSummaryData?.recommendation === overallRecommendation.REJECT)
                        ? ratingReject6 : ratingToImage[`${feedbackSummaryData?.rating ? feedbackSummaryData?.rating?.toString() : '0'}`]}
                    style={[{ marginLeft: "15", width: '65', height: '65', alignSelf: "center", position: "right" }]}
                />

            </View>

            <View style={[stylesPDF.columnstyle, stylesPDF.detailsBoxStyle, { width: "65%", marginLeft: "15", backgroundColor: "#fff" }]}>

            <Text style={[stylesPDF.detailsBoxTitle]}>INTERVIEW DETAILS</Text>

            <View style={[stylesPDF.rowstyle, { justifyContent: "space-around"}]}>

                <View style={[stylesPDF.columnstyle, { width: "50%" }]}>

                    <View style={[stylesPDF.rowstyle]}>
                        <Text style={[stylesPDF.detailsBoxLabelValue]}>Candidate Name:</Text>
                        <Text style={[stylesPDF.detailsBoxLabelValue, stylesPDF.detailsValue]}>
                            {interviewDetails?.candidate_details?.name || 'NA'}
                        </Text>
                    </View>

                  <View style={[stylesPDF.rowstyle]}>
                        <Text style={[stylesPDF.detailsBoxLabelValue]}>Interview Date:</Text>
                        <Text style={[stylesPDF.detailsBoxLabelValue, stylesPDF.detailsValue]}>
                            {interviewDetails?.interview_details?.scheduled_at
                                ? moment(
                                    interviewDetails?.interview_details?.scheduled_at * 1000
                                ).format("DD-MM-YYYY | hh:mm a")
                                : "NA"}
                        </Text>
                    </View>
                </View>

                <View style={[stylesPDF.vertical]} />

                <View style={[stylesPDF.columnstyle, { width: "50%", marginLeft:'20px' }]} >
                    <View style={[stylesPDF.rowstyle]}>
                        <Text style={[stylesPDF.detailsBoxLabelValue]}>Position:</Text>
                        <Text style={[stylesPDF.detailsBoxLabelValue, stylesPDF.detailsValue]}>
                            {interviewDetails?.interview_details?.position_name || 'NA'}
                        </Text>
                    </View>
                    <View style={[stylesPDF.rowstyle]}>
                        <Text style={[stylesPDF.detailsBoxLabelValue]}>Round:</Text>
                        <Text style={[stylesPDF.detailsBoxLabelValue, stylesPDF.detailsValue]}>
                            {interviewDetails?.interview_details?.round_name || 'NA'}
                        </Text>
                    </View>
                    
                    </View>
                </View>
            </View>
        </View>
        </>
    );
};