import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components';

const CustomReactQuill = styled(ReactQuill)`{
    img{
      max-height: 260px;
      max-width: 60%;  
      margin: 6px;
      display: block;
    }
    p{
        word-break: break-all;
    }
}`

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];


const FeedbackTextEditor = ({ value, onChange, uploadFileV2, readOnly = false, placeholder, width = 'width-value-field', defaultValue, onBlur,  onKeyUp}) => {
    let quillRef = useRef();
    const [text, setText] = useState(value);

    useEffect(() => {
        onChange(text)
    }, [text])

    const handleQuillImageUpload = () => {
        const input = document.createElement("input");
        input.setAttribute("type","file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async (e) => {
            const file = e?.target?.files?.[0] || e[0];
            const response = await uploadFileV2(file,{
                public: "True",
            });
            if(response)
            {   
                const uploadedFileURL = response?.data?.["url"] + response?.data?.fields["key"];
                let quill = quillRef?.current?.getEditor();
                const range = quill?.getSelection(true);
                quill?.insertEmbed(range.index, 'image', uploadedFileURL);
            }
        }
    };
            
    const modules = useMemo(() => ({ 
        toolbar: {
            container: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video']
            ],
            handlers: {
                image: handleQuillImageUpload,
            }  
        },
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }),[]);

    return (
        <div className={width}>
            {( onBlur) ?

                <CustomReactQuill
                    readOnly={readOnly}
                    placeholder={placeholder || ""}
                    theme='snow'
                    modules={modules}
                    formats={formats}
                    
                    value={text}
                    onChange={setText}

                    onBlur={(e) => onBlur({...e, type: 'blur'})}
                    onKeyUp={onKeyUp}
                    ref={quillRef}
                />

                :

                <CustomReactQuill
                    readOnly={readOnly}
                    placeholder={placeholder || ""}
                    theme='snow'
                    modules={modules}
                    formats={formats}
                    onChange={setText}
                    value={text}
                    ref={quillRef}
                />
            }

        </div>
    );
}
export default FeedbackTextEditor;