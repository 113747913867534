import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
import { openResume,getFileURL } from '../../services/file'
import { useDispatch, useSelector } from 'react-redux';
import { scheduleAction } from '../../store/actions/Schedule'
import axios from '../../services/axios'
import cogoToast from 'cogo-toast'
import CircularProgress from '@material-ui/core/CircularProgress';
import { InterviewFeedbackStatus } from '@iv/shared/components/pages/Feedback/Constants/FeedbackConstants'
import getWindowDimensions from "../../utilis/windowDimension";
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close';

// CSS file
import './DetailedScheduled.scss';

import { notifyClientCandidateNotJoined } from '../../services/interview'
import './cancelInterview.scss'

// Import all the images which are used in this files
import coderpad_link from '../../images/sideBarRevamp/Coderpad_link.svg';
import Date_Img from '../../images/sideBarRevamp/Date_Img.svg';
import Join_Interview from '../../images/sideBarRevamp/Join_Interview.svg';
import Resume_disable from '../../images/sideBarRevamp/Resume_disable.svg';
import Resume from '../../images/sideBarRevamp/Resume.svg';
import Round_Guide_disable from '../../images/sideBarRevamp/Round_Guide_disable.svg';
import Round_Guide from '../../images/sideBarRevamp/Round_Guide.svg';
import Time from '../../images/sideBarRevamp/Time.svg';
import { INTERVIEWER_TYPE } from '../User/Constant';
import { getApplicationByIdAPI } from '../../services/Application';

const DetailedSchedule = ({data, setShow, setSelected, fetchData, handleClickOpen,currentTimezone, schedule, page, feedbackData}) => {
    const flag = data?.interview_details?.extra_info?.transfer_interview_flag;
    const {width} = getWindowDimensions();
    const dispatch = useDispatch();
    const interviewerType = useSelector((state) => state && state.profile)?.interviewer_type;
    const [resumeLoader, setResumeLoader] = useState(false);
    
    useEffect(() => {
        if(!data?.all_round_details){
          getRoundHistroy();
          if(interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL){
            getApplicationHistory();
          }
        }
      }, [data])
    // function for getting the difference b/w the Date and Time

    const checkDiffDate = ()=>{
      const meetDeactivate = {
        border:"1px solid rgb(202, 200, 200",
        backgroundColor:"#9A9A9A",
        cursor:"not-allowed",
      }

        return (
          <>
              <div class="rounds-button-group">
              {data?.interview_details?.meeting_link ?
              <button className="second-group">
                <a href={data?.interview_details?.meeting_link} target={'_blank'} rel="noreferrer" style={{all:'unset'}}>
                <img src={Join_Interview} alt="Join" />
                  Join Interview</a>
              </button>
              :
              <button className="second-group" style={meetDeactivate}>
              <a rel="noreferrer" style={{all:'unset'}}>
              <img src={Join_Interview} alt="Join" />
                Join Interview</a>
              </button>
              }
              {data?.interview_details?.extra_info?.coderpad_link ?
              <button className="second-group">
                <a href={data?.interview_details?.extra_info?.coderpad_link} target={'_blank'} rel="noreferrer" style={{all:'unset'}}>
                <img src={coderpad_link} alt="CoderPad" />
                CoderPad Link
                </a>
              </button>
              :
              <button className="second-group" style={meetDeactivate}>
              <a rel="noreferrer" style={{all:'unset'}}>
              <img src={coderpad_link} alt="CoderPad" />
              CoderPad Link
              </a>
              </button>
              }
            </div>
          </>
        )
    }

    const getRoundHistroy = async () => {
        if(!data) {
          return;
        }
        try{
            const req_body = {
                "candidate_id": data.candidate_details.id,
                "position_id": data.position_details.id,
                "client_id": data.client_details.id
            }
            const res = await axios.post('/interview/history', req_body)
            const newSchedule = [...schedule];
            const index = newSchedule?.findIndex(item => item?.id === data?.id)
            newSchedule[index].all_round_details = res?.data.map(item => item?.interview_details);
            dispatch(scheduleAction(newSchedule))
        } catch(err){
        }
    }

    const getApplicationHistory = async () => {
      if(!data) {
        return;
      }
      try{
        const res = await getApplicationByIdAPI(data?.application_details?.id, data?.client_details?.id);
        const newSchedule = [...schedule];
        const index = newSchedule?.findIndex(item => item?.id === data?.id)
        newSchedule[index].screenings = res?.data?.data?.screenings;
        dispatch(scheduleAction(newSchedule))
      } catch(err){
      }
    }

    const notifyClient = async () => {
      let InterviewStamp = data?.interview_details?.scheduled_start_timestamp;
      let currDateStamp = parseInt(Date.now()/1000);
      let diff = currDateStamp-InterviewStamp;
      if (diff<=180){
        return cogoToast.error("You can only notify after 3 minutes when the interview has been started");
      }
        try {
            const payload = {
                "interview_id": data?.interview_details?.id,
                "client_id": data?.client_details?.id,
                "message_template": 'CANDIDATE_DID_NOT_JOIN'
            }
            const resp = await notifyClientCandidateNotJoined(payload);
            if (resp?.status === 200) {
                cogoToast.success('Client has been notified!');
                fetchData()
            }
        } catch (err) {
            cogoToast.error("Some error occured!!");
        }
    }

    const handleUrl = async (e, url) => {
        let data = await getFileURL(url);
        window.open(data, "_blank");
        e.preventDefault();
    };
    const handleResume =async ()=>{
      setResumeLoader(true);
      await openResume(data?.candidate_details?.resume_url).then((response)=>{
        setResumeLoader(false);
      })
      .catch((error)=>{
        setResumeLoader(false);
        cogoToast.error("Someting went Wrong!");
      })
    }
    const TimeFormat = (time, format)=>{
      return moment(time)?.tz(currentTimezone)?.format(format);
    }

    const feedbackColor = (val) => {
      if (val === "STRONG_PROCEED") return "bg-strong-proceed"
      else if(val === "PROCEED") return "bg-proceed"
      else if(val === "REJECT") return "bg-reject"
      else if(val === "STRONG_REJECT") return "bg-strong-reject"
    }

    const colorText = (val) => {
      if (val === "STRONG_PROCEED") return "c-45720a"
      else if(val === "PROCEED") return "c-0F7C06"
      else if(val === "REJECT") return "c-DE9811"
      else if(val === "STRONG_REJECT") return "c-B83A14"
    }

    const handleFeedbackStatus = (item,index) => {
       if((item?.feedback_status === "APPROVED" && item?.interview_status=== "COMPLETED" && index < data?.all_round_details?.length -1) || ( index === data?.all_round_details?.length-1 ) )
        {
          window.open( `/interview-feedback?i_id=${item?.id}`);
        }
        else
        {
          cogoToast.error("Feedback can't be open",{position:"top-right"});
         }
    }

    const handlePrescreenReport = (clientId, positionId, candidateId,prescreenId, prescreenRoundId ) => {
      const url = `/quickscreen-report/?client_id=${clientId}&position_id=${positionId}&candidate_id=${candidateId}&quickscreen_id=${prescreenId}&quickscreen_round_id=${prescreenRoundId}`;
      window.open(url, "_blank");
    };
  
    return (
      <>
        <div className={width > 786 ?"modal-content DetailedScheduled" :"modal-content DetailedScheduled mt-50"}>
            <Modal.Body className="modal-body">
            <div className = 'w-100'>
              <div className="close-style">
                <CloseIcon onClick={() => setShow(false)}/>
              </div>
              <div className ={!flag?"details-box":"details-box disabled"} >
                {interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL  && data?.candidate_details?.name ?
                <div className='heading-client'>{data?.candidate_details?.name}</div> :
                  <div class="heading-client">Position {'&'} Client Details
                  </div>
                }
                  {interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL && data?.created_by_user?.name && (data?.created_by_user?.email || data?.created_by_user?.email_id) && 
                  <Box display="flex" gap="10px" fontSize="13px" marginBottom="10px" >
                    <Box>{data?.created_by_user?.name} (<span>{data?.created_by_user?.email || data?.created_by_user?.email_id}</span>) - {data?.companyName} TA</Box>
                  </Box>}
                  <div class="position-client-details">
                    <div className="positon-client-details-box">{data?.companyName}</div>
                    <div className="positon-client-details-box">{data?.position_details.position_name} </div>
                    <div className="positon-client-details-box">{data?.round_details.name}</div>
                  </div>

                  <div class="rounds-button-group">
                    <button className="first-button" disabled={flag}>
                     {
                      <a href={!flag ?  ((data?.round_details?.guide_id || data?.round_details?.interview_guide_id)  ?
                        `/interview-guides/preview?guide_id=${data?.round_details?.guide_id || data?.round_details?.interview_guide_id}&position_id=${data?.position_details?.id}` 
                        :  data?.round_details?.interview_guide_link) : '#'} target={!flag?'_blank':''} rel="noreferrer" style={{all:'unset'}} disabled={flag}>
                        {
                          !flag?<img className="Items-logo-link" src={Round_Guide} alt="Round Guide" />:<img src={Round_Guide_disable} alt="Round Guide" />
                        } Round Guide </a>}
                    </button>
                    {data?.candidate_details?.resume_url &&
                     <button className="first-button" onClick = {handleResume} disabled={flag}>
                     {
                       !flag?<img className="Items-logo-link" src={Resume} alt="Resume" />:<img src={Resume_disable} alt="Resume" />
                     }
                     Resume
                     {resumeLoader? <CircularProgress className="ButtonLoader" style={{width:"15px", height:"15px", marginLeft:"10px"}} /> : ''}
                     </button>
                    }
                  </div>
                {
                  !data?.candidate_details?.resume_url &&
                  <div className='resume-optional-warning'>*The resume has not been uploaded for this candidate.</div>
                }

                <Box display="flex" justifyContent="space-between">
  
                  <div className='border-top pt-4 mb-4'>
                      <div className = 'mb-3 h5'>Interview Details</div>
                      <div className = 'row mb-2'>
                          <div className="interview-details">
                            <img src={Date_Img} alt="Date" className="Interview_details_logo"  />
                            {TimeFormat(data?.interview_details?.scheduled_start_timestamp*1000, "MMM DD YYYY")}
                          </div>
                      </div>
                      <div className = 'row mb-2'>
                          <div className="interview-details">
                            <img src={Time} alt="Time" className="Interview_details_logo" />
                            {TimeFormat(data?.interview_details?.scheduled_start_timestamp*1000,'hh:mm A')}</div>
                      </div>
                  </div>
                 
                 { page === "History" && data?.interview_details?.interview_result && feedbackData?.overall_rating && data?.result?.value && (feedbackData?.feedback_status || feedbackData?.feedback_report_link)? 
                 <Box className={`history-feedback-box ${feedbackColor(data?.interview_details?.interview_result)}`}>
                    <Box className={`result ${colorText(data?.interview_details?.interview_result)}`}>{data?.result?.value}</Box>
                    <Box className='ratings'>{feedbackData?.overall_rating} <span className='fw-400 fs-20'>/</span> <span className='c-grey'>10</span></Box>
                    { feedbackData?.feedback_report_link ? 
                     <a className='report' href={`${feedbackData?.feedback_report_link}`} target="_blank" rel="noreferrer">See feedback report <span className='fw-600 fs-15'>{" >"}</span></a>
                      : (data?.interview_details?.id) ?
                      <a className='report' href={`/interview-feedback?i_id=${data?.interview_details?.id}`} target="_blank" rel="noreferrer">
                      See feedback report <span className='fw-600 fs-15'>{" >"}</span>
                    </a> 
                    : ""}
                  </Box> : ''}
                </Box>

                  {/* <div class="rounds-button-group">
                    <button className="second-group" style={checkDiffDate()} disabled={flag}>
                      <a href={!flag?data?.interview_details?.meeting_link:'#'} target={!flag?'_blank':''} rel="noreferrer" style={{all:'unset'}}>
                      <img src={Join_Interview} alt="Join" />
                        Join Interview</a>
                    </button>
                    <button className="second-group" disabled={flag} style={checkDiffDate()}>
                      <a href={!flag?data?.interview_details?.extra_info?.coderpad_link:'#'} target={!flag?'_blank':''} rel="noreferrer" style={{all:'unset'}}>
                      <img src={coderpad_link} alt="CoderPad" />
                      CoderPad Link
                      </a>
                    </button>
                  </div> */}
                  
                  {page !== "History" && <>
                  { checkDiffDate() }
                  <Box 
                    className={interviewerType === INTERVIEWER_TYPE.IV_EXPERTS? "candidate-did-join" : "candidate-did-join candidate-join-not-allowed"}
                    disabled={interviewerType === INTERVIEWER_TYPE?.CLIENT_INTERNAL} 
                  >
                    <p className="notify-text" >Candidate didn't join? {interviewerType === INTERVIEWER_TYPE.IV_EXPERTS? <span className='c-pointer'
                      onClick={notifyClient}>Notify</span> : <span>Notify</span>} </p>
                    <p className="notify-text" >Click on the notify to notify us.</p>
                  </Box>

                {
                  !flag?(
                    <div>
                    <button class="cancel-interview" onClick={handleClickOpen}>Cancel Interview</button>
                  </div>
                  ):(
                    <div className='disabled_button'>
                      <p className="firstP">Interview Cancelled</p>
                      <p className="secondP"> <span>Cancellation Reason: </span> {data?.interview_details?.extra_info?.last_transfer_interview_comment} </p>
                    </div>
                  )
                }
                </>}
              </div>

              { page !== "History"  && 
              <div className = "feedback-sidebar">
                <p className="feedback-heading">Feedback</p>
                    <div className = {`${
                          (data?.interview_details?.feedback_status === InterviewFeedbackStatus.SUBMITTED) ? "tc-submitted"
                        : (data?.interview_details?.feedback_status === InterviewFeedbackStatus.APPROVED) ? "tc-approved"
                        : (data?.interview_details?.feedback_status === InterviewFeedbackStatus.DRAFT) ? "tc-draft"
                        : "tc-pending"
                    }`}>
                      <span style={{color:"black", marginRight:"5px"}}>Status: </span>
                      {
                        (data?.interview_details?.feedback_status === InterviewFeedbackStatus.SUBMITTED) ? "Not yet Approved"
                        : (data?.interview_details?.feedback_status === InterviewFeedbackStatus.CHANGES_REQUESTED) ? "Changes requested by Admin"
                        : (data?.interview_details?.feedback_status === InterviewFeedbackStatus.APPROVED) ? "Approved"
                        : (data?.interview_details?.feedback_status === InterviewFeedbackStatus.DRAFT) ? "Saved in draft"
                        : "Not yet Initiated"
                      }
                </div>
                {
                  data?.interview_details?.feedback_status &&

                  <div className='feedback-button'>
                    <a className='btn btn-outline-dark btn-block mt-4' href={`/interview-feedback?i_id=${data?.interview_details?.id}`} style={{all:'unset'}}>
                    {
                        (data?.interview_details?.feedback_status === InterviewFeedbackStatus.SUBMITTED ||
                        data?.interview_details?.feedback_status === InterviewFeedbackStatus.CHANGES_REQUESTED) ? "Edit Feedback"
                        : (data?.interview_details?.feedback_status === InterviewFeedbackStatus.APPROVED) ? "View Feedback"
                        : (data?.interview_details?.feedback_status === InterviewFeedbackStatus.DRAFT) ? "Submit Feedback"
                        : "Add Feedback"
                    }
                    </a>
                  </div>
                }
              </div>
              }
                {data?.interview_details?.extra_info?.candidate_did_not_join_message_flag && interviewerType === INTERVIEWER_TYPE.IV_EXPERTS && 
                    (data?.interview_details?.scheduled_start_timestamp * 1000 > (new Date().getTime() - (60 * 60 * 1000))) && //Button for notify feedback visible before schedule end time.
                    <Box display='flex' className='mt-4'>
                        <Typography className='text-candidate-not-joined'>Candidate didn't join?</Typography>
                        <Typography className='text-client-notified ml-2'>Already Notified</Typography>
                    </Box>
                }
                <div className ="comments-sidebar">
                    <div className = 'mb-3 h4'>Comments</div>
                    {
                            data?.application_details?.comments &&
                            data?.application_details?.comments?.length>0 &&
                            data?.application_details?.comments?.map((comment,index)=>{
                                const date = comment?.timestamp ?
                                 typeof (comment?.timestamp) === 'string' ? moment(comment?.timestamp, "DD/MM/YYYY hh:mm:ss") : moment.unix(comment?.timestamp) : "";
                                return (
                                    <>
                                        <div className='row mb-2'>
                                            <span className='small col-5'> <b>{comment?.user?.name}</b></span>
                                            {date && <text className='small col align-right'> {date?.format('lll')} </text>}
                                        </div>
                                        {date && <div className='row mb-2'>
                                            <span className='small col'>{date?.fromNow()}</span>
                                        </div>}
                                        <div className='row mb-2'>
                                        <div className='small col'>{typeof (comment?.content) === "string" ? comment?.content : JSON.stringify(comment?.content)}</div>
                                        </div>
                                        {
                                            index < data?.application_details?.comments?.length-1 &&
                                            <hr/>
                                        }
                                    </>
                                )
                            })
                    }
                    {
                      (!data?.application_details || !data?.application_details?.comments ||
                      data?.application_details?.comments?.length === 0) &&
                      <div>No comments available</div>
                    }
                </div>
                <div className = "round-history-sidebar">
                    <div className = 'mb-3 h4'>Round History</div>
                    <did className = 'w-100'>
                        {data?.all_round_details ? data?.all_round_details.map((item,index) => <div className = ''>
                            <div className='roundHistoryDesign'>
                              <div class="first_col">
                                <p className="roundHistoryText" >{item?.round_name}</p>
                                <p className="roundHistoryText" > {item?.scheduled_start_timestamp ? moment(item?.scheduled_start_timestamp*1000).format('DD/MM/YYYY') : "-"} </p>
                              </div>
                              <div class="second_col">
                              <p className="roundHistoryText small text-success txt-capitalize">
                              {item.interview_status.toLowerCase()}
                              </p>
                              { <p className="roundHistoryText">
                                {!item?.feedback_report_link && item?.feedback_status &&
                                <a className='mt-4 mr-3 redirect-link' onClick={()=>handleFeedbackStatus(item,index)}>
                                    Feedback
                                </a>}
                                {item?.feedback_report_link && <div className = 'my-3 btn-link pointer' onClick = {(e) => handleUrl(e,item?.feedback_report_link)}>Feedback</div>}
                              </p>}
                              </div>
                            </div>

                        </div>) : 'Loading...'}

                        {interviewerType === INTERVIEWER_TYPE.CLIENT_INTERNAL && data?.screenings ? 
                          data?.screenings?.map((item) => <div className = ''>
                            <div className='roundHistoryDesign'>
                              <div class="first_col">
                                <p className="roundHistoryText">QuickScreen</p>
                                <p className="roundHistoryText" > {item?.candidate_screening?.extra_info?.actual_start_timestamp ?
                                  moment(item?.candidate_screening?.extra_info?.actual_start_timestamp*1000).format('DD/MM/YYYY') : "-"} </p>
                              </div>
                              <div class="second_col">
                              <p className="roundHistoryText small text-success txt-capitalize">
                              {item?.candidate_screening?.candidate_result ? item?.candidate_screening?.candidate_result === 'REJECT' ? "Failed" : "Passed" 
                              : item?.candidate_screening?.status?.toLowerCase()}
                              </p>
                              { <p className="roundHistoryText">
                                {item?.candidate_screening?.candidate_result &&
                                <a className='mt-4 mr-3 redirect-link' onClick={()=>
                                  handlePrescreenReport(item?.screening?.client_id, item?.candidate_screening?.position_id, item?.candidate_screening?.candidate_id,
                                     item?.candidate_screening?.pre_screening_id, item?.candidate_screening?.id)
                                }>
                                  Report
                                </a>}
                              </p>}
                              </div>
                            </div>
                        </div>) : ''}
                    </did>
                </div>
            </div>

          </Modal.Body>
        </div>
      </>
    )
}

export default DetailedSchedule;
