import {
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import React,{useEffect, useState} from "react";
import moment from "moment-timezone";
import rightTick from "../../../../assets/images/right_tick.png";
import ivLogo from "../../../../assets/images/iv-logo-white.png";
import switchedTab from "../../../../assets/images/switched-tab.png";
import { PRESCREENING_RESULT, PRESCREEN_TOPIC } from "../Constant";
import { isIntQuestionType } from "../../PrescreeningListForm/Constants";
import Html from 'react-pdf-html';
import { commonDownloadStyle } from "../../Feedback/SectionsV2/Preview/Constant";
import { imageFileArrayBuffer } from '../../../../Services/file';

Font.register({
  family: "Poppins",
  src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
  fonts: [
    {
      src: `/assets/poppins-fonts/Poppins-Regular.ttf`,
    },
    {
      src: `/assets/poppins-fonts/Poppins-Thin.ttf`,
      fontWeight: "thin",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Medium.ttf`,
      fontWeight: "medium",
    },
    {
      src: `/assets/poppins-fonts/Poppins-SemiBold.ttf`,
      fontWeight: "semibold",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Italic.ttf`,
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: `/assets/poppins-fonts/Poppins-Bold.ttf`,
      fontWeight: "bold",
    },
  ],
});


Font.registerHyphenationCallback((word) => [word]);
const stylesPDF = StyleSheet.create({
  page: {
    backgroundColor: "#E9ECEE",
    padding: "32px",
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
  },
  flex_column: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    backgroundColor: "#123fbc",
    padding: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    height: "30px",
    width: "30px",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    color: "white",
    textTransform: "capitalize",
  },
  container: {
    padding: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "12px",
  },
  side_box: {
    height: "200px",
    width: "270px",
    backgroundColor: "white",
    borderRadius: "12px",
    padding: "6px 12px",
  },
  client_logo: {
    height: "60px",
    maxWidth: "240px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    objectFit: "content",
    margin: "auto",
    borderRadius: "12px",
  },
  side_profile_box: {
    height: "85%",
    width: "100%",
    borderRadius: "12px",
    backgroundColor: "#F3F5FA",
    padding: "12px 20px",
  },
  text_1: {
    fontFamily: "Poppins",
    fontWeight: "700",
    color: "#0F2359",
    fontSize: "11px",
  },
  text_2: {
    fontFamily: "Poppins",
    fontWeight: "300",
    color: "#52659B",
    fontSize: "13px",
  },
  right_side: {
    minHeight: "500px",
    width: "820px",
    backgroundColor: "#ffffff",
    boxShadow: "-4px -5px 54px -17px rgba(0, 0, 0, 0.13)",
    borderRadius: "12px",
    margin:"auto 0px auto auto"
  },
  box_padding: {
    padding: "12px",
    paddingLeft: "22px",
    paddingRight: "22px",
  },
  text_3: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    color: "#3D3D3D",
  },
  text_4: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "11px",
    color: "#5A5A5A",
  },
  text_5: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "12px",
    color: "#52659B",
    margin: "14px 0 6px 5px",
  },
  text_6: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "12px",
    color: "#3D3D3D",
    marginLeft:"8px",
  },
  snap_shot_box: {
    backgroundColor:"white",
    padding: "4px 12px",
    paddingBottom:"24px",
    borderRadius:"16px",
    marginTop:"16px"
  },
  snap_shot_img: {
    height: "165px",
    width: "240px",
    borderRadius: "12px",
    marginTop:"4px",
  },
  text_4_2: {
    color: "#A3A3A3",
  },
  box_1: {
    borderRadius: "14px",
    padding: "8px 12px",
    minWidth: "220px",
    display: "flex",
    justifyContent: "center",
  },
  box_1_green: {
    backgroundColor: "#E8F9E7",
  },
  box_1_red: {
    backgroundColor: "#FBECEC",
  },
  box_1_text_1: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "18px",
  },
  box_1_text_1_green: {
    color: "#0B8701",
  },
  box_1_text_1_red: {
    color: "#BC3B12",
  },
  box_1_text_1_1: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#4D4D4D",
  },
  box_1_text_2: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    color: "#494949",
  },
  box_2_text_1: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    color: "#3D3D3D",
  },
  box_2_text_2: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "18px",
    color: "#3D3D3D",
  },
  box_2_text_3: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    color: "#3D3D3D",
  },
  box_3: {
    borderRadius: "14px",
    padding: "3px 8px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  bg_FFD6D3: {
    backgroundColor: "#FFD6D3",
  },
  bg_E8F9E7: {
    backgroundColor: "#E8F9E7",
  },
  box_border: {
    borderBottom: "1px solid #CCD0DB",
    marginTop: "4px",
  },
  question_container_head: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    color: "#A3A3A3",
  },
  question_box: {
    padding: "4px",
    display:"flex",
    flexDirection:"row",
    maxWidth: '900px',
    paddingRight: '26px',
    alignItems: 'flex-start',
  },
  question_heading_box: {
    backgroundColor: "#F3F5FA",
    border: "1px solid #D6D9E2",
    padding: "6px 10px 6px 15px",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    flexDirection:"row",
    gap: "10px",
    color: "#3D3D3D",
    borderRadius: "4px",
  },
  question_box_main:{
    borderBottom: "1px solid #D6D9E2",
  },
  question_body_box: {
    padding: "2px 10px 6px 10px",
    fontFamily: "Poppins",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    flexDirection:"row",
    gap: "10px",
    color: "#3D3D3D",
  },
  question_box_ques: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "13px",
    color: "#3D3D3D",
    marginTop: "4px",
  },
  badge: {
    borderRadius: "6px",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "10px",
    padding: "2px 4px",
    marginTop: "2px",
    color: "white",
    display: "flex",
    alignSelf: "flex-end",
  },
  unattempt : {
    width: "88px",
    backgroundColor: "#3D3D3D",
    textAlign:"center",
  },
  correct : {
    width:"50px",
    backgroundColor:"#0B8701",
    textAlign:"center",
  },
  incorrect : {
    width:"55px",
    backgroundColor:"#CC0F0F",
    textAlign:"center",
  },
  easy: {
    color: "#47AC45",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "right",
  },
  medium: {
    color: "#C9B861",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "right",
  },
  hard: {
    color: "#BC3B12",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "right",
  },
  width_500: {
    width: "500px",
  },
  width_130: {
    width: "130px",
    paddingRight: "10px",
    textAlign: "right",
  },
  width_80: {
    width: "80px",
    textAlign: "right",
  },
  ul: {
    marginTop: "4px",
  },
  li: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    color: "#3D3D3D",
    marginLeft: "20px",
  },
  dot: {
    fontFamily: "Poppins",
    fontWeight: 800,
  },
  right_tick : {
    height:"10px",
    width:"10px",
  },
  tab_img_box : {
    height:"14px",
    width:"14px",
    marginTop:"5px",
    marginLeft:"5px",
  },
  switch_tab : {
    height:"16px",
    width:"16px",
    marginLeft: "20px",
  },
  codebox : {
    backgroundColor:"#F5F5F5",
    padding:"8px",
    borderRadius:"8px",
    fontFamily:"Poppins",
    fontSize:"12px",
    fontWeight:"400",
    wordBreak: 'break-all',
    width: '96%',
    padding:'3%'
  },
  codeboxtext : {
    wordBreak: 'break-all',
  },
  imageBox : {
    margin: "10px 15px",
  },
  imageStyle : {
    width: "400px",
  },
  integerAnswer : {
    marginTop: "5px",
    marginLeft: "15px",
    fontSize: "12px",
    fontWeight: "400",
  },
  topic_box:{
    padding: "6px 10px 2px 10px",
  },
  head_txt : {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
    color: "#123FBC",
  }
});


const PrescreenReportDownload = ({
  candidateDetails,
  clientLogo,
  positionDetails,
  position,
  roundDetails,
  candidatePrescreeningRoundDetails,
  reportQuestions,
  captureImage,
  prescreenQuestion,
  prescreenQuesImage,
}) => {
  const [updatedPrescreenQuestionData, setPrescreenQuestionData] = useState({...prescreenQuestion});
  const [questionsList, setQuestionsList] =  useState([]);
  const [cuttOff, setCuttOff] = useState(null);
  const [modifiedList, setModifiedList] = useState([]);
  const [timeAlloted, setTimeAlloted] = useState(null);
  const correctScore = candidatePrescreeningRoundDetails?.candidate_result_info?.positive_score;
  const inCorrectScore = candidatePrescreeningRoundDetails?.candidate_result_info?.negative_score;
  const totalScore =  candidatePrescreeningRoundDetails?.candidate_result_info?.total_score;
  const startTimestamp = moment(candidatePrescreeningRoundDetails?.extra_info?.actual_start_timestamp*1000);
  const endTimeStamp = moment(candidatePrescreeningRoundDetails?.extra_info?.actual_end_timestamp*1000);
  var timeDifference = moment.utc(moment(endTimeStamp).diff(moment(startTimestamp)));
  let minutes = Math.floor(timeDifference/60000);
  const testCompletionTime = timeDifference.format("mm:ss");
  const tabSwitchCount = (candidatePrescreeningRoundDetails?.extra_info?.tab_switch_count);
  useEffect(() => {

        if(questionsList?.length > 0) {
          let list = [...questionsList];
          list.map((item, index) => {
                      candidatePrescreeningRoundDetails?.candidate_response?.answers.map((data, index) => {
                          if(item?.id == data?.question_id) {
                                  item.candidate_response = data?.question_response;
                                  item.candidate_result = data?.question_result;
                                  item.marks_secured = data?.marks_secured;
                          }
                      })
                       
                  })
                  setModifiedList(list);
        }

  },[questionsList,candidatePrescreeningRoundDetails]);


  useEffect(() => {
      setQuestionsList(reportQuestions);
       setCuttOff(positionDetails?.cut_off_percentage);
       setTimeAlloted(positionDetails?.time_allotted);
  },[positionDetails])

  useEffect( () => {
    const updateHtmlText = async() => {
      if(!prescreenQuestion){
      return
    }
    const copyPrescreenQuestion = {...prescreenQuestion}
    copyPrescreenQuestion && Object.entries(copyPrescreenQuestion)?.map(([topic,questions],i)=>{
      return  questions?.map(async (data, index) => { 
        data.question_text = await updateImgSrc(data?.question_text)
      })})
      setPrescreenQuestionData(copyPrescreenQuestion)
    }
    updateHtmlText();
   },[prescreenQuestion])

  const fetchImageAsBase64 = async (imageUrl) => {
    try {
      const response = await imageFileArrayBuffer(imageUrl);
      const base64 = Buffer.from(response.data, 'binary').toString('base64');
      const mimeType = response.headers['content-type'];
      return `data:${mimeType};base64,${base64}`;
    } catch (error) {
      console.error('Error fetching or converting the image:', error);
      return '';
    }
  };

  const updateImgSrc = async (htmlString) => {
    if (!htmlString) {
        return <></>;
    }
    const imgSrcMatches = htmlString.match(/<img\s+[^>]*?src="([^"]*)"/g);
    if (!imgSrcMatches) {
        return htmlString;
    }

    let updatedString = htmlString;
    for (const imgSrcMatch of imgSrcMatches) {
        const oldSrc = imgSrcMatch.match(/src="([^"]*)"/)[0];
        const oldSrcWithoutTag = imgSrcMatch.match(/src="([^"]*)"/)[1];
        const response = await fetchImageAsBase64(oldSrcWithoutTag);
        updatedString = updatedString.replace(oldSrc, `src="${response}"`); //.replace(oldSrcWithoutTag, response) can be used as well
    }

    return updatedString;

};

   const responseStatus = {
             option1: "OPTION_ONE",
             option2: "OPTION_TWO",
             option3: "OPTION_THREE",
             option4: "OPTION_FOUR",
             option5: 'OPTION_FIVE',
             option6: 'OPTION_SIX',
             option7: 'OPTION_SEVEN',
             option8: 'OPTION_EIGHT',
             option9: 'OPTION_NINE',
             option10: 'OPTION_TEN',

   };

  const containsHTMLTags = (str)  => {
    // Regular expression to match HTML tags
    const htmlTagRegex = /<[a-z][\s\S]*>/i;
    return htmlTagRegex.test(str);
  }

   if(!updatedPrescreenQuestionData){
    return null;
   } 

  return (
    <Document>
      <Page size="A2" style={[stylesPDF.page]} wrap={false}>
        <View style={[stylesPDF.flex_row, stylesPDF.header]}>
          <View style={{ width: "34%",marginLeft:"10%" }}>
            <Image style={stylesPDF.logo} src={ivLogo} />
          </View>
          <View style={{ textAlign: "center" }}>
            <Text style={[stylesPDF.title]}>QUICKSCREEN REPORT</Text>
          </View>
        </View>
        <View style={[stylesPDF.container]}>
          <View>
          <View style={[stylesPDF.side_box]}>
           {
              clientLogo && (
                <View style={{width: "70%", margin: "auto"}}>
                  <Image src={clientLogo} style={[stylesPDF.client_logo]} />
                </View>
              )
           }
            <View style={[stylesPDF.side_profile_box, stylesPDF.flex_column]}>
              <View style={{ marginBottom: "4px" }}>
                <Text style={[stylesPDF.text_1]}>CANDIDATE NAME</Text>
                <Text style={[stylesPDF.text_2]}>{candidateDetails?.name}</Text>
              </View>
              <View style={{ marginBottom: "4px" }}>
                <Text style={[stylesPDF.text_1]}>POSITION</Text>
                <Text style={[stylesPDF.text_2]}>
                  {position?.position_name}
                </Text>
              </View>
              {roundDetails?.name && 
              <View style={{ marginBottom: "4px" }}>
                <Text style={[stylesPDF.text_1]}>ROUND</Text>
                <Text style={[stylesPDF.text_2]}>{roundDetails?.name}</Text>
              </View>
              }
            </View>
          </View>
          { captureImage?.length>0 && (
            <View style={[stylesPDF.snap_shot_box]}>
              <View style={{ marginBottom: "4px" }}>
                <Text style={[stylesPDF.text_5]}>Camera Shot</Text>
                {captureImage?.map((data,index)=>{
                  return (
                    <View
                      style={{
                        width: "100%",
                        marginTop: index === 0 ? "8px" : "24px",
                        borderBottom:
                          index !== captureImage.length - 1
                            ? "1px solid #dadada"
                            : "",
                            paddingBottom: "16px",
                      }}
                    >
                      {data?.timestamp && (
                        <Text style={stylesPDF.text_6}>
                          {moment(data?.timestamp * 1000)?.format("hh:mm A")}
                        </Text>
                      )}
                      <Image
                        style={stylesPDF.snap_shot_img}
                        src={data?.imgSrc}
                      />
                    </View>
                  );
                  })        
                }
              </View>
            </View>
          )}
          </View>
          <View style={[stylesPDF.right_side]}>
            <View style={[stylesPDF.box_padding]}>
              <Text style={stylesPDF.text_3}>Overall Stats</Text>
              <View style={[stylesPDF.flex_row,{justifyContent:"space-between"}]}>
                  <Text style={stylesPDF.text_4}>
                    Cutoff{" "}
                    <Text style={[stylesPDF.text_4_2]}>
                      for this round is set to be
                    </Text>{" "}
                    {cuttOff || "1"}% 

                    {positionDetails?.tab_switch_check?.count ?<>
                      <Text style={[stylesPDF.text_4_2]}>
                        {" "}and tab switched 
                      </Text>
                      {" <"}{positionDetails?.tab_switch_check?.count}
                    </> : ""}
                  </Text>

                {tabSwitchCount > 0 && <View style={[stylesPDF.box_3,stylesPDF.bg_FFD6D3,stylesPDF.flex_row]}>
                  <Image src={switchedTab} style={[stylesPDF.right_tick]}/> &&
                  <Text style={stylesPDF.text_4}> Switched Tabs: <span style={{ fontWeight: "600" }}>{tabSwitchCount} times</span></Text>
                </View>}

                {tabSwitchCount === 0 && <View style={[stylesPDF.box_3, stylesPDF.bg_E8F9E7]}>
                      <Text style={stylesPDF.text_4}> Switched Tabs: <span style={{ fontWeight: "600" }}>{tabSwitchCount} times</span></Text>
                  </View>}
              </View>
            </View>

            <View
              style={[
                stylesPDF.flex_row,
                stylesPDF.box_padding,
                { paddingTop: "0px" },
              ]}
            >
              <View style={candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.PROCEED ? 
                [stylesPDF.box_1, { marginRight: "12px" }, stylesPDF.box_1_green] : [stylesPDF.box_1, { marginRight: "12px" }, stylesPDF.box_1_red]}>
                <View style={[stylesPDF.flex_row]}>
                  <Text style={candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.PROCEED ? 
                    [stylesPDF.box_1_text_1, stylesPDF.box_1_text_1_green] : [stylesPDF.box_1_text_1, stylesPDF.box_1_text_1_red]}>
                    {candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.PROCEED ? "PASSED" : "FAILED"}{" "}
                    ({Math?.trunc(candidatePrescreeningRoundDetails?.candidate_result_info?.percentage)}%)
                  </Text>
                  {candidatePrescreeningRoundDetails?.candidate_result_info?.result === PRESCREENING_RESULT.REJECT && 
                    candidatePrescreeningRoundDetails?.candidate_result_info?.percentage > cuttOff &&
                    tabSwitchCount > positionDetails?.tab_switch_check?.count ?
                    <Image src={switchedTab} style={[stylesPDF.tab_img_box]} />
                  : "" }
                </View>
               { totalScore !== null && totalScore !==undefined && 
                <Text style={[stylesPDF.box_1_text_2]}>
                  Points Scored:
                  <Text style={{ fontWeight: "600" }}>
                    {(correctScore === 0 && inCorrectScore === 0) ? 
                    <>
                          (<Text style={[stylesPDF.box_1_text_2, stylesPDF.box_1_text_1_red]}>0</Text>)
                    </>
                    :
                          <>
                                  ({ correctScore !== 0 && <Text style={[stylesPDF.box_1_text_2, stylesPDF.box_1_text_1_green]}>{"+"}{ correctScore}</Text>}{" "} 
                                  {positionDetails?.negative_marking && inCorrectScore !== 0 && <Text style={[stylesPDF.box_1_text_2, stylesPDF.box_1_text_1_red]}>{inCorrectScore}</Text>})
                          </>
                    } 
                  </Text>{" "}
                  out of{" "}
                  <Text style={{ fontWeight: "600" }}>
                    {totalScore}
                  </Text>{" "}points.
                </Text>
              }
              </View> 
              <View
                style={[
                  stylesPDF.box_1,
                  { backgroundColor: "#F3F5FA", marginRight: "12px" },
                ]}
              >
                <Text style={[stylesPDF.box_2_text_1]}>Test start time</Text>
                <Text style={[stylesPDF.box_2_text_2]}>{startTimestamp.format("hh:mm A")}</Text>
                <Text style={[stylesPDF.box_2_text_3]}>{startTimestamp.format("MMM DD, YYYY")}</Text>
              </View>
              <View
                style={[
                  stylesPDF.box_1,
                  { backgroundColor: "#E2EBFF", marginRight: "12px" },
                ]}
              >
                <Text style={[stylesPDF.box_2_text_1]}>
                  Test completion time
                </Text>
                <Text style={[stylesPDF.box_2_text_2]}>{testCompletionTime}&nbsp;{minutes == 0 ? "sec" : "min"}</Text>
                <Text style={[stylesPDF.box_2_text_3]}>
                  Total Test time:{" "}
                  <Text style={{ fontWeight: "600" }}>{timeAlloted} mins</Text>
                </Text>
              </View>
            </View>
            <View style={stylesPDF.box_border}></View>
            <View style={[stylesPDF.question_container, stylesPDF.box_padding]}>
              <View style={[stylesPDF.question_heading_box]}>
                <Text style={[stylesPDF.width_500]}>Questions</Text>
                <Text style={[stylesPDF.width_130]}>Response</Text>
                <Text style={[stylesPDF.width_80]}>Difficulty Level</Text>
                <Text style={[stylesPDF.width_80]}>Points Earned</Text>
              </View>
              {
                updatedPrescreenQuestionData && Object.entries(updatedPrescreenQuestionData)?.map(([topic,questions],i)=>{
                return  questions?.map((data, index) => { 
                  return (
                    <View style={[index === questions?.length-1 && stylesPDF.question_box_main,{borderLeft:"1px solid #D6D9E2",borderRight:"1px solid #D6D9E2"}]}>
                      <View style={[stylesPDF.topic_box]}>
                          {
                            index === 0 && 
                          <Text style={[stylesPDF.head_txt]}>
                            {topic === PRESCREEN_TOPIC.OTHERS && "Others"}
                            {topic !== PRESCREEN_TOPIC.OTHERS && `${topic}`}
                            </Text>
                          }
                        </View>
                    <View style={[stylesPDF.question_body_box]}>
                      <View style={[stylesPDF.question_box, stylesPDF.width_500]}>
                        <View>
                          <Text style={[stylesPDF.question_box_ques]}>Q{index+1}.&nbsp;</Text>
                        </View>
                        <View>

                        {/* Parsing HTML content (like: react quill) to react pdf renderer */}
                        <View style={{marginTop: containsHTMLTags(data?.question_text) ? '-8px' : '4px'}}>
                        <Html style={{fontSize:"12px"}}>
                          {data?.question_text+commonDownloadStyle}
                        </Html>
                        </View>

                          {
                            data?.question_code && data?.question_code?.length > 0 && (
                            <View style={[stylesPDF.codebox]}>
                              <Text style={[stylesPDF.codeboxtext]}>
                                    {data?.question_code}
                              </Text>
                            </View>
                            )
                          }
                          {
                            data?.question_image && (
                              <View style={[stylesPDF.imageBox]}>
                                <Image src={prescreenQuesImage?.[topic]?.[index]} style={[stylesPDF.imageStyle]}/>
                              </View>
                            )
                          }
                        {isIntQuestionType(data?.type) ?
                        <View style={[stylesPDF.integerAnswer]}>
                          <Text>
                            {data?.candidate_response ? `Candidate Answer = ${data?.candidate_response?.[0]}` : ""}
                          </Text>
                        </View>
                        :
                        <View style={[stylesPDF.ul]}>
                          {data?.options_json?.option1 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option1}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option1)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option2 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option2}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option2)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option3 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option3}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option3)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                         {data?.options_json?.option4 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option4}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option4)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option5 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option5}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option5)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option6 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option6}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option6)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option7 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option7}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option7)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option8 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option8}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option8)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option9 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option9}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option9)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                          {data?.options_json?.option10 && <Text style={[stylesPDF.li]}>
                            <Text style={[stylesPDF.dot]}>&#8226;</Text>
                            &nbsp; {data?.options_json?.option10}
                            &nbsp;{(data?.candidate_response?.includes(responseStatus?.option10)) &&
                            (<Image src={rightTick} style={[stylesPDF.right_tick]}/>)
                            }
                          </Text>}
                        </View>
                        }
                        </View> 
                      </View> 
                      <View style={[stylesPDF.width_130]}>
                      {data.candidate_response == null ? 
                          <Text wrap={false} style={[stylesPDF.badge,stylesPDF.unattempt]}>Not Attempted</Text> : 
                          data.candidate_result === PRESCREENING_RESULT.INCORRECT ? 
                          <Text wrap={false} style={[stylesPDF.badge,stylesPDF.incorrect]}>incorrect</Text> : 
                          <Text wrap={false} style={[stylesPDF.badge,stylesPDF.correct]}>correct</Text>
                          }
                      </View>
                      <View style={[stylesPDF.width_80]}>
                        <Text style={[data?.difficulty_level?.toUpperCase()=="EASY" ? stylesPDF.easy: data?.difficulty_level?.toUpperCase()=="MEDIUM" ? stylesPDF.medium : stylesPDF.hard]}>
                          {data?.difficulty_level?.toUpperCase()=="EASY" ? "Easy" : data?.difficulty_level?.toUpperCase()=="MEDIUM" ? "Medium" : "Hard"}
                        </Text>
                      </View>
                      <View style={[stylesPDF.width_80]}>
                        <Text style={[stylesPDF.question_box_ques]}>{data?.marks_secured}</Text>
                      </View>
                      </View>
                      </View>
                  )
                })
              })
              }

            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default PrescreenReportDownload;
