import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Modal } from '../../../atoms/Modal/Modal';
import "./RejectGuideModal.scss";

const RejectGuideModal = ({open, onClose, handleGuideApproval, setGuideRejectionReason, disable})  => {

    let textfieldText = 'Please add the reason for rejection:'

    const guideRejectionBody = () => {
          return (
            <Box className='guideRejectionBody'>
                    <Typography className='reasonText'>{textfieldText}</Typography>
                    <TextField 
                        multiline                 
                        placeholder={textfieldText}
                        autoComplete="off"
                        className='inputTextField' 
                        id="outlined-secondary"
                        variant="outlined"
                        sx={{ 
                            "& legend": { display: "none" } 
                        }}                   
                        onChange={(e) => setGuideRejectionReason(e?.target?.value) }
                    />
                    
                    <Box className='modalBtnBox'>
                        <Button className='commonBtn cancelBtn' onClick={onClose}>Cancel</Button>
                        <Button className={`commonBtn rejectBtn  ${disable && 'disableBtn'}`}onClick={() => handleGuideApproval('reject')}>Reject</Button>
                    </Box>
             
            </Box>
          )
    }

  return (
    <Box>
       <Modal 
         open={open}
         onClose={onClose}
         title={<Box className='rejectGuideTitle'>Reject Interview Guide</Box>}
         body={guideRejectionBody()}
         height="fit-content"
         width="661px"
        />
    </Box>
  )
}

export default RejectGuideModal;