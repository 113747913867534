import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import checkmark from "./Images/check-mark.png";
import close from "./Images/close.png";

const stylesAssessmentTable = StyleSheet.create({
  InterviewAssessmentHeading: {
    width: 310,
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 17,
    letterSpacing: 0.005,
    color: "#123FBC",
  },

  InterviewAssessmentQuestion: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
  },

  InterviewAssessmentQuestionList: {
    //   text decor...
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: 0.005,
    color: "#3D3D3D",
    width: 620,
  },

  InterviewAssessmentCommentBox: {
    marginTop: -5,
  },

  InterviewAssessmentCommentText: {
    marginLeft: 20,
    width: 609,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#464646",
  },

  InterviewAssessmentImg: {
    width: 20,
    height: 20,
  },

  InterviewAssessmentImgCross: {
    width: 15,
    height: 15,
  },

  commentsHeading: {
    width: 232,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.005,
    color: "#848484",
    marginLeft: 20,
    textDecoration: "underline",
  },

  answer: {
    width: 35,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0.025,
    color: "#3D3D3D",
    marginTop: -18,
  },
});

const InterviewProcessAssessment = ({ data }) => {
  const nonNaData =
    data?.audit_report?.interview_assessment &&
    Object.values(data?.audit_report?.interview_assessment).filter(
      (item) => item.answer !== "NA"
    );

  return (
    <View
      style={[
        {
          border: "1 solid #cdcdcd",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          paddingBottom: 50,
          width: 800,
        },
      ]}
    >
      {/* heading */}
      <View>
        <Text style={[stylesAssessmentTable.InterviewAssessmentHeading]}>
          Interview Process Assessment
        </Text>
      </View>

      {/* map box */}
      <View>
        {data?.audit_report?.interview_assessment &&
          Object.values(nonNaData).map((item, index) => {
            return (
              // (question checkboc yes no) and comments parent container
              <View>
                {/* table box */}
                <View
                  style={[
                    stylesAssessmentTable.InterviewAssessmentQuestion,
                    {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    },
                  ]}
                >
                  {/* indide table box question text box */}
                  <View
                    style={[
                      stylesAssessmentTable.InterviewAssessmentQuestionList,
                      { display: "flex", flexDirection: "row" },
                    ]}
                  >
                    <Text> {index + 1}.</Text>
                    <Text> {item?.question}</Text>
                    {/* question text box ends here */}
                  </View>

                  {/* checkmark and yes no parent box */}
                  <View
                    style={[
                      {
                        display: "flex",
                        flexDirection: "row",
                        // position: "absolute",
                        // marginLeft: 700,
                        marginLeft: 70,
                      },
                    ]}
                  >
                    {/* inside table inside checkmark and yes no parent  box - checkmark box */}
                    <View style={[{ height: 30, width: 30 }]}>
                      {item?.expected_answer && (
                        <Image
                          src={
                            item.expected_answer === item?.answer
                              ? checkmark
                              : close
                          }
                          style={[
                            item.expected_answer === item?.answer
                              ? stylesAssessmentTable.InterviewAssessmentImg
                              : stylesAssessmentTable.InterviewAssessmentImgCross,
                          ]}
                        />
                      )}

                      {!item?.expected_answer && (
                        <Image
                          src={
                            (item?.comments && item?.answer === "Yes") ||
                            item?.answer === "No"
                              ? close
                              : checkmark
                          }
                          style={[
                            (item?.comments && item?.answer === "Yes") ||
                            item?.answer === "No"
                              ? stylesAssessmentTable.InterviewAssessmentImgCross
                              : stylesAssessmentTable.InterviewAssessmentImg,
                          ]}
                        />
                      )}

                      {/* inside table inside checkmark and yes no parent  box - checkmark box  ends here*/}
                    </View>

                    {/*inside table box and inside - checkmark yes no parent box - yes no box */}

                    <View style={[stylesAssessmentTable.answer]}>
                      <Text style={[{ marginLeft: 30 }]}> {item.answer}</Text>

                      {/*inside table box and inside - checkmark yes no parent box - yes no box  ends here*/}
                    </View>
                  </View>

                  {/* table box ends here */}
                </View>

                {/* comment box  parent container*/}

                <View
                  style={[stylesAssessmentTable.InterviewAssessmentCommentBox]}
                >
                  {item?.comments && (
                    <View>
                      <Text style={[stylesAssessmentTable.commentsHeading]}>
                        {" "}
                        Comments
                      </Text>
                      <Text
                        style={[
                          stylesAssessmentTable.InterviewAssessmentCommentText,
                        ]}
                      >
                        {" "}
                        {item.comments}
                      </Text>
                    </View>
                  )}

                  {/* commentbox ends here */}
                </View>

                {/* (question checkbox yes no) and comments parent container */}
              </View>
            );
          })}

        {/* map box ends */}
      </View>

      {/* super parent Interview Proces assessment ends here */}
    </View>
  );
};

export default InterviewProcessAssessment;
